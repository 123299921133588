import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useHistory } from 'react-router-dom';
import { MARS } from '../../colors/colors'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function RecipeReviewCard(props) {


  const { POID, name, description, quantity, value, nextStep } =
    props;
  let history = useHistory();
  const routeChange = () => {
    let path = `/EditInventory`;
    history.push({
      pathname: path,
      state: [POID, name, description, quantity]
    });
  }
  let state = [POID, name, description, quantity, value]
  return (
    <div>
      <Card sx={{
        p: '5px 5px 5px 5px', transition: "0.3s",
        width: 'auto',
        margin: '5px 0px 10px 0px',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
      }}
        onClick={() => nextStep(state)}>

        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <div>
              {/* <Typography align="left" variant="subtitle2">
            <b>Product ID: </b> {POID}
          </Typography> */}
              <Typography align="left" variant="subtitle2">
                <b>Product Name: </b> {name}
              </Typography>
              <Typography align="left" variant="subtitle2">
                <b>Quantity: </b> {quantity}
              </Typography>
            </div>
            <div >
              <ModeEditOutlineOutlinedIcon sx={{ color: MARS }} />
            </div>
          </div>
        </CardContent>

      </Card>
    </div>
  );
}
