import React, { Component } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const minimumRequirements = [
  {
    POID: 1,
    value: "Minimum purchase amount",
  },
  {
    POID: 2,
    value: "Minimum quantity of items",
  },
];
class MinimumRequirements extends Component {
  onHandleBack = () => {
    this.props.setStep(1);
  };
  render() {
    const { quantityOrAmount, amount, quantity } = this.props.values;
    return (
      <div>
        <div>
          <PageTitle
            textType="h6"
            value="Minimum Requirement"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.onHandleBack}
          />
        </div>
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="8px">
          {/* <InputLabel id="Input label">Applies To</InputLabel> */}
          <Select
            labelId="Input label"
            id="Select"
            name="quantityOrAmount"
            value={quantityOrAmount}
            // defaultValue={values.status}
            onChange={this.props.handleChange}
            size="medium"
            variant="outlined"
            fullWidth
          >
            {minimumRequirements.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {quantityOrAmount === "Minimum purchase amount" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="amount"
              label="Amount"
              value={amount}
              //defaultValue={discountPercentage}
              onChange={this.props.handleChange}
              variant="standard"
              placeholder="PKR0"
              size="large"
              fullWidth
              type="number"
            />
          </div>
        )}
        {quantityOrAmount === "Minimum quantity of items" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="quantity"
              label="Quantity"
              value={quantity}
              //defaultValue={discountPercentage}
              onChange={this.props.handleChange}
              variant="standard"
              placeholder="0"
              size="large"
              fullWidth
              type="number"
            />
          </div>
        )}
      </div>
    );
  }
}

export default MinimumRequirements;
