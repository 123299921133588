import React, { Component } from "react";
import helpers from "../../UtilitiesFunctions";
import GlobalConfig from "../../Config";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import CustomProducts from "../ProductsModule/CustomProducts";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Link, Redirect } from "react-router-dom";
import Store from "../../index";
import { render } from "@testing-library/react";
import { connect } from "react-redux";
import { InputLabel } from "@material-ui/core";
import { ThermalPrinterViewRefund } from "../print/thermalPrinterViewRefund";
import { DotMatrixViewRefund } from "../print/dotMatrixPrinterViewRefund";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});
class Refund extends Component {
  constructor(props) {
    super(props);
    console.log("PROPS:", props);
    if (this.props.location.state == undefined) {
      // this.props.history.push("/ViewOrders");
      this.state = {
        redirect: true,
        redirectUrl: "/ViewOrders",
      };
    } else {
      this.state = {
        printReciept: false,
        lineItems: this.props.location.state.order.lineItems.filter(
          (element) => !element?.returned
        ),
        order: this.props.location.state.order,
        returnLineItems: [],
        loader: false,
        open: true,
        totalRefundQuantity: 0,
        discountAdjustment: 0,
        refundableAmount: 0,
        refundedItems: this.props.location.state.order.lineItems.filter(
          (element) => element?.returned
        ),
      };
      console.log(this.state);
    }
  }

  caluclateDiscountAdjustment = () => {
    var adjustment = 0;
    var totalAmount =
      helpers.returnRefundTotal(this.state.returnLineItems) +
      helpers.returnTax(this.state.returnLineItems);
    var percentageOfReturn = (
      (this.state.order.discountAmount / this.state.order.total) *
      100
    ).toFixed(2);
    percentageOfReturn = parseFloat(percentageOfReturn);
    adjustment = (percentageOfReturn / 100) * totalAmount;
    console.log(
      "here is adjustment inside function ",
      adjustment,
      this.state.order.discountAmount,
      this.state.order.total,
      percentageOfReturn,
      this.state.order.payableAmount,
      this.state.order.discountAmount
    );
    this.setState({
      discountAdjustment: adjustment,
      refundableAmount: totalAmount - adjustment,
    });
  };

  handleSubmit = () => {
    let returnedProduct = [];
    this.setState(function (currentState) {
      return { loader: true };
    });
    var createOrder = null;
    if (this.state.lineItems.length > 0) {
      createOrder = {
        orderId: this.state.order.orderId,
        lineItems: this.state.returnLineItems,
      };
      //  console.log(createOrder);
    } else {
      console.log("no orders..");
    }
    // //console.log(createOrder);
    let stringifyString;
    if (createOrder != null) {
      stringifyString = JSON.stringify(createOrder);
    }
    //console.log(stringifyString);

    // this.setState({ printReciept: true });
    // return;
    fetch(
      this.props.global_config.basePath +
        `/orders/returns/${this.state.order.orderId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
        body: stringifyString,
      }
    )
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          this.props.global_config.basePath +
            `/orders/${this.state.order.orderId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then((data2) => {
            // Store.dispatch({
            //     type: "RETURN_ORDER",
            //     orders: data2,
            // });
            returnedProduct = data2[0].lineItems.filter(
              (product) => product.returned === true
            );
            this.updateProductQuantity(
              [returnedProduct[0]?.productId],
              [returnedProduct[0]?.quantity]
            );
          });

        this.setState(function (currentState) {
          return { loader: false };
        });
        helpers.toastNotify("success", "Refund Successful!");
        this.setState({ printReciept: true });
      })
      .catch((errorr) => {
        console.log(errorr);
        this.setState(function (currentState) {
          return { loader: false };
        });
        helpers.toastNotify("error", "Something Went Wrong!");
      });
  };

  onPrintFinish = () => {
    this.setState({ printReciept: false });
    setTimeout(() => {
      this.props.history.push({
        pathname: "/ViewOrders",
      });
    }, 200);
  };

  updateProductQuantity = (productIds, productQuantity) => {
    fetch(this.props.global_config.basePath + "/inventory", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.map((v) => {
          v.value = 0;
          v.selected = null;
        });
        Store.dispatch({
          type: "GET_INVENTORY_ITEMS",
          inventoryItems: data,
        });
        Store.dispatch({
          type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_ON_ORDER",
          id: productIds,
          quantity: productQuantity,
          add: true,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  updateQty = (qty, name) => {
    console.log("update quantity", qty);
    if (qty.quantity === isNaN) {
      helpers.deleteItem(this, qty, "deleteItem - Refund");
    } else {
      helpers.addItemList(this, qty, "addItem - Refund");
    }
    var newQuantity = 0;
    for (var lineItem of this.state.returnLineItems) {
      newQuantity += lineItem?.quantity;
    }
    this.setState({ totalRefundQuantity: newQuantity }, () => {
      this.caluclateDiscountAdjustment();
    });
  };

  render() {
    const { classes } = this.props;
    const { redirect: r, redirectUrl: rU } = this.state;

    if (r) {
      return <Redirect to={rU} />;
    }
    return (
      <div className={classes.root}>
        {this.state.loader ? (
          <>
            <Dialog
              open={this.state.open}
              onClose={this.handleCloseDi}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <CircularProgress />
              </DialogContent>
            </Dialog>
          </>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.header}>
              <Box className={classes.box} p={1} width="100%">
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <ArrowBackIcon
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Box p={0.5} flexGrow={1} fontSize="20px">
                      Refund
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <div style={{ backgroundColor: "white", marginTop: "10px" }}>
                <h6>
                  {this.props.location.state?.order?.fulfilled
                    ? " Fulfilled"
                    : " Unfulfilled"}
                  ({this.state.lineItems.length})
                </h6>
                <div
                  style={{
                    display: "block",
                    //width: "90%",
                    //backgroundColor: "red",
                  }}
                >
                  <Box>
                    <CustomProducts
                      products={this.state.lineItems}
                      isDelete={false}
                      disableQty={false}
                      getQty={this.updateQty}
                      isRefund={true}
                      isEdit={false}
                      refundedItems={this.state.refundedItems}
                    />
                  </Box>
                </div>
              </div>
            </Paper>

            {this.state.refundedItems.length > 0 && (
              <Paper className={classes.paper} style={{ marginTop: "10px" }}>
                <div style={{ backgroundColor: "white", marginTop: "10px" }}>
                  <h6>
                    {"Returned"}({this.state.refundedItems.length})
                  </h6>
                  <div
                    style={{
                      display: "block",
                    }}
                  >
                    <Box>
                      <CustomProducts
                        products={this.state.refundedItems}
                        isDelete={false}
                        disableQty={true}
                        // getQty={this.updateQty}
                        isRefund={false}
                        isEdit={false}
                      />
                    </Box>
                  </div>
                </div>
              </Paper>
            )}

            <Paper className={classes.papertm}>
              <h6>Reason for refund</h6>
              <OutlinedInput
                name="reason"
                fullWidth={true}
                style={{ height: 35 }}
              />
              <p>Only you and other staff can see this reason.</p>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <h5>Order Summary</h5>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                {this.state.order ? (
                  <>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Discount Amount
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs {this.state.order.discountAmount.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Total Tax
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          {Math.round(this.state.order.taxAmount)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Total Amount
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          {Math.round(this.state.order.total)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Paid Amount
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs {this.state.order.payableAmount.toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </>
                ) : (
                  <>
                    <p>No items selected.</p>
                  </>
                )}
              </p>
              <hr />
              <h5>Refund Summary</h5>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                {this.state.returnLineItems.length > 0 ? (
                  <>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Items subtotal
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs{" "}
                          {helpers
                            .returnRefundTotal(this.state.returnLineItems)
                            .toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Items
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          {this.state.returnLineItems.length}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Quantity
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          {this.state.totalRefundQuantity}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>Tax</td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs{" "}
                          {helpers
                            .returnTax(this.state.returnLineItems)
                            .toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Shipping
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs 0.00
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Total Amount
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs{" "}
                          {(
                            helpers.returnRefundTotal(
                              this.state.returnLineItems
                            ) + helpers.returnTax(this.state.returnLineItems)
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          Discount Adjustment
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          Rs {this.state.discountAdjustment.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%", textAlign: "left" }}>
                          <b>Refundable Amount</b>
                        </td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          <b>Rs {this.state.refundableAmount.toFixed(2)}</b>
                        </td>
                      </tr>
                    </table>
                  </>
                ) : (
                  <>
                    <p>No items selected.</p>
                  </>
                )}
              </p>

              <Button
                style={{
                  backgroundColor: "#339966",
                  textTransform: "none",
                  color: "white",
                }}
                variant="contained"
                fullWidth
                disabled={!this.state.returnLineItems.length > 0}
                onClick={this.handleSubmit}
              >
                Refund Rs. {this.state.refundableAmount.toFixed(2)}
              </Button>

              <Box></Box>
            </Paper>
          </Grid>
        </Grid>

        {this.state.printReciept &&
          this.state.returnLineItems &&
          (this?.props?.location?.state?.receipt_metadata?.printerPaperWidth ===
            "80mm" ||
            !this?.props?.location?.state?.receipt_metadata
              ?.printerPaperWidth) && (
            <ThermalPrinterViewRefund
              orderObject={this.state.order}
              refundObject={{
                lineItems: this.state.returnLineItems,
                discountAdjustment: this.state.discountAdjustment,
                refundableAmount: this.state.refundableAmount,
                subTotalAmount: helpers.returnRefundTotal(
                  this.state.returnLineItems
                ),
                taxAmount: helpers.returnTax(this.state.returnLineItems),
                totalAmount:
                  helpers.returnRefundTotal(this.state.returnLineItems) +
                  helpers.returnTax(this.state.returnLineItems),
              }}
              customerObject={this.props.location.state.customer}
              receiptMetadata={this.props.location.state.receipt_metadata}
              onFinish={this.onPrintFinish}
            />
          )}

        {this.state.printReciept &&
          this.state.returnLineItems &&
          this?.props?.location?.state?.receipt_metadata?.printerPaperWidth ===
            "76mm" && (
            <DotMatrixViewRefund
              orderObject={this.state.order}
              refundObject={{
                lineItems: this.state.returnLineItems,
                discountAdjustment: this.state.discountAdjustment,
                refundableAmount: this.state.refundableAmount,
                subTotalAmount: helpers.returnRefundTotal(
                  this.state.returnLineItems
                ),
                taxAmount: helpers.returnTax(this.state.returnLineItems),
                totalAmount:
                  helpers.returnRefundTotal(this.state.returnLineItems) +
                  helpers.returnTax(this.state.returnLineItems),
              }}
              customerObject={this.props.location.state.customer}
              receiptMetadata={this.props.location.state.receipt_metadata}
              onFinish={this.onPrintFinish}
            />
          )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(useStyles)(Refund));
