import React from "react";

const PrintSlip = (props) => {
  return (
    <div className="printSlip">
      <div className="container-fluid" style={{ padding: "0" }}>
        <div className="orderPreview">
          <div className="header">
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-6 leftt align-self-center">
                <img
                  src="http://worker01vm1:3000/static/media/logo.b6c05877.png"
                  style={{ width: "55px" }}
                />
              </div>
              <div
                className="col-6 rightt align-self-center"
                style={{ textAlign: "right" }}
              >
                {/* Order #2892 */}
                Order #{props.orderData.orderId}
                <br />
                {/* March 8, 2022 */}
                {props.orderData.createdAt.substring(0, 10)}
              </div>
            </div>
            <hr />
            <div
              className="row"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div className="col-6" style={{ textAlign: "left" }}>
                <b>SHIP TO</b>
                <p>C-27, Block 14, Gulistan E Jauhar</p>
              </div>
              <div className="col-6" style={{ textAlign: "left" }}>
                <b>BILL TO</b>
                <p>C-27, Block 14, Gulistan E Jauhar</p>
              </div>
            </div>
          </div>
          <hr />
          <div
            className="productss"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div className="row">
              <div className="col-6" style={{ textAlign: "left" }}>
                <b>ITEMS</b>
              </div>
              <div className="col-6" style={{ textAlign: "right" }}>
                <b>Quantity</b>
              </div>
            </div>
            {props.orderData.lineItems.map((p, { index }) => {
              console.log(p);
              console.log(index);
              return (
                <div
                  className="row"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <div className="col-6" style={{ textAlign: "left" }}>
                    {p.productName}
                  </div>
                  <div className="col-6" style={{ textAlign: "right" }}>
                    {p.quantity}
                  </div>
                </div>
              );
            })}
          </div>

          <hr />
          <div
            className="footer"
            style={{ marginTop: "20px", textAlign: "center" }}
          >
            <div className="row">
              <div className="col-12">
                <p>Thank you for shopping with us!</p>
                <b>HULMSOLUTIONS-POS</b>
                <br />
                C-27, Gulistan E Jauhar, Karachi 75300, Pakistan
                <br />
                support@hulmsolutions.com
                <br />
                https://www.hulmsolutions.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintSlip;
