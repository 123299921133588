// import "../App.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DiscountModal from "./DiscountModal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import GlobalConfig from "../../Config";
import LabelIcon from "@material-ui/icons/Label";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE } from "../../colors/colors";

const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  button: {
    "&.active": {
      backgroundColor: "rgb(16, 174,72)",
      color: "white",
      disabled: "true",
    },
  },

  discountheader: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
      padding: "10px",
    },
  },

  managediscount: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    alignItems: "center",
    height: "50vh",
    [theme.breakpoints.only("xs")]: {
      padding: "10px",
    },
  },
});

class AutomatedDiscount extends Component {
  state = {
    backdrop: false,
    promotions: [],
    loading: true,
    query: "",
    previousPromotions: [],
    expireBtn: "black",
    activeBtn: "primary",
  };

  componentDidMount() {
    if (this.props.g_automatic_discounts.length == 0) {
      let url =
        this.props.global_config.basePath +
        `/promotions?key=applicationMode&value=Automatic`;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            promotions: data.filter(
              (item) => item.applicationMode === "Automatic"
            ),
            loading: false,
            previousPromotions: data.filter(
              (item) => item.applicationMode === "Automatic"
            ),
          });
          // localStorage.setItem("auto_Discounts", JSON.stringify(data));
          Store.dispatch({
            type: "GET_AUTOMATIC_DISCOUNTS",
            automated_discounts: data.filter(
              (item) => item.applicationMode === "Automatic"
            ),
          });
        })
        .catch((err) => {
          this.setState({
            // promotions: local_autoDiscounts,
            loading: false,
            mode: "offline",
          });
        });
    } else {
      this.setState({
        promotions: this.props.g_automatic_discounts.filter(
          (item) => item.applicationMode === "Automatic"
        ),
        loading: false,
        mode: "online",
      });
    }
  }

  handleSearchByDiscountName = () => {
    this.setState({ backdrop: true });
    fetch(
      this.props.global_config.basePath +
        `/promotions/search?key=name&value=${this.state.query}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData, "after search query");
        this.setState({
          backdrop: false,
          promotions: responseData,
        });
      });
  };

  cancelSearch = () => {
    this.setState({ query: "", promotions: this.state.previousPromotions });
  };
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  handleChange = (e) => {
    console.log("in handle change");
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        promotions: this.props.g_automatic_discounts,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_automatic_discounts,
        e.target.value
      );
      this.setState({
        backdrop: false,
        promotions: filteredList,
      });
    }
  };
  setColorAutomaticDiscount = () => {
    return this.props.location.pathname === "/AutomatedDiscount"
      ? "primary"
      : "default";
  };
  getExpiredPromotions = () => {
    let newPromotions = this.props.g_discounts.filter(
      (promotion) =>
        promotion.status === "Archived" &&
        promotion.applicationMode === "Automatic"
    );
    this.setState({
      promotions: newPromotions.length === 0 ? [] : newPromotions,
      activeBtn: "black",
      expireBtn: "primary",
    });
  };
  ActivePromotions = () => {
    let newPromotions = this.props.g_automatic_discounts.filter(
      (promotion) =>
        promotion.status === "Active" &&
        promotion.applicationMode === "Automatic"
    );
    this.setState({
      promotions: newPromotions,
      activeBtn: "primary",
      expireBtn: "black",
    });
  };

  render() {
    const { classes } = this.props;
    const { promotions, loading, previousPromotions, query } = this.state;
    console.log(this.state.promotions);
    const { pathname } = this.props.location;
    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 100,
      },
      {
        field: "name",
        headerName: "Discount Title",
        width: 220,
      },
      { field: "type", headerName: "Type", width: 250 },

      {
        field: "applicationMode",
        headerName: "Application Mode",

        width: 200,
      },
      {
        field: "status",
        headerName: "status",

        width: 200,
      },
    ];

    function CustomToolbar() {
      return (
        <>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <GridToolbarContainer
              style={{
                backgroundColor: "#EFEFEF",
                border: "1px solid black",
                margin: "20px",
                width: "100px",
                height: "50px",
              }}
            >
              <GridToolbarExport />
            </GridToolbarContainer>
          </div>
        </>
      );
    }
    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} size={90} color="inherit" />
        </Backdrop>

        <div
          className={classes.discountheader}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <h5>Discounts</h5>
          <DiscountModal />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "30px",
            flexDirection: "row",
          }}
        >
          <Button component={Link} to="/Discount">
            Discounted Code
          </Button>
          <Button
            component={Link}
            to="/AutomatedDiscount"
            color={this.setColorAutomaticDiscount()}
          >
            Automatic Discounts
          </Button>
        </div>

        {this.props.g_discounts.length === 0 &&
        !loading &&
        previousPromotions.length === 0 ? (
          <>
            <div className={classes.managediscount}>
              <img
                src={"../assets/automaticdiscount.svg"}
                alt="discount_image"
                width="200px"
              />
              <h5>Manage discounts and promotions</h5>
              <p>
                Create discount codes and automatic discounts that apply at
                checkout. You can also combine discounts with
                <Link> compare at prices.</Link>
              </p>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/DiscountautomatedForm"
              >
                Create Discount Code
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              height: "100%",
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "flex-start",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                <TextField
                  defaultValue={query}
                  name="query"
                  onChange={this.handleChange}
                  style={{ width: "400px", marginRight: "20px" }}
                  id="outlined-basic"
                  label="Search discount"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ marginRight: "20px" }}>
                        {/* <IconButton> */}
                        <SearchTwoToneIcon />

                        {/* </IconButton> */}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        {this.state.query === "" ? null : (
                          <div
                            style={{ marginRight: "10px" }}
                            variant="outlined"
                            color={SKY_BLUE}
                            onClick={() => this.cancelSearch()}
                          >
                            <CancelIcon style={{ color: "red" }} />
                          </div>
                        )}
                        <Button
                          style={{
                            borderColor: SKY_BLUE,
                            marginRight: "4px",
                          }}
                          variant="outlined"
                          onClick={() => this.handleSearchByDiscountName()}
                        >
                          <LabelIcon style={{ color: SKY_BLUE }} />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
                <Button
                  onClick={() => this.ActivePromotions()}
                  color={this.state.activeBtn}
                >
                  Active
                </Button>
                <Button
                  onClick={() => this.getExpiredPromotions()}
                  color={this.state.expireBtn}
                >
                  Expired
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ margin: "20px 0 10px 20px" }}>
                <span>
                  {promotions.length ? (
                    <b>Showing {promotions.length} Discounts </b>
                  ) : null}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              ></div>
            </div>

            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                autoHeight={true}
                justify={"center"}
                rowHeight={85}
                headerHeight={65}
                sortModel={[
                  {
                    field: "id",
                    sort: "desc",
                  },
                ]}
                // {...this.state.columns}
                components={{
                  Toolbar: CustomToolbar,
                }}
                loading={loading}
                rows={promotions}
                columns={columns}
                onRowClick={(info) => {
                  this.props.history.push({
                    pathname: `/editdiscount/${info.row.id}`,
                    state: promotions[info.rowIndex],
                  });
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    g_discounts: state.discountState.discounts,
    g_automatic_discounts: state.discountState.automatic_discounts,
    global_config: state.globalState,
  };
};

export default connect(mapStateToProps)(withStyles(style)(AutomatedDiscount));
