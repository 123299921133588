import React, { Component } from "react";
import CustomDiscountProduct from "./CustomDiscountProduct";

class CustomDisocuntProducts extends Component {
  render() {
    console.log("Custom Discount Products", this.props);
    return (
      <div>
        {this.props.products?.map((product, index) => (
          <CustomDiscountProduct
            key={index}
            product={product}
            isDelete={this.props.onDelete}
         
          />
        ))}
      </div>
    );
  }
}

export default CustomDisocuntProducts;
