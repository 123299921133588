import React, { Component } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import TextField from "@material-ui/core/TextField";
import TextField from "@mui/material/TextField";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormControlLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import Box from "@material-ui/core/Box";
import Popup from "../Popup";
import ImageUrlModal from "./ImageUrlModal";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import GlobalConfig from "../../Config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { cartesianProduct } from "cartesian-product-multiple-arrays";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ClearIcon from "@material-ui/icons/Clear";
import AddProduct from "../../MobileComponents/ProductMobileComponents/AddProduct";
import Slide from "@material-ui/core/Slide";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Store from "../../../src/index";
import Loader from "../../MobileComponents/Loader";
import SnackBar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { SKY_BLUE, WHITE } from "../../colors/colors";
import { uploadImage } from "./ImageController";
// import Paper from "@material-ui/core/Paper";
import SimpleReactValidator from "simple-react-validator";
import { PathRef } from "slate";
import helpers from "../../UtilitiesFunctions";
import { checkCategoryAvailibility } from "../../utilities/CategoryLogic";
import { Center } from "devextreme-react/map";
import Delete from "@material-ui/icons/Delete";
import { AddPhotoAlternate } from "@material-ui/icons";
// import { checkCategoryAvailibility } from "./CategoryLogic";
const statuses = [
  {
    POID: 1,
    value: "Active",
  },
  {
    POID: 2,
    value: "Draft",
  },
];

const sampleVarient = {
  price: null,
  startDate: "2024-01-11",
  endDate: "2024-01-11",
  taxRate: null,
  barcodes: [
    {
      code: null,
      unitOfMeasureRefId: "1",
    },
  ],
  status: "Active",
  images: [],
  quantity: null,
  costPrice: null,
  productSpecCharacteristics: [],
  unitsOfMeasures: [
    {
      id: 1,
      isDefault: true,
      conversionFactor: 1,
      name: "Nos",
      relation: "includes",
      price: null,
    },
  ],
};
const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },
  formContainer: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "55%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      // width: "55%",
    },
  },

  formItems: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
  },
  formInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  formList: {
    width: "85%",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formRightBox: {
    width: "40%",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textDanger: {
    color: "red",
  },

  arrowroute: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
      marginBottom: "20px",
      marginLeft: "10px",
    },
  },

  pricing: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.only("lg")]: {
      display: "flex",

      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  pricingfixwd: {
    [theme.breakpoints.only("xs")]: {
      width: "300px",
    },
    [theme.breakpoints.only("lg")]: {
      width: "200px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("md")]: {
      width: "200px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "200px",
      marginLeft: "10px",
    },
  },

  variantresponsive: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "55vw",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
  },

  variantshow: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "55vw",
    overflowX: "scroll",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
  },

  variantgenerationd: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
    maxWidth: "55vw",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100vw",
    },
  },
  addproductsave: {
    marginRight: "10px",
    [theme.breakpoints.only("xs")]: {
      marginRight: "30px",
      marginBottom: "10px",
      padding: "20px",
    },
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

const initialFormState = {
  nameError: "",
  priceError: "",
  quantityError: "",
  costPriceError: "",
  categoryError: "",
  descriptionError: "",
  mediaError: "",
};

const status = [
  { code: "Active", label: "Active" },
  { code: "Draft", label: "Draft" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checked: false,
      backdrop: false,
      inputList: [
        { name: "", prodSpecCharValues: [{ isDefault: true, value: "black" }] },
      ],
      snackBar: false,
      snackBarMessage: "",
      snackBarType: "",
      loader: false,
      description: "",
      name: "",
      price: null,
      url: null,
      taxRate: 0,
      allAttributeVariant: [],
      startDate: "2021-01-14",
      endDate: "2021-01-14",
      Category_Id: "12",
      algorithm: "RuleEngine.groovy",
      status: "Active",
      images: [],
      quantity: null,
      barcodes: [
        {
          code: null,
          unitOfMeasureRefId: "1",
        },
      ],
      users: [{ name: "", value: "" }], //""used for dynamic fields
      arr: [],
      productSpecCharacteristics: [],
      categoryName: "",
      variantPrice: "",
      categoryDescription: "",
      obj_parent: {},
      numberOfVarients: [],
      show: false,
      submit: false,
      snackbar: false,
      errorSnackbar: false,
      categories: [],
      variants: [],
      categoryId: null,
      costPrice: null,
      nameError: null,
      priceError: null,
      quantityError: null,
      costPriceError: null,
      categoryError: null,
      descriptionError: null,
      CategorySnackbar: false,
      mediaError: null,
      files: [],
      categoryModal: false,
      variantModal: false,
      imagePickerModal: false,
      attributes: {
        attributeName: "",
        attributeValues: [{ value: "" }],
      }, // Initial empty object with an array of value objects
      countries: [],
      allVariants: [],
      updateIndex: null,
      variantImages: [],
      selectedIndexForImages: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleSubmit_DF = this.handleSubmit_DF.bind(this);
    this.addVar = this.addVar.bind(this);
    this.validator = new SimpleReactValidator({
      validators: {
        positiveNumber: {
          // name the rule
          message: "The :attribute must be valid",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(
                val,
                /^[+]?([0-9]+(?:[\.][1-9]*)?|\.[1-9]+)$/i
              ) && params.indexOf(val) === -1
            );
          },
          required: true, // optional
        },
      },
    });
  }
  //create a validate function
  validate = () => {
    if (this.state.name === null) {
      this.setState({ nameError: "Name is required" });
    }
    if (this.state.price === null) {
      this.setState({ priceError: "Price is required" });
    }
    if (this.state.quantity === null) {
      this.setState({ quantityError: "Quantity is required" });
    }
    if (this.state.costPrice === null) {
      this.setState({ costPriceError: "Cost Price is required" });
    }
    if (this.state.categoryId === null) {
      this.setState({ categoryError: "Category is required" });
    }
    if (this.state.description === null) {
      this.setState({ descriptionError: "Description is required" });
    }
    if (this.state.images.length === 0) {
      this.setState({ mediaError: "Media is required" });
    }
    if (
      this.state.nameError === null &&
      this.state.priceError === null &&
      this.state.quantityError === null &&
      this.state.costPriceError === null &&
      this.state.categoryError === null &&
      this.state.descriptionError === null
      // && this.state.mediaError === null
    ) {
      return false;
    } else {
      return true;
    }
  };
  printCategotyId = () => {
    console.log("ca" + this.state.categoryId);
  };
  removeImage = (index) => {};
  removeImage = (image, index) => {
    this.setState({
      images: this.state.images.filter((index) => image !== index),
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };

  updateAllAttributeValues = (value, key, index) => {
    const deepCopyOfAllAttributeVariant = JSON.parse(
      JSON.stringify(this.state.allAttributeVariant)
    );
    if (key === "price") {
      deepCopyOfAllAttributeVariant[index].unitsOfMeasures[0][key] = value;
      deepCopyOfAllAttributeVariant[index][key] = value;
    } else if (key === "barcode") {
      deepCopyOfAllAttributeVariant[index].barcodes[0].code = value;
    } else {
      deepCopyOfAllAttributeVariant[index][key] = value;
    }
    this.setState({
      allAttributeVariant: deepCopyOfAllAttributeVariant,
    });
  };

  removeImageFromVariant = (image, index) => {
    const deepCopyOfAllAttributeVariant = this.state.allAttributeVariant;
    deepCopyOfAllAttributeVariant[this.state.selectedIndexForImages].images =
      deepCopyOfAllAttributeVariant[
        this.state.selectedIndexForImages
      ].images.filter((index) => image !== index);
    this.setState({
      allAttributeVariant: deepCopyOfAllAttributeVariant,
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  componentDidMount() {
    let url = this.props.global_config.basePath + "/categories";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          loading: false,
          categories: data,
        })
      )
      .catch(() => {
        this.setState({
          snackBar: false,
          snackBarMessage: "Error! unable to get Categories",
          snackBarType: "error",
        });
      });
  }

  componentDidUpdate() {
    console.log("title is: ", this.state.name);
  }

  addVar = (name, array) => {
    var productSpecCharValues = [];
    var obj_parent = {};

    for (var i = 0; i < array.length; i++) {
      var obj_child = {};
      obj_child.value = array[i];
      productSpecCharValues.push(obj_child);
    }

    obj_parent.name = name;
    obj_parent.productSpecCharValues = productSpecCharValues;

    this.setState(function (currentState) {
      return {
        productSpecCharacteristics: [
          ...currentState.productSpecCharacteristics,
          obj_parent,
        ],
      };
    });
  };
  setValues = (input) => (e) => {
    console.log("key is: ", input);
    console.log("value is: ", e.target.value);
    this.setState({ [input]: e.target.value });
  };
  setSpecificValues = (key, value) => {
    console.log("key is: ", key);
    console.log("value is: ", value);
    this.setState({ [key]: value });
  };

  handleChange_DF = (i, e) => {
    const { name, value } = e.target;
    var array = "";
    if (name === "value") {
      array = value.split(",");
      //console.log("if");
    } else {
      array = value;
      //console.log("else");
    }
    let users = [...this.state.users];
    users[i] = { ...users[i], [name]: array };
    this.setState({ users });
    //console.log(this.state.users[i]);
  };

  addClick_DF() {
    this.setState((prevState) => ({
      users: [...prevState.users, { name: "", value: "" }],
    }));
  }

  removeClick_DF(i) {
    let users = [...this.state.users];
    users.splice(i, 1);
    this.setState({ users });
  }

  handleSubmit_DF = (e) => {
    this.setState({ submit: true });
    console.log("submit_DF");

    for (var i = 0; i < this.state.users.length; i++) {
      this.addVar(this.state.users[i].name, this.state.users[i].value, i);
    }
    console.log("productSpecCharacteristics: ");
    console.log(this.state.productSpecCharacteristics);
    console.log(this.state.obj_parent);
    e.preventDefault();
  };
  value = (value) => {
    const val = value.split(":");
    return val[1];
  };
  superList = () => {
    this.setState({ show: true });
    let superlist = [];
    for (var j = 0; j < this.state.productSpecCharacteristics.length; j++) {
      let values = [];
      for (
        var k = 0;
        k <
        this.state.productSpecCharacteristics[j].productSpecCharValues.length;
        k++
      ) {
        values.push(
          this.state.productSpecCharacteristics[j].name +
            ":" +
            this.state.productSpecCharacteristics[j].productSpecCharValues[k]
              .value
        );
      }
      superlist.push(values);
    }
    console.log("this is super list", superlist);
    console.log("this is varients", cartesianProduct(...superlist));
    let varients = cartesianProduct(...superlist);
    console.log("varients", varients);
    this.setState({ numberOfVarients: varients });
  };
  createUI_DF() {
    return this.state.users.map((el, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          // width: "70%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          placeholder="Attribute"
          name="name"
          value={el.name || ""}
          onChange={this.handleChange_DF.bind(this, i)}
        />
        <TextField
          size="small"
          variant="outlined"
          placeholder="Value"
          name="value"
          value={el.value || ""}
          onChange={this.handleChange_DF.bind(this, i)}
        />
        <Button
          color="secondary"
          value="remove"
          size="medium"
          variant="contained"
          onClick={this.removeClick_DF.bind(this, i)}
        >
          Remove
        </Button>
      </div>
    ));
  }

  handleChangeBarcode = (event) => {
    this.setState({
      barcodes: [
        {
          code: event.target.value,
          unitOfMeasureRefId: "1",
        },
      ],
      //checked: !this.state.checked,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      //checked: !this.state.checked,
    });
  };
  handleChangeCheckBox = (event) => {
    console.log("in handle change check box");
    this.setState({
      checked: !this.state.checked,
    });
  };

  handleChangeVariant = (attrIndex, valueIndex, fieldName, value) => {
    const updatedAttributes = this.state.attributes;
    updatedAttributes.attributeValues[valueIndex][fieldName] = value;
    if (
      valueIndex === updatedAttributes.attributeValues.length - 1 &&
      value !== ""
    ) {
      updatedAttributes.attributeValues.push({ value: "" });
    }

    this.setState({ attributes: updatedAttributes });
  };

  openCategoryModal = () => {
    this.setState({ categoryModal: true });
  };
  closeCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  openVariantModal = (updateIndex = null) => {
    this.setState({ variantModal: true, updateIndex: updateIndex });
  };
  closeVariantModal = () => {
    this.setState({ variantModal: false, updateIndex: null });
  };

  openImageModal = (selectedIndex = null) => {
    this.setState({ selectedIndexForImages: selectedIndex });
    this.setState({ imagePickerModal: true });
  };
  closeImageModal = () => {
    this.setState({ imagePickerModal: false, updateIndex: null });
  };

  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: Number(event.target.value) });
  };
  onHandleBack = (props) => {
    this.props.history.push("/Products");
  };

  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  generateCombinations = (variants, index = 0, currentCombination = {}) => {
    const combinations = [];

    if (index == variants.length) {
      const sampleCombination = JSON.parse(JSON.stringify(sampleVarient));
      sampleCombination.productSpecCharacteristics =
        Object.values(currentCombination);
      combinations.push(JSON.parse(JSON.stringify(sampleCombination)));
    } else {
      const attribute = variants[index];

      attribute.attributeValues.forEach((value) => {
        const newCombination = { ...currentCombination };
        newCombination[attribute.attributeName] = {
          name: attribute.attributeName,
          productSpecCharValues: { value: value.value },
        };

        const subCombinations = this.generateCombinations(
          variants,
          index + 1,
          newCombination
        );

        combinations.push(...subCombinations);
      });
    }

    return combinations;
  };

  submitVariant = () => {
    console.log(
      "here is every",
      this.state.attributes.attributeValues.every(
        (item) => item["value"].trim() === ""
      )
    );
    if (
      this.state.attributes.attributeName.trim() === "" ||
      this.state.attributes.attributeValues.every(
        (item) => item["value"].trim() === ""
      )
    ) {
      helpers.toastNotify("error", "All attribute values cannot be empty");
      return;
    }

    if (
      this.state.attributes.attributeValues.some(
        (item, index, arr) =>
          item["value"].trim() !== "" &&
          arr.findIndex((i) => i["value"] === item["value"]) !== index
      )
    ) {
      helpers.toastNotify(
        "error",
        "Attribute Can not contain duplicate values"
      );
      return;
    }

    if (this.state.updateIndex == null) {
      if (
        this.state.allVariants.filter(
          (v) => v.attributeName == this.state.attributes.attributeName
        ).length > 0
      ) {
        helpers.toastNotify(
          "error",
          'Attribute Name  "' +
            this.state.attributes.attributeName +
            '" already exist.'
        );
        return;
      }
      this.setState(
        (prevState) => ({
          allVariants: [...prevState.allVariants, prevState.attributes],
          attributes: { attributeName: "", attributeValues: [{ value: "" }] }, // Reset to initial empty state
        }),
        this.prepareCombinations
      );
    } else {
      var localVarient = this.state.allVariants;
      localVarient[this.state.updateIndex] = this.state.attributes;
      this.setState(
        {
          allVariants: localVarient,
          attributes: { attributeName: "", attributeValues: [{ value: "" }] },
          updateIndex: null,
        },
        this.prepareCombinations
      );
    }

    this.setState({ variantModal: false });

    console.log("here is all variant outside callback", this.state.allVariants);
  };

  prepareCombinations = () => {
    const clearAttribute = [];
    this.state.allVariants.map((variant) => {
      var deepCopy = JSON.parse(JSON.stringify(variant));
      deepCopy.attributeValues = deepCopy.attributeValues?.filter(
        (attributeVal) => attributeVal.value !== ""
      );
      clearAttribute.push(deepCopy);
    });

    console.log("here is clear attribute", clearAttribute);
    var combvar = this.generateCombinations(clearAttribute);
    this.setState(
      {
        allAttributeVariant: combvar,
      },
      () => {
        console.log("here is all variants combinations", combvar);
      }
    );
  };

  submitCategory = () => {
    if (this.state.categoryName == "" || this.state.categoryDescription == "") {
      helpers.toastNotify("error", "Fields are empty!");
      return;
    }
    this.setState({ backdrop: true, categoryModal: false });

    const data = {
      name: this.state.categoryName,
      description: this.state.categoryDescription,
    };

    console.log("data", data);

    fetch(this.props.global_config.basePath + "/categories", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(data),
    })
      .then((response) => {
        this.setState({ backdrop: false, CategorySnackbar: false });
        helpers.toastNotify("success", "Category Successfully Added!");

        fetch(this.props.global_config.basePath + "/categories", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              categories: data,
              linearSpinner: false,
            });
            Store.dispatch({ type: "GET_CATEGORIES", categories: data });
          })
          .catch((e) => {
            console.log(e);
            helpers.toastNotify("error", "Something went wrong!");
          });
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Failed To Create Category. ");
      });
  };

  handleSubmit = (event) => {
    console.log("in handle submit");
    event.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ ...this.state, initialFormState });

      if (
        this.state.costPrice.length <= 0 ||
        this.state.name.length <= 0 ||
        this.state.description.length <= 0 ||
        this.state.description.length <= 0 ||
        this.state.price === null
      ) {
        return;
      } else {
        this.setState({ loader: true, backdrop: true });
        if (this.state.allAttributeVariant.length > 0) {
          var error = false;

          this.state.allAttributeVariant?.map((variantinputs) => {
            if (
              variantinputs.price === null ||
              variantinputs.taxRate === null ||
              variantinputs.costPrice === null ||
              variantinputs.quantity === null ||
              variantinputs.barcodes[0].code === null ||
              variantinputs.price === "" ||
              variantinputs.taxRate === "" ||
              variantinputs.costPrice === "" ||
              variantinputs.quantity === "" ||
              variantinputs.barcodes[0].code === ""
            ) {
              error = true;
            }
          });

          if (error) {
            console.log("varient info", this.state.allAttributeVariant);
            helpers.toastNotify(
              "error",
              "All fields of varients must be filled"
            );
            this.setState({ loader: false, backdrop: false });
            return;
          }
          const data = {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            algorithm: this.state.algorithm,
            taxRate: this.state.taxRate,
            status: this.state.status,
            category_Id: this.state.category_Id,
            barcodes:
              this.state.barcodes[0].code === "" ? null : this.state.barcodes,
            images: this.state.images,
            varients: this.state.allAttributeVariant,
            quantity: parseInt(this.state.quantity),
            costPrice: Number(this.state.costPrice),
            productSpecCharacteristics: this.state.productSpecCharacteristics,
            unitsOfMeasures: [
              {
                id: 1,
                isDefault: true,
                conversionFactor: 1,
                name: "Nos",
                relation: "includes",
                price: this.state.price,
              },
            ],
            categoryId: this.state.categoryId,
          };
          console.log("here is varint json", JSON.stringify(data));
          let url = this.props.global_config.basePath + "/products/varients";

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
            // convert the React state to JSON and send it as the POST body
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((resp) => {
              let url = this.props.global_config.basePath + "/products/";

              fetch(url + resp.POID, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer my-token",
                },
              })
                .then((response2) => response2.json())
                .then((data2) => {
                  console.log(data2);

                  Store.dispatch({
                    type: "POST_PRODUCTS",
                    products: data2[0],
                  });
                  helpers.toastNotify(
                    "success",
                    "Product Successfully Created!"
                  );
                })
                .catch((e) => {
                  console.log(e);
                  helpers.toastNotify("error", "Unable To Create Product!");
                });
              this.setState({
                backdrop: false,
                name: "",
                description: "",
                price: "",
                inventory: "",
                images: [],
                snackbar: false,
              });
              this.redirectToProducts();
            });
        } else {
          const data = {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            algorithm: this.state.algorithm,
            taxRate: this.state.taxRate,
            status: this.state.status,
            // barcodes: && 0,
            barcodes:
              this.state.barcodes[0].code === "" ? null : this.state.barcodes,
            images: this.state.images,
            quantity: parseInt(this.state.quantity),
            categoryId: this.state.categoryId,
            costPrice: Number(this.state.costPrice),
            productSpecCharacteristics: [],
            unitsOfMeasures: [
              {
                id: 1,
                isDefault: true,
                conversionFactor: 1,
                name: "Nos",
                relation: "null",
                price: this.state.price,
              },
            ],
          };
          console.log("product to be created: ", JSON.stringify(data));
          let url = this.props.global_config.basePath + "/products";

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((resp) => {
              let url = this.props.global_config.basePath + "/products/";

              fetch(url + resp.POID, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer my-token",
                },
              })
                .then((response2) => response2.json())
                .then((data2) => {
                  Store.dispatch({
                    type: "POST_PRODUCTS",
                    products: data2[0],
                  });

                  helpers.toastNotify(
                    "success",
                    "Product Successfully Created!"
                  );
                  this.redirectToProducts();
                  this.setState({
                    backdrop: false,
                    name: "",
                    description: "",
                    price: "",
                    inventory: "",
                    snackbar: false,
                    images: [],
                  });
                })
                .catch((e) => {
                  helpers.toastNotify("error", "Product Creation Failed.");
                  this.setState({
                    backdrop: false,
                    name: "",
                    description: "",
                    price: "",
                    inventory: "",
                    snackbar: false,
                    images: [],
                  });
                });
            })
            .catch((err) => {
              this.setState({ backdrop: false, errorSnackbar: false });
              helpers.toastNotify("error", "Error Occured!");
            });
        }
      }
    } else {
      this.validator.showMessages();
      helpers.toastNotify("error", "Mandatory Field Missing");

      this.forceUpdate();
    }
  };

  redirectToProducts = () => {
    const { history } = this.props;
    setTimeout(() => {
      history.push("/Products");
    }, 3000);
  };
  updateParent = (data) => {
    const obj = {
      imageURL: data.image,
    };
    this.setState({ images: [...this.state.images, obj] });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    console.log("list", list);
    list[index][name] = value;
    list[0].prodSpecCharValues.push({ value: value });
    console.log("list", list);
    this.setState({ inputList: list });
    console.log("list", list);
  };
  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const list = this.state.inputList;
    list.splice(1, 1);
    this.setState({ inputList: list });
  };

  handleAddClick = () => {
    const obj = [
      {
        variantName: "aa",
        variantValue: [{ value: "black" }, { value: "red" }],
      },
    ];
    console.log("clicked");
    if (this.state.inputList.length < 3) {
      console.log(this.inputList);
      this.setState({ inputList: [...this.state.inputList, obj] });
    } else {
      console.log("can not add");
    }
  };

  handleClick = () => {
    this.setState(true);
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState(false);
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false, errorSnackbar: false });
  };

  handleImages = (images) => {
    const { files } = this.state;
    console.log("uploaded images are: ", images);
    const addedImages = images.filter(
      (image) => !files.find((file) => image === file)
    );
    console.log("Added iamges are: ", addedImages);

    this.postImages(addedImages);
    this.setState({ files: images });
  };

  handleImagesUploading = async (images) => {
    return new Promise(async (resolve, reject) => {
      const { files } = this.state;

      const addedImages = images.filter(
        (image) => !files.find((file) => image === file)
      );

      this.postImagesToS3(addedImages)
        .then((uploads) => {
          resolve(uploads);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleAddImage = (image) => {
    console.log("added image is: ", image);
  };
  handleDeleteImage = (deletedImage) => {
    console.log("deleted image is: ", deletedImage);
    console.log("gobal config is: ", GlobalConfig.temp);
    this.deleteImageFromArray(deletedImage);
  };
  deleteImageFromArray = (imageToDelete) => {
    const { images } = this.state;
    images.forEach((image, index) => {
      let myArray = image.imageURL.split("/");
      if (imageToDelete.name === myArray[myArray.length - 1]) {
        console.log("Removing index: ", index, " image: ", image);
        images.splice(index, 1);
      }
    });
    console.log("now images in state are ", images);
  };

  handleChangeCat = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  postImagesToS3 = (images) => {
    return new Promise(async (resolve, reject) => {
      let path = "";
      let uploadedFiles = [];
      await images.forEach(async (image) => {
        console.log("Image", image);
        let formData = new FormData();
        formData.append("file", image);
        let urll = `https://s3.hulmsolutions.com/upload`;
        await this.setState({ loader: true, backdrop: true });
        fetch(urll, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then(async (data) => {
            console.log("here is data of imgaes", data);
            console.log("here is data of dataa of images", data.data);

            await data.files.map(async (i) => {
              await uploadedFiles.push({
                imageURL: i.location,
              });
            });
            await this.setState({ loader: false, backdrop: false });
            resolve(uploadedFiles);
          })
          .catch(async (error) => {
            console.error(error);
            await this.setState({ loader: false, backdrop: false });
            reject(error);
          });
      });
    });
  };

  postImages = async (images) => {
    let path = "";
    await images.forEach(async (image) => {
      console.log("Image", image);
      let formData = new FormData();
      formData.append("file", image);
      let urll = `https://s3.hulmsolutions.com/upload`;
      await this.setState({ loader: true, backdrop: true });
      fetch(urll, {
        method: "POST",

        body: formData,
      })
        .then((response) => response.json())
        .then(async (data) => {
          let imagess = [];

          await data.files.map(async (i) => {
            await imagess.push({
              imageURL: i.location,
            });
          });
          this.setState({ images: imagess });
          await this.setState({ loader: false, backdrop: false });
        })
        .catch(async (error) => {
          console.error(error);
          await this.setState({ loader: false, backdrop: false });
        });
    });
  };

  setImageInState = (image) => {
    const obj = {
      imageURL: image.filePath,
    };
    this.setState({ images: [...this.state.images, obj] });
  };

  render() {
    const { classes, history } = this.props;
    const {
      status,
      name,
      description,
      snackBar,
      snackBarMessage,
      snackBarType,
    } = this.state;
    const values = { status, name, description };

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} color="inherit" />
        </Backdrop>

        <h5 className={classes.arrowroute}>
          <ArrowBackIosIcon
            style={{ cursor: "pointer" }}
            onClick={this.onHandleBack}
          />
          Product
        </h5>

        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.errorSnackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="error"
                color="error"
                variant="filled"
                onClose={this.closeSnack}
              >
                Error! Request Failed
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="success"
                color="success"
                variant="filled"
                onClose={this.closeSnack}
              >
                Product Created
                <div>
                  <Link to="/Products" style={{ color: "white" }}>
                    clik here to see products
                  </Link>
                </div>
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        <form>
          <div className={classes.formContainer}>
            <div className={classes.formList}>
              <div className={classes.formItems}>
                <TextField
                  required={true}
                  name="name"
                  style={{ marginTop: "10px" }}
                  label="Title"
                  size="small"
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth={true}
                  InputProps={{ classes: { input: classes.inputHeight } }}
                />

                {this.validator.message("title", this.state.name, "required", {
                  className: classes.textDanger,
                })}
                <div style={{ marginTop: "20px" }}>
                  <b>Description</b>
                </div>

                <div style={{ marginTop: "5px" }}>
                  <ReactQuill
                    required={true}
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    edit
                    name="description"
                  />
                </div>
                {this.validator.message(
                  "description",
                  this.state.description,
                  "required",
                  {
                    className: classes.textDanger,
                  }
                )}
              </div>
              <div className={classes.formItems}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Media
                  </div>

                  <div>
                    <Box p={1}>
                      <Popup
                        title="Add Media Url"
                        btnText="Add Media Url"
                        btnFloat="right"
                        btnColor="darkgreen"
                        name="image"
                        contentTxt={<hr />}
                        contentTxtBe={<hr />}
                        content={
                          <ImageUrlModal
                            updateParent={this.updateParent}
                            onClickBtnClose={() =>
                              this.setState({ modal: false })
                            }
                          />
                        }
                        openPopup={this.state.modal} //open
                        onClickBtnOpen={() => this.setState({ modal: true })} //on click open
                        onClickBtnClose={() => this.setState({ modal: false })} //on click close
                      />
                    </Box>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    display: "flex",
                    height: "300px",
                  }}
                >
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    onChange={this.handleImages.bind(this)}
                    //onAdd={this.handleAddImage}
                    onDelete={this.handleDeleteImage}
                  />
                </div>
                {this.state.images.map((image, index) => (
                  <>
                    <img
                      key={index}
                      src={image.imageURL}
                      alt=""
                      style={{
                        // width: "100px",
                        height: "100px",
                        margin: "20px 0 0 25px",
                      }}
                    />
                    <ClearIcon
                      onClick={() => this.removeImage(image, index)}
                      style={{
                        color: "red",
                        marginTop: "-75px",
                        cursor: "pointer",
                      }}
                    />
                  </>
                ))}
              </div>

              <div className={classes.formItems}>
                <p style={{ fontWeight: "bold", fontSize: "16px" }}>Pricing</p>
                <div
                  className={classes.pricing}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.pricingfixwd}>
                    <TextField
                      required={true}
                      label="Selling Price"
                      type="number"
                      defaultValue={this.state.price}
                      size="small"
                      name="price"
                      onChange={this.onPriceChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        classes: { input: classes.inputHeight },
                        inputProps: { min: 1 },
                      }}
                    />
                    {this.validator.message(
                      "Price",
                      this.state.price,
                      "required|currency",
                      {
                        className: classes.textDanger,
                      }
                    )}
                  </div>
                  <div className={classes.pricingfixwd}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      type="number"
                      label="Buying Price"
                      size="small"
                      required
                      name="costPrice"
                      onChange={this.handleChange}
                      value={this.state.costPrice}
                      InputProps={{
                        classes: { input: classes.inputHeight },
                        inputProps: { min: 1 },
                      }}
                    />
                    {this.validator.message(
                      "Cost Price",
                      this.state.costPrice,
                      "required|currency",
                      {
                        className: classes.textDanger,
                      }
                    )}
                  </div>

                  <div className={classes.pricingfixwd}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      label="Tax Rate %"
                      size="small"
                      type="number"
                      min={0}
                      name="taxRate"
                      onChange={this.handleChange}
                      value={this.state.taxRate}
                      InputProps={{
                        classes: { input: classes.inputHeight },
                        inputProps: { min: 0 },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.formItems}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Quantity
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <TextField
                      value={this.state.quantity}
                      label="Availble Quantity"
                      name="quantity"
                      size="small"
                      required
                      onChange={this.handleChange}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputHeight },
                        inputProps: { min: 1 },
                      }}
                      type="number"
                    />
                    {this.validator.message(
                      "quantity",
                      this.state.quantity,
                      "required|numeric|min:0,num",
                      { className: classes.textDanger }
                    )}
                  </div>
                </div>
              </div>

              <div className={classes.formItems}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Barcode
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <TextField
                      value={this.state.barcodes[0].code}
                      label="Enter Barcode"
                      name="barcode"
                      size="small"
                      onChange={this.handleChangeBarcode}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className={classes.formItems}>
                <div className={classes.variantresponsive} style={{}}>
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Variants
                  </p>
                  <Button
                    onClick={() => this.openVariantModal()}
                    style={{ backgroundColor: SKY_BLUE, color: WHITE }}
                    variant="contained"
                    color="default"
                    size="medium"
                  >
                    Add Variant
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                ></div>
                <div className={classes.variantshow} style={{}}>
                  {this.state.allVariants &&
                    this.state.allVariants?.map((productVar, productindex) => {
                      return (
                        <>
                          <div
                            key={productindex}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                {productVar?.attributeName}
                              </span>
                            </div>
                            {productVar?.attributeValues?.map(
                              (attrVal, valindex) => {
                                if (attrVal.value == "") return;

                                return (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "#D3D3D3",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      borderRadius: "30px",
                                    }}
                                  >
                                    {attrVal?.value}
                                  </div>
                                );
                              }
                            )}
                            <div style={{ display: "flex" }}>
                              <Button
                                style={{
                                  backgroundColor: "#93C572",
                                  color: WHITE,
                                  outline: "none",
                                }}
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={() => {
                                  this.setState({
                                    attributes: productVar,
                                  });
                                  this.openVariantModal(productindex);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  var list = this.state.allVariants.filter(
                                    (singlevarient) =>
                                      singlevarient.attributeName !=
                                      productVar.attributeName
                                  );
                                  this.setState(
                                    { allVariants: list },
                                    this.prepareCombinations
                                  );
                                }}
                                style={{
                                  marginLeft: "5px",
                                  backgroundColor: "#d11a2a",
                                  color: WHITE,
                                  outline: "none",
                                }}
                                type="submit"
                                variant="contained"
                                color="default"
                                size="small"
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                {this.state.checked && (
                  <>
                    {this.createUI_DF()}
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="contained"
                      onClick={this.addClick_DF.bind(this)}
                    >
                      Add more
                    </Button>
                    <Button
                      variant="contained"
                      style={{ margin: "10px" }}
                      onClick={this.handleSubmit_DF}
                    >
                      Submit
                    </Button>
                    {this.state.submit ? (
                      <Button
                        style={{ margin: "10px" }}
                        onClick={() => this.superList()}
                        color="primary"
                        variant="contained"
                      >
                        Show
                      </Button>
                    ) : null}
                  </>
                )}
                {this.state.productSpecCharacteristics.length > 0 &&
                this.state.show ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableCell
                          style={{
                            color: "blue",
                            fontWeight: "bold",
                          }}
                        >
                          S.No
                        </TableCell>
                        {this.state.productSpecCharacteristics.map(
                          (characteristics) => (
                            <TableCell
                              style={{
                                color: "blue",
                                fontWeight: "bold",
                              }}
                            >
                              {characteristics.name}
                            </TableCell>
                          )
                        )}
                      </TableHead>
                      <TableBody>
                        {this.state.numberOfVarients.map(
                          (characteristicsValue, index) => (
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                {index + 1}
                              </TableCell>
                              {characteristicsValue.map((value) => (
                                <TableCell>{this.value(value)}</TableCell>
                              ))}
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </div>
              {this.state?.allAttributeVariant?.length > 0 && (
                <div className={classes.variantgenerationd}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      maxWidth: "55vw",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        maxWidth: "55vw",
                      }}
                    >
                      Generated Variant Combinations
                    </div>
                  </div>
                  <div style={{ maxWidth: "55vw", overflowX: "scroll" }}>
                    <table>
                      {this.state?.allAttributeVariant &&
                        this.state?.allAttributeVariant?.map(
                          (variantValue, index) => {
                            console.log(
                              "here is all variant inside map" + variantValue
                            );
                            return (
                              <tr
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <td style={{ width: "auto", padding: "5px" }}>
                                  <span>{index + 1}.</span>
                                </td>
                                <td
                                  style={{
                                    width: "auto",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{ display: "flex" }}
                                    onClick={() => this.openImageModal(index)}
                                  >
                                    <span>
                                      <AddPhotoAlternate />
                                    </span>
                                    {variantValue?.images?.length > 0 && (
                                      <span>
                                        {" (" +
                                          variantValue?.images?.length +
                                          ")"}
                                      </span>
                                    )}
                                  </span>
                                </td>
                                <td style={{ padding: "5px" }}>
                                  {variantValue?.productSpecCharacteristics.map(
                                    (variantval, index) => {
                                      console.log(
                                        "here is all variant value inside second map",
                                        variantval.productSpecCharValues?.value
                                      );

                                      {
                                        return (
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            <span>{variantval?.name}-</span>
                                            <span>
                                              {
                                                variantval
                                                  ?.productSpecCharValues.value
                                              }

                                              {variantValue
                                                ?.productSpecCharacteristics
                                                ?.length -
                                                1 !=
                                                index && ", "}
                                            </span>
                                          </span>
                                        );
                                      }
                                    }
                                  )}
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <TextField
                                      variant="outlined"
                                      placeholder="Price"
                                      name="Pricefor variant"
                                      size="small"
                                      type="number"
                                      value={variantValue?.price}
                                      onChange={(e) => {
                                        this.updateAllAttributeValues(
                                          e.target.value,
                                          "price",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div style={{}}>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Cost Price"
                                      name="costPrice"
                                      size="small"
                                      type="number"
                                      value={variantValue?.costPrice}
                                      onChange={(e) => {
                                        this.updateAllAttributeValues(
                                          e.target.value,
                                          "costPrice",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div style={{}}>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Barcode"
                                      name="barcode"
                                      size="small"
                                      value={variantValue?.barcodes[0]?.code}
                                      onChange={(e) => {
                                        this.updateAllAttributeValues(
                                          e.target.value,
                                          "barcode",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div>
                                    <TextField
                                      name="quantity"
                                      size="small"
                                      placeholder="Quantity"
                                      variant="outlined"
                                      value={variantValue?.quantity}
                                      onChange={(e) => {
                                        this.updateAllAttributeValues(
                                          e.target.value,
                                          "quantity",
                                          index
                                        );
                                      }}
                                      type="number"
                                    />
                                    {this.validator.message(
                                      "quantity",
                                      this.state.quantity,
                                      "required|numeric|min:0,num",
                                      { className: classes.textDanger }
                                    )}
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div style={{}}>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Tax Rate"
                                      name="taxRate"
                                      type="number"
                                      size="small"
                                      value={variantValue?.taxRate}
                                      onChange={(e) => {
                                        this.updateAllAttributeValues(
                                          e.target.value,
                                          "taxRate",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>

                                <td
                                  style={{
                                    width: "auto",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                      var list =
                                        this.state.allAttributeVariant.filter(
                                          (singlevarient, cindex) =>
                                            cindex != index
                                        );
                                      this.setState(
                                        { allAttributeVariant: list }
                                        //                                     this.prepareCombinations
                                      );
                                    }}
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <div>Incoming</div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  ></div>
                </div>
              )}
            </div>

            <div className={classes.formRightBox}>
              <div className={classes.formItems}>
                <p
                  style={{
                    margin: "10px 0 10px 10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Product Status
                </p>
                <Grid item xs={12}>
                  {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                  <Select
                    labelId="Input label"
                    style={{ height: "40px" }}
                    id="Select"
                    value={status}
                    defaultValue={status}
                    onChange={(e) => {
                      this.setState({ status: e.target.value });
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {statuses.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </div>

              <div className={classes.formItems}>
                <Autocomplete
                  onChange={(event, value) =>
                    this.setState({ categoryId: value.POID })
                  }
                  options={this.state.categories}
                  classes={{
                    option: classes.option,
                  }}
                  disableClearable="true"
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{option.name}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      name="categoryId"
                      value={this.state.categoryId}
                      onChange={this.handleChange}
                      {...params}
                      Status
                      label="Select Category"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
                {this.validator.message(
                  "Category",
                  this.state.categoryId,
                  "required",
                  {
                    className: classes.textDanger,
                  }
                )}

                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    style={{
                      backgroundColor: SKY_BLUE,
                      color: "white",
                    }}
                    onClick={() => this.openCategoryModal()}
                  >
                    Create Category
                  </Button>
                </div>
              </div>
              <Dialog
                fullWidth={true}
                open={this.state.categoryModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeCategoryModal}
              >
                <DialogTitle>{"Create New Category "}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        size="medium"
                        label="Category Name"
                        name="categoryName"
                        value={this.state.categoryName}
                        onChange={this.handleChangeCat}
                      ></TextField>
                      <TextField
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        size="medium"
                        multiline
                        rows={4}
                        label="Category Description"
                        name="categoryDescription"
                        onChange={this.handleChangeCat}
                        value={this.state.categoryDescription}
                      ></TextField>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeCategoryModal} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={this.submitCategory} color="primary">
                    Create
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                fullWidth={true}
                open={this.state.variantModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeVariantModal}
              >
                <DialogTitle>{"Add Variant "}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div>
                        <TextField
                          style={{ marginBottom: "20px" }}
                          variant="outlined"
                          label="Attribute Name"
                          size="medium"
                          name={`attributeName`}
                          value={this.state.attributes.attributeName}
                          onChange={(e) => {
                            const updatedAttributes = this.state.attributes;

                            updatedAttributes.attributeName = e.target.value;
                            this.setState({ attributes: updatedAttributes });
                          }}
                        />
                        {this.state.attributes.attributeValues.map(
                          (valueObj, valueIndex) => (
                            <div key={valueIndex}>
                              <TextField
                                style={{ marginBottom: "20px" }}
                                variant="outlined"
                                size="medium"
                                label={`Value ${valueIndex + 1}`}
                                name={`attributeValue${valueIndex}`}
                                value={valueObj.value}
                                onChange={(e) =>
                                  this.handleChangeVariant(
                                    0,
                                    valueIndex,
                                    "value",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                      {/* ))} */}
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeVariantModal} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={this.submitVariant} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              {/* image picker modal */}
              {this.state.imagePickerModal && (
                <Dialog
                  fullWidth={true}
                  open={this.state.imagePickerModal}
                  TransitionComponent={Transition}
                  onClose={this.closeImageModal}
                >
                  <DialogTitle>{"Image Picker"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <h6>Image</h6>
                        </div>
                        <div
                          style={{
                            paddingTop: "20px",
                            display: "flex",
                            height: "300px",
                          }}
                        >
                          <DropzoneArea
                            filesLimit={1}
                            acceptedFiles={["image/*"]}
                            onChange={async (images) => {
                              console.log(
                                "uploadedindex",
                                this.state.selectedIndexForImages
                              );
                              const deepCopyOfAllAttributeVariant =
                                this.state.allAttributeVariant;
                              deepCopyOfAllAttributeVariant[
                                this.state.selectedIndexForImages
                              ].images = await this.handleImagesUploading(
                                images
                              );
                              this.setState({
                                allAttributeVariant:
                                  deepCopyOfAllAttributeVariant,
                              });
                            }}
                            onDelete={this.handleDeleteImage}
                            clearOnUnmount={true}
                          />
                        </div>
                        {this.state.allAttributeVariant[
                          this.state.selectedIndexForImages
                        ].images.map((image, index) => (
                          <>
                            <img
                              key={index}
                              src={image.imageURL}
                              alt=""
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "20px 0 0 25px",
                                objectFit: "contain",
                              }}
                            />
                            <ClearIcon
                              onClick={() =>
                                this.removeImageFromVariant(image, index)
                              }
                              style={{
                                color: "red",
                                marginTop: "-75px",
                                cursor: "pointer",
                              }}
                            />
                          </>
                        ))}
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeImageModal} color="primary">
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          </div>

          <div className={classes.addproductsave} style={{}}>
            <Button
              onClick={this.handleSubmit}
              style={{ backgroundColor: SKY_BLUE, color: WHITE }}
              type="submit"
              variant="contained"
              color="default"
              size="medium"
            >
              Save
            </Button>
          </div>
        </form>

        <div>
          {snackBar ? (
            <SnackBar message={snackBarMessage} type={snackBarType} />
          ) : null}
        </div>
      </>
    );
    // }
  }
}
const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(AddProductForm));
