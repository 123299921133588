import React, { Component } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import "./Discount.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, Select, MenuItem } from "@mui/material";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const discountAppliedOn = [
  {
    POID: 1,
    value: "All Products",
  },
  {
    POID: 2,
    value: "Specific Products",
  },
];

class AppliesTo extends Component {
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onHandleBack = () => {
    this.props.prevStep();
  };

  render() {
    const { appliesTo, selectedProducts } = this.props.values;
    return (
      <>
        <div>
          <PageTitle
            textType="h6"
            value="Applies To"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.onHandleBack}
          />
        </div>
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="8px">
          {/* <InputLabel id="Input label">Discount type</InputLabel> */}
          <Select
            labelId="Input label"
            id="Select"
            name="appliesTo"
            value={appliesTo}
            // defaultValue={values.status}
            onChange={this.props.handleChange}
            size="large"
            variant="outlined"
            fullWidth
          >
            {discountAppliedOn.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        {appliesTo === "Specific Products" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                marginTop: "15px",
                marginLeft: "5px",
                marginBottom: "5px",
              }}
            >
              <div className="label-products">
                <label>Products</label>
              </div>
              {selectedProducts.length === 0 ? (
                <AddCircleOutlineIcon
                  onClick={this.props.nextStep}
                  color="success"
                  fontSize="medium"
                />
              ) : (
                <EditIcon
                  onClick={this.props.nextStep}
                  color="success"
                  fontSize="medium"
                />
              )}
            </div>
            {selectedProducts.length === 0 ? (
              <div
                style={{
                  width: "90%",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              >
                <p>No products Added</p>
              </div>
            ) : (
              selectedProducts.map((product) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "8px",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      width="35"
                      height="35"
                      src="https://cdn.iconscout.com/icon/free/png-256/gallery-187-902099.png"
                    />
                    <p style={{ marginLeft: "10px", textAlign: "center" }}>
                      {product.name.slice(0, 20)}
                    </p>
                  </div>
                  <div>
                    <CancelIcon
                      color="warning"
                      onClick={()=>this.props.handleRemove(product.POID)}
                    />
                  </div>
                </div>
              ))
            )}
          </>
        ) : null}
      </>
    );
  }
}

export default AppliesTo;
