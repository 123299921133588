import React from "react";
import { Typography, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import '../ProductMobileComponents/PageTitle.css';

const PageTitle = function (props) {
    const handleClick = () => {
        console.log("clicked");
        props.ChangeRoute();
    };
    return (
        <div className={`title-bar-customer`}>
            <IconButton onClick={handleClick} sx={{ color: "#ffff" }}>
                {props.Icon}
            </IconButton>
            <Typography
                sx={{ margin: "5px 5px 5px 5px", color: "#ffff" }}
                variant={props.textType}
                align={props.alignment}
            >
                {props.value}
            </Typography>


        </div>
    );
};
PageTitle.defaultProps = {
    textType: "subtitle2",
    alignment: "left",
    value: "provide some text",
    buttonColor: "primary",
    Icon: <CancelIcon />,
    border: 'border'
};

export default PageTitle;

