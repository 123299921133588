import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@material-ui/core/InputAdornment";


class SearchBar extends React.Component {
    state = {
        searchTerm: '',
        open: false
    }
    style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        p: 4,
    };
    editSearchTerm = (e) => {
        this.setState({ searchTerm: e.target.value });
        console.log(`search term: ${e.target.value}`);
        console.log("this.props.data", this.props.data);

        const filteredList = this.dynamicSearch(this.props.data, e.target.value);
        this.props.onChange(filteredList);
    };
    handleSubmit = (event) => {
        console.log(`clicked on submit`);
        event.preventDefault();
        const filteredList = this.dynamicSearch(this.props.data, this.state.searchTerm);
        this.props.onSubmit(filteredList);
    };
    dynamicSearch = (list, searchTerm) => {
        return list.filter((item) => item.userName.toLowerCase().includes(searchTerm.toLowerCase()))
    };
    handleOpen = () => this.setState({ open: true })
    handleClose = () => this.setState({ open: false })

    render() {
        return (
            <div>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px 0px 4px', display: 'flex', alignItems: 'center', width: 'auto', margin: '5px 5px 5px 5px' }}
                    onChange={this.editSearchTerm} onSubmit={this.handleSubmit}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search User"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                    <InputAdornment>
                        <SearchIcon style={{ marginRight: "5px" }} />
                    </InputAdornment>
                </Paper>
            </div>

        );
    }
};

export default SearchBar;