import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import GlobalConfig from "../../Config";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Store from "../../index";
import { connect } from "react-redux";
import CustomersOverTimeMobile from "../../MobileComponents/Analytics/CustomersOverTimeMobile";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import helpers from "../../UtilitiesFunctions";

class CustomersOverTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEndDate: new Date(),
      selectedDate: this.previousMonthDate(new Date()),
      customersReportOverTime: [],
      loading: true,
      startDate: this.setdate(new Date()),
      endDate: this.setenddate(new Date()),
      errorSnackbar: false,
      validationSnackbar: false,
    };
  }

  componentDidMount() {
    if (this.props.g_customersReportOverTime.length === 0) {
      fetch(
        this.props.global_config.basePath +
          `/reporting/customers?startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            loading: false,
            customersReportOverTime: data,
          });
          Store.dispatch({
            type: "GET_CUSTOMERS_REPORT_OVER_TIME",
            customersReportOverTime: data,
          });
        })
        .catch((err) => {
          this.setState({ errorSnackbar: false, loading: false });
          helpers.toastNotify("error", "Fetching Data Failed. ");
        });
    } else {
      this.setState({
        loading: false,
        customersReportOverTime: this.props.g_customersReportOverTime,
      });
    }
    this.fetchcallback();
  }

  fetchcallback() {
    fetch(
      this.props.global_config.basePath +
        `/reporting/customers?startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loading: false,
          customersReportOverTime: data,
        });
        Store.dispatch({
          type: "GET_CUSTOMERS_REPORT_OVER_TIME",
          customersReportOverTime: data,
        });
      })
      .catch((err) => {
        this.setState({ errorSnackbar: false, loading: false });
        helpers.toastNotify("error", "Fetching Data Failed. ");
      });
  }

  previousMonthDate = (date) => {
    date.setMonth(date.getMonth() - 1);
    return date;
  };

  setdate = (date) => {
    date.setMonth(date.getMonth() - 1);
    return this.dates(date);
  };
  setenddate = (date) => {
    return this.dates(date);
  };

  dates = (dates) => {
    var year = "" + dates.getFullYear();
    var month = "" + (dates.getMonth() + 1);
    var day = "" + dates.getDate();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  };
  handleDateChange = (date) => {
    let selectedDate = this.dates(date);
    this.setState({ startDate: selectedDate, selectedDate: date });
  };
  handleEndDateChange = (date) => {
    let selectedEndDate = this.dates(date);
    this.setState({ endDate: selectedEndDate, selectedEndDate: date });
  };
  fetchData = () => {
    const validTime = new Date();
    if (
      this.state.selectedDate.getTime() > validTime.getTime() ||
      this.state.selectedEndDate.getTime() < this.state.selectedDate.getTime()
    ) {
      this.setState({ validationSnackbar: false });
      helpers.toastNotify("error", "Invalid Dates!");
    } else {
      this.setState({ loading: true, customersReportOverTime: [] });
      fetch(
        this.props.global_config.basePath +
          `/reporting/customers?startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
          // convert the React state to JSON and send it as the POST body
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            loading: false,
            customersReportOverTime: data,
          });
          Store.dispatch({
            type: "GET_CUSTOMERS_REPORT_OVER_TIME",
            customersReportOverTime: data,
          });
        })
        .catch((err) => {
          this.setState({ errorSnackbar: false, loading: false });
          helpers.toastNotify("error", "Fetching Data Failed. ");
        });
    }
  };
  dash = (value) => {
    if (value === undefined) return "-";
    else return value;
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ validationSnackbar: false, errorSnackbar: false });
  };

  changeDate = (dates) => {
    const formatedDates = dates.split(".");
    let year = formatedDates[0];
    let month = formatedDates[1];
    let day = formatedDates[2];
    let hours = formatedDates[3];
    let minutes = formatedDates[4];
    let seconds = formatedDates[5];
    console.log("month" + formatedDates[1]);
    console.log("day" + formatedDates[2]);
    console.log("hours" + formatedDates[3]);
    console.log("hours" + formatedDates[4]);
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  render() {
    const columns = [
      {
        key: "customerId",
        label: "Customer ID",
        field: "customerId",
        headerName: "Customer ID",
        width: 150,
      },
      {
        key: "name",
        label: "Name",
        field: "name",
        headerName: "Name",
        width: 150,
      },
      {
        key: "customerEmail",
        label: "Email",
        field: "customerEmail",
        headerName: "Email",
        width: 200,
      },
      {
        key: "number",
        label: "Number",
        field: "number",
        headerName: "Number",
        width: 150,
      },
      {
        key: "createdAt",
        label: "Joining Date",
        field: "createdAt",
        headerName: "Joining Date",
        width: 170,
      },
      {
        key: "firstOrder",
        label: "First Order",
        field: "firstOrder",
        headerName: "First Order",
        width: 150,
      },
      {
        key: "lastOrder",
        label: "Last Order",
        field: "lastOrder",
        headerName: "Last Order",
        width: 150,
      },
      {
        key: "orders",
        label: "Orders",
        field: "orders",
        headerName: "Orders",
        width: 130,
        editable: true,
      },
      {
        key: "grossSales",
        label: "Gross Sales",
        field: "grossSales",
        headerName: "Gross Sales",
        width: 130,
      },
      {
        key: "discounts",
        label: "Discounts",
        field: "discounts",
        headerName: "Discounts",
        width: 130,
      },
      {
        key: "returns",
        label: "Returns",
        field: "returns",
        headerName: "Returns",
        width: 130,
      },
      {
        key: "netSales",
        label: "Net Sales",
        field: "netSales",
        headerName: "Net Sales",
        width: 130,
      },
      {
        key: "netQuantity",
        label: "Net Quantity",
        field: "netQuantity",
        headerName: "Net Quantity",
        width: 150,
      },
      {
        key: "tax",
        label: "Tax",
        field: "tax",
        headerName: "Tax",
        width: 130,
      },
      {
        key: "taxAmount",
        label: "Tax Amount",
        field: "taxAmount",
        headerName: "Tax Amount",
        width: 130,
      },
      {
        key: "totalSales",
        label: "Total Sales",
        field: "totalSales",
        headerName: "Total Sales",
        width: 130,
      },
    ];

    const rows = [];
    const obj = this.state.customersReportOverTime;

    for (var i = 0; i < obj.length; i++) {
      rows[i] = {
        id: i + 1,
        customerId: this.dash(obj[i].customerId),
        name: this.dash(obj[i].name),
        customerEmail: this.dash(obj[i].customerEmail),
        number: this.dash(obj[i].number),
        createdAt: this.changeDate(this.dash(obj[i].createdAt)),
        firstOrder: this.dash(obj[i].firstOrder),
        lastOrder: this.dash(obj[i].lastOrder),
        orders: this.dash(obj[i].orders),
        grossSales: this.dash(obj[i].grossSales),
        discounts: this.dash(obj[i].discounts),
        returns: this.dash(obj[i].returns),
        netSales: this.dash(obj[i].netSales),
        netQuantity: this.dash(obj[i].netQuantity),
        tax: this.dash(obj[i].tax),
        taxAmount: this.dash(obj[i].taxAmount),
        totalSales: this.dash(obj[i].totalSales),
        avgOrderValue: this.dash(obj[i].avgOrderValue),
        avgReturnsValue: this.dash(obj[i].avgReturnsValue),
        avgUnitsSoldValue: this.dash(obj[i].avgUnitsSoldValue),
      };
    }

    const exportPDF = () => {
      let dataCol = ["id"];
      let dataRow = [];

      columns.forEach((c) => {
        dataCol.push(c.label);
      });

      rows.forEach((r) => {
        let singleR = [];
        for (const [key, value] of Object.entries(r)) {
          console.log(`${key}: ${value}`);
          singleR.push(value);
        }
        dataRow.push(singleR);
      });

      const unit = "pt";
      const size = "A4";
      const orientation = "landscape";

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);
      autoTable(doc, {
        head: [dataCol],
        body: dataRow,
      });

      window.open(doc.output("dataurlnewwindow"));
    };

    const csvReport = {
      data: rows,
      headers: columns,
      filename:
        "CustomersOverTimeReport_from " +
        this.state.startDate +
        " to " +
        this.state.endDate +
        ".csv",
    };
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      this.state.height < 200 ||
      this.state.width < 300
    ) {
      return (
        <CustomersOverTimeMobile
          rows={rows}
          columns={columns}
          loading={this.state.loading}
          selectedDate={this.state.selectedDate}
          selectedEndDate={this.state.selectedEndDate}
          onDateChange={this.handleDateChange}
          onEndDateChange={this.handleEndDateChange}
          history={this.props.history}
          fetchData={this.fetchData}
          csvReport={csvReport}
        />
      );
    } else
      return (
        <div>
          {this.state.errorSnackbar ? (
            <>
              <Snackbar
                open={this.state.errorSnackbar}
                autoHideDuration={5000}
                onClose={this.closeSnack}
              >
                <MuiAlert
                  severity="error"
                  color="error"
                  variant="filled"
                  onClose={this.closeSnack}
                >
                  Error! Request Failed
                </MuiAlert>
              </Snackbar>
            </>
          ) : null}
          {this.state.validationSnackbar ? (
            <>
              <Snackbar
                open={this.state.validationSnackbar}
                autoHideDuration={4000}
                onClose={this.closeSnack}
              >
                <MuiAlert
                  severity="error"
                  color="error"
                  variant="filled"
                  onClose={this.closeSnack}
                >
                  Invalid Dates
                </MuiAlert>
              </Snackbar>
            </>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <h5>
                <ArrowBackIosIcon
                  fontSize="large"
                  style={{ cursor: "pointer" }}
                  onClick={(props) => this.props.history.push("/Analytics")}
                />
                Customers Over Time
              </h5>
            </div>
            <div>
              <Button onClick={() => exportPDF()}>Print</Button>
              <Button>
                <CSVLink {...csvReport}>Export</CSVLink>
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "48%",
              marginTop: "20px",
            }}
          >
            <div>
              <Grid container justify="space-around">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="Pick Start Date"
                    format="dd/MM/yyyy"
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </div>
            <div>
              <Grid container justify="space-around">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="Pick End Date"
                    format="dd/MM/yyyy"
                    value={this.state.selectedEndDate}
                    onChange={this.handleEndDateChange}
                    showTodayButton={true}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button
                onClick={() => this.fetchData()}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              hideFooter
              autoHeight
              rows={rows}
              columns={columns}
              loading={this.state.loading}
              checkboxSelection={false}
              disableSelectionOnClick
            />
          </div>
        </div>
      );
  }
}
const mapStateToProps = (state) => {
  return {
    g_customersReportOverTime: state.analyticsState.customersReportOverTime,
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps, null)(CustomersOverTime);
