import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageTitle from "./PageTitle";
import SearchBar from "./SearchBar";
import UserItems from "./UserItems";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import GlobalConfig from "../../Config";
import "../ProductMobileComponents/Product.css";
import Loader from "../Loader";
import SnackBar from "../ProductMobileComponents/Snackbar";
import Store from "../../index";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSelector } from "react-redux";
import helpers from "../../UtilitiesFunctions";

const ShowUsers = (props) => {
    const { users, token, nextStep } = props;

    const [filteredUsers, setFilteredUsers] = useState(users);

    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const history = useHistory();

    const global_config = useSelector((state) => state.globalState);

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    console.log(filteredUsers);
    // console.log(users)

    const setUser = (newFilteredUsers) => {
        setFilteredUsers(newFilteredUsers);
    };
    const deleteUser = (POID) => {
        setLoading(true);
        fetch(global_config.basePath + "/users/" + POID, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },

            body: JSON.stringify(),
        })
            .then((response) => {
                Store.dispatch({ type: "DELETE_USERS", POID: POID });
                setLoading(false);
                setSnackbar(false);
                setSnackbarMessage("User Deleted");
                setSnackbarType("success");
                helpers.toastNotify("success", "User Successfully Deleted!");
            })
            .catch((err) => {
                setLoading(false);
                setSnackbar(false);
                setSnackbarMessage("Error Unable to delete user");
                setSnackbarType("error");
                helpers.toastNotify("error", "Could Not Delete User.");
            });
    };
    const ChangeRoute = () => {
        history.push("/");
    };
    return (
        <>
            {loading ? (
                <Loader value={"Please Wait.."} />
            ) : (
                <>
                    <div>
                        <div>
                            <PageTitle
                                textType="h6"
                                value="Users"
                                Icon={<ArrowBackIosNewIcon />}
                                prevStep={ChangeRoute}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            margin: "20px",
                            marginTop: "0px",
                            position: "relative",
                            top: "2px",
                        }}>
                        <Button
                            variant="outlined"
                            endIcon={<PersonAddIcon fontSize="medium" style={{ fill: "green" }} />}
                            onClick={() => nextStep()}>
                            New User
                        </Button>
                    </div>

                    <div>
                        <div style={{ margin: "20px", marginTop: "20px" }}>
                            <SearchBar barvalue="Search Users" data={users} onChange={setUser} />
                        </div>
                        <UserItems
                            userItems={filteredUsers}
                            nextStep={nextStep}
                            deleteUser={deleteUser}
                        />
                    </div>
                    <div>
                        {snackbar ? (
                            <SnackBar message={snackbarMessage} severity={snackbarType} />
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};

export default ShowUsers;
