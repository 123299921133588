import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


export default function IconLabelTabs(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log('value of tab:  ', newValue);
        setValue(newValue);
        props.onClick(filterData(props.data, event.nativeEvent.srcElement.innerText));
    };

    const filterData = (list, value) => {
        console.log(`in filter data with value: ${value.toLowerCase()} `);
        var filteredData = [];
        value = value.toLowerCase();
        if (value === 'new') {
            console.log('returning list for type new')
            filteredData = list.filter(data => data.numberOfOrders === 1);
            return filteredData;
        }
        else if (value === 'returning') {
            console.log('returning list for type returning')
            filteredData = list.filter(data => data.numberOfOrders >= 1);
            return filteredData;
        }
        filteredData = list.filter(data => data.status.toLowerCase() === value.toLowerCase());
        console.log(`filtered customers size is ${filteredData.length}`);
        return filteredData;
    };
    console.log(`products: ${props.data.length}`);
    return (
        <Tabs sx={{
            p: '2px 2px 2px 2px', margin: '5px 5px 5px 5px'
        }} value={value} onChange={handleChange} aria-label="icon label tabs example" width='auto' centered>
            <Tab label={props.Item1} />
            <Tab label={props.Item2} />
            <Tab label={props.Item3} />
            <Tab label={props.Item4} />
        </Tabs>
    );
}