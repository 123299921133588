import { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import Barcode from 'react-barcode';
import Loader from "../../MobileComponents/Loader";
import SingleProduct from "../SingleProduct";
import { useSelector } from "react-redux";

function OrderStatus(){ 
    let { id } = useParams();
    let [orderId, setOrderId] = useState()
    let [order, setOrder] = useState([]);
    const global_config = useSelector((state) => state.globalState);
    
    function fetchOrder(orderId){
        fetch(`${global_config.basePath}/orders/${orderId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          }
        })
        .then(async (response)=>{
          let data = await response.json()
          console.log(data)
          setOrder(data)
        })
        .catch((e)=>{
          console.log(e)
        })
    }
    useEffect(()=>{
        console.log(id)
        setOrderId(id)
        fetchOrder(id)
    }, [])
    return(
        <>
            {order.length<1 ?                
                <Loader value={"Loading Order ..."} /> :
                <div className="orderStatus">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 orderInfo">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-2 col-lg-2 col-12 align-self-center">
                                            <img src="/assets/tick.png" className="imgTick" alt="tick-success" />
                                        </div>
                                        <div className="col-lg-10 col-md-10 col-12 align-self-center">
                                            <h5>HULM</h5>
                                            <p>Order #{id}</p>
                                            <h5>Thank You!</h5>
                                        </div>
                                    </div>
                                    <div className="orderConfirmation boxx">
                                        <div className="row boxxRow">
                                            <h4>Your order is {order[0].fulfilled ? 'fulfilled' : 'confirmed' }</h4>
                                            <p>Contact us by email or phone to receive an order confirmation.</p>
                                        </div>
                                    </div>
                                    <div className="orderUpdates boxx">
                                        <div className="row boxxRow align-self-center">
                                            <h4>Order updates</h4>
                                            <p>You are not receiving shipping updates.</p>
                                            <button><i className="fas fa-envelope"></i> Get shipping updates by email</button>
                                        </div>
                                    </div>
                                    <div className="customerInfo boxx">
                                        <div className="row boxxRow align-self-center">
                                            <h4>Customer Infomation</h4>
                                            <p>You are not receiving shipping updates.</p>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12 coll">
                                                    <div className="contactInfo">
                                                        <p><b>Contact Information</b></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 coll">
                                                    <div className="contactInfo">
                                                        <p><b>Payment method</b></p>
                                                        <p>manual - Rs 1,432.44</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contactControls ">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12 leftt align-self-center">
                                                <p>Need help? <Link to="/contact">Contact us</Link></p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 rightt align-self-center">
                                                <Link to="/"><button>Continue shopping</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <p>All rights reserved Hulm</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 orderPreview">
                                <div className="container">
                                    <div className="productss">
                                        <div className="container">
                                            {
                                                order[0].lineItems?.map(p=>{
                                                    console.log(p)
                                                    return(
                                                        <SingleProduct name={p.productName} quantity={p.quantity} price={p.price} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="calculation">
                                        <div className="row">
                                            <div className="col-6 leftt">
                                                Subtotal
                                            </div>
                                            <div className="col-6 rightt">
                                                <b>{order[0].subTotal}</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 leftt">
                                                Shipping
                                            </div>
                                            <div className="col-6 rightt">
                                                Calculated at next step
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 leftt">
                                                Taxes
                                            </div>
                                            <div className="col-6 rightt">
                                                {order[0].taxAmount}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-6 leftt">
                                                Total
                                            </div>
                                            <div className="col-6 rightt">
                                                <b>{order[0].total}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderStatus;