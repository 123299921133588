import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchIcon from "@material-ui/icons/Search";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Modal from "@material-ui/core/Modal";

import Grid from "@material-ui/core/Grid";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";
import CartList from "../cart/CartList";
import {
  addToCartAction,
  updateCartUnits,
} from "../../Redux/action/cart_actions";
import Store from "../../index";
import GlobalConfig from "../../Config";
import { SKY_BLUE } from "../../colors/colors";
const styles = {
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
  },
  paper: {
    backgroundColor: "white",
   
    width: "700px",
    minHeight: "300px",
   
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
};
class DiscountForm extends Component {
  state = {
    backdrop: false,
    id: this.props.location.state.id,
    name: this.props.location.state.name,
    type: this.props.location.state.type,
    status: this.props.location.state.status,

  };
  addToCart = (product) => {
    console.log("order added");
    this.props.addToCartAction(product);
  };
  handleChange = (event) => {
    console.log(this.state.startDate);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleDateChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    this.setState({ startDate: !this.state.startDate });
    console.log(this.state.startDate);
  };
  handleSubmit = (props) => {
    this.props.history.push("/Discount");
  };
  handleOpen = () => {
    this.setState({ open: true });
    this.getProducts();
  };

  handleClose = () => {
    this.setState({ open: false });
    console.log("on close");
  };
  handleDelete = (id) => {
    this.setState({ backdrop: true });
    fetch(this.props.global_config.basePath + `/promotions/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
     
      this.props.history.push({
        pathname: "/Discount",
      });
    });
  };

  handleActive = (id) => {
    this.setState({ backdrop: true });
    const data = {
      status: "Active",
    };
    fetch(this.props.global_config.basePath + `/promotions/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      body: JSON.stringify(data),
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      console.log(response);
      fetch(this.props.global_config.basePath + `/promotions/${this.state.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response2) => response2.json())
        .then((data2) => {
          Store.dispatch({
            type: "TOGGLE_STATUS",
            discounts: data2,
          });
        });

      this.props.history.push({
        pathname: "/Discount",
      });
    });
  };
  getProducts() {}
  handleDiscount = (e) => {
    this.setState({ backdrop: true });
    e.preventDefault();
    console.log("name :" + this.state.name);
    console.log("id :" + this.state.id);

    
    const data = {
      actionType: this.state.actionType,
      discountAmount: this.state.discountAmount,
      customerBuys: this.state.customerBuys,
      customerGets: this.state.customerGets,
      discountCode: this.state.discountCode,
      discountPercentage: this.state.discountPercentage,
      endDate: this.state.endDate,
      minimumQuantityOrAmountToBuy: this.state.minimumQuantityOrAmountToBuy,
      id: this.state.id,
      name: this.state.name,

      quantitycustomerGets: this.state.quantitycustomerGets,
      startDate: this.state.startDate,
      status: this.props.location.state.status,
      type: this.state.type,
      quantityOrAmount: this.state.quantityOrAmount,
      discountAppliedOn: this.state.discountAppliedOn,
    };

    fetch(this.props.global_config.basePath + `/promotions/${this.state.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    }).then((response) => {
      fetch(this.props.global_config.basePath + `/promotions/${this.state.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response2) => response2.json())
        .then((data2) => {
          console.log("Before Dispatch");
          Store.dispatch({
            type: "EDIT_AUTOMATED_DISCOUNT",
            updated_automated_discount: data2,
          });

          this.props.history.push({
            pathname: "/Discount",
          });
        });
    });
  };
  render() {
    console.log("Edit Discount props ", this.props);
    const { cart, addToCartAction, updateCartUnits } = this.props;

    const { classes } = this.props;
    const { type } = this.state;
    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} size={90} color="inherit" />
        </Backdrop>
        <form onSubmit={this.handleDiscount.bind(this)}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5>
              <ArrowBackIosIcon
                fontSize="large"
                style={{ cursor: "pointer" }}
                onClick={() => this.props.history.push("/Discount")}
              />
              Back
            </h5>
            {this.state.status === "Active" ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.handleDelete(this.state.id)}
              >
                Disable
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.handleActive(this.state.id)}
              >
                Enable
              </Button>
            )}
          </div>
          <div
            style={{
              padding: "0 150px",
            }}
          >
            <h5>Create Discount Code</h5>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "65%",
                  marginRight: "25px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Discount Code
                    </div>
                    <span style={{backgroundColor: '#49A5AC', color: 'white', padding: '5px 10px', borderRadius: '5px' }} onClick={()=>{
                      this.setState({ name: 'promo-'+Math.random().toString(36).slice(2) })
                    }}>
                      Generate Code
                    </span>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <TextField
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#6d7175", fontWeight: "400" }}>
                      Customers will enter this discount code at checkout.
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Types
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={this.state.type}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            onChange={this.handleChange}
                            value="Percentage"
                            color="primary"
                            size="small"
                          />
                        }
                        label="Percentage"
                      />
                      <FormControlLabel
                        // value={this
                        control={
                          <Radio
                            value="FixedAmount"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Fixed Amount"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            value="Buy One Get One:"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Buy X get Y"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {type === "Buy One Get One:" && (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0.8rem",
                      padding: "25px",
                      marginBottom: "20px",
                      boxShadow:
                        "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                      outline: ".1rem solid transparent",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        Customer Buys
                      </div>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                      <RadioGroup
                        aria-label="type"
                        name="minimumQuantityOrAmountToBuy"
                        value={this.state.minimumQuantityOrAmountToBuy}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              name="minqty"
                              color="primary"
                              onChange={this.handleChange}
                              size="small"
                            />
                          }
                          label="Minumum Quantity of items"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              name="minamount"
                              color="primary"
                              onChange={this.handleChange}
                              size="small"
                            />
                          }
                          label="Minimum purchase amount "
                        />
                      </RadioGroup>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="Quantity"
                          type="number"
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                      <div style={{ width: "63%" }}>
                        <FormControl
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                          variant="outlined"
                          // className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Any Items from
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // value={age}
                            // onChange={handleChange}
                            label="Any Items from "
                          >
                            <MenuItem value={10}>Specific Products</MenuItem>
                            <MenuItem value={20}>Specific Collections</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "75%", marginTop: "18px" }}>
                        <TextField
                          fullWidth={true}
                          id="filled-search"
                          label="Search Products"
                          // type={this}
                          variant="outlined"
                          onChange={this.handleChange}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            endAdornment: (
                              <InputAdornment>
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                 
                      <div style={{ width: "20%", marginTop: "25px" }}>
                        <Button
                          onClick={this.handleOpen}
                          variant="outlined"
                          size="large"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        >
                          Browse
                        </Button>
                        <Modal
                       
                          
                          className={classes.modal}
                          open={this.state.open}
                          onClose={() => this.handleClose}
                          closeAfterTransition
                       
                        >
                          <Fade
                            disableBackdropClick={false}
                            in={this.state.open}
                            style={{
                              borderRadius: "10px",
                            
                            }}
                          >
                            <div className={classes.paper}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5 style={{ margin: "15px 0 0  15px" }}>
                                  Add Products
                                </h5>
                                <Button
                                  style={{ margin: "10px 15px 0 0" }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleClose}
                                >
                                  close
                                </Button>
                              </div>
                              <hr />

                              <TextField
                                style={{ padding: "0 10px 0 10px" }}
                                variant="outlined"
                                type="text"
                                fullWidth={true}
                                name="discountPercentage"
                                onChange={this.handleChange}
                                InputProps={{
                                  classes: { input: classes.inputHeight },
                                  endAdornment: (
                                    <InputAdornment>
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {!this.state.products ? (
                                <>
                                  <hr></hr>
                                  <p>
                                    Your store doesn’t have any products yet.
                                  </p>
                                  <hr></hr>
                                </>
                              ) : (
                                ""
                              )}

                              {this.state.products.map((data) => {
                                return (
                                  <table
                                    style={{
                                      border: "0px",
                                      margin: "0px",
                                      padding: "0px",
                                      width: "100%",
                                      height: "auto",
                                      webkitBoxShadow: "none",
                                      mozBoxShadow: "none",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <th>Name</th>
                                    <th>Price</th>
                                    <tr>
                                      <td>{data.name}</td>
                                      <td>{data.price}</td>
                                    </tr>
                                  </table>
                                );
                              })}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                  margin: "10px 15px 10px 10px ",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={this.handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button variant="contained">Add</Button>
                              </div>
                            </div>
                          </Fade>
                        </Modal>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        Customer Gets
                      </div>
                    </div>
                    <p>
                      Customers must add the quantity of items specified below
                      to their cart.
                    </p>

                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "30%" }}>
                        <TextField
                          id="filled-search"
                          label="Quantity"
                          type="number"
                          variant="outlined"
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                      <div style={{ width: "63%" }}>
                      
                        <FormControl
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                          variant="outlined"
                          // className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Any Items from
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // value={age}
                            // onChange={handleChange}
                            label="Any Items from "
                          >
                            <MenuItem value={10}>Specific Products</MenuItem>
                            <MenuItem value={20}>Specific Collections</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "75%", marginTop: "18px" }}>
                        <TextField
                          onFocus={this.handleOpen}
                          fullWidth={true}
                          onClose={() => this.handleClose}
                          // autoFocus={true}
                          label="Search Products"
                          type="search"
                          variant="outlined"
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            endAdornment: (
                              <InputAdornment>
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div style={{ width: "20%", marginTop: "25px" }}>
                        <Button
                          onClick={this.handleOpen}
                          variant="outlined"
                          size="large"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        >
                          Browse
                        </Button>
                        <Modal
                          disableEnforceFocus={false}
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          className={classes.modal}
                          open={this.state.open}
                          onClose={() => this.handleClose}
                          closeAfterTransition
                          // autoFocus={false}
                          BackdropComponent={Backdrop}
                          fullWidth={true}
                          maxWidth="xl"
                          // fullWidth={true}
                          BackdropProps={{
                            timeout: 200,
                          }}
                        >
                          <Fade
                            in={this.state.open}
                            style={{
                              borderRadius: "10px",
                              //   display: "flex",
                              //   flexDirection: "column",
                              //   justifyContent: "normal",
                              //   alignItems: "center",
                            }}
                          >
                            <div className={classes.paper}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5 style={{ margin: "15px 0 0  15px" }}>
                                  Add Products
                                </h5>
                                <Button
                                  style={{ margin: "10px 15px 0 0" }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleClose}
                                >
                                  close
                                </Button>
                              </div>
                              <hr />

                              <TextField
                                style={{ padding: "0 10px 0 10px" }}
                                variant="outlined"
                                type="text"
                                fullWidth={true}
                                name="discountPercentage"
                                onChange={this.handleChange}
                                InputProps={{
                                  classes: { input: classes.inputHeight },
                                  endAdornment: (
                                    <InputAdornment>
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {!this.state.products ? (
                                <>
                                  <hr></hr>
                                  <p>
                                    Your store doesn’t have any products yet.
                                  </p>
                                  <hr></hr>
                                </>
                              ) : (
                                ""
                              )}

                              {this.state.products.map((data) => {
                                return (
                                  <table
                                    style={{
                                      border: "0px",
                                      margin: "0px",
                                      padding: "0px",
                                      width: "100%",
                                      height: "auto",
                                      webkitBoxShadow: "none",
                                      mozBoxShadow: "none",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <th>Name</th>
                                    <th>Price</th>
                                    <tr>
                                      <td>{data.name}</td>
                                      <td>{data.price}</td>
                                    </tr>
                                  </table>
                                );
                              })}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                  margin: "10px 15px 10px 10px ",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={this.handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button variant="contained">Add</Button>
                              </div>
                            </div>
                          </Fade>
                        </Modal>
                      </div>
                    </div>

                    <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                      <p>AT A DISCOUNT VALUE</p>
                    </div>
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={type}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="minqty"
                        control={<Radio color="primary" size="small" />}
                        label="Percentage"
                      />
                      <FormControlLabel
                        value="minamount"
                        control={<Radio color="primary" size="small" />}
                        label="Free "
                      />
                    </RadioGroup>
                    <FormControlLabel
                      value="start"
                      control={<Checkbox color="primary" />}
                      label="Set a maximum number of uses per order"
                      // labelPlacement="start"
                    />
                  </div>
                )}

                {type === "Percentage" || type === "FixedAmount" ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0.8rem",
                      padding: "25px",
                      marginBottom: "20px",
                      boxShadow:
                        "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                      outline: ".1rem solid transparent",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        Value
                      </div>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                      <p style={{ color: "#6d7175", fontWeight: "400" }}>
                        Discount value
                      </p>
                    </div>

                    {type === "Percentage" && (
                      <div>
                        <TextField
                          value={this.state.discountPercentage}
                          variant="outlined"
                          type="number"
                          name="discountPercentage"
                          onChange={this.handleChange}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                    {type === "FixedAmount" ? (
                      <div>
                        <TextField
                          value={this.state.discountAmount}
                          variant="outlined"
                          name="discountAmount"
                          onChange={this.handleChange}
                          type="number"
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            endAdornment: (
                              <InputAdornment position="start">
                                Rs
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <hr />
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Applies to
                    </div>

                    <div style={{ paddingTop: "20px" }}>
                      <RadioGroup
                        aria-label="type"
                        name="discountAppliedOn"
                        value={this.state.discountAppliedOn}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              value="allproducts"
                              size="small"
                              onChange={this.handleChange}
                            />
                          }
                          label="All Products"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              value="specificcollection"
                              size="small"
                              onChange={this.handleChange}
                            />
                          }
                          label="Specific collections"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              onChange={this.handleChange}
                              value="specificproducts"
                            />
                          }
                          label="Specific products"
                        />
                        {this.state.discountAppliedOn ===
                          "specificcollection" ||
                        this.state.discountAppliedOn === "specificproducts" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              // name="specificcollection"
                              label={
                                this.state.discountAppliedOn ===
                                "specificcollection"
                                  ? "Specific Collection"
                                  : "Specifi Products"
                              }
                              // value="minpurchaseamount"
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: { input: classes.inputHeight },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <div style={{ width: "20%" }}>
                              <Button
                                onClick={this.handleOpen}
                                variant="outlined"
                                size="large"
                                inputprops={{
                                  classes: { input: classes.inputHeight },
                                }}
                              >
                                Browse
                              </Button>
                              <CartList
                                cart={cart}
                                updateCartUnits={updateCartUnits}
                              />
                              <Modal
                                disableEnforceFocus={false}
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={this.state.open}
                                onClose={() => this.handleClose}
                                closeAfterTransition
                                // autoFocus={false}
                                BackdropComponent={Backdrop}
                                fullWidth={true}
                                maxWidth="xl"
                                // fullWidth={true}
                                BackdropProps={{
                                  timeout: 200,
                                }}
                              >
                                <Fade
                                  in={this.state.open}
                                  style={{
                                    borderRadius: "10px",
                                    //   display: "flex",
                                    //   flexDirection: "column",
                                    //   justifyContent: "normal",
                                    //   alignItems: "center",
                                  }}
                                >
                                  <div className={classes.paper}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h5 style={{ margin: "15px 0 0  15px" }}>
                                        Add Products
                                      </h5>
                                      <Button
                                        style={{ margin: "10px 15px 0 0" }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.handleClose}
                                      >
                                        close
                                      </Button>
                                    </div>
                                    <hr />

                                    <TextField
                                      style={{ padding: "0 10px 0 10px" }}
                                      variant="outlined"
                                      type="text"
                                      fullWidth={true}
                                      name="discountPercentage"
                                      onChange={this.handleChange}
                                      InputProps={{
                                        classes: { input: classes.inputHeight },
                                        endAdornment: (
                                          <InputAdornment>
                                            <SearchIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    {!this.state.products ? (
                                      <>
                                        <hr></hr>
                                        <p>
                                          Your store doesn’t have any products
                                          yet.
                                        </p>
                                        <hr></hr>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {this.state.products.map((data) => {
                                      return (
                                        <table
                                          style={{
                                            border: "0px",
                                            margin: "0px",
                                            padding: "0px",
                                            width: "100%",
                                            height: "auto",
                                            webkitBoxShadow: "none",
                                            mozBoxShadow: "none",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <th>Name</th>
                                          <th>Price</th>
                                          <tr style={{ cursor: "pointer" }}>
                                            <td>{data.name}</td>
                                            <td>{data.price}</td>
                                          </tr>
                                        </table>
                                      );
                                    })}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        margin: "10px 15px 10px 10px ",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        onClick={this.handleClose}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={this.addToCart}
                                        variant="contained"
                                      >
                                        Add
                                      </Button>
                                    </div>
                                  </div>
                                </Fade>
                              </Modal>
                            </div>
                          </div>
                        ) : null}
                       
                      </RadioGroup>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {type === "Freeshipping" ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0.8rem",
                      padding: "25px",
                      marginBottom: "20px",
                      boxShadow:
                        "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                      outline: ".1rem solid transparent",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        Countries
                      </div>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                      <RadioGroup
                        aria-label="type"
                        name="type"
                        value={type}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="allcountries"
                          control={<Radio color="primary" size="small" />}
                          label="All Countries"
                        />
                        <FormControlLabel
                          value="selectedcountries"
                          control={<Radio color="primary" size="small" />}
                          label="Selected Countries"
                        />
                      </RadioGroup>
                      <hr />
                      {/* <FormGroup> */}
                      <p>SHIPPING RATES</p>
                      <FormControlLabel
                        value="start"
                        control={<Checkbox color="primary" />}
                        label="Exclude shipping rates over a certain amount"
                        // labelPlacement="start"
                      />
                      {/* </FormGroup> */}
                    </div>
                  </div>
                ) : null}
                {type === "Percentage" ||
                type === "FixedAmount" ||
                type === "Freeshipping" ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0.8rem",
                      padding: "25px",
                      marginBottom: "20px",
                      boxShadow:
                        "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                      outline: ".1rem solid transparent",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        Minimum Requirements
                      </div>
                    </div>
                    <div style={{ paddingTop: "20px" }}>
                      <RadioGroup
                        name="minimumQuantityOrAmountToBuy"
                        value={this.state.minimumQuantityOrAmountToBuy}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              value="none"
                              onChange={this.handleChange}
                              size="small"
                            />
                          }
                          label="None"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value="minpurchaseamount"
                              color="primary"
                              onChange={this.handleChange}
                              size="small"
                            />
                          }
                          label="Minimum purchase amount (Rs)"
                        />
                        {this.state.minimumQuantityOrAmountToBuy ===
                        "minpurchaseamount" ? (
                          <div>
                            <TextField
                              label="RS 0.00"
                              onChange={this.handleChange}
                              variant="outlined"
                              InputProps={{
                                classes: { input: classes.inputHeight },
                              }}
                            />
                            <p
                              style={{
                                margin: "10px 0 10px 7px",
                                color: "rgba(109, 113, 117, 1)",
                              }}
                            >
                              Applies to All Products
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              value="minimumquantity"
                              onChange={this.handleChange}
                              size="small"
                            />
                          }
                          label="Minimum quantity of items"
                        />
                        {this.state.minimumQuantityOrAmountToBuy ===
                        "minimumquantity" ? (
                          <div>
                            <TextField
                              onChange={this.handleChange}
                              variant="outlined"
                              InputProps={{
                                classes: { input: classes.inputHeight },
                              }}
                            />
                            <p
                              style={{
                                margin: "10px 0 10px 7px",
                                color: "rgba(109, 113, 117, 1)",
                              }}
                            >
                              Applies to All Products
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer eligibility
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      size="small"
                      name="customerEligibility"
                      value={this.state.customerEligibility}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="everyone"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Everyone"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            value="SpecificGroupofCustomer"
                            onChange={this.handleChange}
                          />
                        }
                        label="specific group of customers"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            value="SpecificCustomer"
                            onChange={this.handleChange}
                          />
                        }
                        label="specific Customers"
                      />
                      {this.state.customerEligibility ===
                      "SpecificGroupofCustomer" ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              name="SpecificGroupofCustomer"
                              label="Specific group of customers"
                              // value="minpurchaseamount"
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: { input: classes.inputHeight },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Button variant="outlined" size="large">
                              Browse
                            </Button>
                          </div>
                          <p
                            style={{
                              margin: "10px 0 0 10px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Select Group of Customers that can use this discount
                            <Link>Learn more</Link>
                          </p>
                        </div>
                      ) : null}
                      {this.state.customerEligibility === "SpecificCustomer" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            name="SpecificCustomer"
                            label="Specific Customers"
                            // value="minpurchaseamount"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                              classes: { input: classes.inputHeight },
                              endAdornment: (
                                <InputAdornment>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button variant="outlined" size="large">
                            Browse
                          </Button>
                        </div>
                      ) : null}
                    </RadioGroup>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Usage Limits
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <FormControlLabel
                      value="start"
                      control={<Checkbox color="primary" />}
                      label="Limit number of times this discount can be used in total"
                      // labelPlacement="start"
                    />
                    <FormControlLabel
                      value="start"
                      control={<Checkbox color="primary" />}
                      label="Limit to one use per customer"
                      // labelPlacement="start"
                    />
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Active Dates
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <Grid container justify="space-around">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          label="Date picker dialog"
                          format="yyyy/MM/dd"
                          value={this.state.selectedDate}
                          // onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <KeyboardTimePicker
                          label="Time picker"
                          // value={this.state.selectedDate}
                          // onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <div style={{ margin: "10px 0px 0px 30px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="startDate"
                            value={this.state.startDate}
                            onChange={this.handleDateChange}
                          />
                        }
                        label="Set End Date"
                      />
                    </div>

                    {this.state.startDate ? (
                      <Grid container justify="space-around">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            mt={2}
                            label="Date picker dialog"
                            format="MM/dd/yyyy"
                            value={this.state.selectedDate}
                            // onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                          <KeyboardTimePicker
                            id="time-picker"
                            label="Time picker"
                            // value={this.state.selectedDate}
                            // onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "28%",
                  position: "fixed",
                  right: "0px",
                  paddingRight: "6rem",
                }}
              >
                <div
                  style={{
                    padding: "20px 10px",
                    backgroundColor: "#fafbfb",
                    borderRadius: "0.8rem",
                    marginBottom: "10px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <p
                    style={{
                      margin: "10px 0 0 15px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Summary
                  </p>
                  <p
                    style={{
                      margin: "15px 0 15px 15px",
                      color: "#6d7175",
                    }}
                  >
                    {type === "Percentage" && "No information entered yet"}
                    {type === "FixedAmount" && "No information entered yet"}
                    {type === "Buy One Get One:" &&
                      "No information entered yet"}
                    {type === "Freeshipping" && (
                      <>
                        <List className={classes.root} subheader={<li />}>
                          {[0, 1, 2].map((sectionId) => (
                            <li
                              key={`section-${sectionId}`}
                              className={classes.listSection}
                              style={{ listStyleType: "circle" }}
                            >
                              <ul className={classes.ul}>
                                <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                              </ul>
                            </li>
                          ))}
                        </List>
                      </>
                    )}
                  </p>
                  <hr></hr>
                  <p
                    style={{
                      margin: "15px 0 0 15px",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                  >
                    PERFORMANCE
                  </p>
                  <p style={{ color: "#6d7175", margin: "10px 0 15px 15px" }}>
                    Discount is not active yet.
                  </p>
                </div>

                <div
                  style={{
                    padding: "20px 10px",
                    backgroundColor: "#fafbfb",
                    borderRadius: "0.8rem",
                    marginBottom: "10px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <p
                    style={{
                      margin: "10px 0 0 15px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    CAN’T COMBINE WITH OTHER AUTOMATIC DISCOUNTS
                  </p>
                  <p
                    style={{
                      margin: "10px 0 15px 15px",
                      color: "#6d7175",
                    }}
                  >
                    Customers won’t be able to enter a code if an automatic
                    discount is already applied at checkout.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                size="large"
                onClick={() => this.props.history.push("/Discount")}
              >
                Discard
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: SKY_BLUE, color: "white" }}
                size="large"
                onClick={() => this.handleSubmit}
              >
                Save Discount Code
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = ({ cart,state }) => {
  return {
    cart,
    global_config: state.globalState

  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCartAction,
      updateCartUnits,
    },
    dispatch
  );
};


export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(DiscountForm));
