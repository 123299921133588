import React from "react";
import CustomerCard from "./Card";
import { SKY_BLUE } from "../../colors/colors";

class ProductList extends React.Component {
  state = {
    customers: [],
    error: null,
  };

  componentDidUpdate() {
    console.log("customer card props: ", this.props.customers);
  }
  displayData = () => {
    const data =
      this.props.customers.length > 0 ? (
        this.props.customers.map((item) => {
          return (
            <div key={item.POID}>
              <CustomerCard data={item} />{" "}
            </div>
          );
        })
      ) : (
        <div
          style={{
            color: SKY_BLUE,
            display: "flex",
            justifyContent: "center",
            marginTop: "10rem",
          }}
        >
          <h5>No Customer Found!!</h5>
        </div>
      );
    return data;
  };
  render() {
    return (
      <div>
        {this.displayData()}
        {/* Customers size is: {this.props.customers.length} */}
      </div>
    );
  }
}

ProductList.defaultProps = {
  customers: [],
};
export default ProductList;
