import GlobalConfig from "./Config";
import toast from "react-hot-toast";
import Store from "./index";

let taxAmount = 0;
let totalPrice = 0.0;
let discountAmount = 0.0;
let totalWithTax = 0.0;

const helpers = {
  handleChange: function (event) {
    console.log(event);
  },

  clearData: function () {
    Store.dispatch({ type: "CLEAR_ANALYTICS_DATA" });
    Store.dispatch({ type: "CLEAR_CART_DATA" });
    Store.dispatch({ type: "CLEAR_DISCOUNT_DATA" });
    Store.dispatch({ type: "CLEAR_CUSTOMERS_DATA" });
    Store.dispatch({ type: "CLEAR_INVENTORY_DATA" });
    Store.dispatch({ type: "CLEAR_ORDER_DATA" });
    Store.dispatch({ type: "CLEAR_USERS_DATA" });
    Store.dispatch({ type: "CLEAR_PRODUCT_DATA" });
    Store.dispatch({ type: "CLEAR_COMPANY_INFO" });
  },
  checkQuantity: function (lineItems, products) {
    for (var i = 0; i < lineItems.length; i++) {
      if (lineItems[0].quantityAvailable != undefined) {
        if (
          lineItems[i].quantity > products[i]?.quantityAvailable ||
          lineItems[i].quantity <= 0 ||
          lineItems[i].quantity === isNaN ||
          lineItems[i].quantity === null
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  },

  total: function (lineItems) {
    var sum = 0.0;
    var tax = 0.0;
    for (var i = 0; i < lineItems.length; i++) {
      tax +=
        lineItems[i].price * (lineItems[i].tax / 100) * lineItems[i].quantity;
      sum = sum + lineItems[i].price * lineItems[i].quantity;
    }
    sum = sum + tax;
    return sum;
  },

  taxAddedTotal: function () {
    totalWithTax = taxAmount + totalPrice;
    return totalWithTax;
  },

  payableAmount: function () {
    return totalWithTax - discountAmount;
  },

  calculcateTax: function (lineItems) {
    var tax = 0.0;
    for (var i = 0; i < lineItems.length; i++) {
      tax +=
        lineItems[i].price * (lineItems[i].tax / 100) * lineItems[i].quantity;
    }
    return tax;
  },
  calculcateTaxByElevenPercent: function (lineItems) {
    var tax = 0.11; // equals 11/100
    totalPrice = 0;
    taxAmount = 0;
    lineItems?.map((item) => {
      var lineItemPrice = item.price * item.quantity;
      taxAmount += (lineItemPrice * parseFloat(item.tax)) / 100;
      totalPrice += lineItemPrice;
    });

    return taxAmount;
  },
  calculateSubTotal: function (lineItems) {
    var subTotal = 0.0;
    for (var i = 0; i < lineItems.length; i++) {
      subTotal += lineItems[i].price * lineItems[i].quantity;
    }
    return subTotal;
  },
  returnRefundTotal: function (lineItems) {
    var refundTotal = 0;
    for (var i = 0; i < lineItems.length; i++) {
      refundTotal = refundTotal + lineItems[i].price * lineItems[i].quantity;
    }
    return refundTotal;
  },
  returnTax: function (lineItems) {
    var tax = 0;
    for (var i = 0; i < lineItems.length; i++) {
      tax =
        tax +
        (lineItems[i].tax * lineItems[i].price * lineItems[i].quantity) / 100;
    }
    return Math.round(tax);
  },

  getDiscountAmount: function (discount) {
    if (discount?.totalWithTax) {
      discountAmount =
        discount?.discount_type === "percentage"
          ? discount?.totalWithTax *
            (parseFloat(discount?.discount_value) / 100)
          : parseFloat(discount?.discount_value);
      discountAmount = discountAmount ? discountAmount : 0;
      return discountAmount;
    }
    discountAmount =
      discount?.discount_type === "percentage"
        ? totalWithTax * (parseFloat(discount?.discount_value) / 100)
        : parseFloat(discount?.discount_value);
    discountAmount = discountAmount ? discountAmount : 0;
    return discountAmount;
  },

  //handling adding of items and updating items
  addItemList: async function (classRef, item, varName) {
    if (!Number.isNaN(item.quantity)) {
      if (varName === "editedLineItems") {
        classRef.setState(function (currentState) {
          return {
            editedLineItems: [
              ...currentState.editedLineItems,
              {
                id: item.id,
                quantity: item.quantity,
                price: item.price,
                tax: item.tax,
                productId: item.productId,
              },
            ],
            btnDisable: !currentState.btnDisable,
            btnColor: "#339966",
          };
        });
        const array = classRef.state.editedLineItems;

        for (var i = 0; i < array.length; i++) {
          if (array[i].id === item.id && array[i].quantity !== item.quantity) {
            array[i] = {
              ...array[i],
              quantity: item.quantity,
            };
          }

          await classRef.setState(function (currentState) {
            return {
              editedLineItems: array,
            };
          });
        }
      } else if (varName === "lineItems") {
        for (let i = 0; i < item.length; i++) {
          classRef.setState({
            editedLineItems: [...classRef.state.editedLineItems, item[i]],
            lineItems: [...classRef.state.lineItems, item[i]],
            btnDisable: !classRef.state.btnDisable,
            btnColor: "#339966",
          });
        }
      } else if (varName === "addItem - Refund") {
        if (classRef.state.returnLineItems.length === 0) {
          classRef.setState(function (currentState) {
            return {
              returnLineItems: [...currentState.returnLineItems, item],
            };
          });
        } else {
          const array = classRef.state.returnLineItems;
          const inArray = classRef.state.returnLineItems.filter(
            (arrayItem) => arrayItem.id === item.id
          );

          if (inArray.length === 0) {
            array.push(item);
          }

          for (let i = 0; i < array.length; i++) {
            if (
              array[i].id === item.id &&
              array[i].quantity !== item.quantity
            ) {
              array[i] = {
                ...array[i],
                quantity: item.quantity,
                subtotal: item.subtotal,
              };
            }
          }
          await classRef.setState(function (currentState) {
            return {
              returnLineItems: array,
            };
          });
        }
      }
    }
  },

  deleteItem: function (classRef, event, varName) {
    var name = null;
    if (varName === "editOrder") {
      if (event.productId === null) {
        name = event.productName;

        classRef.setState(function (currentState) {
          return {
            lineItems: currentState.lineItems.filter(
              (item) => item.productId !== name
            ),
            editedLineItems: currentState.editedLineItems.filter(
              (item) => item.id !== name
            ),
          };
        });
      } else {
        name = event.productId;
        classRef.setState(function (currentState) {
          return {
            lineItems: currentState.lineItems.filter(
              (item) => item.productId !== name
            ),
            editedLineItems: currentState.editedLineItems.filter(
              (item) => item.productId !== name
            ),
            btnColor: "#D3D3D3",
          };
        });
      }
    } else if (varName === "lineItems") {
      for (var i = 0; i < classRef.state.lineItems.length; i++) {
        if (
          event.quantity === isNaN ||
          event.quantity === classRef.state.lineItems[i].quantity ||
          event.quantity === ""
        ) {
          if (classRef.state.editedLineItems.length > 0) {
            classRef.setState({
              editedLineItems: classRef.state.editedLineItems[i].filter(
                (item) => item.id !== event.id
              ),
            });
          }
        }
      }
    } else if (varName === "deleteItem - Refund") {
      if (
        event.quantity === isNaN ||
        event.quantity === 0 ||
        event.quantity === ""
      ) {
        if (classRef.state.returnLineItems.length > 0) {
          classRef.setState({
            returnLineItems: classRef.state.returnLineItems.filter(
              (item) => item.id !== event.id
            ),
          });
        }
      }
    }
  },

  convertTime(timestamp) {
    // Parse the input timestamp
    let parts = timestamp?.split(".");
    let year = parseInt(parts[0]);
    let month = parseInt(parts[1]);
    let day = parseInt(parts[2]);
    let hour = parseInt(parts[3]);
    let minute = parseInt(parts[4]);
    let second = parseInt(parts[5]);
    let milliseconds = parseInt(parts[6]);

    // Create a new Date object
    let date = new Date(
      year,
      month - 1,
      day,
      hour,
      minute,
      second,
      milliseconds
    );

    // Format the date in the desired format
    let formattedDate = `${("0" + date.getDate()).slice(-2)}/${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}/${date.getFullYear()} ${(
      "0" + (date.getHours() % 12 || 12)
    ).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${(
      "0" + date.getSeconds()
    ).slice(-2)} ${date.getHours() >= 12 ? "PM" : "AM"}`;

    return formattedDate;
  },

  handleCloseModal: function (classRef, modalName) {
    if (modalName === "addCustomItemModal") {
      classRef.setState({ addCustomItemModal: false });
    } else if (modalName === "addDiscountModal") {
      classRef.setState({ addDiscountModal: false });
    } else if (modalName === "browseProductsModal") {
      classRef.setState({ browseProductsModal: false });
    } else if (modalName === "addTaxModal") {
      classRef.setState({ addTaxModal: false });
    } else if (modalName === "addShippingModal") {
      classRef.setState({ addShippingModal: false });
    }
  },
  handleOpenModal: function (classRef, modalName) {
    if (modalName === "addCustomItemModal") {
      classRef.setState({ addCustomItemModal: true });
    } else if (modalName === "addDiscountModal") {
      classRef.setState({ addDiscountModal: true });
    } else if (modalName === "browseProductsModal") {
      classRef.setState({ browseProductsModal: true });
    } else if (modalName === "addTaxModal") {
      classRef.setState({ addTaxModal: true });
    } else if (modalName === "addShippingModal") {
      classRef.setState({ addShippingModal: true });
    }
  },
  helper3: function (param1, param2) {
    console.log(param1);
    console.log(param2);
  },
  toastNotify: (status, message, promise) => {
    if (status == "success") {
      toast.success(message, {
        duration: 4000,
      });
    } else if (status == "promise") {
      toast.promise(promise, {
        loading: "Loading...",
        success: message,
        error: "Please try again later.",
      });
    } else if (status == "warning") {
      toast(message, {
        icon: " ⚠️ ",
      });
    } else {
      toast.error(message, {
        duration: 4000,
      });
    }
  },
  generateBaseUrl: () => {
    fetch("./instance.json")
      .then(function (response) {
        return response.json();
      })
      .then(async function (myJson) {
        // console.log(myJson.companyName);
        GlobalConfig.companyName = myJson.companyName;
        GlobalConfig.instanceId = myJson.instanceId;
        GlobalConfig.basePath =
          GlobalConfig.ip + myJson.companyName + "/" + myJson.instanceId;
        console.log("Base Url", GlobalConfig.basePath);
        GlobalConfig.appPath =
          "apps/" + myJson.companyName + "/" + myJson.instanceId;
        return GlobalConfig.basePath;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default helpers;
