import React, { Component } from "react";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { TextField } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

class SelectDates extends Component {
    onHandleBack = () => {
        this.props.setStep(1);
      };
  render() {
    const { startDate, endDate } = this.props.values;
    return (
      <>
        <div>
          <PageTitle
            textType="h6"
            value="Set Dates"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.onHandleBack}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ marginLeft: "8px" }}>
            <p>Set Start Date</p>
            <MobileDatePicker
              label="Start Date"
              inputFormat="MM/dd/yyyy"
              value={startDate}
              onChange={this.props.handleChangeStartDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div style={{ marginLeft: "8px", marginTop: "10px" }}>
            <p>Set End Date</p>
            <MobileDatePicker
              label="End Date"
              inputFormat="MM/dd/yyyy"
              value={endDate}
              onChange={this.props.handleChangeEndDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>
      </>
    );
  }
}

export default SelectDates;
