const initial_state = {
  organizations: [],
};

const organizationsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_ORGANIZATIONS":
      console.log(action);
      return Object.assign({}, state, {
        organizations: action.organizations,
      });

    case "CLEAR_DATA":
      state = {
        organizations: [],
      };

    default:
      return state;
  }
};

export default organizationsReducer;
