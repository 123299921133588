import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import CustomProducts from "../../components/ProductsModule/CustomProducts";
import EditInfoModal from "../../components/OrderModals/EditContactInfoModal";
import Popup from "../../components/Popup";
import ShippingAddressModal from "../../components/OrderModals/ShippingAddressModal";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GlobalConfig from "../../Config";
import Store from "../../index";
import Loader from "../../MobileComponents/Loader";
import SnackBar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { connect } from "react-redux";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import { useHistory } from "react-router-dom";
import helpers from "../../UtilitiesFunctions";
import CancelOrderModal from "../../components/OrderModals/CancelOrderModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintSlip from "../../components/print/PrintSlip";
import autoTable from "jspdf-autotable";
import PrintNew from "../../components/print/PrintNew";
const useStyles = (theme) => ({
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
  root: {
    flexGrow: 1,
    //padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    console.log(this.state);
    console.log("SINGLE PROPS", this.props);
    console.log(this.props.global_config.basePath);
    let orderr = {};
    function fetchOrder() {
      fetch(
        `${this.props.global_config.basePath}/orders/${
          this.props.history.location.pathname.split("/")[2]
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        }
      )
        .then(async (response) => {
          let data = await response.json();
          console.log(data);
          this.setState({ orderData: data[0] });
          this.props.location.state["order"] = data[0];
          orderr = data[0];
          return data[0];
        })
        .catch((e) => {
          console.log(e);
        });
    }
    this.state = {
      printingSlip: false,
      printingNewSlip: false,
      orderPreviewCustomer: true,
      loaded: false,
      snackBar: false,
      snackBarMessage: "",
      snackBarType: "",
      loader: true,
      loaderState: "Loading order ...",
      openCancelOrderModal: false,
      setAnchorEl: null,
      openAddNote: false,
      openEditContact: false,
      openEditShipping: false,
      modelClose: false,
      note: "",
      contactInfo: {
        email: "",
        phone_number: null,
      },
      shippingAddress: {
        addressContactId: "",
        addressId: "",
        address: "",
        stateOrProvince: "",
        city: "",
        country: "",
        postal_code: null,
        phone: null,
      },
      order: null,
      orderNo: null,
      lineItems: null,
      returnedItems: null,
      customerData: null,
      orderData: orderr,
    };
  }

  handleClick = (event) => {
    console.log("here is target", event.target);
    this.state.modelClose = true;
    this.setState({ setAnchorEl: event.currentTarget });
  };

  handleCloseAnchor = () => {
    this.setState({ setAnchorEl: null });
  };

  componentDidMount = () => {
    this.setState({ loaderState: "Loading order ..." });
    console.log(this.props.history.location.pathname.split("/")[2]);
    this.getCustomerData(this.state.order?.customerId);
    console.log(this.props.global_config.basePath + "/orders/");
    this.setState({
      orderNo: this.props.history.location.pathname.split("/")[2],
    });
    fetch(
      `${this.props.global_config.basePath}/orders/${
        this.props.history.location.pathname.split("/")[2]
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      }
    )
      .then(async (response) => {
        let data = await response.json();
        if (data.length > 0) {
          this.setState({ orderData: data[0] });
          this.setState({ order: data.length && data[0] });
          console.log(this.state.loaded);
          if (data[0].customerId != null && data[0].customerId != "null") {
            await this.setState({
              loaderState: "Loading customer details ...",
            });
            fetch(
              `${this.props.global_config.basePath}/customers/${data[0].customerId}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer my-token",
                },
              }
            )
              .then(async (cusResponse) => {
                let cusData = await cusResponse.json();
                await this.setState({ customerData: cusData });
                await this.setState({ loaded: true });
                await this.setState({ loader: false });
                await this.setState({ orderPreviewCustomer: true });
              })
              .catch(async (err) => {
                console.log(err);
                helpers.toastNotify(
                  "error",
                  "Could Not Loading Customer Details."
                );
                // await this.props.history.push("/orders")
                await this.setState({
                  loaderState: "Error loading customer details!",
                });
                await this.setState({ orderPreviewCustomer: false });
                await setTimeout(() => {
                  this.setState({ loaded: true });
                  this.setState({ loader: false });
                }, 1500);
              });
          } else {
            await this.setState({ orderPreviewCustomer: false });
            await setTimeout(() => {
              this.setState({ loaded: true });
              this.setState({ loader: false });
            }, 1500);
          }
        } else {
          helpers.toastNotify("error", "Not found!");
          this.props.history.push("/ViewOrders");
        }
      })
      .catch(async (e) => {
        console.log(e);
        await this.setState({ loaderState: "Error loading order details!" });
      });
  };

  getCustomerData = (customerID) => {
    if (customerID !== null && customerID !== "null") {
      const customers = this.props.g_active_customers.concat(
        this.props.g_archived_customers
      );

      // find customer from redux and set it to getCustomerData
      const customerData = customers.find(
        (customer) => customer?.id === customerID
      );
      this.setState({ customerData: customerData });
    }
  };

  handleClickOpen = (name) => {
    if (name === "AddNote") {
      this.setState({ openAddNote: true });
    } else if (name === "EditContact") {
      this.setState({ openEditContact: true });
    } else if (name === "EditShipping") {
      this.setState({ openEditShipping: true });
    } else if (name === "OrderCancelModal") {
      this.setState({ openCancelOrderModal: true });
    }
  };
  handleClose = (name) => {
    if (name === "AddNote") {
      this.setState({ openAddNote: false });
    } else if (name === "EditContact") {
      this.setState({ openEditContact: false });
    } else if (name === "EditShipping") {
      this.setState({ openEditShipping: false });
    } else if (name === "OrderCancelModal") {
      this.setState({ openCancelOrderModal: false });
    }
  };
  updateNote = (note) => {
    console.log(this.props);
    this.setState({ note: note });
    this.handleClose("AddNote");
  };
  updateContactInfo = (contactInfo) => {
    console.log(contactInfo);
    const updateData = {
      email: contactInfo.email,
      phoneNumber: contactInfo.phoneNumber,
      phoneNumberContactId: this.state.customerData.phoneNumberContactId,
      phoneNumberId: this.state.customerData.phoneNumberId,
      emailContactId: this.state.customerData.emailContactId,
      emailId: this.state.customerData.emailId,
      name: this.state.customerData.name,
      querry: "name",
    };
    console.log("updated Data" + JSON.stringify(updateData));
    let url =
      this.props.global_config.basePath +
      `/customers/${this.state.order.customerId}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(updateData),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          this.props.global_config.basePath +
            `/customers/${this.state.order.customerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_ACTIVE_CUSTOMER",
              updated_customer: data2,
            });
            this.getCustomerData(this.state.order.customerId);
          })
          .catch((err) => {
            console.log(err);
            helpers.toastNotify(
              "error",
              "Something Went Wrong While Fetching Customer!"
            );
          });

        setTimeout(() => {
          this.setState({ backdrop: false, snackbar: false });
        }, 10000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Cannot Edit Customer.");
      });

    this.handleClose("EditContact");
  };
  setSnackbarToFalse = () => {
    setTimeout(() => {
      this.setState({ snackbar: false });
    }, 5000);
  };
  updateOrderAsPaid = (order) => {
    if (order.orderId != null) {
      this.setState({ loader: true, loaderMessage: "Processing Payment" });
      let url = this.props.global_config.basePath + "/orders/" + order.orderId;
      console.log("url: " + url);
      fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
        body: JSON.stringify(order),
      })
        .then((result) => {
          if (result.status === 200) {
            console.log(
              this.props.global_config.basePath + "/orders/" + order?.orderId
            );
            fetch(
              this.props.global_config.basePath + "/orders/" + order.orderId,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer my-token",
                },
              }
            )
              .then((response2) => response2.json())
              .then((data2) => {
                if (data2[0].fulfilled !== true) {
                  Store.dispatch({
                    type: "MARK_AS_PAID_UNFULFILLED",
                    orders: data2,
                  });
                } else {
                  Store.dispatch({
                    type: "MARK_AS_PAID_FULFILLED",
                    orders: data2,
                  });
                }
                console.log(data2);

                helpers.toastNotify("success", "Order Successfully Paid! ");
                setTimeout(() => {
                  this.props.history.push("/ViewOrders");
                }, 4000);
              });
          } else {
            this.setState({
              loader: false,
              snackBar: false,
              snackBarMessage: "Error! Something went wrong",
              snackBarType: "error",
            });
            helpers.toastNotify("error", "Something Went Wrong!");
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
            snackBar: false,
            snackBarMessage: "Error! Network Error Occured!",
            snackBarType: "error",
          });
          helpers.toastNotify("error", "Network Error Occured!");
        });
    }
  };

  handleOrderUdpateAsPaid = () => {
    const order = this.state.orderData;
    // const order = this.props.location.state.order;
    const billUnitId = "2";
    const billStatus = "pending";
    const { customerId, orderId, paid } = order;
    var orderToBePaid = { customerId, orderId, paid, billUnitId, billStatus };
    if (!customerId || customerId === "null") orderToBePaid = { orderId, paid };
    orderToBePaid.paid = true;
    console.log("order to be paid is:", orderToBePaid);
    this.updateOrderAsPaid(orderToBePaid);
  };

  fetchData = (id) => {
    this.setState({ loaderState: "Loading order ..." });
    fetch(`${this.props.global_config.basePath}/orders/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then(async (response) => {
        let data = await response.json();
        if (data.length > 0) {
          this.setState({ orderData: data[0] });
          this.setState({ order: data.length && data[0] });
          if (data[0].customerId != null) {
            this.setState({ loaderState: "Loading customer details ..." });
            fetch(
              `${this.props.global_config.basePath}/customers/${data[0].customerId}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer my-token",
                },
              }
            )
              .then(async (cusResponse) => {
                let cusData = await cusResponse.json();
                await this.setState({ orderPreviewCustomer: true });
                await this.setState({ customerData: cusData });
                await this.setState({ loaded: true });
                await this.setState({ loader: false });
                return false;
              })
              .catch(async (err) => {
                this.setState({
                  loaderState: "Error fetching customer details!",
                });
                await this.setState({ orderPreviewCustomer: false });
                await setTimeout(() => {
                  this.setState({ loaded: true });
                  this.setState({ loader: false });
                }, 1500);
                return false;
              });
          }
        } else {
          helpers.toastNotify("error", "Not found!");
          this.props.history.push("/ViewOrders");
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loaderState: "Error fetching order details!" });
      });
  };

  printslip = async (orderData) => {
    console.log(orderData);

    window.onbeforeprint = this.setState({ printingSlip: true });
    setTimeout(() => {
      window.print();
      window.onafterprint = this.setState({ printingSlip: false });
    }, 1000);
  };

  exportPDF = async (data) => {
    window.onbeforeprint = this.setState({
      printingSlip: true,
      printingNewSlip: true,
    });
    setTimeout(() => {
      window.print();
      window.onafterprint = this.setState({
        printingSlip: false,
        printingNewSlip: false,
      });
    }, 1000);
  };

  printContent = async () => {
    console.log(this.state.modelClose);
    await window.print();
  };

  updateCancelModalVis = (newValue) => {
    this.setState({ openCancelOrderModal: newValue });
  };

  updateShippingAddress = async (shippingAddress) => {
    const updateAddres = {
      address: [
        {
          addressContactId: this.state.customerData.address[0].addressId,
          addressId: this.state.customerData.address[0].addressId,
          city: shippingAddress.city,
          country: shippingAddress.country,
          postCode: shippingAddress.postal_code,
          stateOrProvince: shippingAddress.stateOrProvince,
          street: shippingAddress.address,
          preferred: true,
        },
      ],
    };
    console.log("updated Data" + JSON.stringify(updateAddres));
    let url = `${this.props.global_config.basePath}/customers/${this.state.order.customerId}`;
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(updateAddres),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          this.props.global_config.basePath +
            `/customers/${this.state.order.customerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_ACTIVE_CUSTOMER",
              updated_customer: data2,
            });
          });
        this.getCustomerData(this.state.order.customerId);

        setTimeout(() => {
          this.setState({ backdrop: false, snackbar: false });
          helpers.toastNotify(
            "error",
            "Shipping Address Successfully Updated!"
          );
        }, 10000);
      })
      .catch((err) => {
        window.alert(err);
        helpers.toastNotify("error", "Shipping Address Updation Failed.");
      });
    console.log(GlobalConfig);
    console.log(url);

    this.handleClose("EditShipping");
  };
  render() {
    let orderData = {};

    const { classes } = this.props;
    const { fulfilled, paid } = orderData;
    const { loader, snackBar, snackBarMessage, snackBarType, loaded } =
      this.state;
    console.log(this.state.orderData);

    if (this.state.printingSlip) {
      if (this.state.printingNewSlip) {
        return <PrintNew orderData={this.state.orderData} />;
      }
      return <PrintSlip orderData={this.state.orderData} />;
    } else {
      if (this.state.loaded) {
        return (
          <>
            <div>
              {snackBar ? (
                <SnackBar
                  message={"Order is Paid Successfully"}
                  type={"success"}
                />
              ) : null}
            </div>
            {loader ? (
              <Loader value="Processing Payment" />
            ) : (
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper style={{ backgroundColor: SKY_BLUE, color: WHITE }}>
                      <Box className={classes.box} p={1} width="100%">
                        <Grid container alignItems="center">
                          <Grid item>
                            <ArrowBackIcon
                              style={{
                                verticalAlign: "middle",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.history.push("/ViewOrders");
                              }}
                            />
                          </Grid>

                          <Grid item>
                            <Box p={0.5} flexGrow={1} fontSize="20px">
                              #{this.state.orderData.orderId}{" "}
                              {this.state.orderData.paid ? (
                                <>
                                  <img
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "5px",
                                    }}
                                    src={"../../done.png"}
                                    alt="logo"
                                  />
                                  {" Paid"}
                                </>
                              ) : (
                                <>
                                  <img
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "5px",
                                    }}
                                    src={"../../undone.png"}
                                    alt="logo"
                                  />
                                  {" Unpaid"}
                                </>
                              )}
                              {this.state.orderData.fulfilled ? (
                                <>
                                  <img
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "5px",
                                    }}
                                    src={"../../done.png"}
                                    alt="logo"
                                  />
                                  {" Fulfilled"}
                                </>
                              ) : (
                                <>
                                  <img
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "5px",
                                    }}
                                    src={"../../undone.png"}
                                    alt="logo"
                                  />
                                  {" Unfulfilled"}
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row">
                          <Button
                            size="small"
                            // variant="text"
                            style={{
                              color: WHITE,
                            }}
                            onClick={() => {
                              if (!this.state.order.fulfilled) {
                                helpers.toastNotify(
                                  "error",
                                  "Unfulfilled/Unpaid Orders Can Not Be Returned!"
                                );
                              } else {
                                this.props.history.push({
                                  pathname: "/refund",
                                  state: {
                                    order: this.state.order,
                                    customer: this.state.customerData,
                                    receipt_metadata:
                                      this?.props?.location?.state
                                        ?.receipt_metadata,
                                  },
                                });
                              }
                            }}
                          >
                            Refund
                          </Button>
                          <Button
                            size="small"
                            //variant="contained"
                            style={{
                              marginRight: "10px",
                              color: WHITE,
                            }}
                            onClick={() => {
                              if (this.state.order.paid) {
                                helpers.toastNotify(
                                  "error",
                                  "Paid Orders Can Not Be Edited!"
                                );
                                // alert("paid orders can not be edited!");
                              } else if (this.state.order.fulfilled) {
                                helpers.toastNotify(
                                  "error",
                                  "Fulfilled Orders Can Not Be Edited!"
                                );
                                // alert("fulfilled orders can not be edited!");
                              } else {
                                this.props.history.push({
                                  pathname: "/edit",
                                  state: this.state.order,
                                });
                              }
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            style={{ color: WHITE }}
                            onClick={this.handleClick}
                          >
                            More actions
                          </Button>
                          <Menu
                            elevation={20}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                            setAnchorEl={this.state.setAnchorEl}
                            keepMounted
                            open={Boolean(this.state.setAnchorEl)}
                            // open={this.state.modelClose}
                            onClose={this.handleCloseAnchor}
                            // onClose={this.state.modelClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                setTimeout(() => {
                                  this.exportPDF(this.state.orderData);
                                }, 1000);
                                this.handleCloseAnchor();
                                // alert("print order page");
                              }}
                            >
                              <ListItemIcon>
                                <PrintIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Print order page" />
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                this.printslip(this.state.orderData);
                                // alert("print packing slips");
                              }}
                            >
                              <ListItemIcon>
                                <PrintIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Print packing slips" />
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                this.props.history.push(
                                  `/order/status/${
                                    this.props.history.location.pathname.split(
                                      "/"
                                    )[2]
                                  }`
                                );
                              }}
                            >
                              <ListItemIcon>
                                <VisibilityIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="View order status page" />
                            </MenuItem>
                          </Menu>
                          <Grid item>
                            <KeyboardArrowLeftIcon
                              fontSize="large"
                              style={{
                                verticalAlign: "middle",
                                marginTop: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // alert("left");
                                console.log(
                                  this.props.history.location.pathname.split(
                                    "/"
                                  )
                                );
                                let nextUrl =
                                  this.props.history.location.pathname.split(
                                    "/"
                                  )[2];
                                nextUrl = parseInt(nextUrl);
                                this.props.history.push({
                                  pathname:
                                    "/viewOrder/" + parseInt(nextUrl - 1),
                                });
                                this.setState({
                                  orderId:
                                    "/viewOrder/" + parseInt(nextUrl - 1),
                                });
                                this.setState({ loaded: false });
                                this.fetchData(parseInt(nextUrl - 1)) &&
                                  this.setState({ loaded: true });
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon
                              fontSize="large"
                              style={{
                                verticalAlign: "middle",
                                marginTop: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // alert("right");
                                console.log(
                                  this.props.history.location.pathname.split(
                                    "/"
                                  )
                                );
                                let nextUrl =
                                  this.props.history.location.pathname.split(
                                    "/"
                                  )[2];
                                nextUrl = parseInt(nextUrl);
                                this.props.history.push({
                                  pathname:
                                    "/viewOrder/" + parseInt(nextUrl + 1),
                                });
                                this.setState({
                                  orderId:
                                    "/viewOrder/" + parseInt(nextUrl + 1),
                                });
                                this.setState({ loaded: false });
                                this.fetchData(parseInt(nextUrl - 1)) &&
                                  this.setState({ loaded: true });
                              }}
                            />
                          </Grid>
                        </Box>
                      </Box>
                      <Box style={{ marginLeft: "35px" }}>
                        {this.state.orderData.createdAt.substring(0, 10) +
                          " at " +
                          this.state.orderData.createdAt.substring(11, 16) +
                          " from Orders"}
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Paper className={classes.paper}>
                      <div
                        style={{
                          backgroundColor: "white",
                          marginTop: "10px",
                        }}
                      >
                        <h5>
                          {this.state.orderData.fulfilled ? (
                            <>
                              <img
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  padding: "5px",
                                }}
                                src={"../../done.png"}
                                alt="logo"
                              />
                              {" Fulfilled"}
                            </>
                          ) : (
                            <>
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  marginTop: "-6px",
                                  padding: "5px",
                                }}
                                src={"../../undone.png"}
                                alt="logo"
                              />
                              {" Unfulfilled"}
                            </>
                          )}
                          ({this.state.orderData.lineItems.length})
                        </h5>
                        <div
                          style={{
                            display: "block",
                          }}
                        >
                          <Box>
                            <CustomProducts
                              products={this.state.orderData.lineItems}
                              isDelete={false}
                              disableQty={true}
                            />
                          </Box>
                        </div>
                        <hr />

                        <Button
                          size="small"
                          variant="contained"
                          style={{
                            textTransform: "none",
                            float: "right",
                            marginRight: "10px",
                            backgroundColor: this.state.orderData.paid
                              ? MARS
                              : SKY_BLUE,
                            color: WHITE,
                          }}
                          disabled={this.state.orderData.paid}
                          onClick={() => {
                            //alert("paid clicked");
                            this.handleOrderUdpateAsPaid();
                          }}
                        >
                          Mark as Paid
                        </Button>

                        <Button
                          size="small"
                          variant="contained"
                          style={{
                            textTransform: "none",
                            float: "right",
                            marginRight: "10px",
                            backgroundColor: this.state.orderData.fulfilled
                              ? MARS
                              : SKY_BLUE,
                            color: WHITE,
                          }}
                          disabled={this.state.orderData.fulfilled}
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/fulfill",
                              state: this.state,
                            });
                          }}
                        >
                          Mark as fulfilled
                        </Button>
                      </div>

                      <div
                        style={{
                          backgroundColor: "white",
                          marginTop: "50px",
                        }}
                      >
                        {this.state.orderData.paid ? (
                          <>
                            <Box display="inline-flex">
                              <img
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  padding: "5px",
                                }}
                                src={"../../done.png"}
                                alt="logo"
                              />
                              <h6>Paid</h6>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box display="inline-flex">
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  marginTop: "-6px",
                                  padding: "5px",
                                }}
                                src={"../../undone.png"}
                                alt="logo"
                              />
                              <h6>Pending</h6>
                            </Box>
                          </>
                        )}

                        <Box display="inline-flex" p={1} width="100%">
                          <Box p={0.5} flexGrow={2}>
                            Subtotal
                          </Box>
                          <Box p={0.5} flexGrow={2}>
                            {this.state.orderData.returned
                              ? this.state.orderData.lineItems?.length
                              : this.state.orderData.lineItems?.length}{" "}
                            items
                          </Box>
                          <Box p={0.5}>Rs {this.state.orderData.subTotal}</Box>
                        </Box>
                        {this.state.returnedItems?.length > 0 ? (
                          <>
                            <Box display="inline-flex" p={1} width="100%">
                              <Box p={0.5} flexGrow={2}>
                                Returned Amount (Inc Taxes)
                              </Box>
                              <Box p={0.5}>
                                Rs{" "}
                                {
                                  this.props.location.state.returnedItems[0]
                                    .total
                                }
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                        <Box display="inline-flex" p={1} width="100%">
                          <Box p={0.5} flexGrow={2}>
                            Tax
                          </Box>
                          <Box p={0.5} flexGrow={2}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GST{" "}
                            {Math.round(this.state.orderData.taxRate)}%
                          </Box>
                          <Box p={0.5}>
                            Rs {Math.round(this.state.orderData.taxAmount)}
                          </Box>
                        </Box>

                        <Box display="inline-flex" p={1} width="100%">
                          <Box p={0.5} flexGrow={2}>
                            Total
                          </Box>
                          {/* <Box p={0.5} flexGrow={2}>
                </Box> */}
                          <Box p={0.5}>
                            Rs {Math.round(this.state.orderData.total)}
                          </Box>
                        </Box>

                        <hr />
                        {this.state.orderData?.discountAmount > 0 && (
                          <Box display="inline-flex" p={1} width="100%">
                            <Box p={0.5} flexGrow={2}>
                              Discount
                            </Box>
                            <Box p={0.5}>
                              Rs{" "}
                              {Math.round(this.state.orderData.discountAmount)}
                            </Box>
                          </Box>
                        )}
                        <Box display="inline-flex" p={1} width="100%">
                          <Box p={0.5} flexGrow={2}>
                            <b>Paid Amount</b>
                          </Box>

                          <Box p={0.5}>
                            <b>
                              Rs{" "}
                              {Math.round(this.state.orderData.payableAmount)}
                            </b>
                          </Box>
                        </Box>

                        {this.state.orderData?.discountReason != "null" &&
                          this.state.orderData?.discountReason != "" &&
                          this.state.orderData?.discountReason && (
                            <>
                              <hr />
                              <Box display="inline-flex" p={1} width="100%">
                                <Box p={0.5} flexGrow={2}>
                                  <u>Discount Reason:</u>

                                  <p>{this.state.orderData?.discountReason}</p>
                                </Box>
                              </Box>
                            </>
                          )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    {this.state.orderPreviewCustomer && (
                      <Paper
                        style={{ marginTop: "20px" }}
                        className={classes.paper}
                      >
                        <Box p={1}>
                          Customer
                          <Box>
                            {this.state.customerData === null ? (
                              <>No Customer</>
                            ) : (
                              <>{this.state.customerData?.name}</>
                            )}
                          </Box>
                        </Box>
                        <hr />
                        <Box p={1}>
                          CONTACT INFORMATION
                          <Popup
                            title="Edit contact information"
                            btnText="Edit"
                            btnFloat="right"
                            marginTop="-30px"
                            btnColor="lightblue"
                            contentTxt={<hr />}
                            contentTxtBe={<hr />}
                            content={
                              <EditInfoModal
                                updateContactInfo={this.updateContactInfo}
                                close={this.onClickBtnClose}
                                state={{
                                  note: this.state.contactInfo,
                                }}
                                customer={this.state.customerData}
                              />
                            } //component to send
                            openPopup={this.state.openEditContact} //open
                            onClickBtnOpen={() =>
                              this.state.customerData &&
                              this.handleClickOpen("EditContact")
                            } //on click open
                            onClickBtnClose={() =>
                              this.handleClose("EditContact")
                            } //on click close
                          />
                          <Box>
                            {this.state.customerData === null ? ( //this.state.contactInfo.email === ""
                              <>No email provided No phone number</>
                            ) : (
                              <>
                                {
                                  this.state.customerData?.email + //this.state.contactInfo.email
                                    "\n" +
                                    this.state.customerData?.phoneNumber //this.state.contactInfo.phone_number
                                }
                              </>
                            )}
                          </Box>
                        </Box>

                        <hr />
                        <Box p={1}>
                          SHIPPING ADDRESS
                          <Popup
                            title="Edit shipping address"
                            btnText="Edit"
                            btnFloat="right"
                            marginTop="-30px"
                            btnColor="lightblue"
                            contentTxt={<hr />}
                            contentTxtBe={<hr />}
                            content={
                              <ShippingAddressModal
                                updateShippingAddress={
                                  this.updateShippingAddress
                                }
                                close={this.onClickBtnClose}
                                state={this.state.shippingAddress}
                                address={this.state.customerData?.address[0]}
                              />
                            } //component to send
                            openPopup={this.state.openEditShipping} //open
                            onClickBtnOpen={() =>
                              this.state.customerData &&
                              this.state.customerData.address &&
                              this.state.customerData.address[0] &&
                              this.handleClickOpen("EditShipping")
                            } //on click open
                            onClickBtnClose={() =>
                              this.handleClose("EditShipping")
                            } //on click close
                          />
                          <Box>
                            {this.state.customerData === null ? ( //this.state.shippingAddress.first_name === ""
                              <>No shipping address provided</>
                            ) : (
                              <>
                                {
                                  this.state.customerData?.address[0].street + //this.state.shippingAddress.first_name
                                    "\n" +
                                    this.state.customerData?.address[0].city + //this.state.shippingAddress.last_name
                                    "\n" +
                                    this.state.customerData?.address[0]
                                      .stateOrProvince + //this.state.shippingAddress.company
                                    "\n" +
                                    this.state.customerData?.address[0]
                                      .postCode + //this.state.shippingAddress.address
                                    "\n" +
                                    this.state.customerData?.address[0].country //this.state.shippingAddress.apartment
                                }
                              </>
                            )}
                          </Box>
                        </Box>

                        <hr />
                        <Box p={1}>
                          BILLING ADDRESS
                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              marginTop: "-10px",
                              float: "right",
                              textTransform: "none",
                              backgroundColor: SKY_BLUE,
                              color: WHITE,
                            }}
                          >
                            Edit
                          </Button>
                          <Box>No billing address provided</Box>
                        </Box>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
                <CancelOrderModal
                  visibility={this.state.openCancelOrderModal}
                  orderData={this.state.orderData}
                  setVisibility={this.updateCancelModalVis}
                />
              </div>
            )}
          </>
        );
      } else {
        return (
          <Loader
            value={
              this.state.loaderState != null
                ? this.state.loaderState
                : "Loading Order ..."
            }
          />
        );
      }
    }
  }
}
const mapStateToProps = (state) => {
  return {
    g_archived_customers: state.customersState.g_archived_customers,
    g_active_customers: state.customersState.active_customers,
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps, null)(withStyles(useStyles)(ViewOrder));
