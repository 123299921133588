import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import Box from "@material-ui/core/Box";
import Popup from "../Popup";
import ImageUrlModal from "./ImageUrlModal";
import "react-dropdown/style.css";
import ImageIcon from "@material-ui/icons/Image";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import ClearIcon from "@material-ui/icons/Clear";
import TableRow from "@material-ui/core/TableRow";
import { cartesianProduct } from "cartesian-product-multiple-arrays";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Store from "../../../src/index";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { SKY_BLUE, WHITE } from "../../colors/colors";
import { uploadImage } from "./ImageController";
import SimpleReactValidator from "simple-react-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import "./product.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// import TextEditor from "../Editor/TextEditor";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DropzoneArea } from "material-ui-dropzone";
import GlobalConfig from "../../Config";
import helpers from "../../UtilitiesFunctions";
import { AddPhotoAlternate, Delete, Edit } from "@material-ui/icons";
import Chip from "@mui/material/Chip";

const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },
  formContainer: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "55%",
    },
  },

  formItems: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
  },
  formInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  formList: {
    width: "85%",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formRightBox: {
    width: "40%",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textDanger: {
    color: "red",
  },

  arrowroute: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
      marginBottom: "20px",
      marginLeft: "10px",
    },
  },

  pricing: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.only("lg")]: {
      display: "flex",

      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  pricingfixwd: {
    [theme.breakpoints.only("xs")]: {
      width: "300px",
    },
    [theme.breakpoints.only("lg")]: {
      width: "200px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("md")]: {
      width: "200px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "200px",
      marginLeft: "10px",
    },
  },

  variantresponsive: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "55vw",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
  },

  variantshow: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "55vw",
    overflowX: "scroll",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
  },

  variantgenerationd: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
    maxWidth: "55vw",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100vw",
    },
  },

  editproductsave: {
    marginRight: "10px",
    [theme.breakpoints.only("xs")]: {
      marginRight: "30px",
      marginBottom: "10px",
      padding: "20px",
    },
  },
});
const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];
// const options = ["Active", "Draft"];
const statuses = [
  {
    POID: 1,
    value: "Active",
  },
  {
    POID: 2,
    value: "Draft",
  },
  {
    POID: 3,
    value: "Archived",
  },
];
const status = [
  { code: "Active", label: "Active" },
  { code: "Draft", label: "Draft" },
];

const sampleVarient = {
  price: null,
  startDate: "2024-01-11",
  endDate: "2024-01-11",
  taxRate: null,
  barcodes: [
    {
      code: null,
      unitOfMeasureRefId: "1",
    },
  ],
  status: "Active",
  images: [],
  quantity: null,
  costPrice: null,
  productSpecCharacteristics: [],
  unitsOfMeasures: [
    {
      id: 1,
      isDefault: true,
      conversionFactor: 1,
      name: "Nos",
      relation: "includes",
      price: null,
    },
  ],
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EditProduct extends Component {
  constructor(props) {
    super(props);

    console.log(`Edit Product`, props);

    this.state = {
      backdrop: false,
      name: "",
      description: "",
      status: "",
      changedStatus: "",
      price: "",
      id: "",
      algorithm: "RuleEngine.groovy",
      images: "",
      quantity: "",
      varients: [],
      varientLoading: true,
      varientData: [],
      checked: false,
      users: [{ name: "", value: "" }],
      arr: [],
      productSpecCharacteristics: [],
      obj_parent: {},
      numberOfVarients: [],
      show: false,
      submit: false,
      snackbar: false,
      errorSnackbar: false,
      categoryName: "",
      categoryDescription: "",
      categories: [],
      categoryName: "",
      categoryId: "",
      costPrice: "",
      categoryModal: false,
      variantModal: false,
      imagePickerModal: false,
      taxRate: 0,
      barcodes: [
        {
          code: null,
          unitOfMeasureRefId: "1",
        },
      ],
      variantImages: [],
      attributes: { attributeName: "", attributeValues: [{ value: "" }] }, // Initial empty object with an array of value objects
      allAttributeVariant: [],
      allVariants: [],
    };
    this.handleSubmit_DF = this.handleSubmit_DF.bind(this);
    this.addVar = this.addVar.bind(this);
    this.validator = new SimpleReactValidator({
      validators: {
        positiveNumber: {
          // name the rule
          message: "The :attribute must be valid positive number",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(
                val,
                /^[+]?([1-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/i
              ) && params.indexOf(val) === -1
            );
          },
          required: true, // optional
        },
      },
    });
  }

  componentDidMount() {
    let url1 = this.props.global_config.basePath + "/categories";

    fetch(url1, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          loading: false,
          categories: data,
        })
      )
      .catch(() => {
        this.setState({
          snackBar: false,
          snackBarMessage: "Error! unable to get Categories",
          snackBarType: "error",
        });
      });

    let myProduct = this.getData();

    let url = this.props.global_config.basePath;

    if (this.state.varients > 0 || myProduct.length < 1) {
      fetch(
        url + `/products/${this.props.history.location.pathname.split("/")[2]}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
          // convert the React state to JSON and send it as the POST body
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            varientLoading: false,
            varientData: data,
          });
          console.log("here is all data", data);
          myProduct = data;
          this.setState(
            {
              name: myProduct[0].name,
              varientLoading: false,
              description: myProduct[0].description,
              status: myProduct[0].status,
              price: myProduct[0].price,
              id: myProduct[0].POID,
              taxRate: myProduct[0].taxRate,
              images: myProduct[0].images,
              quantity: myProduct[0].quantity,
              barcodes: myProduct[0].barcodes,
              varients: myProduct[0]?.varients?.filter((value, index) => {
                if (value?.status === "Active") {
                  return value;
                }
              }),
              varientData: [],
              checked: false,
              users: [{ name: "", value: "" }],
              arr: [],
              productSpecCharacteristics: [],
              obj_parent: {},
              numberOfVarients: [],
              show: false,
              submit: false,
              snackbar: false,
              errorSnackbar: false,
              categories: [],
              categoryName: "",
              categoryId: myProduct[0].categoryId,
              costPrice: myProduct[0].costPrice,
              changedStatus: myProduct[0].status,
            },
            function () {
              this.setCategoryName();
            }
          );
          fetch(`${url}/categories`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer Bilo",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              let thisCategory = data.filter(
                (d) => d.POID == myProduct[0].categoryId
              );
              this.setState({
                categoryName: thisCategory[0].name,
              });
            })
            .catch((e) => {
              console.log("Error fetch category");
            });
        })
        .catch((e) => {
          alert("error fetching data");
          // console.log(e);
        });
    } else {
      this.setState(
        {
          name: myProduct[0].name,
          varientLoading: false,
          description: myProduct[0].description,
          status: myProduct[0].status,
          price: myProduct[0].price,
          id: myProduct[0].POID,
          images: myProduct[0].images,
          taxRate: myProduct[0].taxRate,
          quantity: myProduct[0].quantity,
          barcodes: myProduct[0].barcodes,
          varients: myProduct[0]?.varients?.filter((value, index) => {
            if (value?.status === "Active") {
              return value;
            }
          })?.length,
          varientData: [],
          checked: false,
          users: [{ name: "", value: "" }],
          arr: [],
          productSpecCharacteristics: [],
          obj_parent: {},
          numberOfVarients: [],
          show: false,
          submit: false,
          snackbar: false,
          errorSnackbar: false,
          categories: [],
          categoryName: "",
          categoryId: myProduct[0].categoryId,
          costPrice: myProduct[0].costPrice,
          varients: myProduct[0]?.varients?.filter((value, index) => {
            if (value?.status === "Active") {
              return value;
            }
          }),
          changedStatus: myProduct[0].status,
        },
        function () {
          this.setCategoryName();
        }
      );
      fetch(`${url}/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer Musab",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          let thisCategory = data.filter(
            (d) => d.POID == myProduct[0].categoryId
          );
          this.setState({
            categoryName: thisCategory[0].name,
          });
        })
        .catch((e) => {
          console.log("Error fetch category");
        });
    }

    this.generateVariants(
      myProduct[0]?.varients?.filter((value) => {
        if (value?.status === "Active") {
          return value;
        }
      })
    );
  }

  getData = () => {
    const uri = window.location.href.split("editproduct/");
    const product = this.props.g_products.filter((prod) => prod.POID == uri[1]);
    return product;
  };

  setCategoryName = () => {
    let name = this.props.g_categories.filter(
      (cat) => cat.id === this.state.categoryId
    );
    if (name.length > 0) {
      this.setState({ categoryName: name[0].name });
    }
  };
  addVar = (name, array, index) => {
    console.log(name);
    console.log(array);
    console.log(index);
    var productSpecCharValues = [];
    var obj_parent = {};

    for (var i = 0; i < array.length; i++) {
      var obj_child = {};
      obj_child.value = array[i];
      productSpecCharValues.push(obj_child);
    }

    obj_parent.name = name;
    obj_parent.productSpecCharValues = productSpecCharValues;

    this.setState(function (currentState) {
      return {
        productSpecCharacteristics: [
          ...currentState.productSpecCharacteristics,
          obj_parent,
        ],
      };
    });
  };

  handleChangeCat = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (event) => {
    console.log("in handle change");
    this.setState({
      [event.target.name]: event.target.value,
      // checked: !this.state.checked,
    });
  };

  findCategoriesById(id) {
    const categoryList = this.props.g_categories.filter(
      (category) => category.id === id
    );
    return categoryList[0]?.name;
  }

  postImagesToS3 = (images) => {
    //post images here
    return new Promise(async (resolve, reject) => {
      let path = "";
      let uploadedFiles = [];
      await images.forEach(async (image) => {
        console.log("Image", image);
        let formData = new FormData();
        formData.append("file", image);
        let urll = `https://s3.hulmsolutions.com/upload`;
        await this.setState({ loader: true, backdrop: true });
        fetch(urll, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then(async (data) => {
            await data.files.map(async (i) => {
              await uploadedFiles.push({
                imageURL: i.location,
              });
            });
            await this.setState({ loader: false, backdrop: false });
            resolve(uploadedFiles);
          })
          .catch(async (error) => {
            console.error(error);
            await this.setState({ loader: false, backdrop: false });
            reject(error);
          });
      });
    });
  };
  handleImagesUploading = async (images) => {
    return new Promise(async (resolve, reject) => {
      const { files } = this.state;
      const addedImages = images.filter(
        (image) => !files.find((file) => image === file)
      );
      console.log("Added iamges are: ", addedImages);
      this.postImagesToS3(addedImages)
        .then((uploads) => {
          resolve(uploads);
        })
        .catch((error) => {
          reject(error);
        });
    });

    //this.setState({ files: images });
  };

  openVariantModal = (updateIndex = null) => {
    this.setState({ variantModal: true, updateIndex: updateIndex });
  };
  closeVariantModal = () => {
    this.setState({ variantModal: false, updateIndex: null });
  };

  submitCategory = () => {
    if (this.state.categoryName == "" || this.state.categoryDescription == "") {
      helpers.toastNotify("error", "Fields are empty!");
      return;
    }
    this.setState({ backdrop: true, categoryModal: false });

    const data = {
      name: this.state.categoryName,
      description: this.state.categoryDescription,
    };

    console.log("data", data);

    fetch(this.props.global_config.basePath + "/categories", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.setState({ backdrop: false, CategorySnackbar: false });
        helpers.toastNotify("success", "Category Successfully Added!");
        this.setState({ categoryName: "", categoryDescription: "" });
        fetch(this.props.global_config.basePath + "/categories", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              categories: data,
              linearSpinner: false,
            });
            Store.dispatch({ type: "GET_CATEGORIES", categories: data });
          })
          .catch((e) => {
            console.log(e);
            helpers.toastNotify("error", "Something went wrong!");
          });
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Failed To Create Category. ");
      });
  };

  handleSubmit_DF = (e) => {
    this.setState({ submit: true });
    console.log("submit_DF");

    for (var i = 0; i < this.state.users.length; i++) {
      this.addVar(this.state.users[i].name, this.state.users[i].value, i);
    }
    console.log("productSpecCharacteristics: ");
    console.log(this.state.productSpecCharacteristics);
    console.log(this.state.obj_parent);
    e.preventDefault();
  };
  superList = () => {
    this.setState({ show: true });
    let superlist = [];
    for (var j = 0; j < this.state.productSpecCharacteristics.length; j++) {
      let values = [];
      for (
        var k = 0;
        k <
        this.state.productSpecCharacteristics[j].productSpecCharValues.length;
        k++
      ) {
        values.push(
          this.state.productSpecCharacteristics[j].name +
            ":" +
            this.state.productSpecCharacteristics[j].productSpecCharValues[k]
              .value
        );
      }
      superlist.push(values);
    }
    console.log("this is super list", superlist);
    console.log("this is varients", cartesianProduct(...superlist));
    let varients = cartesianProduct(...superlist);
    console.log("varients", varients);
    this.setState({ numberOfVarients: varients });
  };
  handleChange_DF = (i, e) => {
    const { name, value } = e.target;
    var array = "";
    if (name === "value") {
      array = value.split(",");
    } else {
      array = value;
    }
    let users = [...this.state.users];
    users[i] = { ...users[i], [name]: array };
    this.setState({ users });
  };

  addClick_DF() {
    this.setState((prevState) => ({
      users: [...prevState.users, { name: "", value: "" }],
    }));
  }

  removeClick_DF(i) {
    let users = [...this.state.users];
    users.splice(i, 1);
    this.setState({ users });
  }
  createUI_DF() {
    return this.state.users.map((el, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          width: "70%",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          placeholder="Attribute"
          name="name"
          value={el.name || ""}
          onChange={this.handleChange_DF.bind(this, i)}
        />
        <TextField
          size="small"
          variant="outlined"
          placeholder="Value"
          name="value"
          value={el.value || ""}
          onChange={this.handleChange_DF.bind(this, i)}
        />
        <Button
          color="secondary"
          value="remove"
          size="small"
          variant="contained"
          onClick={this.removeClick_DF.bind(this, i)}
        >
          Remove
        </Button>
      </div>
    ));
  }

  handleChangeBarcode = (event) => {
    const codeValue = event.target.value.trim(); // Trim whitespace from the input value

    // Check if codeValue is empty or whitespace only
    const code = codeValue === "" ? null : codeValue;
    this.setState({
      barcodes: [
        {
          code: code,
          unitOfMeasureRefId: "1",
        },
      ],
      //checked: !this.state.checked,
    });
  };

  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ price: Number(event.target.value) });
  };
  onCostPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ costPrice: Number(event.target.value) });
  };
  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };
  handleDelete = (product) => {
    this.setState({
      backdrop: true,
    });
    fetch(this.props.global_config.basePath + `/products/${this.state.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      console.log("order response...");
      console.log(response);
      this.props.history.push({
        pathname: "/Products",
      });
    });
  };
  value = (value) => {
    const val = value.split(":");
    return val[1];
  };

  removeImage = (image, index) => {
    this.setState({
      images: this.state.images.filter((index) => image !== index),
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };

  updateAllAttributeValues = (value, key, index) => {
    const deepCopyOfAllAttributeVariant = JSON.parse(
      JSON.stringify(this.state.allAttributeVariant)
    );
    if (key === "price") {
      deepCopyOfAllAttributeVariant[index].unitsOfMeasures[0][key] = value;
      deepCopyOfAllAttributeVariant[index][key] = value;
    } else if (key === "barcode") {
      deepCopyOfAllAttributeVariant[index].barcodes[0].code = value;
    } else {
      deepCopyOfAllAttributeVariant[index][key] = value;
    }
    this.setState({
      allAttributeVariant: deepCopyOfAllAttributeVariant,
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };

  updateVariantValues = (value, key, index) => {
    const deepCopyOfAllAttributeVariant = JSON.parse(
      JSON.stringify(this.state.varients)
    );
    if (key === "price") {
      deepCopyOfAllAttributeVariant[index].unitsOfMeasures[0][key] = value;
      deepCopyOfAllAttributeVariant[index][key] = value;
    } else if (key === "barcode") {
      deepCopyOfAllAttributeVariant[index].barcodes[0].code = value;
    } else {
      deepCopyOfAllAttributeVariant[index][key] = value;
    }
    this.setState({
      varients: deepCopyOfAllAttributeVariant,
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };

  removeImageFromVariant = (image, index) => {
    const deepCopyOfAllAttributeVariant = this.state.allAttributeVariant;
    deepCopyOfAllAttributeVariant[this.state.selectedIndexForImages].images =
      deepCopyOfAllAttributeVariant[
        this.state.selectedIndexForImages
      ].images.filter((index) => image !== index);
    this.setState({
      allAttributeVariant: deepCopyOfAllAttributeVariant,
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  generateCombinations = (variants, index = 0, currentCombination = {}) => {
    const combinations = [];

    if (index == variants.length) {
      // Push the ordered combination
      const sampleCombination = { ...sampleVarient };
      sampleCombination.productSpecCharacteristics =
        Object.values(currentCombination);
      combinations.push(sampleCombination);
    } else {
      const attribute = variants[index];

      attribute.attributeValues.forEach((value) => {
        const newCombination = { ...currentCombination }; // Create a new object for each iteration
        newCombination[attribute.attributeName] = {
          name: attribute.attributeName,
          productSpecCharValues: { value: value.value },
        };

        const subCombinations = this.generateCombinations(
          variants,
          index + 1,
          newCombination
        );

        combinations.push(...subCombinations);
      });
    }

    return combinations;
  };

  submitVariant = () => {
    console.log(
      "here is every",
      this.state.attributes.attributeValues.every(
        (item) => item["value"].trim() === ""
      )
    );
    if (
      this.state.attributes.attributeName.trim() === "" ||
      this.state.attributes.attributeValues.every(
        (item) => item["value"].trim() === ""
      )
    ) {
      helpers.toastNotify("error", "All attribute values cannot be empty");
      return;
    }

    if (
      this.state.attributes.attributeValues.some(
        (item, index, arr) =>
          item["value"].trim() !== "" &&
          arr.findIndex((i) => i["value"] === item["value"]) !== index
      )
    ) {
      helpers.toastNotify(
        "error",
        "Attribute Can not contain duplicate values"
      );
      return;
    }

    if (this.state.updateIndex == null) {
      if (
        this.state.allVariants.filter(
          (v) => v.attributeName == this.state.attributes.attributeName
        ).length > 0
      ) {
        helpers.toastNotify(
          "error",
          'Attribute Name  "' +
            this.state.attributes.attributeName +
            '" already exist.'
        );
        return;
      }
      this.setState(
        (prevState) => ({
          allVariants: [...prevState.allVariants, prevState.attributes],
          attributes: { attributeName: "", attributeValues: [{ value: "" }] }, // Reset to initial empty state
        }),
        this.prepareCombinations
      );
    } else {
      var localVarient = this.state.allVariants;
      localVarient[this.state.updateIndex] = this.state.attributes;
      this.setState(
        {
          allVariants: localVarient,
          attributes: { attributeName: "", attributeValues: [{ value: "" }] },
          updateIndex: null,
        },
        this.prepareCombinations
      );
    }
    // this.setState({
    //   allVariants: [...this.state.allVariants, this.state.attributes],
    // });
    this.setState({ variantModal: false });

    console.log("here is all variant outside callback", this.state.allVariants);

    // console.log("here is attributes", this.state.attributes);
  };

  prepareCombinations = () => {
    // console.log("here is all variant", this.state.allVariants); // Log the updated allVariants state

    const clearAttribute = [];
    this.state.allVariants.map((variant) => {
      var deepCopy = JSON.parse(JSON.stringify(variant));
      deepCopy.attributeValues = deepCopy.attributeValues?.filter(
        (attributeVal) => attributeVal.value !== ""
      );
      clearAttribute.push(deepCopy);
    });

    // .filter((attribute) => attribute.value !== "");

    console.log("here is clear attribute", clearAttribute);
    var combvar = this.generateCombinations(clearAttribute);
    this.setState(
      {
        allAttributeVariant: combvar,
      },
      () => {
        console.log(
          "here is all variants combinations",
          // this.generateAttributeCombinations(this.state.allVariants)
          combvar
        );
      }
    );
  };

  handleSubmit = (event) => {
    // this.setState({ backdrop: true });
    console.log("here is image state", this.state.images);
    console.log("here is costprice", this.state.costPrice);
    event.preventDefault();
    console.log("in handle submit");

    if (
      this.state.status === "Archived" &&
      this.state.changedStatus === "Active" &&
      this.validator.allValid()
    ) {
      this.setState({ changedStatus: "" });
      helpers.toastNotify(
        "error",
        "You cannot Change status of product from archive to active"
      );
      return;
    }

    if (this.validator.allValid()) {
      this.setState({ backdrop: true });

      let data = {};
      // this.setState({ status: this.state.changedStatus });
      if (this.state.images.length) {
        data = {
          name: this.state.name,
          description: this.state.description,
          price: this.state.price,
          status: this.state.changedStatus,
          images: this.state.images,
          costPrice: this.state.costPrice,
          taxRate: this.state.taxRate,
          barcodes: this.state.barcodes[0].c,
          // startDate: this.state.startDate,
          // endDate: this.state.endDate,
          algorithm: this.state.algorithm,
          categoryId: this.state.categoryId,
          varients: [...this.state.varients, ...this.state.allAttributeVariant],
        };
      } else {
        if (this.state.categoryId) {
          data = {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            costPrice: this.state.costPrice,
            status: this.state.changedStatus,
            images: this.state.images,
            categoryId: this.state.categoryId,
            algorithm: this.state.algorithm,
            taxRate: this.state.taxRate,
            barcodes: this.state.barcodes,
            varients: [
              ...this.state.varients,
              ...this.state.allAttributeVariant,
            ],
          };
        } else {
          data = {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            costPrice: this.state.costPrice,
            status: this.state.changedStatus,
            categoryId: this.state.categoryId,
            images: this.state.images,
            algorithm: this.state.algorithm,
            taxRate: this.state.taxRate,
            barcodes: this.state.barcodes,
            varients: [
              ...this.state.varients,
              ...this.state.allAttributeVariant,
            ],
          };
        }
      }

      console.log("here is stringyfy", JSON.stringify(data));

      fetch(this.props.global_config.basePath + `/products/${this.state.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        // convert the React state to JSON and send it as the POST body
        body: JSON.stringify(data),
      })
        .then((resp) => {
          console.log("Resp" + resp);
          fetch(
            this.props.global_config.basePath + `/products/${this.state.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
              },
            }
          )
            .then((response2) => response2.json())
            .then((data2) => {
              let myProduct = data2;
              myProduct[0].images = this.state.images;
              this.setState({
                varientLoading: false,
                varientData: data,
              });
              this.setState({
                name: myProduct[0].name,
                varientLoading: false,
                description: myProduct[0].description,
                status: myProduct[0].status,
                changedStatus: myProduct[0].status,
                price: myProduct[0].price,
                id: myProduct[0].POID,
                images: myProduct[0].images,
                quantity: myProduct[0].quantity,
                varients: this.state.varients,
                varientData: [],
                checked: false,
                users: [{ name: "", value: "" }],
                arr: [],
                productSpecCharacteristics: [],
                obj_parent: {},
                numberOfVarients: [],
                show: false,
                submit: false,
                snackbar: false,
                errorSnackbar: false,
                costPrice: myProduct[0].costPrice,
              });
              // Store.dispatch({
              //   type: "EDIT_ACTIVE_PRODUCTS",
              //   updated_product: data2,
              // });
            });

          setTimeout(() => {
            this.setState({ backdrop: false, snackbar: false });

            helpers.toastNotify("success", "Product Successfully Updated!");
            this.props.history.push({
              pathname: "/Products",
            });
          }, 3000);
        })
        .catch((err) => {
          window.alert(err);
          this.setState({ backdrop: false, errorSnackbar: false });
          helpers.toastNotify("error", "Could Not Update Product.");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  updateParent = (data) => {
    const obj = {
      imageURL: data.image,
    };
    this.setState({ images: [...this.state.images, obj] });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false, errorSnackbar: false });
  };

  openImageModal = (selectedIndex = null) => {
    this.setState({ selectedIndexForImages: selectedIndex });
    this.setState({ imagePickerModal: true });
  };
  closeImageModal = () => {
    this.setState({ imagePickerModal: false, updateIndex: null });
  };

  deleteImage = (image) => {
    const { images } = this.state;
    // console.log();
    image.delete = true;
    this.setState({ images: [...images, image] });
    console.log("deleteImage", image);
  };
  handleImages = (images) => {
    const { files } = this.state;
    console.log("uploaded images are: ", images);
    const addedImages = images.filter(
      (image) => !files.find((file) => image === file)
    );
    console.log("Added iamges are: ", addedImages);
    this.postImages(addedImages);
    this.setState({ files: images });
  };
  handleAddImage = (image) => {
    console.log("added image is: ", image);
  };
  handleDeleteImage = (deletedImage) => {
    console.log("deleted image is: ", deletedImage);
    //  console.log("gobal config is: ", GlobalConfig.temp);
    this.deleteImageFromArray(deletedImage);
  };
  deleteImageFromArray = (imageToDelete) => {
    const { images } = this.state;
    images.forEach((image, index) => {
      let myArray = image.imageURL.split("/");
      if (imageToDelete.name === myArray[myArray.length - 1]) {
        console.log("Removing index: ", index, " image: ", image);
        images.splice(index, 1);
      }
    });
    console.log("now images in state are ", images);
  };

  postImages = async (images) => {
    //post images here
    let path = "";
    await images.forEach(async (image) => {
      console.log("Image", image);
      let formData = new FormData();
      formData.append("file", image);
      let urll = `https://s3.hulmsolutions.com/upload`;
      await this.setState({ loader: true, backdrop: true });
      fetch(urll, {
        method: "POST",
        // headers: {
        //     "Content-Type": "multipart/form-data",
        //     Authorization: "Bearer saad",
        // },
        body: formData,
      })
        .then((response) => response.json())
        .then(async (data) => {
          let imagess = [];
          console.log("here is data of imgaes", data);
          console.log("here is data of dataa of images", data.data);
          // await data.data.map(async (i) => {
          //   await imagess.push({
          //     imageURL: i,
          //   });
          // });
          await data.files.map(async (i) => {
            await imagess.push({
              imageURL: i.location,
            });
          });

          console.log("here is images all from", imagess);
          this.setState({ images: imagess });
          await this.setState({ loader: false, backdrop: false });
        })
        .catch(async (error) => {
          console.error(error);
          await this.setState({ loader: false, backdrop: false });
        });
    });
  };

  openCategoryModal = () => {
    this.setState({ categoryModal: true });
  };
  closeCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  generateVariants = (variants = []) => {
    var combinationValue = [];

    variants?.map((variantvalue) => {
      variantvalue?.productSpecCharacteristics?.map(
        (variantcharacteristicvalue) => {
          let index = combinationValue.findIndex(
            (val) => val.attributeName === variantcharacteristicvalue.name
          );
          if (index === -1) {
            combinationValue.push({
              attributeName: variantcharacteristicvalue.name,
              attributeValues: [
                {
                  value:
                    variantcharacteristicvalue.productSpecCharValues[0]?.value,
                },
              ],
            });
          } else {
            let indexi = combinationValue[index].attributeValues.findIndex(
              (val) =>
                val.value ===
                variantcharacteristicvalue.productSpecCharValues[0]?.value
            );
            if (indexi === -1) {
              combinationValue[index].attributeValues.push({
                value:
                  variantcharacteristicvalue.productSpecCharValues[0]?.value,
              });
            }
          }
        }
      );
    });
    console.log("from generate variant function", combinationValue);
    this.setState({ allVariants: combinationValue }, () => {
      console.log("this.state.allVariants", this.state.allVariants);
    });
  };

  handleChangeVariant = (attrIndex, valueIndex, fieldName, value) => {
    const updatedAttributes = this.state.attributes;
    updatedAttributes.attributeValues[valueIndex][fieldName] = value;

    // Automatically add a new empty value object when entering a value
    if (
      valueIndex === updatedAttributes.attributeValues.length - 1 &&
      value !== ""
    ) {
      updatedAttributes.attributeValues.push({ value: "" });
    }

    this.setState({ attributes: updatedAttributes });
  };

  setImageInState = (image) => {
    console.log(image);
    if (image != undefined) {
      const obj = {
        imageURL: image.filePath ? image.filePath : image,
      };
      this.setState({ images: [...this.state.images, obj] });
    }
  };
  render() {
    // const defaultOption = this.state.status;
    // this.generateVariants(this.state.varients)
    console.log("edit products state props", this.state);
    console.log("edit products props", this.props);
    const dummyImage =
      "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081";
    const { classes } = this.props;
    // const { type } = this.state;
    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} color="inherit" />
        </Backdrop>
        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="success"
                color="success"
                variant="filled"
                onClose={this.closeSnack}
              >
                Product Updated
                {/* <div>
                  <Link to="/Products" style={{ color: "white" }}>
                    clik here to see products
                  </Link>
                </div> */}
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        {this.state.errorSnackbar ? (
          <>
            <Snackbar
              open={this.state.errorSnackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="error"
                color="error"
                variant="filled"
                onClose={this.closeSnack}
              >
                Unable to update
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        <div className={classes.arrowroute}>
          <Link
            to={"/Products"}
            style={{
              textDecoration: "none",
              color: "black",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ArrowBackIosIcon
              fontSize="medium"
              // onClick={() => this.props.history.push("/Products")}
              style={{ cursor: "pointer" }}
            />
            <h5>Product</h5>
          </Link>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className={classes.formContainer}>
            <div className={classes.formList}>
              <div className={classes.formItems}>
                <div className="" style={{ marginBottom: "15px" }}>
                  <b>Title</b>
                </div>

                <TextField
                  label="Title"
                  name="name"
                  size="small"
                  value={this.state.name}
                  onChange={this.handleChange.bind(this)}
                  variant="outlined"
                  fullWidth={true}
                  InputProps={{ classes: { input: classes.inputHeight } }}
                />
                {this.validator.message("title", this.state.name, "required", {
                  className: classes.textDanger,
                })}
                <div style={{ marginTop: "20px", marginBottom: "15px" }}>
                  <b>Description</b>
                </div>
                {this.validator.message(
                  "description",
                  this.state.description,
                  "required",
                  { className: classes.textDanger }
                )}

                <div>
                  <ReactQuill
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                  />
                </div>
              </div>

              <div className={classes.formItems}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Media
                  </div>
                  <div>
                    <Box p={1}>
                      <Popup
                        title="Add Media Url"
                        btnText="Add Media Url"
                        btnFloat="right"
                        // marginTop="-20px"
                        btnColor="lightblue"
                        contentTxt={<hr />}
                        contentTxtBe={<hr />}
                        content={
                          <ImageUrlModal
                            updateParent={this.updateParent}
                            onClickBtnClose={() =>
                              this.setState({ modal: false })
                            }
                          />
                        } //component to send
                        openPopup={this.state.modal} //open
                        onClickBtnOpen={() => this.setState({ modal: true })} //on click open
                        onClickBtnClose={() => this.setState({ modal: false })} //on click close
                      />
                    </Box>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    // marginLeft: "2px",
                    display: "flex",
                    height: "300px",
                  }}
                >
                  <DropzoneArea
                    files={1}
                    acceptedFiles={["image/*"]}
                    onChange={this.handleImages.bind(this)}
                    //onAdd={this.handleAddImage}
                    onDelete={this.handleDeleteImage}
                  />
                </div>

                {this.state.images.length === 0 ? (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h5>
                      <ImageIcon
                        fontSize="large"
                        style={{ color: "rgba(16,174,72)" }}
                      />
                      No Images attached for {this.state.name}
                    </h5>
                  </div>
                ) : (
                  this.state.images.slice(0, 1).map((image, index) =>
                    !image.delete ? (
                      <>
                        <div>
                          <img
                            key={index}
                            src={
                              this.state.images[this.state.images.length - 1]
                                .imageURL
                            }
                            alt=""
                            style={{
                              // width: "100px",
                              height: "100px",
                              marginTop: "20px",
                              // margin: "15px 0 0 25px",
                            }}
                          />
                          <ClearIcon
                            onClick={() => this.deleteImage(image)}
                            style={{ color: "red", marginTop: "-60px" }}
                          />
                        </div>
                      </>
                    ) : null
                  )
                )}
              </div>
              {this.state.varients > 0 ? null : (
                <div className={classes.formItems}>
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Pricing
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: "5px" }}>
                        {/* <label>Price</label> */}
                      </div>
                      <TextField
                        label="Selling Price"
                        value={this.state.price}
                        name="price"
                        onChange={this.onPriceChange}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                      <div style={{ marginBottom: "5px" }}>
                        {/* <label>Cost per item</label> */}
                      </div>
                      <TextField
                        variant="outlined"
                        label="Buying Price"
                        size="small"
                        name="costPrice"
                        value={this.state.costPrice}
                        fullWidth={true}
                        onChange={this.onCostPriceChange}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                      <div style={{ marginBottom: "5px" }}>
                        {/* <label>Cost per item</label> */}
                      </div>
                      <TextField
                        variant="outlined"
                        fullWidth={true}
                        label="Tax Rate %"
                        size="small"
                        type="number"
                        min={0}
                        name="taxRate"
                        onChange={this.handleChange}
                        value={this.state.taxRate}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          inputProps: { min: 0 },
                        }}
                      />
                    </div>
                  </div>
                  {this.validator.message(
                    "Price",
                    this.state.price,
                    "required|currency",
                    { className: classes.textDanger }
                  )}

                  {this.validator.message(
                    "Cost Price",
                    this.state.costPrice,
                    "required|currency",
                    { className: classes.textDanger }
                  )}
                </div>
              )}

              <div className={classes.formItems}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Quantity
                  </div>
                  {this.state.varients > 0 ? null : (
                    <div>
                      <Button
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/inventryHistory",
                            state: this.state.id,
                          });
                        }}
                      >
                        View Inventory history
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginBottom: "15px" }}>Available</div>
                  {/* <div>Incoming</div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <TextField
                      variant="outlined"
                      label="Quantity"
                      size="small"
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                      value={this.state.quantity}
                      type="number"
                      disabled
                    />
                  </div>
                  {/* <div>0</div> */}
                </div>
              </div>
              {this.state.varients > 0 ? null : (
                <div className={classes.formItems}>
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Barcode
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div style={{}}>
                      <div style={{ marginBottom: "5px" }}>
                        {/* <label>Cost per item</label> */}
                      </div>
                      <TextField
                        value={
                          this.state.barcodes[this.state.barcodes.length - 1]
                            ?.code
                        }
                        label="Enter Barcode"
                        name="barcode"
                        size="small"
                        onChange={this.handleChangeBarcode}
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  {this.validator.message(
                    "Price",
                    this.state.price,
                    "required|currency",
                    { className: classes.textDanger }
                  )}
                </div>
              )}
              <div className={classes.formItems}>
                <div className={classes.variantresponsive}>
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Variants
                  </p>
                  <Button
                    onClick={() => this.openVariantModal()}
                    style={{ backgroundColor: SKY_BLUE, color: WHITE }}
                    variant="contained"
                    color="default"
                    size="medium"
                  >
                    Add Variant
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                ></div>
                {/* <hr></hr> */}

                <div className={classes.variantshow} style={{}}>
                  {this.state.allVariants &&
                    this.state.allVariants?.map((productVar, productindex) => {
                      return (
                        <>
                          <div
                            key={productindex}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                {productVar?.attributeName}
                              </span>
                            </div>
                            {productVar?.attributeValues?.map(
                              (attrVal, valindex) => {
                                if (attrVal.value == "") return;

                                return (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "#D3D3D3",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      borderRadius: "30px",
                                    }}
                                  >
                                    {attrVal?.value}
                                  </div>
                                );
                              }
                            )}
                            <div style={{ display: "flex" }}>
                              <Button
                                // onClick={() => this.openVariantModal()}
                                style={{
                                  backgroundColor: "#93C572",
                                  color: WHITE,
                                  outline: "none",
                                }}
                                variant="contained"
                                color="default"
                                size="small"
                                onClick={() => {
                                  this.setState({
                                    attributes: productVar,
                                  });
                                  this.openVariantModal(productindex);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  var list = this.state.allVariants.filter(
                                    (singlevarient) =>
                                      singlevarient.attributeName !=
                                      productVar.attributeName
                                  );
                                  this.setState(
                                    { allVariants: list },
                                    this.prepareCombinations
                                  );
                                }}
                                style={{
                                  marginLeft: "5px",
                                  backgroundColor: "#d11a2a",
                                  color: WHITE,
                                  outline: "none",
                                }}
                                variant="contained"
                                color="default"
                                size="small"
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                {this.state.checked && (
                  <>
                    {this.createUI_DF()}
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="contained"
                      onClick={this.addClick_DF.bind(this)}
                    >
                      Add more
                    </Button>
                    <Button
                      variant="contained"
                      style={{ margin: "10px" }}
                      onClick={this.handleSubmit_DF}
                    >
                      Submit
                    </Button>
                    {this.state.submit ? (
                      <Button
                        style={{ margin: "10px" }}
                        onClick={() => this.superList()}
                        color="primary"
                        variant="contained"
                      >
                        Show
                      </Button>
                    ) : null}
                  </>
                )}
                {this.state.productSpecCharacteristics.length > 0 &&
                this.state.show ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableCell
                          style={{
                            color: "blue",
                            fontWeight: "bold",
                          }}
                        >
                          S.No
                        </TableCell>
                        {this.state.productSpecCharacteristics.map(
                          (characteristics) => (
                            <TableCell
                              style={{
                                color: "blue",
                                fontWeight: "bold",
                              }}
                            >
                              {characteristics.name}
                            </TableCell>
                          )
                        )}
                      </TableHead>
                      <TableBody>
                        {this.state.numberOfVarients.map(
                          (characteristicsValue, index) => (
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                {index + 1}
                              </TableCell>
                              {characteristicsValue.map((value) => (
                                <TableCell>{this.value(value)}</TableCell>
                              ))}
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </div>

              {this.state?.varients?.length > 0 &&
                !this.state?.allAttributeVariant?.length > 0 && (
                  <div className={classes.variantgenerationd}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        maxWidth: "55vw",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          maxWidth: "55vw",
                        }}
                      >
                        Available Product Variants
                      </div>
                      {/* <Link>View Inventory history</Link> */}
                    </div>
                    <div style={{ maxWidth: "55vw", overflowX: "scroll" }}>
                      <table>
                        {this.state?.varients &&
                          this.state?.varients?.map((variantValue, index) => {
                            console.log(
                              "here is all variant inside map",
                              variantValue.status
                            );
                            <tr
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></tr>;
                            return (
                              <>
                                {variantValue.status === "Active" && (
                                  <tr
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <td
                                      style={{ width: "auto", padding: "5px" }}
                                    >
                                      <span>{index + 1}.</span>
                                    </td>
                                    <td
                                      style={{
                                        width: "auto",
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span
                                        style={{ display: "flex" }}
                                        onClick={() =>
                                          this.openImageModal(index)
                                        }
                                      >
                                        <span>
                                          <AddPhotoAlternate />
                                        </span>
                                        {variantValue?.images?.length > 0 && (
                                          <span>
                                            {" (" +
                                              variantValue?.images?.length +
                                              ")"}
                                          </span>
                                        )}
                                      </span>
                                    </td>
                                    <td style={{ padding: "5px" }}>
                                      {variantValue?.productSpecCharacteristics?.map(
                                        (variantval, index) => {
                                          console.log(
                                            "here is all variant value inside second map",
                                            variantval,
                                            variantval.productSpecCharValues[0]
                                              ?.value
                                          );

                                          {
                                            return (
                                              <span
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                <span>{variantval?.name}-</span>
                                                <span>
                                                  {
                                                    variantval
                                                      ?.productSpecCharValues[0]
                                                      ?.value
                                                  }

                                                  {variantValue
                                                    ?.productSpecCharacteristics
                                                    ?.length -
                                                    1 !=
                                                    index && ", "}
                                                </span>
                                              </span>
                                            );
                                          }
                                        }
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <TextField
                                          variant="outlined"
                                          placeholder="Price"
                                          name="Price"
                                          size="small"
                                          type="number"
                                          defaultValue={variantValue?.price}
                                          onChange={(e) => {
                                            this.updateVariantValues(
                                              e.target.value,
                                              "price",
                                              index
                                            );
                                          }}
                                        ></TextField>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <div style={{}}>
                                        <TextField
                                          variant="outlined"
                                          placeholder="Cost Price"
                                          name="costPrice"
                                          size="small"
                                          type="number"
                                          defaultValue={variantValue?.costPrice}
                                          onChange={(e) => {
                                            this.updateVariantValues(
                                              e.target.value,
                                              "costPrice",
                                              index
                                            );
                                          }}
                                        ></TextField>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <div style={{}}>
                                        <TextField
                                          variant="outlined"
                                          placeholder="Barcode"
                                          name="barcode"
                                          size="small"
                                          defaultValue={
                                            variantValue?.barcodes[0]?.code
                                          }
                                          onChange={(e) => {
                                            this.updateVariantValues(
                                              e.target.value,
                                              "barcode",
                                              index
                                            );
                                          }}
                                        ></TextField>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <div>
                                        <TextField
                                          name="quantity"
                                          size="small"
                                          placeholder="Quantity"
                                          variant="outlined"
                                          defaultValue={variantValue?.quantity}
                                          onChange={(e) => {
                                            this.updateVariantValues(
                                              e.target.value,
                                              "quantity",
                                              index
                                            );
                                          }}
                                          type="number"
                                          disabled
                                        />
                                        {this.validator.message(
                                          "quantity",
                                          this.state.quantity,
                                          "required|numeric|min:0,num",
                                          { className: classes.textDanger }
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <div style={{}}>
                                        <TextField
                                          variant="outlined"
                                          placeholder="Tax Rate"
                                          name="taxRate"
                                          type="number"
                                          size="small"
                                          defaultValue={variantValue?.taxRate}
                                          onChange={(e) => {
                                            this.updateVariantValues(
                                              e.target.value,
                                              "taxRate",
                                              index
                                            );
                                          }}
                                        ></TextField>
                                      </div>
                                    </td>

                                    <td
                                      style={{
                                        width: "auto",
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Button
                                        onClick={() => {
                                          variantValue.status = "Archived";
                                          this.setState({
                                            varients: this.state.varients,
                                          }); // var list = this.state.allVariants.filter(
                                        }}
                                        style={{
                                          marginLeft: "5px",
                                          backgroundColor: "#d11a2a",
                                          color: WHITE,
                                          outline: "none",
                                        }}
                                        variant="contained"
                                        color="default"
                                        size="small"
                                      >
                                        Archive
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                )}

              {this.state?.allAttributeVariant?.length > 0 && (
                <div className={classes.formItems} style={{ maxWidth: "55vw" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      maxWidth: "55vw",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        maxWidth: "55vw",
                      }}
                    >
                      Generated Variant Combinations
                    </div>
                    {/* <Link>View Inventory history</Link> */}
                  </div>
                  <div style={{ maxWidth: "55vw", overflowX: "scroll" }}>
                    <table>
                      {this.state?.allAttributeVariant &&
                        this.state?.allAttributeVariant?.map(
                          (variantValue, index) => {
                            console.log(
                              "here is all variant inside map" +
                                Object.values(this.state?.allAttributeVariant)
                            );
                            return (
                              <tr
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <td style={{ width: "auto", padding: "5px" }}>
                                  <span>{index + 1}.</span>
                                </td>
                                <td
                                  style={{
                                    width: "auto",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{ display: "flex" }}
                                    onClick={() => this.openImageModal(index)}
                                  >
                                    <span>
                                      <AddPhotoAlternate />
                                    </span>
                                    {variantValue?.images?.length > 0 && (
                                      <span>
                                        {" (" +
                                          variantValue?.images?.length +
                                          ")"}
                                      </span>
                                    )}
                                  </span>
                                </td>
                                <td style={{ padding: "5px" }}>
                                  {variantValue?.productSpecCharacteristics.map(
                                    (variantval, index) => {
                                      console.log(
                                        "here is all variant value inside second map",
                                        variantval.productSpecCharValues?.value
                                      );

                                      {
                                        return (
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            <span>{variantval?.name}-</span>
                                            <span>
                                              {
                                                variantval
                                                  ?.productSpecCharValues.value
                                              }

                                              {variantValue
                                                ?.productSpecCharacteristics
                                                ?.length -
                                                1 !=
                                                index && ", "}
                                            </span>
                                          </span>
                                        );
                                      }
                                    }
                                  )}
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <TextField
                                      variant="outlined"
                                      placeholder="Price"
                                      name="Price"
                                      size="small"
                                      type="number"
                                      value={variantValue?.price}
                                      onChange={(e) => {
                                        this.updateVariantValues(
                                          e.target.value,
                                          "price",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div style={{}}>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Cost Price"
                                      name="costPrice"
                                      size="small"
                                      type="number"
                                      value={variantValue?.costPrice}
                                      onChange={(e) => {
                                        this.updateVariantValues(
                                          e.target.value,
                                          "costPrice",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div style={{}}>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Barcode"
                                      name="barcode"
                                      size="small"
                                      value={variantValue?.barcodes[0]?.code}
                                      onChange={(e) => {
                                        this.updateVariantValues(
                                          e.target.value,
                                          "barcode",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div>
                                    <TextField
                                      name="quantity"
                                      size="small"
                                      placeholder="Quantity"
                                      variant="outlined"
                                      value={variantValue?.quantity}
                                      onChange={(e) => {
                                        this.updateVariantValues(
                                          e.target.value,
                                          "quantity",
                                          index
                                        );
                                      }}
                                      type="number"
                                    />
                                    {this.validator.message(
                                      "quantity",
                                      this.state.quantity,
                                      "required|numeric|min:0,num",
                                      { className: classes.textDanger }
                                    )}
                                  </div>
                                </td>
                                <td
                                  style={{ padding: "5px", minWidth: "150px" }}
                                >
                                  <div style={{}}>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Tax Rate"
                                      name="taxRate"
                                      type="number"
                                      size="small"
                                      value={variantValue?.taxRate}
                                      onChange={(e) => {
                                        this.updateVariantValues(
                                          e.target.value,
                                          "taxRate",
                                          index
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                </td>

                                <td
                                  style={{
                                    width: "auto",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{ display: "flex" }}
                                    onClick={() => {}}
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <div>Incoming</div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  ></div>
                </div>
              )}

              {this.state.varientLoading ? (
                <>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                    <p>Loading variants</p>
                  </div>
                </>
              ) : this.state.varients > 0 ? (
                <>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0.8rem",
                      padding: "25px",
                      marginBottom: "20px",
                      // width: "100%",
                      boxShadow:
                        "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                      outline: ".1rem solid transparent",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h5>Variants</h5>
                      </div>
                    </div>

                    <div style={{ overflowX: "scroll" }}>
                      <table
                        style={{
                          width: "100%",
                          margin: "0px",
                          padding: "0px",
                          // overflowX: "scroll",
                        }}
                      >
                        <th className="thtd"></th>
                        {console.log(
                          "chachah",
                          this.state.varientData[0].productSpecCharacteristics
                        )}
                        {this.state.varientData[0].productSpecCharacteristics.map(
                          (characteristics) => (
                            <th style={{ minWidth: "180px" }} className="thtd">
                              {characteristics.name}
                            </th>
                          )
                        )}

                        <th style={{ minWidth: "150px" }} className="thtd">
                          Quantity
                        </th>
                        <th style={{ minWidth: "150px" }} className="thtd">
                          Price
                        </th>
                        {this.state.varientData.map((varient) => {
                          const url =
                            varient.images.length > 0
                              ? varient.images[0].imageURL
                              : dummyImage;
                          return (
                            <>
                              <tr>
                                <td className="thtd">
                                  <img
                                    style={{
                                      width: "60px",
                                      padding: "0px",
                                      margin: "0px",
                                      height: "60px",
                                      border: "2px solid black",
                                      borderRadius: "5px",
                                    }}
                                    src={url}
                                    alt="images"
                                  />
                                </td>
                                {varient.productSpecCharacteristics.map(
                                  (char, index) => (
                                    <td
                                      className="thtd"
                                      style={{
                                        minWidth: "40%",
                                      }}
                                    >
                                      <TextField
                                        value={
                                          char.productSpecCharValues[0].value
                                        }
                                        id="outlined-basic"
                                        // label="Green"
                                        variant="outlined"
                                        fullWidth={true}
                                        InputProps={{
                                          classes: {
                                            input: classes.inputHeight,
                                          },
                                        }}
                                      />
                                    </td>
                                  )
                                )}{" "}
                                <td
                                  className="thtd"
                                  style={{ minWidth: "40%" }}
                                >
                                  <TextField
                                    InputProps={{
                                      classes: {
                                        input: classes.inputHeight,
                                      },
                                    }}
                                    value={varient.quantity}
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                  />
                                </td>
                                <td
                                  className="thtd"
                                  style={{ minWidth: "40%" }}
                                >
                                  <TextField
                                    InputProps={{
                                      classes: {
                                        input: classes.inputHeight,
                                      },
                                    }}
                                    value={varient.price}
                                    id="outlined-basic"
                                    variant="outlined"
                                  />
                                </td>
                                <td className="thtd">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/editvariant/${varient.POID}`,
                                        state: varient,
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </td>
                                {/* <td className="thtd">
                                  <IconButton aria-label="delete">
                                    <DeleteIcon />
                                  </IconButton>
                                </td> */}
                              </tr>
                              ;
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className={classes.formRightBox}>
              <div className={classes.formItems}>
                <p
                  style={{
                    margin: "10px 0 10px 10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Product Status
                </p>
                <Grid item xs={12}>
                  {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                  <Select
                    labelId="Input label"
                    id="Select"
                    style={{ height: "40px" }}
                    value={
                      this.state.changedStatus
                        ? this.state.changedStatus
                        : this.state.status
                    }
                    // defaultValue={status}
                    onChange={(e) => {
                      this.setState({ changedStatus: e.target.value });
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {statuses.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </div>
              <div className={classes.formItems}>
                <div></div>

                <div>
                  <p
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    Current Category:{" "}
                    {this.findCategoriesById(this.state.categoryId)}
                  </p>
                </div>

                <Autocomplete
                  // size="small"
                  onChange={(event, value) =>
                    this.setState({ categoryId: value.POID })
                  } // prints the selected value
                  // defaultValue={{ POID: this.state.categoryId}}
                  defaultValue={"shirt"}
                  options={this.state.categories}
                  classes={{
                    option: classes.option,
                  }}
                  disableClearable="true"
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{option.name}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // required
                      name="categoryId"
                      size="small"
                      value={this.state.categoryId}
                      onChange={this.handleChange}
                      {...params}
                      Status
                      label="Category"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />

                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    style={{
                      backgroundColor: SKY_BLUE,
                      color: "white",
                    }}
                    onClick={() => this.openCategoryModal()}
                  >
                    Create Category
                    {/* <ArrowDropDownIcon /> */}
                  </Button>
                </div>
              </div>

              <Dialog
                fullWidth={true}
                open={this.state.categoryModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeCategoryModal}
              >
                <DialogTitle>{"Create New Category"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        label="Category Name"
                        size="medium"
                        // placeholder="Category Name"
                        name="categoryName"
                        value={this.state.categoryName}
                        onChange={this.handleChangeCat}
                      ></TextField>
                      <TextField
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        multiline
                        rows={4}
                        size="medium"
                        label="Category Description"
                        // placeholder="Category Description"
                        name="categoryDescription"
                        onChange={this.handleChangeCat}
                        value={this.state.categoryDescription}
                      ></TextField>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeCategoryModal} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={this.submitCategory} color="primary">
                    Create
                  </Button>
                </DialogActions>
              </Dialog>

              <div className={classes.editproductsave} style={{}}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  // fullWidth
                  style={{ backgroundColor: SKY_BLUE, color: WHITE }}
                  onClick={() => this.handleSubmit}
                >
                  Save
                </Button>
              </div>

              <Dialog
                fullWidth={true}
                open={this.state.variantModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeVariantModal}
              >
                <DialogTitle>{"Add Variant "}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {}
                      {/* {this.state.attributes.map((attribute, attrIndex) => ( */}
                      <div>
                        {/* <h6>Attribute Name</h6> */}
                        <TextField
                          style={{ marginBottom: "20px" }}
                          variant="outlined"
                          label="Attribute Name"
                          // placeholder="Attribute Name"
                          name={`attributeName`}
                          value={this.state.attributes.attributeName}
                          onChange={(e) => {
                            const updatedAttributes = this.state.attributes;

                            updatedAttributes.attributeName = e.target.value;
                            this.setState({ attributes: updatedAttributes });
                          }}
                        />
                        {/* <h6>Attribute Values</h6> */}
                        {this.state.attributes?.attributeValues?.map(
                          (valueObj, valueIndex) => (
                            <div key={valueIndex}>
                              <TextField
                                style={{ marginBottom: "20px" }}
                                variant="outlined"
                                label={`Value ${valueIndex + 1}`}
                                // placeholder={`Value ${valueIndex + 1}`}
                                name={`attributeValue${valueIndex}`}
                                value={valueObj.value}
                                onChange={(e) =>
                                  this.handleChangeVariant(
                                    0,
                                    valueIndex,
                                    "value",
                                    e.target.value
                                  )
                                }
                              />
                              {/* Add additional fields as needed */}
                            </div>
                          )
                        )}
                      </div>
                      {/* ))} */}
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeVariantModal} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={this.submitVariant} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>

              {/* image picker modal */}
              {this.state.imagePickerModal && (
                <Dialog
                  fullWidth={true}
                  open={this.state.imagePickerModal}
                  TransitionComponent={Transition}
                  onClose={this.closeImageModal}
                >
                  <DialogTitle>{"Image Picker"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {}
                        {/* {this.state.attributes.map((attribute, attrIndex) => ( */}
                        <div>
                          <h6>Image</h6>
                        </div>
                        {/* ))} */}

                        <div
                          style={{
                            paddingTop: "20px",
                            display: "flex",
                            height: "300px",
                          }}
                        >
                          <DropzoneArea
                            filesLimit={1}
                            acceptedFiles={["image/*"]}
                            onChange={async (images) => {
                              console.log(
                                "uploadedindex",
                                this.state.selectedIndexForImages
                              );
                              const deepCopyOfAllAttributeVariant =
                                this.state.allAttributeVariant;
                              deepCopyOfAllAttributeVariant[
                                this.state.selectedIndexForImages
                              ].images = await this.handleImagesUploading(
                                images
                              );
                              this.setState({
                                allAttributeVariant:
                                  deepCopyOfAllAttributeVariant,
                              });
                            }}
                            //onAdd={this.handleAddImage}
                            onDelete={this.handleDeleteImage}
                            clearOnUnmount={true}
                          />
                        </div>
                        {this.state.allAttributeVariant[
                          this.state.selectedIndexForImages
                        ].images.map((image, index) => (
                          <>
                            <img
                              key={index}
                              src={image.imageURL}
                              alt=""
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "20px 0 0 25px",
                                objectFit: "contain",
                              }}
                            />
                            <ClearIcon
                              onClick={() =>
                                this.removeImageFromVariant(image, index)
                              }
                              style={{
                                color: "red",
                                marginTop: "-75px",
                                cursor: "pointer",
                              }}
                            />
                          </>
                        ))}
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeImageModal} color="primary">
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          ></div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    g_active_products: state.productsState.active_products,
    g_draft_products: state.productsState.draft_products,
    g_archived_products: state.productsState.archived_products,
    g_categories: state.productsState.categories,
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(EditProduct));
