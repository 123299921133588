import React, { useState, useEffect } from "react";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "./RichTextEditor.css";

class EditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log();
    const description = this.props.values.contentOfDescription;
    if (description) {
      //console.log('In rich text editor -> setting description with ->  ', description);
      this.state.editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(description))
      );
    } else {
      this.state.editorState = EditorState.createEmpty();
    }
  }
  onEditorStateChange = (editorState) => {
    //  console.log(value);
    const contentState = editorState.getCurrentContent();
    //this.saveContent(contentState);
    //console.log('content state', convertToRaw(contentState));

    var jsonCont = JSON.stringify(convertToRaw(contentState));
    this.props.onChange(jsonCont);
    this.saveContent(jsonCont);
    // setEditorState(editorState);
    this.setState({ editorState });

    // setValue(editorState.getCurrentContent().getPlainText());
    // props.onChange(editorState.getCurrentContent().getPlainText());
  };
  saveContent = (content) => {
    //console.log("in save content function: ", this.props.values.contentOfDescription);
    // var jsonCont = JSON.stringify(convertToRaw(this.props.values.contentOfDescription));
    var jsonContParsed = JSON.parse(content);
    var text = jsonContParsed.blocks[0].text;
    //console.log(text);
    //window.localStorage.setItem('content', JSON.stringify(convertToRaw(content)));
    this.props.setDesc(text);
  };

  render() {
    return (
      <div className="editor">
        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          placeholder={this.props.placeholder}
          // value={props.values.description}
        />
      </div>
    );
  }
}

export default EditorContainer;
