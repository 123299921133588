import React, { Component } from "react";
import GlobalConfig from "../../Config";
import helpers from "../../UtilitiesFunctions";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import CustomProducts from "../ProductsModule/CustomProducts";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import Popup from "../Popup";
import AddCustomItem from "../OrderModals/AddCustomItem";
import BrowseProducts from "../OrderModals/BrowseProducts";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE, WHITE, MARS } from "../../colors/colors";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});

class EditOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnDisable: true,
      btnColor: "#D3D3D3",
      order: this.props.location.state,
      lineItems: this.props.location.state?.lineItems,
      nonReturnedItems: [],
      loader: false,
      open: true,
      editedLineItems: [],
    };
  }

  addItem = (item) => {
    if (!Number.isNaN(item.quantity)) {
      this.setState(function (currentState) {
        return {
          editedLineItems: [...currentState.editedLineItems, item],
          btnDisable: !currentState.btnDisable,
          btnColor: "#339966",
        };
      });
      console.log(this.state.editedLineItems);
    }
  };

  objectReplacer(arrayOfObjects, newObject) {
    let index = arrayOfObjects.findIndex((value) => value.id === newObject.id);
    console.log(index);
    if (index === -1) {
      arrayOfObjects.push(newObject);
      this.setState(function (currentState) {
        return { btnDisable: false };
      });
    } else {
      arrayOfObjects[index] = newObject;
      this.setState(function (currentState) {
        return { btnDisable: false };
      });
    }
    console.log(this.state.editedLineItems);
  }
  updateQty = (qty, name) => {
    console.log(name, qty);
    if (qty.quantity === isNaN) {
      helpers.deleteItem(this, qty, "lineItems");
    } else {
      console.log(qty, name);
      // /helpers.addItemList(this, qty, "editeItemList");
      //this.addItem(qty);
      this.objectReplacer(this.state.editedLineItems, qty);
    }
  };
  handleClickOpen = (name) => {
    if (name === "addCustomItemModal") {
      this.setState({ addCustomItemModal: true });
    } else if (name === "addDiscountModal") {
      this.setState({ addDiscountModal: true });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: true });
    } else if (name === "addTaxModal") {
      this.setState({ addTaxModal: true });
    } else if (name === "addShippingModal") {
      this.setState({ addShippingModal: true });
    }
  };

  handleClose = (name) => {
    if (name === "addCustomItemModal") {
      this.setState({ addCustomItemModal: false });
    } else if (name === "addDiscountModal") {
      this.setState({ addDiscountModal: false });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: false });
    } else if (name === "addTaxModal") {
      this.setState({ addTaxModal: false });
    } else if (name === "addShippingModal") {
      this.setState({ addShippingModal: false });
    }
  };

  updateMyCounter = (data, dest) => {
    if (dest === "customItem") {
      if (this.state.lineItems.length === 0) {
        this.setState({ lineItems: [data] });
        this.handleClose("addCustomItemModal");
      } else {
        this.setState({ lineItems: [...this.state.lineItems, data] });
        this.handleClose("addCustomItemModal");
      }
    } else if (dest === "dbItem") {
      if (this.state.lineItems.length === 0) {
        this.setState({ lineItems: data });
        this.handleClose("browseProductsModal");
      } else {
        helpers.addItemList(this, data, "lineItems");
        this.handleClose("browseProductsModal");
      }
    }
  };
  handleOnSearchProducts = (string, results) => {
    console.log(this.props.g_active_products);
    console.log(string, results);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.loader ? (
          <>
            <Dialog
              open={this.state.open}
              onClose={this.handleCloseDi}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <CircularProgress />
              </DialogContent>
            </Dialog>
          </>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ backgroundColor: SKY_BLUE, color: WHITE }}>
              <Box className={classes.box} p={1} width="100%">
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <ArrowBackIcon
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Box p={0.5} flexGrow={1} fontSize="20px">
                      Edit order
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <div style={{ backgroundColor: "white", marginTop: "10px" }}>
                <Box display="flex" flexDirection="row">
                  <Box p={1}>
                    <Popup
                      title="Select products"
                      btnVar="contained"
                      btnText="Browse products"
                      btnColor={WHITE}
                      backgroundColor={MARS}
                      contentTxt={<hr />}
                      contentTxtBe={<hr />}
                      content={
                        <BrowseProducts
                          updateParent={this.updateMyCounter}
                          onClickBtnClose={() =>
                            this.handleClose("browseProductsModal")
                          }
                        />
                      } //component to send
                      openPopup={this.state.browseProductsModal} //open
                      closePop={() => this.handleClose("browseProductsModal")} //close
                      onClickBtnOpen={(event) =>
                        helpers.handleOpenModal(this, "browseProductsModal")
                      } //on click open
                      onClickBtnClose={() =>
                        helpers.handleCloseModal(this, "browseProductsModal")
                      } //on click close
                    />
                  </Box>
                </Box>
                <Box display="inline-flex" width="100%">
                  <h6>Add product</h6>

                  <Box flexGrow={1}>
                    <Popup
                      title="Add custom item"
                      btnText="Add custom item"
                      btnColor={SKY_BLUE}
                      btnFloat="right"
                      contentTxt={<hr />}
                      contentTxtBe={<hr />}
                      content={
                        <AddCustomItem
                        //onClickBtnClose={() => handleClose("A")}
                        //updateParent={updateMyCounter}
                        />
                      } //component to send
                    />
                  </Box>
                </Box>
                <Box p={1}>fulfilled status here</Box>
                <Box>
                  <CustomProducts
                    products={this.state.lineItems}
                    isDelete={true}
                    onDelete={(event) => {
                      helpers.deleteItem(this, event, "editOrder");
                    }}
                    getQty={this.updateQty}
                    isRefund={false}
                    isEdit={true}
                  />
                </Box>
              </div>
            </Paper>
            <Paper className={classes.papertm}>
              <h6>Payment</h6>
              <Box display="inline-flex" p={1} width="100%">
                <Box p={0.5} flexGrow={2}>
                  Subtotal
                </Box>
                <Box p={0.5} flexGrow={2}>
                  {this.props.location.state?.lineItems.length} items
                </Box>
                <Box p={0.5}>
                  Rs {helpers.calculateSubTotal(this.state.editedLineItems)}
                </Box>
              </Box>
              <Box display="inline-flex" p={1} width="100%">
                <Box p={0.5} flexGrow={2}>
                  Tax
                </Box>
                <Box p={0.5} flexGrow={2}>
                  GST {this.props.location.state.taxRate}%
                </Box>
                <Box p={0.5}>
                  Rs {helpers.calculcateTax(this.state.editedLineItems)}
                </Box>
              </Box>
              <hr />
              <Box display="inline-flex" p={1} width="100%">
                <Box p={0.5} flexGrow={2}>
                  Total
                </Box>
                {/* <Box p={0.5} flexGrow={2}>
            </Box> */}
                <Box p={0.5}>
                  Rs {helpers.total(this.state.editedLineItems)}
                </Box>
              </Box>
            </Paper>
            <Paper className={classes.papertm}>
              <h6>Reason for edit</h6>
              <OutlinedInput
                name="reason"
                fullWidth={true}
                style={{ height: 35 }}
              />
              <p>Only you and other staff can see this reason.</p>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Box p={1}>
                <h6>Summary</h6>
                <Box>
                  {this.state.editedLineItems.length === 0 ? (
                    <>No changes have been made.</>
                  ) : (
                    <>
                      <div>
                        <Box style={{ float: "left" }}>
                          <p>Updated total</p>
                          <p>Previous total</p>
                          <p>Tax</p>
                          <b>Amount to collect</b>
                        </Box>

                        <Box style={{ float: "right" }}>
                          <p>Rs {helpers.total(this.state.editedLineItems)}</p>
                          <p>Rs {this.state.order.total}</p>
                          <p>
                            Rs
                            {helpers.calculcateTax(this.state.editedLineItems)}
                          </p>
                          <b>Rs {helpers.total(this.state.editedLineItems)}</b>
                        </Box>
                      </div>
                    </>
                  )}
                </Box>
              </Box>

              <div>
                <Button
                  style={{
                    marginTop: "10px",
                    backgroundColor: SKY_BLUE,
                    textTransform: "none",
                    color: "white",
                  }}
                  variant="outlined"
                  fullWidth
                  //disabled={this.handleChange}
                  disabled={this.state.btnDisable}
                  onClick={() => {
                    this.setState(function (currentState) {
                      return { loader: true };
                    });
                    //console.log(this.state.order);
                    var createOrder = {
                      orderId: this.state.order.orderId,
                      lineItems: this.state.editedLineItems,
                    };
                    let stringifyString;
                    if (createOrder != null) {
                      stringifyString = JSON.stringify(createOrder);
                    }
                    console.log(stringifyString);
                    fetch(
                      this.props.global_config.basePath +
                        `/orders/${this.state.order.orderId}`,
                      {
                        method: "PUT",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer token",
                        },
                        body: stringifyString, //JSON.stringify(createOrder),
                      }
                    ).then((resp) => {
                      console.log("Resp" + resp);
                      fetch(
                        this.props.global_config.basePath +
                          `/orders/${this.state.order.orderId}`,
                        {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer my-token",
                          },
                        }
                      )
                        .then((response2) => response2.json())
                        .then((data2) => {
                          Store.dispatch({
                            type: "EDIT_ORDERS",
                            orders: data2,
                          });
                        });
                      setTimeout(() => {
                        this.setState({ backdrop: false, snackbar: false });
                        helpers.toastNotify(
                          "Success",
                          "Order Updated Successfully!"
                        );
                      }, 10000);
                    });
                    this.props.history.push({
                      pathname: "/ViewOrders",
                    });
                  }}
                >
                  Update Order
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_orders: state.ordersState.orders,
    g_unfulfilled_orders: state.ordersState.unfulfilled_orders,
    g_unpaid_orders: state.ordersState.unpaid_orders,
    // g_draft_orders: state.ordersState.draft_orders,
    g_open_orders: state.ordersState.open_orders,
    g_active_products: state.productsState.active_products,
    // g_active_customers: state.customersState.active_customers
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps, null)(withStyles(useStyles)(EditOrder));
