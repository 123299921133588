import React, { useState } from "react";
import Label from "../ProductMobileComponents/label";
import InputField from "../ProductMobileComponents/InputField";
import SelectionMenu from "./SelectionMenu";
import TextEditor from "./RictTextEditor";
import AddProductScreen1 from "../ProductMobileComponents/AddProductScreen1";
import ButtonGroup from "../ProductMobileComponents/ButtonGroup";
import AddProductInventory from "./AddProduct-Inventory";
import AddProductPricing from "./AddProduct-Pricing";
import GlobalConfig from "../../Config";
import SnackBar from "../ProductMobileComponents/Snackbar";
import Loader from "../Loader";
import Store from "../../index";
import { useHistory } from "react-router-dom";

import "./AddProduct.css";
import { connect } from "react-redux";
import helpers from "../../UtilitiesFunctions";

const statuses = [
    {
        POID: 1,
        value: "Active",
    },
    {
        POID: 2,
        value: "Draft",
    },
];
class AddProduct extends React.Component {
    state = {
        step: 1,
        category: "",
        status: "",
        name: "",
        description: "",
        quantity: 0,
        costPrice: 0,
        price: 0,
        contentOfDescription: "",
        categories: [],
        snackbar: false,
        snackbarMessage: "",
        snackbarType: "success",
        products: [],
        loading: false,
        productSpecCharacteristics: [],
    };

    componentDidMount() {
        console.log("props from product from: ", this.props.prevProps);
        const fetchCategories = () => {
            fetch(this.props.global_config.basePath + "/categories", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer token",
                },
            })
                .then((response) => response.json())
                .then((data) =>
                    this.setState({
                        loading: false,
                        categories: data,
                    })
                )
                .catch((e) => {
                    console.log(e);
                });
        };
        if (this.state.categories.length === 0) {
            fetchCategories();
        }
    }

    getSpecificProduct = (productId) => {
        console.log("in getSpecificProduct function");
        var url = this.props.global_config.basePath + "/products/" + productId;
        console.log("url: " + url);
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer token",
            },
        })
            .then((response2) => response2.json())
            .then((data2) => {
                console.log("dispatching product in redux store");
                Store.dispatch({
                    type: "POST_PRODUCTS",
                    products: data2,
                });
                this.changeRouteToProduct();
            })
            .catch((error) => {
                this.changeRouteToProduct();
                this.setState({
                    snackbar: false,
                    snackbarMessage: "unable to set product in redux store",
                    snackbarType: "error",
                });
                helpers.toastNotify("error", "Error Occured!");
            });
    };

    changeRouteToProduct = () => {
        const history = this.props.prevProps;
        history.push("/Products");
    };

    postProduct = () => {
        // convert the React state to JSON and send it as the POST body
        const data = JSON.stringify(this.setObjectForProductPosting());
        //setLoader to true
        this.setState({ loading: true });
        console.log("object ready for posting: ", data);
        fetch(this.props.global_config.basePath + "/products", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
            },
            body: data,
        }).then((response) => {
            response
                .json()
                .then((resp) => {
                    console.log("response is ", resp);
                    const createdProductId = resp.POID;
                    this.getSpecificProduct(createdProductId);
                    this.setState({
                        snackbar: false,
                        snackbarMessage: "Product Created Successfully",
                        snackbarType: "success",
                        loading: false,
                    });
                    helpers.toastNotify("success", "Product Successfully Created!");
                })
                .catch((error) => {
                    this.setState({
                        snackbar: false,
                        snackbarMessage: "Somwthing went wrong! unable to create product",
                        snackbarType: "error",
                        loading: false,
                    });
                    helpers.toastNotify("error", "Could Not Create Product.");
                });

            //alert("Product Posted Successfully");
        });
    };

    setObjectForProductPosting = () => {
        const {
            category,
            status,
            name,
            description,
            quantity,
            costPrice,
            price,
            productSpecCharacteristics,
        } = this.state;
        const product = {
            categoryId: category,
            status: status,
            name: name,
            description: description,
            quantity: Number(quantity),
            costPrice: Number(costPrice),
            price: Number(price),
            images: [],
            startDate: "2021-10-20",
            endDate: "2021-10-30",
            taxRate: 18,
            algorithm: "RuleEngine.groovy",
            productSpecCharacteristics: productSpecCharacteristics,
            unitsOfMeasures: [
                {
                    id: 1,
                    isDefault: true,
                    conversionFactor: 1,
                    name: "Nos",
                    relation: "includes",
                    price: Number(price),
                },
            ],
        };
        return product;
    };

    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    };

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    };

    // Handle fields change
    handleChange = (input) => (e) => {
        console.log(e.target.value);
        this.setState({ [input]: e.target.value });
    };
    handleDescription = (key, value) => {
        console.log(`Key: ${key}\nvalue: ${value}`);
        this.setState({ [key]: value });
    };
    setSnackbarToFalse = () => {
        setTimeout(() => {
            this.setState({ snackbar: false });
        }, 3000);
    };

    render() {
        const { prevProps } = this.props;
        const { step } = this.state;
        const {
            category,
            status,
            name,
            description,
            quantity,
            price,
            costPrice,
            contentOfDescription,
        } = this.state;
        const values = {
            category,
            status,
            name,
            description,
            quantity,
            price,
            costPrice,
            contentOfDescription,
        };
        console.log("Categories size from api is: ", this.state.categories.length);
        const { snackbar, snackbarMessage, snackbarType, loading } = this.state;
        if (snackbar) {
            //agar snackbar true hai to usay false karde wrna wo har render bar display hoga
            console.log("in snackbar condiiton");
            this.setSnackbarToFalse();
        }

        switch (step) {
            case 1:
                return (
                    <AddProductScreen1
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values}
                        list={this.state.categories}
                        statuses={statuses}
                        handleDescription={this.handleDescription}
                        history={prevProps}
                    />
                );
            case 2:
                return (
                    <AddProductInventory
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values}
                    />
                );
            case 3:
                return (
                    <>
                        <div>
                            {loading ? (
                                <Loader value={"Creating Product.."} />
                            ) : (
                                <>
                                    {" "}
                                    <AddProductPricing
                                        prevStep={this.prevStep}
                                        nextStep={this.nextStep}
                                        handleChange={this.handleChange}
                                        values={values}
                                        handlePost={this.postProduct}
                                    />
                                    <div>
                                        {snackbar ? (
                                            <SnackBar
                                                message={snackbarMessage}
                                                type={snackbarType}
                                            />
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                );
            // case 4:
            //   return (
            //     <Success />
            //   )
            default:
            // do nothing
        }
    }
}
const mapStateToProps = (state) => {
    return {
        global_config: state.globalState,
    };
};
export default connect(mapStateToProps)(AddProduct);
