import React, { useState } from "react";
import PageTitle from "./PageTitle";
import { Grid, TextField, Button, InputLabel, Select, MenuItem } from "@material-ui/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GlobalConfig from "../../Config";
import Loader from "../Loader";
import SnackBar from "../ProductMobileComponents/Snackbar";
import Store from "../../index";
import { useSelector } from "react-redux";
import helpers from "../../UtilitiesFunctions";

const AddUser = ({ prevStep, token, handlePost }) => {
    // console.log(values);
    const PostObject = (e) => {
        e.preventDefault();
        handlePost();
    };
    const [passwordField, setPasswordField] = useState("password");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");

    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const global_config = useSelector((state) => state.globalState);

    const Previous = (e) => {
        e.preventDefault();
        prevStep();
    };
    const setField = () => {
        passwordField === "password" ? setPasswordField("text") : setPasswordField("password");
    };
    const submitUser = (e) => {
        setLoading(true);
        e.preventDefault();
        let createUser = {
            role: [
                {
                    name: role === "Agent" ? "agent" : role === "Manager" ? "manager" : role,
                },
            ],
            userName: name,
            email: email,
            password: password,
        };

        let stringifyString;
        if (createUser != null) {
            stringifyString = JSON.stringify(createUser);
        }
        console.log("created object of user: ", stringifyString);
        console.log("created object of token: ", token);

        fetch(global_config.basePath + "/users", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: stringifyString,
        })
            .then((response) => {
                fetch(global_config.basePath + "/users", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        Store.dispatch({ type: "POST_USERS", users: data });
                    })
                    .catch((err) => {
                        console.log("error");
                    });
                setLoading(false);
                setSnackbar(false);
                setSnackbarMessage("User Added");
                setSnackbarType("success");
                helpers.toastNotify("success", "User Successfully Created!");
            })
            .catch((err) => {
                setLoading(false);
                setSnackbar(false);
                setSnackbarMessage("Unable to Add User");
                setSnackbarType("error");

                helpers.toastNotify("error", "Could Not Create User.");
            });
    };
    const roles = ["Admin", "Manager", "Agent"];
    return (
        <>
            {loading ? (
                <Loader value={"Please Wait.."} />
            ) : (
                <>
                    <div>
                        <PageTitle
                            textType="h5"
                            value="ADD USER"
                            Icon={<ArrowBackIosNewIcon />}
                            prevStep={prevStep}
                        />
                    </div>
                    <div className="label spacing">
                        <Grid container spacing={2}>
                            {/* price */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    placeholder="Name"
                                    label="Name"
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    placeholder="Email"
                                    label="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="password"
                                    placeholder="Password"
                                    label="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={passwordField}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        endAdornment:
                                            passwordField === "text" ? (
                                                <InputAdornment onClick={() => setField()}>
                                                    <IconButton>
                                                        <VisibilityOffIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : (
                                                <InputAdornment onClick={() => setField()}>
                                                    <IconButton>
                                                        <RemoveRedEyeIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="Input label">Roles</InputLabel>
                                <Select
                                    labelId="Input label"
                                    id="Select"
                                    // value="Admin"
                                    defaultValue=""
                                    required
                                    onChange={(e) => setRole(e.target.value)}
                                    variant="outlined"
                                    fullWidth>
                                    {roles.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="label">
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        onClick={(e) => submitUser(e)}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div>
                        {snackbar ? (
                            <SnackBar message={snackbarMessage} type={snackbarType} />
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};

export default AddUser;
