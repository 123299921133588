import React, { useState, useEffect } from 'react'
import PageTitle from './PageTitle'
import SearchBar from './SearchBar';
import InventoryItems from './InventoryItems'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material";
import EditInventory from './EditInventory'
import '../ProductMobileComponents/Product.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SKY_BLUE, MARS } from '../../colors/colors'



const ShowInventory = (props) => {
    const { products, nextStep } = props;
    let history = useHistory();
    const [filteredProducts, setFilteredProducts] = useState(products);

    const changeRoute = () => {
        history.push("/");
    };


    useEffect(() => { setFilteredProducts(products) }, [products])

    console.log(filteredProducts)
    console.log(products)

    const setProducts = (newFilteredProducts) => {
        setFilteredProducts(newFilteredProducts);
    };

    return (
        <>
            <div>

                <PageTitle textType="h6" value="Inventory" Icon={<ArrowBackIosNewIcon />} ChangeRoute={changeRoute} />


            </div>
            <div style={{
                display: "flex", flexDirection: "row", justifyContent: "flex-end", margin: "20px",
                marginTop: "0px", position: "relative", top: "2px"
            }}>
                <Button
                    component={Link}
                    to="/Products"
                    variant="contained" size="small"
                    sx={{ backgroundColor: MARS }}
                >
                    View Products
                </Button>
            </div>
            <div>
                <div style={{ margin: "20px", marginTop: "40px" }}>
                    <SearchBar barvalue="Search Products" data={products} onChange={setProducts} />
                </div>
                <InventoryItems
                    inventoryItems={filteredProducts}
                    nextStep={nextStep}
                />
            </div>

        </>
    )
}

export default ShowInventory
