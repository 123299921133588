import React, { Component } from "react";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Grid, Button } from "@material-ui/core";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CSVLink } from "react-csv";

class CustomersOverTimeMobile extends Component {
  changeRouteToAnalytics = () => {
    const history = this.props.history;
    console.log("Changing route to view customers...");
    history.push("/Analytics");
  };
  render() {
    const { rows, columns, loading, selectedDate, selectedEndDate, csvReport } =
      this.props;
    return (
      <div>
        <div>
          <PageTitle
            textType="h6"
            value="Customers Over Time"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.changeRouteToAnalytics}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginTop: "10px",
            display: "flex",
            marginRight: "10px",
            flexDirection: "row",
          }}
        >
          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="Start Date"
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={this.props.onDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <div style={{ alignSelf: "flex-end" }}>
            <Button>
              <CSVLink {...csvReport}>Export</CSVLink>
            </Button>
          </div>
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginTop: "10px",
            display: "flex",
            marginRight: "10px",
            flexDirection: "row",
          }}
        >
          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="End Date"
                format="dd/MM/yyyy"
                value={selectedEndDate}
                onChange={this.props.onEndDateChange}
                showTodayButton={true}
                // maxDate={this.state.endDate}
                // maxDateMessage
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <div style={{ alignSelf: "flex-end" }}>
            <Button
              onClick={this.props.fetchData}
              color="primary"
              variant="contained"
              size="small"
            >
              Submit
            </Button>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            width: "100%",
            backgroundColor: "white",
            marginBottom: "45px",
          }}
        >
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            hideFooter={false}
            pageSize={5}
            loading={loading}
            checkboxSelection={false}
            disableSelectionOnClick
          />
        </div>
      </div>
    );
  }
}
export default CustomersOverTimeMobile;
