import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { SKY_BLUE, WHITE } from "../colors/colors";

export default function Popup(props) {
  return (
    <div>
      <Button
        style={{
          fontSize: props?.btnFontSize,
          textTransform: "capitalize",
          float: props.btnFloat,
          color: props.btnColor,
          marginTop: props.marginTop,
          height: props.height,
          width: props.width,
          marginLeft: props.marginLeft,
          marginRight: props?.marginRight,
          backgroundColor: props.backgroundColor,
          borderRadius: "none",
        }}
        color={props.btnColor}
        variant={props.btnVar}
        disabled={props.btnDisable}
        onClick={props.onClickBtnOpen}
        fullWidth={props.btnWidth}
        size={props.size}
      >
        {props.btnText}
      </Button>
      <Dialog
        open={props.openPopup}
        onClose={props.closePop}
        //disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <Box display="flex">
            <Box flexGrow={1}>{props.title}</Box>
            <Box>
              <CloseIcon color="error" onClick={props.onClickBtnClose} />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.contentTxt}</DialogContentText>
          {props.content}
          {/* {props.contentTxtBe} */}
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
