
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import InputField from './InputField';
import InputFieldWithIcon from './InputFieldWithIcon';
import Label from './label';
import PageTitle from './PageTitle';
import ToggleButton from './ToggleButton';
import Link from './Link';
import CustomButton from './Button';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core';
import './AddProduct-Inventory.css';


const AddProductPricing = ({ nextStep, prevStep, handleChange, values, handlePost }) => {
    // console.log(values);
    const PostObject = e => {
        e.preventDefault();
        // nextStep();
        handlePost();
    }


    const Previous = e => {
        e.preventDefault();
        prevStep();
    }
    return (
        <>
            <div>
                <PageTitle textType="h6" value="PRICING" Icon={<ArrowBackIosNewIcon />} ChangeRoute={prevStep} />
            </div>
            <div className="label spacing">
                <Grid container spacing={2}>

                    {/* price */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter Price"
                            label="Price"
                            onChange={handleChange('price')}
                            defaultValue={values.price}
                            type="number"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter Cost Price"
                            label="Cost Price"
                            onChange={handleChange('costPrice')}
                            defaultValue={values.costPrice}
                            type="number"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="label">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Previous}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={PostObject}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
};

export default AddProductPricing;