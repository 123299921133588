import React, { Component } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

class VariantDetails extends Component {
  render() {
    console.log("variant  Props", this.props);
    return (
      <>
        <h5>
          <ArrowBackIosIcon
            fontSize="large"
            onClick={() => this.props.history.push("/POS")}
            style={{ cursor: "pointer" }}
          />
          Product
        </h5>
        <div>
          <h4>{this.props.location.state.name}</h4>
          <h6>{this.props.location.state.description}</h6>
          <img
            src={
              this.props.location.state.imageURL &&
              this.props.location.state.images[0].imageURL
            }
            alt=""
          />
          <div>
            <p>
              {this.props.location.state.relatedProducts.map((variant) => {
                return (
                  <>
                    <p>{variant.POID}</p>
                    <p>{variant.relationShipType}</p>
                  </>
                );
              })}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default VariantDetails;
