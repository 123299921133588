import React from "react";
import TextField from "@mui/material/TextField";
class InputField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
    };
    handleChange = (event) => {
        //console.log(event.target.value);
        this.setState({ value: event.target.value });

    };

    render() {
        return (

            <TextField
                id="outlined-basic"
                variant="outlined"
                label={this.props.label}
                // value={this.state.value}
                placeholder={this.props.placeholder}
                onChange={this.handleChange}
                type={this.props.type}
                // sx={{
                //     margin: '5px 5px 5px 5px'
                // }}
                fullWidth
                defaultValue={''}
            />
        );
    }

};

InputField.defaultProps = {
    //label:'label',
    value: '',
    placeholder: 'write some text here',
    type: '',
    fieldValue: ''
};

export default InputField;
