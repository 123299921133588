import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@mui/material/TextField";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import Backdrop from "@material-ui/core/Backdrop";
import { DataGrid } from "@material-ui/data-grid";
import GlobalConfig from "../../Config";
import Customer from "../../MobileComponents/Customers/Customer";
import Loader from "../../MobileComponents/Loader";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import Stack from "@mui/material/Stack";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import Store from "../../index";
import helpers from "../../UtilitiesFunctions";

const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
  button: {
    background: SKY_BLUE,
    color: "white",
  },
});

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      list: [],
      loading: true,
      query: "",
      backdrop: false,
      previousCustomers: [],
      snackbar: false,
      filter: "Active",
      loaderMessage: "Loading Customers..",
      ExportButtonLoader: false,
      ImportButtonLoader: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    // Simple GET request using fetch
    fetch(`${this.props.global_config.basePath}/customers?status=Active`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          customers: data,
          previousCustomers: data,
          loading: false,
        });

        Store.dispatch({
          type: "GET_ACTIVE_CUSTOMERS",
          active_customers: data,
        });
      })
      .catch((err) => {
        // let localCustomers = JSON.parse(localStorage.getItem("customers"));
        this.setState({
          //  customers: localCustomers,
          loading: false,
        });
      });
    if (this.props.g_active.length === 0) {
      fetch(`${this.props.global_config.basePath}/customers?status=Active`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            customers: data,
            previousCustomers: data,
            loading: false,
          });

          Store.dispatch({
            type: "GET_ACTIVE_CUSTOMERS",
            active_customers: data,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({ customers: this.props.g_active });
    }
    if (this.props.g_archived_customers.length === 0) {
      fetch(`${this.props.global_config.basePath}/customers?status=Archived`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            // loading: false,
          });

          Store.dispatch({
            type: "GET_ARCHIVED_CUSTOMERS",
            archived_customers: data,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        customers: this.props.g_active,
        loading: false,
        mode: "online",
      });
    }
  }
  getNewCustomers = () => {
    let newCustomers = this.props.g_active.filter(
      (customer) => customer.numberOfOrders === 0
    );
    this.setState({ customers: newCustomers });
  };
  getReturningCustomers = () => {
    let returningCustomers = this.props.g_active.filter(
      (customer) => customer.numberOfOrders > 1
    );
    this.setState({ customers: returningCustomers });
  };
  getCustomersByStatus = (status) => {
    this.setState({
      loading: true,

      customers: [],
    });

    if (status === "Archived") {
      this.setState({
        customers: this.props.g_archived_customers,
        query: "",
        loading: false,
      });
    } else {
      this.setState({
        customers: this.props.g_active,
        query: "",
        loading: false,
      });
    }
  };
  dynamicSearch = (list, searchTerm) => {
    if (searchTerm.length > 0) {
      return list.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };
  handleChange = (event) => {
    //
    let returningCustomers = this.props.g_active.filter(
      (customer) => customer.numberOfOrders > 1
    );
    let newCustomers = this.props.g_active.filter(
      (customer) => customer.numberOfOrders === 0
    );
    if (this.state.filter === "Active") {
      this.setState({
        list: this.props.g_active,
        previousCustomers: this.props.g_active,
      });
    } else if (this.state.filter === "Archived") {
      this.setState({
        list: this.props.g_archived_customers,
        previousCustomers: this.props.g_archived_customers,
      });
    } else if (this.state.filter === "Returning") {
      this.setState({
        list: returningCustomers,
        previousCustomers: returningCustomers,
      });
    } else {
      this.setState({ list: newCustomers, previousCustomers: newCustomers });
    }

    if (event.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        customers: this.state.list,
        filter: this.state.filter,
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      const filteredList = this.dynamicSearch(
        this.state.customers,
        event.target.value
      );
      this.setState({
        backdrop: false,
        customers: filteredList,
      });
    }
  };
  handleSearch = (key, value) => {
    if (value.length > 0) {
      this.setState({ backdrop: true });
      const filteredList = this.dynamicSearch(this.state.customers, value);
      if (filteredList.length > 0) {
        this.setState({
          backdrop: false,
          customers: filteredList,
        });
      } else {
        if (this.state.query.length <= 0) {
          this.setState({ snackbar: false });

          helpers.toastNotify("error", "Empty Field Detected!");
        } else {
          this.setState({ backdrop: true });
          fetch(
            this.props.global_config.basePath +
              `/customers/search?key=${key}&value=${value}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer token",
              },
            }
          )
            .then((response) => response.json())
            .then((responseData) => {
              console.log(responseData, "after search query");
              this.setState({
                backdrop: false,
                customers: responseData,
              });
            })
            .catch((e) => {
              helpers.toastNotify("error", "Could Not Find!");
              this.setState({
                backdrop: false,
              });
            });
        }
      }
    }
  };

  handleDelete = (customer) => {
    fetch(this.props.global_config.basePath + `/customers/${customer.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      console.log(response);
      Store.dispatch({ type: "DELETE_ACTIVE_CUSTOMERS", POID: customer.POID });
    });

    this.props.history.push({
      pathname: "/Customers",
    });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  cancelSearch = () => {
    this.setState({
      query: "",
      customers: this.state.previousCustomers,
    });
  };
  onHandleBack = (props) => {
    this.props.history.push("/");
  };
  enableExportButtonLoader = () => {
    this.setState({ ExportButtonLoader: true });
  };
  disableExportButtonLoader = () => {
    this.setState({ ExportButtonLoader: false });
  };
  enableImportButtonLoader = () => {
    this.setState({ ImportButtonLoader: true });
  };
  disableImportButtonLoader = () => {
    this.setState({ ImportButtonLoader: false });
  };
  ExportCustomers = () => {
    this.enableExportButtonLoader();
    let url = this.props.global_config.basePath + "/customers/exportCustomers";
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer token",
      },
    })
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "customers.xlsx";
          a.click();
          this.disableExportButtonLoader();
          alert("Customers exported Successfully");
        });
        //window.location.href = response.url;
      })
      .catch((err) => {
        this.disableExportButtonLoader();
        alert("Error! Something went wrong in exporting customers");
        console.log(err);
      });
  };
  handleFileUpload = (event) => {
    this.enableImportButtonLoader();
    console.log("file is: ", event.target.files[0].name);
    const selectedFile = event.target.files[0];
    let url = this.props.global_config.basePath + "/customers/importCustomers";
    const formData = new FormData();

    formData.append("file", selectedFile);
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer token",
      },
      body: formData,
    })
      .then((response) => {
        this.disableImportButtonLoader();
        alert("Customers imported successfully");
        //window.location.href = response.url;
      })
      .catch((err) => {
        this.disableImportButtonLoader();
        alert("Error! Something went wrong in importing customers");
        console.log(err);
      });
  };

  render() {
    const { history, classes } = this.props;
    const {
      customers,
      loading,
      query,
      backdrop,
      ImportButtonLoader,
      ExportButtonLoader,
    } = this.state;

    const columns = [
      {
        field: "POID",
        header: "ID",
        width: 110,
      },
      {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: true,
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        editable: true,
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        width: 200,
        editable: true,
      },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        editable: true,
      },
      {
        field: "totalOrders",
        headerName: "Total Orders",
        width: 145,
        editable: true,
      },
      {
        field: "amountSpent",
        headerName: "Amount Spent",
        width: 145,
        editable: true,
      },
    ];
    const rows = [];
    const obj = customers;
    if (customers) {
      for (var i = 0; i < obj.length; i++) {
        rows[i] = {
          id: i,
          POID: obj[i].POID,
          name: obj[i].name,
          email: obj[i].email,
          phoneNumber: obj[i].phoneNumber,
          status: obj[i].status,
          totalOrders: obj[i].numberOfOrders,
          amountSpent: obj[i].totalSpendOnOrder,
        };
      }
    }
    // console.log("Customers", customers);
    function CustomToolbar() {
      return (
        <div style={{ width: "400px" }}>
          <GridToolbarContainer
            style={{
              backgroundColor: "#EFEFEF",
              border: "1px solid black",
              margin: "20px",
              width: "100px",
              height: "50px",
            }}
          >
            <GridToolbarExport />
          </GridToolbarContainer>
        </div>
      );
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      this.state.height < 200 ||
      this.state.width < 300
    ) {
      return (
        <>
          {this.props.g_active.length === 0 &&
          this.props.g_archived_customers.length === 0 ? (
            <Loader value={"Loading Customers"} />
          ) : (
            <div>
              <Customer
                data={this.props.g_active.concat(
                  this.state.customers
                  // this.props.g_archived_customers
                )}
                history={history}
              />
            </div>
          )}
        </>
      );
    } else
      return (
        <>
          {loading ? (
            <Loader value={this.state.loaderMessage} />
          ) : (
            <>
              <Backdrop
                style={{
                  zIndex: 1,
                  color: "#fff",
                }}
                open={backdrop}
              >
                <CircularProgress thickness={2} size={90} color="inherit" />
              </Backdrop>
              {this.state.snackbar ? (
                <>
                  <Snackbar
                    open={this.state.snackbar}
                    autoHideDuration={2000}
                    onClose={this.closeSnack}
                  >
                    <MuiAlert
                      severity="error"
                      variant="filled"
                      color="error"
                      onClose={this.closeSnack}
                    >
                      Please enter valid name!
                    </MuiAlert>
                  </Snackbar>
                </>
              ) : null}
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "20px",
                }}
              >
                <div>
                  <h5>Customers</h5>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/customer"
                    style={{ backgroundColor: SKY_BLUE, color: "white" }}
                  >
                    Add Customer
                  </Button>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  height: "100%",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                    <TextField
                      fullWidth={true}
                      value={query}
                      name="query"
                      onChange={this.handleChange}
                      id="outlined-basic"
                      size="small"
                      label="Search Customers"
                      // placeholder="search customer by name or Email"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ marginRight: "10px" }}>
                            {/* <IconButton> */}
                            <SearchTwoToneIcon />

                            {/* </IconButton> */}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment>
                            {this.state.query === "" ? null : (
                              <div
                                style={{ marginRight: "10px" }}
                                variant="outlined"
                                color="primary"
                                onClick={() => this.cancelSearch()}
                              >
                                <CancelIcon style={{ color: "red" }} />
                              </div>
                            )}
                            <h2>{this.searchTerm}</h2>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div
                    style={{
                      marginTop: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <input
                        ref="fileInput"
                        onChange={this.handleFileUpload}
                        type="file"
                        style={{ display: "none" }}
                        // multiple={false}
                      />
                    </Stack>
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "40px", marginLeft: "20px" }}>
                    <Button
                      className={
                        this.state.filter === "Active" ? classes.button : null
                      }
                      onClick={() =>
                        this.getCustomersByStatus(
                          "Active",
                          this.setState({ filter: "Active" })
                        )
                      }
                      style={{ marginRight: "10px" }}
                    >
                      Active
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ margin: "20px 0 10px 20px" }}>
                    <span>
                      {customers && customers.length > 0 ? (
                        <b>Showing {customers.length} Customers </b>
                      ) : null}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  ></div>
                </div>

                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    loading={loading}
                    hideFooter
                    rows={rows}
                    columns={columns}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    sortModel={[
                      {
                        field: "POID",
                        sort: "desc",
                      },
                    ]}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      console.log(data);
                      console.log(data.row.id);
                      console.log(data.row.POID);
                      this.props.history.push({
                        pathname: `/editcustomer/${data.row.POID}`,
                        state: customers[data.row.id],
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      );
  }
}
const mapStateToProps = (state) => {
  return {
    g_active: state.customersState.active_customers,
    g_archived_customers: state.customersState.archived_customers,
    global_config: state.globalState,
  };
};

export default connect(mapStateToProps)(withStyles(style)(Customers));
