import React from "react";
import CartItem from "./cartItem";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";

import { SKY_BLUE } from "../../colors/colors";
import { emptyCart } from "../../Redux/action/cart_actions";
import Store from "../../index";
import { DeleteForever, Money } from "@mui/icons-material";
class CartList extends React.PureComponent {
  render() {
    const { history } = this.props;

    // console.log("Cart List props", history);
    const dummyImage =
      "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081";
    const { cart, updateCartUnits } = this.props;

    console.log("cart Item", this.props);
    return (
      <>
        <div
          className=""
          style={{
            width: "100%",
            // backgroundColor: "red",
            padding: "20px",
            borderRadius: "5px",
            border: "1px solid lightgray",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Cart</h1>
          </div>
          <div>
            {cart.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>Your Cart is Empty</h4>
              </div>
            )}
          </div>
          {cart.map((item) => (
            <CartItem
              imageurl={dummyImage}
              {...item}
              key={item.POID}
              updateCartUnits={updateCartUnits}
              customers={this.props.customers}
            />
          ))}

          {cart.length ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                onClick={() =>
                  history.push({
                    pathname: "./checkout",
                    state: cart,
                    customers: this.props.customers,
                  })
                }
                variant="contained"
                size="large"
                color="secondary"
                fullwidth="true"
                style={{
                  width: " 200px",
                  textAlign: "center",
                  marginTop: "20px",
                  backgroundColor: SKY_BLUE,
                }}
              >
                Pay<span style={{ marginLeft: "5px" }}></span>
                {cart.length &&
                  cart
                    .map((obj) =>
                      Number(
                        obj.price * obj.units +
                          parseFloat(
                            (
                              (obj.price / 100) *
                              obj.taxRate *
                              obj.units
                            ).toFixed(2)
                          )
                      )
                    )
                    .reduce((a, b) => a + b)
                    .toFixed(2)}
              </Button>
              <Button
                onClick={() => Store.dispatch({ type: "CLEAR_CART_DATA" })}
                variant="contained"
                size="large"
                color="secondary"
                fullwidth="true"
                style={{
                  width: " 200px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <DeleteForever />
                Empty Cart
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
export default withRouter(CartList);
