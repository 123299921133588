import React, { Component } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, Select, MenuItem } from "@mui/material";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const eligibleCustomers = [
  {
    POID: 1,
    value: "EveryOne",
  },
  {
    POID: 2,
    value: "Specific Customers",
  },
];

class CustomerEligibility extends Component {
  onHandleBack = () => {
    this.props.setStep(1);
  };
  render() {
    const { customerEligibility, selectedCustomers } = this.props.values;
    return (
      <>
        <div>
          <PageTitle
            textType="h6"
            value="Customer Eligibility"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.onHandleBack}
          />
        </div>
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="8px">
          {/* <InputLabel id="Input label">Discount type</InputLabel> */}
          <Select
            labelId="Input label"
            id="Select"
            name="customerEligibility"
            value={customerEligibility}
            // defaultValue={values.status}
            onChange={this.props.handleChange}
            size="large"
            variant="outlined"
            fullWidth
          >
            {eligibleCustomers.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {customerEligibility === "Specific Customers" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                marginTop: "15px",
                marginLeft: "5px",
                marginBottom: "5px",
              }}
            >
              <div className="label-products">
                <label>Customers</label>
              </div>
              {selectedCustomers.length === 0 ? (
                <AddCircleOutlineIcon
                  onClick={this.props.nextStep}
                  color="success"
                  fontSize="medium"
                />
              ) : (
                <EditIcon
                  onClick={this.props.nextStep}
                  color="success"
                  fontSize="medium"
                />
              )}
            </div>
            {selectedCustomers.length === 0 ? (
              <div
                style={{
                  width: "90%",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              >
                <p>No customers Added</p>
              </div>
            ) : (
              selectedCustomers.map((customer) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "8px",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                  <AccountCircleIcon style={{ fill: "green" }}/>
                    <p style={{ marginLeft: "10px", textAlign: "center" }}>
                      {customer.name.slice(0, 20)}
                    </p>
                  </div>
                  <div>
                    <CancelIcon
                      color="warning"
                      onClick={() => this.props.handleRemove(customer.POID)}
                    />
                  </div>
                </div>
              ))
            )}
          </>
        ) : null}
      </>
    );
  }
}

export default CustomerEligibility;
