import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MARS, SKY_BLUE } from "../colors/colors";
import { Textarea } from "@mobiscroll/react-lite";
import helpers from "../UtilitiesFunctions";

export default function ResponsiveDialog(props) {
  console.log("here is props", props);
  const { title, message, button1, button2, confirmation, cancelation } = props;
  const [open, setOpen] = React.useState(true);
  const [reason, setReason] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    setOpen(false);
    cancelation();
  };
  const handleConfirmation = () => {
    if (!reason) {
      helpers.toastNotify("error", "please Provide a reason");
    } else {
      handleClose();
      confirmation(reason);
    }
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <DialogContentText>
            <Textarea
              style={{
                width: "400px",
                height: "100px",
                marginTop: "10px",
                padding: "5px",
              }}
              placeholder="Provide reason"
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleConfirmation}
            variant="contained"
            sx={{ backgroundColor: MARS }}
          >
            {button1}
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            variant="contained"
            sx={{ backgroundColor: SKY_BLUE }}
          >
            {button2}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
ResponsiveDialog.defaultProps = {
  title: "Confirmation",
  message: "Are you sure?",
  button1: "Yes",
  button2: "No",
};
