import React from 'react';
import EditInventory from './EditInventory'
import '../ProductMobileComponents/Product.css';
import ShowInventory from './ShowInventory'

class InventoryMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      error: null,
      value: "",
      step: 1,
      product: []
    };
  }
  componentDidMount() {
    this.setState({ products: this.props.data })
    console.log(this.state.products)
    console.log(this.props.data)
  };

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  // proceed to the next step
  nextStep = (productState) => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
      product: productState
    });

  }
  render() {



    switch (this.state.step) {
      case 1:
        return (
          <ShowInventory
            products={this.state.products}
            nextStep={this.nextStep}
          />

        )
      case 2:
        return (
          <EditInventory
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            product={this.state.product}
          />
        )
      default:
      //do none
    };
  };
}

export default InventoryMobile;