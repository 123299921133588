/**
 * action = {
 *  type: "STRING",
 *  payload: "DATA"
 * }
 *
 */

import { ADD_TO_CART, UPDATE_CART_UNITS, EMPTY_CART } from "../action/cart_actions";

const INITIAL_STATE = [

];

export default function cartReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case ADD_TO_CART: {
            const product = action.payload;
            const cart = state;
            const existingProductIndex = findProductIndex(cart, product.POID);

            const updatedCart =
                existingProductIndex >= 0 ? updateProductUnits(cart, product) : [...cart, product];

            return updatedCart;
        }

        case UPDATE_CART_UNITS: {
            const payload = action.payload;
            const cart = state;

            const existingProductIndex = findProductIndex(cart, payload.POID);
            if (payload.units <= 0) {
                cart.splice(existingProductIndex, 1);
                return [...cart];
            }
            if (existingProductIndex >= 0) {
                let product = cart[existingProductIndex];
                product.units = payload.units;

                cart[existingProductIndex] = product;
            }

            return [...cart];
        }
        case EMPTY_CART: {
            const cart = [];
            return [...cart];
        }
        case "CLEAR_CART_DATA":
            return INITIAL_STATE;

        default:
            return state;
    }
}

const findProductIndex = (cart, POID) => {
    return cart.findIndex((p) => p.POID === POID);
};

const updateProductUnits = (cart, product) => {
    const productIndex = findProductIndex(cart, product.POID);

    const updatedCart = [...cart];
    const existingProduct = updatedCart[productIndex];

    const updatedUnitsProduct = {
        ...existingProduct,
        units: existingProduct.units + product.units,
    };

    updatedCart[productIndex] = updatedUnitsProduct;

    return updatedCart;
};
