export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART_UNITS = "UPDATE_CART_UNITS";
export const EMPTY_CART = "EMPTY_CART";
export function addToCartAction({
  POID,
  name,
  description,
  images,
  price,
  units,
  uOMPOID,
  costPrice,
  productSpecCharacteristics,
  taxRate
}) {
  return {
    type: ADD_TO_CART,
    payload: {
      POID, name, description, images, price, units, uOMPOID,
      costPrice, taxRate, productSpecCharacteristics
    },
  };
}

export function updateCartUnits({ POID, units }) {
  return {
    type: UPDATE_CART_UNITS,
    payload: { POID, units },
  };
}
export function emptyCart() {
  return {
    type: EMPTY_CART,
  };
}
