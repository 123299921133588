import React, { Component } from "react";
import { DataGrid } from "@material-ui/data-grid";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import GlobalConfig from "../Config";
import { connect } from "react-redux";




class InventoryHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      productId: this.props.location.state,
      InventoryHistory: [],
    };
  }

  componentDidMount() {
    fetch(
      this.props.global_config.basePath + `/inventory/history/${this.state.productId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        // convert the React state to JSON and send it as the POST body
      }
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          loading: false,
          InventoryHistory: data,
        })
      );
    // this.props.history.push({
    //   pathname: "/Products",
    // });
  }

  dates = (dates) => {
    // var dates="2021.06.30.10.17";
    const formatedDates = dates.split(".");
    let year = formatedDates[0];
    let month = formatedDates[1];
    let day = formatedDates[2];
    let hours = formatedDates[3];
    let minutes = formatedDates[4];
    let seconds = formatedDates[5];
    console.log("month" + formatedDates[1]);
    console.log("day" + formatedDates[2]);
    console.log("hours" + formatedDates[3]);
    console.log("hours" + formatedDates[4]);
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };
  render() {
    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 70,
      },
      {
        field: "event",
        headerName: "Event",
        width: 200,
        editable: true,
      },
      {
        field: "adjustedBy",
        headerName: "Adjusted by",
        width: 140,
        editable: true,
      },
      {
        field: "adjustment",
        headerName: "Adjustment",
        type: "number",
        width: 130,
        editable: true,
      },
      {
        field: "added",
        headerName: "Added",
        type: "number",
        width: 130,
      },
      {
        field: "removed",
        headerName: "Removed",
        type: "number",
        width: 130,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        type: "number",
        width: 130,
        editable: true,
      },
      {
        field: "date",
        headerName: "Date",
        width: 200,
        editable: true,
      },
    ];

    const rows = [];
    const obj = this.state.InventoryHistory;

    for (var i = 0; i < obj.length; i++) {
      rows[i] = {
        id: i + 1,
        event: obj[i].event,
        adjustedBy: obj[i].adjustedBy,
        adjustment: obj[i].adjustment,
        removed: obj[i].removed,
        added: obj[i].added,
        quantity: obj[i].quantity,
        date: this.dates(obj[i].date),
      };
    }

    // function dates(){
    //   return(

    //   )
    // }
    console.log(this.state.InventoryHistory);
    return (
      <>
        {this.state.loading ? (
          <>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          </>
        ) : (
          <>
            <div style={{ marginBottom: "30px" }}>
              <h5>
                <ArrowBackIosIcon
                  style={{ cursor: "pointer" }}
                  // onClick={this.onHandleBack}
                  onClick={() => this.props.history.push("/Products")}
                />
                Inventory History
              </h5>
            </div>
            <div style={{ width: "100%" }}>
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                pageSize={10}
                checkboxSelection={false}
                disableSelectionOnClick
              />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_config: state.globalState
  };
};

export default connect(mapStateToProps)(InventoryHistory);
