import React, { useState } from "react";
import PageTitle from "./PageTitle";
import GlobalConfig from "../../Config";
import { Grid, TextField, Button } from "@material-ui/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import DescriptionIcon from "@mui/icons-material/Description";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { connect, useSelector } from "react-redux";
import Store from "../../index";
import Loader from "../Loader";
import SnackBar from "../ProductMobileComponents/Snackbar";
import helpers from "../../UtilitiesFunctions";

const EditInventory = ({ product, prevStep, g_products }) => {
    const g_product = g_products.filter((prod) => prod.POID === product[0]);

    const global_config = useSelector((state) => state.globalState);

    const editInventory = (event) => {
        if (addColor === "green" && editColor === "black") {
            handleAddInventory();
        } else if (editColor === "green" && addColor === "black") {
            handleUpdateInventory();
        } else {
            setBtnDisable(true);
        }
    };
    const [quantity, setQuantity] = useState(product[3]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [addColor, setAddColor] = useState("black");
    const [editColor, setEditColor] = useState("black");
    const [btnDisable, setBtnDisable] = useState(true);
    const [loading, setLoading] = useState(false);

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("");

    const onAmountChange = (value, index) => {
        setQuantity(value);
    };
    const updateInventory = () => {
        setIsDisabled(false);
        setAddColor("black");
        setEditColor("green");
        setBtnDisable(false);
    };
    const addInventory = () => {
        setIsDisabled(false);
        setAddColor("green");
        setEditColor("black");
        setBtnDisable(false);
    };
    const updateQuantity = (product) => {
        Store.dispatch({ type: "EDIT_ACTIVE_PRODUCTS_QUANTITY", updated_product: product });
    };
    const handleUpdateInventory = () => {
        setLoading(true);
        const amount = Number(quantity);
        const prod = g_product[0].unitsOfMeasures.filter((prod) => prod.isDefault === true);
        const data = {
            ProductSpecification_Id: product[0],
            balance: amount,
            transactionType: "adjustment",
            unitOfMeasureId: prod[0].POID,
        };
        fetch(global_config.basePath + "/inventory/adjustment/" + product[0], {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
            },
            // convert the React state to JSON and send it as the POST body
            body: JSON.stringify(data),
        })
            .then((response) => {
                fetch(global_config.basePath + `/inventory/${product[0]}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer token",
                    },
                })
                    .then((response) => response.json())
                    .then((responseData) => {
                        updateQuantity(responseData);
                        Store.dispatch({
                            type: "EDIT_INVENTORY_ITEM",
                            inventoryItem: responseData[0],
                        });
                    })
                    .catch((err) => {
                        console.log("err");
                    });
                setLoading(false);
                setSnackbar(false);
                setSnackbarMessage("Inventory Updated");
                setSnackbarType("success");

                helpers.toastNotify("success", "Inventory Updated Successfully!");
            })
            .catch((err) => {
                setLoading(false);
                setSnackbar(false);
                setSnackbarMessage("Unable to Update Inventory");
                setSnackbarType("error");
                helpers.toastNotify("error", "Could Not Update Inventory.");
            });
    };

    const addQuantity = (product) => {
        Store.dispatch({ type: "EDIT_ACTIVE_PRODUCTS_QUANTITY", updated_product: product });
    };

    const handleAddInventory = () => {
        setLoading(true);
        const amount = Number(quantity);
        const prod = g_product[0].unitsOfMeasures.filter((prod) => prod.isDefault === true);

        const data = {
            transactionType: "ADD",
            added: amount,
            removed: 0,
            ProductSpecification_Id: product[0],
            unitOfMeasureId: prod[0].POID,
        };

        fetch(global_config.basePath + "/inventory/" + product[0], {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
            },
            // convert the React state to JSON and send it as the POST body
            body: JSON.stringify(data),
        }).then((response) => {
            fetch(global_config.basePath + `/inventory/${product[0]}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer token",
                },
            })
                .then((response) => response.json())
                .then((responseData) => {
                    addQuantity(responseData);
                    Store.dispatch({
                        type: "EDIT_INVENTORY_ITEM_QUANTITY",
                        inventoryItems: responseData[0],
                    });
                    setQuantity(responseData[0].quantity);
                    console.log(quantity);
                    setLoading(false);
                    setSnackbar(false);
                    setSnackbarMessage("Inventory Updated");
                    setSnackbarType("success");
                    helpers.toastNotify("success", "Inventory Successfully Updated!");
                })
                .catch((err) => {
                    helpers.toastNotify("error", "Error Occured!");
                });
        });
    };
    return (
        <>
            {loading ? (
                <Loader value={"Please Wait.."} />
            ) : (
                <>
                    <div>
                        <PageTitle
                            textType="h5"
                            value="Inventory"
                            Icon={<ArrowBackIosNewIcon />}
                            prevStep={prevStep}
                        />
                    </div>

                    <div className="label spacing">
                        <Grid container spacing={2} style={{ paddingTop: "50px" }}>
                            <Grid item xs={12} sm={6}>
                                <Grid3x3Icon />
                                <label style={{ fontSize: "15px" }}>Product ID:</label>
                                <TextField
                                    style={{ marginLeft: "54px", width: "100px" }}
                                    type="text"
                                    value={product[0]}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <ShoppingBagIcon />
                                <label style={{ fontSize: "15px" }}>Product Name:</label>

                                <TextField
                                    style={{ marginLeft: "27px", width: "100px" }}
                                    type="text"
                                    value={product[1]}
                                    multiline
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DescriptionIcon />
                                <label style={{ fontSize: "15px" }}>Description:</label>

                                <TextField
                                    style={{ marginLeft: "48px", width: "100px" }}
                                    type="text"
                                    value={product[2]}
                                    multiline
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ProductionQuantityLimitsIcon />
                                <label style={{ fontSize: "15px" }}>Quantity:</label>

                                <TextField
                                    style={{ marginLeft: "68px", width: "100px" }}
                                    onChange={(e) => onAmountChange(e.target.value)}
                                    type="number"
                                    defaultValue={quantity}
                                    disabled={isDisabled}
                                />
                                <EditIcon
                                    style={{ marginLeft: "50px", fill: editColor }}
                                    onClick={() => {
                                        updateInventory();
                                    }}
                                />
                                <AddBoxIcon
                                    style={{ marginLeft: "25px", fill: addColor }}
                                    onClick={() => addInventory()}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        onClick={() => editInventory()}
                                        // type="submit"
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginRight: "10px" }}
                                        disabled={btnDisable}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>

                    <div>
                        {snackbar ? (
                            <SnackBar message={snackbarMessage} type={snackbarType} />
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        g_products: state.productsState.products,
    };
};
export default connect(mapStateToProps)(EditInventory);
