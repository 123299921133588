import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cartReducer from "./cart_reducer";
import getCustomers from "./customers_reducers";
import productsReducer from "./product_reducer";
import getOrders from "./order_reducer";
import discountReducer from "./discount_reducer";
import inventoryReducer from "./inventory_reducer";
import analyticsReducer from "./analytics_reducer";
import usersReducer from "./users_reducer";
import globalReducer from "./global_reducer";
import companyInfoReducer from "./companyinfo_reducer";
import vendorsReducer from "./vendor_reducer";
import individualsReducer from "./individual_reducer";
import organizationsReducer from "./organizations_reducer";
import purchaseOrderReducer from "./purchaseorder_reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [""],
};
const appReducer = combineReducers({
  cart: cartReducer,
  customersState: getCustomers,
  productsState: productsReducer,
  ordersState: getOrders,
  discountState: discountReducer,
  inventoryState: inventoryReducer,
  analyticsState: analyticsReducer,
  usersState: usersReducer,
  globalState: globalReducer,
  companyInfoState: companyInfoReducer,
  vendorsState: vendorsReducer,
  individualsState: individualsReducer,
  organizationsState: organizationsReducer,
  purchaseOrderState: purchaseOrderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    const { globalState } = state;
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
