const initial_state = {
  vendors: [],
};

const vendorsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_VENDORS":
      console.log(action);
      return Object.assign({}, state, {
        vendors: action.vendors,
      });

    case "CLEAR_DATA":
      state = {
        vendors: [],
      };

    default:
      return state;
  }
};

export default vendorsReducer;
