import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      marginTop: "49px",
      margin: theme.spacing(2),
      width: theme.spacing(30),
      height: theme.spacing(38),
    },
  },
  table: {
    minWidth: 600,
    height: 300,
  },
  Avatar: {
    display: "flex",
    "& > *": {
      marginLeft: theme.spacing(9),
      marginTop: theme.spacing(2),
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },
});
class Profile extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: null,
    };
  }

  componentDidMount() {
    this.props.keycloak.loadUserInfo().then((UserInfo) => {
      this.setState({ userInfo: UserInfo, name: UserInfo.name });
    });
  }
  render() {
    const { classes, userInfo } = this.props;
    console.log("user info in profile is: ", userInfo);
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={classes.root}>
            <Paper elevation={3}>
              <Stack direction="column" spacing={2}>
                <div className={classes.Avatar}>
                  <Avatar />
                </div>
                <Typography align="center" variant="h6">
                  {userInfo.name ? userInfo.name.toUpperCase() : "-"}
                </Typography>
              </Stack>
            </Paper>
          </div>

          <table style={{ width: "100%", height: "300px" }}>
            <tr>
              <th>User Name:</th>
              <td>{userInfo.preferred_username}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{userInfo.email}</td>
            </tr>
          </table>
        </div>
      </>
    );
  }
}
Profile.defaultProps = {
  userInfo: {
    preferred_username: "guest",
    email: "sampleuser@hulmsolutions.com",
  },
};
export default withStyles(style)(Profile);
