import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Popup from "../Popup";
import CustomerContactInfoEditWithName from "./CustomerContactInfoEditWithName";
import CustomerAddressInfoModel from "./CustomerAddressInfoModel";
import { Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import DataUsageTwoToneIcon from "@material-ui/icons/DataUsageTwoTone";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Backdrop from "@material-ui/core/Backdrop";
import "date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GlobalConfig from "../../Config";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE, WHITE, MARS } from "../../colors/colors";
import Loader from "../../MobileComponents/Loader";
import helpers from "../../UtilitiesFunctions";
const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },

  formContainer: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  formItems: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
  },
  formList: {
    width: "85%",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formRightBox: {
    width: "40%",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewAddressModal: false,
      status: "",
      open: false,
      backdrop: false,
      OpenNewAddressModal: false,
      loading: true,
      loaderStatus: null,
      openAddressModal: false,
      name: "",
      email: "",
      phoneNumber: "",
      POID: "",
      phoneNumberContactId: "",
      phoneNumberId: "",
      emailContactId: "",
      emailId: "",
      address: "",
      addressContactId: "",
      addressId: "",
      city: "",
      country: "",
      postCode: "",
      stateOrProvince: "",
      street: "",
      createdAt: "",
      preferred: "",
      customer: null,
      newCity: "",
      newCountry: "",
      newPostCode: "",
      newStateOrProvince: "",
      newStreet: "",
      snackbar: false,
      payableamount: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });

    let myCustomer = this.getData();

    console.log("myCustomer", myCustomer);
    if (myCustomer.length > 0) {
      this.setState({
        loading: false,
        name: myCustomer[0]?.name,
        email: myCustomer[0]?.email,
        phoneNumber: myCustomer[0]?.phoneNumber,
        POID: myCustomer[0]?.POID,
        phoneNumberContactId: myCustomer[0]?.phoneNumberContactId,
        phoneNumberId: myCustomer[0]?.phoneNumberId,
        emailContactId: myCustomer[0]?.emailContactId,
        emailId: myCustomer[0]?.emailId,
        address: myCustomer[0]?.address,
        addressContactId: myCustomer[0]?.address[0]?.addressContactId,
        addressId: myCustomer[0]?.address[0]?.addressId,
        city: myCustomer[0]?.address[0]?.city,
        country: myCustomer[0]?.address[0]?.country,
        postCode: myCustomer[0]?.address[0]?.postCode,
        stateOrProvince: myCustomer[0]?.address[0]?.stateOrProvince,
        street: myCustomer[0]?.address[0]?.street,
        createdAt: myCustomer[0]?.createdAt,
        preferred: myCustomer[0]?.address[0]?.preferred,

        customer: myCustomer[0],
      });
    } else {
      fetch(
        `${this.props.global_config.basePath}/customers/${
          this.props.history.location.pathname.split("/")[2]
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        }
      )
        .then((response2) => response2.json())
        .then(async (data2) => {
          myCustomer = await data2;
          await this.setState({
            loading: false,
            name: data2?.name,
            email: data2?.email,
            phoneNumber: data2?.phoneNumber,
            POID: data2?.POID,
            phoneNumberContactId: data2?.phoneNumberContactId,
            phoneNumberId: data2?.phoneNumberId,
            emailContactId: data2?.emailContactId,
            emailId: data2?.emailId,
            address: data2?.address,
            addressContactId: data2?.address[0]?.addressContactId,
            addressId: data2?.address[0]?.addressId,
            city: data2?.address[0]?.city,
            country: data2?.address[0]?.country,
            postCode: data2?.address[0]?.postCode,
            stateOrProvince: data2?.address[0]?.stateOrProvince,
            street: data2?.address[0]?.street,
            createdAt: data2?.createdAt,
            preferred: data2?.address[0]?.preferred,

            customer: data2,
          });
          this.setState({ loading: false });
        })
        .catch((e) => {
          console.log(e);
          // this.props.history.push("/customers")
        });
    }
  }

  componentWillUnmount() {
    this.setState({ backdrop: false });
  }
  getData = () => {
    const uri = window.location.href.split("editcustomer/");
    console.log(" this.props.g_active", this.props.g_active);

    const activecustomer = this.props.g_active.filter(
      (cust) => cust.POID === uri[1]
    );

    if (activecustomer.length === 0) {
      const archivedcustomer = this.props.g_archived_customers.filter(
        (cust) => cust.POID == uri[1]
      );
      return archivedcustomer;
    } else {
      return activecustomer;
    }
  };
  addToCart = (product) => {
    console.log("order added");
    this.props.addToCartAction(product);
  };
  handleChange = (event) => {
    console.log(this.state.startDate);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleDateChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    this.setState({ startDate: !this.state.startDate });
    console.log(this.state.startDate);
  };
  handleSubmit = (props) => {
    this.props.history.push("/");
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenAddressModal = () => {
    this.setState({ openAddressModal: true });
  };
  handleNewOpenAddressModal = () => {
    this.setState({ openNewAddressModal: true });
  };
  handleCloseAddressModal = () => {
    this.setState({ openAddressModal: false });
  };
  handleCloseNewAddressModal = () => {
    this.setState({ openNewAddressModal: false });
  };
  updateContactInfo = (data) => {
    console.log("data from contact modal...");
    this.setState(
      {
        backdrop: false,
        email: data.email,
        phoneNumber: data.phone_number,
        open: false,
        name: data.name,
        snackbar: false,
      },
      function () {
        this.callBackss();
      }
    );
  };
  callBackss = () => {
    const updateData = {
      //status: "Active",
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      phoneNumberContactId: this.state.phoneNumberContactId,
      phoneNumberId: this.state.phoneNumberId,
      emailContactId: this.state.emailContactId,
      emailId: this.state.emailId,
      name: this.state.name,
      querry: "name",
    };
    let url = `${this.props.global_config.basePath}/customers/${this.state.POID}`;
    this.setState({ loading: true });
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(updateData),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          `${this.props.global_config.basePath}/customers/${this.state.POID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then(async (data2) => {
            await this.setState({
              loading: false,
              name: data2.name,
              email: data2.email,
              phoneNumber: data2.phoneNumber,
              POID: data2.POID,
              phoneNumberContactId: data2.phoneNumberContactId,
              phoneNumberId: data2.phoneNumberId,
              emailContactId: data2.emailContactId,
              emailId: data2.emailId,
              address: data2.address,
              addressContactId: data2.address[0].addressContactId,
              addressId: data2.address[0].addressId,
              city: data2.address[0].city,
              country: data2.address[0].country,
              postCode: data2.address[0].postCode,
              stateOrProvince: data2.address[0].stateOrProvince,
              street: data2.street,
              createdAt: data2.address[0].createdAt,
              preferred: data2.address[0].preferred,

              customer: data2,
            });
            this.setState({ loading: false });
            this.setState({ backdrop: false });
          });
      })
      .catch((err) => {
        window.alert(err);
        this.setState({ backdrop: false });
      });
  };
  addNewAddress = (data) => {
    console.log("address");
    console.log(data);
    this.setState(
      {
        openNewAddressModal: false,
        backdrop: true,
        newCity: data.city,
        newCountry: data.country.label,
        newPostCode: data.postCode,
        newStateOrProvince: data.stateOrProvince,
        newStreet: data.street,
        preferred: true,
      },
      function () {
        this.newAddressCallBack();
      }
    );
  };
  newAddressCallBack = () => {
    const updateAddres = {
      name: this.state.name,
      address: [
        {
          city: this.state.newCity,
          country: this.state.newCountry,
          postCode: this.state.newPostCode,
          stateOrProvince: this.state.newStateOrProvince,
          street: this.state.newStreet,
          preferred: this.state.preferred,
        },
      ],
    };
    console.log("updated Data" + JSON.stringify(updateAddres));
    let url =
      this.props.global_config.basePath + `/customers/${this.state.POID}`;

    this.setState({ loading: true });
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(updateAddres),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          this.props.global_config.basePath + `/customers/${this.state.POID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_ACTIVE_CUSTOMER",
              updated_customer: data2,
            });
            this.setState({
              city: this.state.newCity,
              country: this.state.newCountry,
              postCode: this.state.newPostCode,
              stateOrProvince: this.state.newStateOrProvince,
              street: this.state.newStreet,
              preferred: this.state.preferred,
            });
            this.setState({ loading: false });
            this.setState({ backdrop: false });
          })
          .catch((e) => {
            helpers.toastNotify(
              "error",
              "Something Went Wrong Fetching Customer Data."
            );
          });
      })
      .catch((err) => {
        // window.alert(err);
        this.setState({ backdrop: false });
        helpers.toastNotify(
          "error",
          "Something Went Wrong Updating Customer Data."
        );
      });
  };
  updateAddressInfo = (data) => {
    console.log("address");
    console.log(data);
    this.setState(
      {
        backdrop: true,
        city: data.city,
        country: data.country.label,
        postCode: data.postCode,
        stateOrProvince: data.stateOrProvince,
        street: data.street,
        openAddressModal: false,
        preferred: true,
      },
      function () {
        this.callBackAddress();
      }
    );
  };
  callBackAddress = () => {
    const updateAddres = {
      name: this.state.name,
      address: [
        {
          addressContactId: this.state.addressContactId,
          addressId: this.state.addressId,
          city: this.state.city,
          country: this.state.country,
          postCode: this.state.postCode,
          stateOrProvince: this.state.stateOrProvince,
          street: this.state.street,
        },
      ],
    };
    this.setState({ loading: true });
    this.setState({ backdrop: false });
    let url = `${this.props.global_config.basePath}/customers/${this.state.POID}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(updateAddres),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          `${this.props.global_config.basePath}/customers/${this.state.POID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then((data2) => {
            // eslint-disable-next-line no-unused-expressions
            this.setState({
              customer: data2,
            });
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        window.alert(err);
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Could Not Fetch Customer Data.");
      });
  };

  fullFilled = () => {
    if (this.state.customer.recentOrder.fulfilled) return "fulfilled";
    else if (this.state.customer.partiallyFulfilled)
      return "partially fulfilled";
    else return "unfulfilled";
  };
  paid = () => {
    if (this.state.customer.recentOrder.paid) return "paid";
    else return "unpaid";
  };
  draft = () => {
    if (this.state.customer.recentOrder.draft) return "draft";
    else return null;
  };

  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  handleDelete = () => {
    this.setState({ backdrop: true });
    let url = `${this.props.global_config.basePath}/customers/${this.state.POID}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      console.log("order response...");
      console.log(response);

      Store.dispatch({
        type: "DELETE_ACTIVE_CUSTOMERS",
        POID: this.state.POID,
      });
      this.props.history.push({
        pathname: "/Customers",
      });
    });
  };
  setCustomerObjectForActivation = () => {
    const activateCustomer = {
      status: "Active",
    };
    return activateCustomer;
  };
  updateCustomerStatus = () => {
    let updatedCustomer = { ...this.state.customer, status: "Active" };
    this.setState({ customer: updatedCustomer });
    return updatedCustomer;
  };
  handleCustomerActiviton = () => {
    const { customer } = this.state;
    this.setState({ backdrop: false });
    this.setState({ loading: true });
    let url = `${this.props.global_config.basePath}/customers/${this.state.POID}`;
    const activateCustomer = this.setCustomerObjectForActivation();
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(activateCustomer),
    }).then((response) => {
      console.log("Customer response...");
      console.log(response);
      if (response.status === 200) {
        // alert("Customer successfully activated");
        helpers.toastNotify("success", "Customer Successfully Activated!");
        //setting customer state as active
        const updatedCustomer = this.updateCustomerStatus();

        this.setState({ loading: false });
        this.props.history.push({
          pathname: "/Customers",
        });
      } else {
        helpers.toastNotify("error", "Could Not Activate Customer.");
        // alert("Error! something went wrong! unable to activate customer");
      }
    });
  };
  handleCustomerDeletionAndActivation = () => {
    const { customer } = this.state;
    if (customer) {
      if (customer.status.toLowerCase() === "active") {
        this.handleDelete();
      } else if (customer.status.toLowerCase() === "archived") {
        this.handleCustomerActiviton();
      }
    } else {
      alert("Error! Something went wrong! Contact your Administrator");
    }
  };
  render() {
    const { classes } = this.props;
    const { customer } = this.state;
    console.log("Customer is: ", customer);
    console.log("edit customer props", this.props);
    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>

        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="success"
                variant="filled"
                color="success"
                onClose={this.closeSnack}
              >
                Address info updated Successfully
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        {this.state.loading ? (
          <Loader
            value={
              this.state.loaderStatus != null
                ? this.status.loaderStatus
                : "Loading ..."
            }
          />
        ) : (
          <div>
            <h5>
              <ArrowBackIosIcon
                style={{ cursor: "pointer" }}
                onClick={() => this.props.history.push("/Customers")}
              />
              Customer
            </h5>
            <form>
              <div className={classes.formContainer}>
                <div className={classes.formList}>
                  <div className={classes.formItems}>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      {this.state.customer.name}
                    </div>

                    <label>{this.state?.customer?.address[0]?.city}</label>
                    <p>
                      Customer since{" "}
                      {helpers.convertTime(this.state?.customer?.creationTime)}
                    </p>

                    <hr></hr>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p>No: of Orders</p>

                        <p style={{ textAlign: "center" }}>
                          <b>{this.state.customer?.numberOfOrders + " "}</b>
                        </p>
                      </div>
                      <div>
                        <p>Total Spent to date</p>
                        <p style={{ textAlign: "center" }}>
                          <b>{this.state.customer?.totalSpendOnOrder}</b>
                        </p>
                      </div>
                      <div>
                        <p> Average Order Value</p>
                        <p style={{ textAlign: "center" }}>
                          <b>{this.state.customer?.averageOrderValue}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.state.customer?.recentOrder === undefined ? (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "0.8rem",
                        padding: "25px",
                        marginBottom: "20px",
                        boxShadow:
                          "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                        outline: ".1rem solid transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "1rem",
                          }}
                        >
                          Last Order
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={"../assets/addcustomer.svg"}
                          alt="addCutomer"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "0% 0% 3% 0%",
                        }}
                      >
                        {this.state.customer?.numberOfOrders ? (
                          <h4>
                            This customer has placed{" "}
                            {this.state.customer?.numberOfOrders} orders.
                          </h4>
                        ) : (
                          <h4>This customer hasn’t placed any orders.</h4>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {!this.state.customer?.numberOfOrders ? (
                          <Button
                            onClick={() =>
                              this.props.history.push({
                                pathname: "/Orders",
                                state: customer,
                              })
                            }
                            style={{ backgroundColor: MARS, color: WHITE }}
                            varian="contained"
                            size="large"
                          >
                            Create Order
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              this.props.history.push({
                                pathname: "/Orders",
                                state: this.state.customer,
                              })
                            }
                            style={{ backgroundColor: MARS, color: WHITE }}
                            varian="contained"
                            size="large"
                          >
                            View Orders
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "0.8rem",
                        padding: "25px",
                        marginBottom: "20px",
                        boxShadow:
                          "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                        outline: ".1rem solid transparent",
                      }}
                    >
                      <div>Last Order</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              marginRight: "10px",
                              // color: "blue",
                            }}
                          >
                            <b>
                              order #
                              {" " + this.state.customer.recentOrder.orderId}
                            </b>
                          </div>
                          <div>
                            <Chip
                              avatar={
                                <Avatar>
                                  <DataUsageTwoToneIcon size="small" />
                                </Avatar>
                              }
                              label={this.fullFilled()}
                              size="small"
                              clickable
                            />
                          </div>
                          <div>
                            <Chip
                              avatar={
                                <Avatar>
                                  <DataUsageTwoToneIcon size="small" />
                                </Avatar>
                              }
                              label={this.paid()}
                              size="small"
                              clickable
                            />
                          </div>
                          {this.state.customer.recentOrder !== undefined &&
                          this.state.customer.recentOrder.draft ? (
                            <div>
                              <Chip
                                avatar={
                                  <Avatar>
                                    <DataUsageTwoToneIcon size="small" />
                                  </Avatar>
                                }
                                label="Draft"
                                size="small"
                                clickable
                              />
                            </div>
                          ) : null}
                        </div>
                        <div>
                          {helpers.convertTime(
                            this.state.customer.recentOrder.createdAt
                          )}
                        </div>
                      </div>
                      <div>
                        <p>
                          Rs.{" "}
                          {this.state.customer.recentOrder.payableAmount +
                            "  (" +
                            this.state.customer.recentOrder.lineItems.length +
                            (this.state.customer.recentOrder.lineItems.length >
                            1
                              ? " Items)"
                              : " Item)")}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              border: "1px solid black",
                            }}
                            src="https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                            alt=""
                          />
                        </div>

                        <div style={{ margin: "46px 0 0  35px" }}>
                          {
                            this.state.customer.recentOrder.lineItems[0]
                              .productName
                          }
                        </div>
                      </div>
                      <hr></hr>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ marginRight: "30px" }}>
                          <Button
                            style={{
                              backgroundColor: "rgb(16,174,72)",
                            }}
                            varian="outlined"
                            size="large"
                            onClick={() => {
                              this.props.history.push({
                                pathname: "/Orders",
                                state: this.state.customer,
                              });
                            }}
                          >
                            All orders
                          </Button>
                        </div>
                        <div>
                          <Button
                            style={{
                              backgroundColor: "rgb(16,174,72)",
                            }}
                            varian="outlined"
                            size="large"
                            onClick={() => {
                              this.props.history.push({
                                pathname: "/CreateOrders",
                                // state: this.state.customer,
                              });
                            }}
                          >
                            Create Order
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={classes.formRightBox}>
                  <div className={classes.formItems}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          //   margin: "10px 0 0 15px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Customer Overview
                      </p>
                      <Box p={1}>
                        <Popup
                          title="Edit contact information"
                          btnText="Edit"
                          btnFloat="right"
                          marginTop="-15px"
                          btnColor="lightblue"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <CustomerContactInfoEditWithName
                              updateContactInfo={this.updateContactInfo}
                              name={this.state.customer.name}
                              phoneNumber={this.state.customer.phoneNumber}
                              email={this.state.customer.email}
                              onClickBtnClose={() => this.handleClose()}
                            />
                          } //component to send
                          openPopup={this.state.open} //open
                          on
                          onClickBtnOpen={() => this.handleOpen()} //on click open
                          onClickBtnClose={() => this.handleClose()} //on click close
                        />
                      </Box>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link>{this.state.email}</Link>
                      {/* <div>
                        <FileCopyIcon />
                      </div> */}
                    </div>
                    <p>{this.state.phoneNumber}</p>
                    <hr></hr>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold" }}>DEFAULT ADDRESS</p>
                      <Box p={1}>
                        <Popup
                          title="Edit shipping address"
                          btnText="Manage"
                          btnFloat="right"
                          marginTop="-15px"
                          btnColor="lightblue"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <CustomerAddressInfoModel
                              updateAddressInfo={this.updateAddressInfo}
                              city={this.state.customer?.address[0]?.city}
                              stateOrProvince={
                                this.state.customer?.address[0]?.stateOrProvince
                              }
                              country={
                                this.state?.customer?.address[0]?.country
                              }
                              postCode={
                                this.state?.customer?.address[0]?.postCode
                              }
                              street={this.state?.customer?.address[0]?.street}
                              onClickBtnClose={() =>
                                this.handleCloseAddressModal()
                              }
                            />
                          } //component to send
                          openPopup={this.state.openAddressModal} //open
                          onClickBtnOpen={() => this.handleOpenAddressModal()} //on click open
                          onClickBtnClose={() => this.handleCloseAddressModal()} //on click close
                        />
                      </Box>
                    </div>
                    {this.state?.customer?.address?.length > 0 && (
                      <div style={{ margin: "0px", padding: "0px" }}>
                        <span>{this.state?.name}</span>
                        <br />
                        <span>{this?.state?.street}</span>
                        <br />
                        <span>
                          {this?.state?.postCode + " " + this?.state?.city}
                        </span>
                        <br />
                        <span>
                          {this?.state?.stateOrProvince +
                            ", " +
                            this?.state?.country}
                        </span>
                      </div>
                    )}
                    {this.state.customer.address.length <= 0 && (
                      <div style={{ marginBottom: "10px", padding: "5px" }}>
                        <center>
                          <span>❌ No Address Provided!</span>
                        </center>
                      </div>
                    )}
                    <div>
                      <Box p={1}>
                        <Popup
                          title="Add New Address"
                          btnText="Add new Address"
                          btnFloat="right"
                          marginTop="-10px"
                          btnColor="green"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <CustomerAddressInfoModel
                              updateAddressInfo={this.addNewAddress}
                              city=""
                              stateOrProvince=""
                              country=""
                              postCode=""
                              street=""
                              onClickBtnClose={() =>
                                this.handleCloseNewAddressModal()
                              }
                            />
                          }
                          openPopup={this.state.openNewAddressModal}
                          onClickBtnOpen={() =>
                            this.handleNewOpenAddressModal()
                          }
                          onClickBtnClose={() =>
                            this.handleCloseNewAddressModal()
                          }
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="button"
                variant="contained"
                style={{ backgroundColor: MARS, color: WHITE }}
                size="large"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                {customer && customer.status === "Active"
                  ? "Delete Customer"
                  : "Set as Active Customer"}
              </Button>

              <div
                class="modal fade "
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-sm">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {customer.status.toLowerCase() == "active"
                          ? " Are you sure you want to delete the customer?"
                          : "Are You Sure ?"}
                      </h5>
                    </div>

                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        NO
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        data-bs-dismiss="modal"
                        onClick={this.handleCustomerDeletionAndActivation}
                      >
                        YES
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    g_active: state.customersState.active_customers,
    g_archived_customers: state.customersState.archived_customers,
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(style)(EditCustomer));
