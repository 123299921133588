import React, { Component } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export default class CustomerContactInfoEditWithName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      email: this.props.email,
      phone_number: this.props.phoneNumber,
      name: this.props.name,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.updateContactInfo(this.state);
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };
  render() {
    return (
      <div onSubmit={this.handleSubmit}>
        <Box display="flex" flexDirection="row" bgcolor="white">
          <Box>
            <Box>Name</Box>
            <OutlinedInput
              style={{ width: "70%", height: "50%", marginTop: "10px" }}
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </Box>
          <Box>
            <Box>Email</Box>
            <OutlinedInput
              style={{ width: "150%", height: "50%", marginTop: "10px" }}
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Box>Phone Number</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40, marginTop: "10px" }}
              name="phone_number"
            
              value={this.state.phone_number}
              onChange={this.handleChange}
            />
          </Box>
          {<hr />}
          <Button
            style={{
              textTransform: "none",
              float: "right",
            }}
            onClick={this.handleSubmit}
            variant="contained"
            color="dark"
          >
            Save
          </Button>
          <Button
            style={{
              textTransform: "none",
              marginRight: "10px",
              float: "right",
            }}
            onClick={this.props.onClickBtnClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </div>
    );
  }
}
