import React, { Component } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

class ProductDetails extends Component {
  render() {
    const html = this.props.location.state.description;
    const div = document.createElement(`div-`);
    div.innerHTML = html;
    const text = div.textContent || "";
    console.log("Product Details Props", this.props);
    return (
      <>
        <h5>
          <ArrowBackIosIcon
            fontSize="large"
            onClick={() => this.props.history.push("/POS")}
            style={{ cursor: "pointer" }}
          />
          Product
        </h5>
        <div>
          <span>Name :</span>

          <h4>{this.props.location.state.name}</h4>
          <h4>{this.props.location.state.price}</h4>
          <h4>{this.props.location.state.costPrice}</h4>

          <h6>{text}</h6>
          <div>
            <img
              style={{ width: "100px", height: "100px" }}
              src={
                this.props.location.state.images[0].imageURL &&
                this.props.location.state.images[0].imageURL
              }
              alt=""
            />
          </div>
        </div>
      </>
    );
  }
}

export default ProductDetails;
