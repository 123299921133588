import { Button, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { green, red } from "@material-ui/core/colors";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import "./index.css";
const CartItem = ({
  POID,
  name,
  price,
  units,
  imageurl,
  images,
  updateCartUnits,
  productSpecCharacteristics,
  cart,
  taxRate,
}) => {
  const dummyImage =
    "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081";
  let imageUrl;
  console.log(images);
  if (images.length === 0) {
    imageUrl = dummyImage;
  } else imageUrl = images[0].imageURL;

  const handleOnChange = (e) => {
    let quantity;
    if (e.target.value !== "") {
      quantity = Number(e.target.value);
      updateCartUnits({ POID, units: quantity });

      setQuantity(quantity);
    } else if (e.target.value === "") {
      setQuantity(e.target.value);
    }
  };
  const [quantity, setQuantity] = useState(units);
  const inputEl = useRef(null);

  console.log(productSpecCharacteristics);

  useEffect(() => {
    setQuantity(units);
  }, []);
  return (
    <>
      {units > 0 ? (
        <>
          <div
            className=""
            style={{
              width: "100%",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="products"
              style={{
                backgroundColor: "#ededed",
                borderRadius: "20px",
                width: "100%",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  // marginRight: "auto",
                  // marginLeft: "auto",
                  backgroundColor: "lightgray",
                  width: "100%",
                  padding: "5px",
                }}
              >
                <b style={{ marginLeft: "10px" }}>{name}</b>
                <CancelIcon
                  onClick={() => updateCartUnits({ POID, units: 0 })}
                  style={{
                    color: red[400],
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                className="product-bar"
                style={{
                  backgroundColor: "#ededed",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    alt="cartimage"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "5px",
                      marginRight: "2%",
                    }}
                    src={imageUrl}
                  />
                  <div>
                    Quantity: <b>{units} </b>
                    <div>
                      Unit Price: <b>{price}</b>
                      <br />
                      Tax Rate: <b>{taxRate}%</b>
                      {productSpecCharacteristics?.length > 0 && (
                        <>
                          {productSpecCharacteristics?.map(
                            (productspec, index) => {
                              return (
                                <div>
                                  {productspec?.name + ": "}
                                  {productspec?.productSpecCharValues?.map(
                                    (productSpecCharValues, index) => {
                                      console.log(
                                        "here is a product specificationvalues",
                                        productSpecCharValues
                                      );
                                      return (
                                        <b>{productSpecCharValues?.value}</b>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      Total Amount: <b>{(units * price).toFixed(2)}</b>
                      <br />
                      Total Tax:{" "}
                      <b>{((price / 100) * taxRate * units).toFixed(2)}</b>
                      <br />
                      Gross Amount:{" "}
                      <b>
                        {(
                          units * price +
                          parseFloat(
                            ((price / 100) * taxRate * units).toFixed(2)
                          )
                        ).toFixed(2)}
                      </b>
                    </div>
                    <div style={{}}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        <TextField
                          ref={inputEl}
                          size="small"
                          variant="outlined"
                          value={quantity}
                          onChange={(e) => handleOnChange(e)}
                          style={{
                            width: "80px",
                            padding: 0,
                            marginRight: "5px",
                          }}
                          type="number"
                        />

                        <Button
                          variant="contained"
                          onClick={() => {
                            updateCartUnits({ POID, units: units + 1 });
                            setQuantity(units + 1);
                          }}
                          style={{ marginRight: "10px" }}
                        >
                          <AddCircleIcon style={{ color: green[400] }} />
                        </Button>
                        <Button
                          variant="contained"
                          disabled={units <= 0}
                          // size="small"

                          onClick={() => {
                            updateCartUnits({ POID, units: units - 1 });
                            setQuantity(units - 1);
                          }}
                        >
                          <RemoveCircleIcon style={{ color: red[400] }} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default CartItem;
