import React, { Component } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Country, State, City } from "country-state-city";
import { Link } from "react-router-dom";
import GlobalConfig from "../../Config";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCustomer from "../../MobileComponents/Customers/AddCustomer";
import Store from "../../index";
import { SKY_BLUE } from "../../colors/colors";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import helpers from "../../UtilitiesFunctions";

const style = (theme) => ({
  spinner: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "60px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        heigh: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  customerOverview: {
    marginTop: "70px",
    marginLeft: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      margin: "0px",
    },
  },
  contentHeading: {
    margin: theme.spacing(1),
  },
  Customerform: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  responsiveForm: {
    display: "flex",
    flexDirection: "column",
    width: "65%",
    justifyContent: "space-evenly",
    backgroundColor: "white",
    height: "50vh",
    borderRadius: "10px",
    margin: "10px",
    padding: "10px",
    boxShadow:
      "1px 1px 1px 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textDanger: {
    color: "red",
  },
});

class AddCustomerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phoneNumber: "",
      email: "",
      status: "Active",
      preferred: true,
      city: "Karachi",
      country: "PK",
      countryName: "Pakistan",
      postCode: "",
      stateOrProvince: "SD",
      stateOrProvinceName: "Sindh",
      street: "",
      open: false,
      snackbar: false,
      backdrop: false,
      errorSnackbar: false,
      success: false,
      openMobile: false,
      customer: [],
      countries: [],
    };
    this.validator = new SimpleReactValidator();
  }
  //component did mount
  componentDidMount() {
    this.setState({ countries: Country.getAllCountries() });
    // console.log(City.getCitiesOfState("PK", "SD"))

    const selectBox = document.getElementById("city");
    selectBox.options[selectBox.selectedIndex].innerHTML = "Karachi";
  }
  selectCountry = () => {
    const selectBox = document.getElementById("demo-simple-select");
    const selectedValue = selectBox.options[selectBox.selectedIndex].value;
    this.setState({
      country: selectedValue,
      countryName: selectBox.options[selectBox.selectedIndex].innerHTML,
    });
    console.log(selectedValue);
  };

  selectRegion = () => {
    const selectBox = document.getElementById("stateOrProvince");
    const selectedValue = selectBox.options[selectBox.selectedIndex].value;
    this.setState({
      stateOrProvince: selectedValue,
      stateOrProvinceName: selectBox.options[selectBox.selectedIndex].innerHTML,
    });
    console.log(selectedValue);
  };

  selectCity = () => {
    const selectBox = document.getElementById("city");
    const selectedValue = selectBox.options[selectBox.selectedIndex].innerHTML;
    this.setState({
      city: selectedValue,
    });
  };

  onHandleBack = (props) => {
    this.props.history.push("/Customers");
  };
  handleChange = (event) => {
    this.state.setChecked(event.target.checked);
  };
  handleSubmit = (event) => {
    if (this.validator.allValid()) {
      if (
        this.state.name.length <= 0 ||
        this.state.email.length <= 0 ||
        this.state.phoneNumber.length <= 0 ||
        this.state.city.length <= 0 ||
        this.state.countryName.length <= 0 ||
        this.state.stateOrProvinceName.length <= 0 ||
        this.state.street.length <= 0 ||
        this.state.postCode.length <= 0
      ) {
        this.setState({ errorSnackbar: false });

        helpers.toastNotify("error", "Empty Field Detected!");
      } else {
        this.setState({ backdrop: true });

        event.preventDefault();
        

       
        const data = {
          name: this.state.name,
          address: [
            {
              city: this.state.city,
              country: this.state.countryName,
              preferred: this.state.preferred,
              postCode: this.state.postCode,
              stateOrProvince: this.state.stateOrProvinceName,
              street: this.state.street,
            },
          ],
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
          status: this.state.status,
        };
        this.setState({ redirectToReferrer: true });

        console.log(JSON.stringify(data));
        let oldurl = `${this.props.global_config.basePath}/customers`;

        fetch(this.props.global_config.basePath + "/customers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
          // convert the React state to JSON and send it as the POST body
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((resp) => {
            fetch(
              this.props.global_config.basePath + "/customers/" + resp.POID,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer my-token",
                },
              }
            )
              .then((response2) => response2.json())
              .then((data2) => {
                Store.dispatch({
                  type: "POST_ACTIVE_CUSTOMERS",
                  active_customers: data2,
                });
                this.setState(
                  {
                    name: "",
                    phoneNumber: "",
                    email: "",
                    city: "",
                    country: "",
                    postCode: "",
                    stateOrProvince: "",
                    street: "",
                    backdrop: false,
                    snackbar: false,
                    customer: data2,
                    success: true,
                  },

                  this.props.setCurrentCustomer(data2, "customer")
                );

                helpers.toastNotify(
                  "success",
                  "Customer Successfully Created!"
                );
              });
          })
          .catch((e) => console.log(e));
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (event) => {
    // console.log("in handle change");
    this.setState({ [event.target.name]: event.target.value });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false, errorSnackbar: false });
  };
  handleClick = () => {
    this.setState({ openMobile: !this.state.openMobile });
  };
  render() {
    const { classes, history } = this.props;
    const { country, countries, stateOrProvince, city } = this.state;
    const state = State.getStatesOfCountry(country);
    const myCity = City.getCitiesOfState(country, stateOrProvince);

   
      return (
        <form>
          {/* <hr></hr> */}
          <Backdrop
            style={{
              zIndex: 1,
              color: "#fff",
            }}
            open={this.state.backdrop}
          >
            <CircularProgress thickness={2} color="inherit" />
          </Backdrop>
          {this.state.snackbar ? (
            <>
              <Snackbar
                open={this.state.snackbar}
                autoHideDuration={6000}
                onClose={this.closeSnack}
              >
                <MuiAlert
                  color="success"
                  severity="success"
                  variant="filled"
                  onClose={this.closeSnack}
                >
                  Customer Created
                  <div>
                    <Link to="/Customers" style={{ color: "white" }}>
                      Go to Customer List
                    </Link>
                  </div>
                </MuiAlert>
              </Snackbar>
            </>
          ) : null}
          {this.state.errorSnackbar ? (
            <>
              <Snackbar
                open={this.state.errorSnackbar}
                autoHideDuration={6000}
                onClose={this.closeSnack}
              >
                <MuiAlert
                  severity="error"
                  color="error"
                  variant="filled"
                  onClose={this.closeSnack}
                >
                  Enter Valid Details
                </MuiAlert>
              </Snackbar>
            </>
          ) : null}
          <div className={classes.Customerform}>
            <div>
              <h6 className={classes.customerOverview}>Customer Overview</h6>
            </div>
            <div className={classes.responsiveForm} style={{}}>
              <div>
                <TextField
                  required={true}
                  name="name"
                  label="Name"
                  size="small"
                  
                  // placeholder="Name"
                  variant="outlined"
                  fullWidth={true}
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </div>
              {this.validator.message("name", this.state.name, "required", {
                className: classes.textDanger,
              })}
              <TextField
                required={true}
                fullWidth={true}
                label="Email"
                // placeholder="email"
                size="small"
                variant="outlined"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              {this.validator.message(
                "email",
                this.state.email,
                "required|email",
                {
                  className: classes.textDanger,
                }
              )}

              <div>
                <TextField
                  required={true}
                  fullWidth={true}
                    label="Phone Number"
                  // placeholder="Phone Number"
                  size="small"
                  variant="outlined"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                />
              </div>
              {this.validator.message(
                "Phone Number",
                this.state.phoneNumber,
                "required|phone",
                { className: classes.textDanger }
              )}

              <div>
                <Checkbox
                  checked={this.state.checked}
                  onChange={this.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <span>
                  <p style={{ display: "inline", marginTop: "5px" }}>
                    Customer agreed to receive marketing emails.
                  </p>
                </span>
              </div>
            </div>
          </div>

          {/* <hr></hr> */}
          <div className={classes.Customerform}>
            <h6 className={classes.customerOverview}>Address </h6>
            <div
              className={classes.responsiveForm}
              style={{
                paddingTop: "10px",
                paddingBottom: "50px",
                height: "300px",
              }}
            >
              <div
                style={{
                  // width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "50px",
                }}
              >
                <TextField
                  required={true}
                  name="street"
                  label="Street"
                  // placeholder="Street"
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  value={this.state.street}
                  onChange={this.handleChange}
                />
              </div>
              {this.validator.message("street", this.state.street, "required", {
                className: classes.textDanger,
              })}

              <div
                style={{
                
                  paddingTop: "10px",
                }}
              >
                <select
                  style={{
                    marginBottom: "10px",
                    height: "50px",
                    width: "100%",
                    borderColor: "lightgrey",
                    borderRadius: "5px",
                  }}
                  defaultValue=""
                  // labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={country}
                  label="Country"
                  onChange={this.selectCountry}
                >
                  {countries.map((option) => (
                    <option value={option.isoCode}>{option.name}</option>
                  ))}
                </select>

                <select
                  style={{
                    marginBottom: "10px",
                    height: "50px",
                    width: "100%",
                    borderColor: "lightgrey",
                    borderRadius: "5px",
                  }}
                  defaultValue=""
                  // labelId="stateOrProvince"
                  id="stateOrProvince"
                  value={stateOrProvince}
                  label="State"
                  onChange={this.selectRegion}
                >
                  {state.map((option) => (
                    <option value={option.isoCode}>{option.name}</option>
                  ))}
                </select>

                <select
                  style={{
                    marginBottom: "20px",
                    height: "50px",
                    width: "100%",
                    borderColor: "lightgrey",
                    borderRadius: "5px",
                  }}
                  defaultValue=""
                  // labelId="city"
                  id="city"
                  value={city}
                  label="City"
                  onChange={this.selectCity}
                >
                  {myCity.map((option) => (
                    <option value={option.name}>{option.name}</option>
                  ))}
                </select>

                <TextField
                  required={true}
                    label="Post Code"
                  size="small"
                  // placeholder="Post Code"
                  name="postCode"
                  variant="outlined"
                  fullWidth={true}
                  value={this.state.postCode}
                  onChange={this.handleChange}
                />
                {this.validator.message(
                  "Post Code",
                  this.state.postCode,
                  "required|integer",
                  { className: classes.textDanger }
                )}
              </div>
            </div>
          </div>

          {/* <hr /> */}

          <Button
            style={{
              marginLeft: "78%",
              marginBottom: "20px",
              backgroundColor: SKY_BLUE,
            }}
            variant="contained"
            size="large"
            color="primary"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(AddCustomerModal));
