import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Badge from "@material-ui/core/Badge";
import ArrowDropUpTwoToneIcon from "@material-ui/icons/ArrowDropUpTwoTone";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import "./product.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import InputAdornment from "@material-ui/core/InputAdornment";
import { DropzoneArea } from "material-ui-dropzone";
const styles = {
  inputHeight: {
    height: 1,
    marginBottom: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
  },
  th: {
    padding: "0px",
  },
  td: {
    padding: "10px",
  },
  paper: {
    backgroundColor: "white",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
};

class Variant extends Component {
  state = { type: "", checkedB: true };
  onHandleBack = (props) => {
    this.props.history.push("/Customers");
  };
  render() {
    const dummyImage =
      "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081";
    // const { type } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <form>
          <div>
            <h5>
              <ArrowBackIosIcon
                style={{ cursor: "pointer" }}
                onClick={this.handleBack}
              />
              Product
            </h5>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "45%",
                  //   position: "fixed",
                  right: "0px",
                  paddingRight: "6rem",
                }}
              >
                <div
                  style={{
                    padding: "20px 10px",

                    backgroundColor: "#fafbfb",
                    borderRadius: "0.8rem",
                    marginBottom: "10px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      //   justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          border: "2px solid black",
                          borderRadius: "5px",
                        }}
                        src={dummyImage}
                        alt="variant-pic"
                      />
                    </div>
                    <div style={{ margin: "15px", padding: "10px" }}>
                      <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Product
                        <Badge badgeContent={"Active"} color="secondary">
                          <ArrowDropUpTwoToneIcon />
                        </Badge>
                      </div>
                      <div>3 variants</div>
                      <Link>Back to products</Link>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    padding: "20px 10px",
                    backgroundColor: "#fafbfb",
                    borderRadius: "0.8rem",
                    marginBottom: "10px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Variants
                  </p>
                  <hr></hr>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={dummyImage}
                      alt="varian-pic"
                      style={{
                        width: "50",
                        height: "50px",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                    />
                    <Link style={{ margin: "15px" }}>Blue</Link>
                  </div>
                  <hr></hr>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={dummyImage}
                      alt="varian-pic"
                      style={{
                        width: "50",
                        height: "50px",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                    />
                    <Link style={{ margin: "15px" }}>Green</Link>
                  </div>
                  <hr></hr>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={dummyImage}
                      alt="varian-pic"
                      style={{
                        width: "50",
                        height: "50px",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                    />
                    <Link style={{ margin: "15px" }}>Red</Link>
                  </div>
                  <hr></hr>
                </div>
              </div>
              <div
                style={{
                  width: "65%",
                  marginRight: "25px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div>
                    <p>Options</p>
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "1rem",
                      }}
                    >
                      Color
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      value={this.state.discountCode}
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />
                    <div
                      style={{
                        margin: "0px 0px 10px 10px",
                      }}
                    >
                      <DropzoneArea />
                      <Link style={{ marginTop: "12px" }}>Choose existing</Link>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Pricing
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <label>Price</label>
                      <TextField
                        variant="outlined"
                        fullWidth={true}
                        InputProps={{ classes: { input: classes.inputHeight } }}
                      />
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                      <label>Compare at price</label>
                      <TextField
                        variant="outlined"
                        fullWidth={true}
                        InputProps={{ classes: { input: classes.inputHeight } }}
                      />
                    </div>
                  </div>
                  <hr></hr>
                  <p>Cost per item</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <TextField
                        variant="outlined"
                        InputProps={{ classes: { input: classes.inputHeight } }}
                      />
                    </div>
                    <div style={{ margin: "0 50px 0 50px" }}>Margin</div>
                    <div>Profict</div>
                  </div>
                  <div>Customer Won't see this</div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checkedB}
                        // onChange={handleChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Charge tax on this variant"
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Inventory
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <label>SKU (Sock Keeping Unit)</label>
                      <TextField
                        variant="outlined"
                        InputProps={{ classes: { input: classes.inputHeight } }}
                      />
                    </div>
                    <div>
                      <label>Barcode (ISBN, UPC, GTIN, etc.)</label>
                      <TextField
                        variant="outlined"
                        fullWidth={true}
                        InputProps={{ classes: { input: classes.inputHeight } }}
                      />
                    </div>
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedB}
                          // onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Track quantity"
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checkedB}
                        // onChange={handleChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Continue selling when out of stock"
                  />
                  <hr></hr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        //   margin: "3px 15px, 20px,10px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Quantity
                    </div>
                    <Link>View Inventory history</Link>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Available</div>
                    <div>Incoming</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <TextField
                        variant="outlined"
                        InputProps={{ classes: { input: classes.inputHeight } }}
                        type="number"
                      />
                    </div>
                    <div>0</div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Shipping
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedB}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="This is a physical product"
                    />
                  </div>
                  <hr></hr>
                  <label>
                    <b>Weight</b>
                  </label>
                  <p>
                    Used to calculate shipping rates at checkout and label
                    prices during fulfillment.
                  </p>
                  <label>Weight</label>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <TextField variant="outlined" type="number" />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Kg
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        // value={age}
                        // onChange={handleChange}
                        label="Age"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>oz</MenuItem>
                        <MenuItem value={20}>lb</MenuItem>
                        <MenuItem value={30}>g</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <hr></hr>
                  <label>
                    <b>CUSTOMS INFORMATION</b>
                  </label>
                  <p>
                    Customs authorities use this information to calculate duties
                    when shipping internationally. Shown on printed customs
                    forms.
                  </p>
                  <label>Country/Region of origin</label>
                  <Autocomplete
                    id="combo-box-demo"
                    // options={top100Films}
                    getOptionLabel={(option) => option.title}
                    style={{ width: 650 }}
                    renderInput={(params) => (
                      <TextField
                        InputProps={{ classes: { input: classes.inputHeight } }}
                        {...params}
                        variant="outlined"
                        label="Checkboxes"
                        placeholder="Favorites"
                      />
                    )}
                  />
                  <p>In most cases, where the product is manufactured.</p>
                  <label>HS (Harmonized System) code</label>
                  <TextField
                    InputProps={{
                      classes: { input: classes.inputHeight },
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth={true}
                  />
                  <p>Manually enter codes that are longer than 6 numbers.</p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  style={{}}
                >
                  Delete Variant
                </Button>
              </div>
              <Button
                type="submit"
                variant="contained"
                color="default"
                size="large"
                onClick={() => this.handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(Variant);
