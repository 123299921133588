import React, { Component } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";

export default class ImageUrlModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      image: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);
    this.props.updateParent(this.state, "customItem");

    this.props.onClickBtnClose({ modal: false });
  };

  handleChange = (event) => {
    let nam = event.target.name;

    let val = event.target.value;
    this.setState({ [nam]: val, image: val });
    //console.log(nam + " " + val);
  };

  render() {
    return (
      <div onSubmit={this.handleSubmit}>
        <Box display="flex" flexDirection="row">
          <Box style={{ color: "#fff" }}>
            <TextField
              style={{ width: "260%" }}
              name="url"
              label="Enter Url"
              size="small"
              type="text"
              onChange={this.handleChange}
            />
          </Box>
        </Box>
        <Box>
          {<hr />}
          <Button
            style={{
              textTransform: "none",
              float: "right",
            }}
            onClick={this.handleSubmit}
            variant="contained"
            // color="dark"
          >
            Save
          </Button>
          <Button
            style={{
              textTransform: "none",
              marginRight: "10px",
              float: "right",
            }}
            onClick={this.props.onClickBtnClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </div>
    );
  }
}
