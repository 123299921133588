import React, { Component } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Select, MenuItem, TextField, InputLabel } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const minimumRequirements = [
  {
    POID: 1,
    value: "Minimum purchase amount",
  },
  {
    POID: 2,
    value: "Minimum quantity of items",
  },
];
const discountAppliedOn = [
  {
    POID: 1,
    value: "All Products",
  },
  {
    POID: 2,
    value: "Specific Products",
  },
];
class CustomerBuys extends Component {
  onHandleBack = () => {
    this.props.setStep(1);
  };
  nextStep = () => {
    this.props.setStep(9);
  };
  render() {
    const {
      quantity,
      amount,
      quantityOrAmount,
      appliesTo,
      selectedProductsCustomerBuys,
    } = this.props.values;
    return (
      <div>
        <div>
          <PageTitle
            textType="h6"
            value="Customer Buys"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.onHandleBack}
          />
        </div>
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="8px">
          {/* <InputLabel id="Input label">Applies To</InputLabel> */}
          <Select
            labelId="Input label"
            id="Select"
            name="quantityOrAmount"
            value={quantityOrAmount}
            onChange={this.props.handleChange}
            size="small"
            variant="outlined"
            fullWidth
          >
            {minimumRequirements.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {quantityOrAmount === "Minimum purchase amount" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="amount"
              label="Amount"
              value={amount}
              onChange={this.props.handleChange}
              variant="standard"
              placeholder="PKR0"
              size="large"
              fullWidth
              type="number"
            />
          </div>
        )}
        {quantityOrAmount === "Minimum quantity of items" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="quantity"
              label="Quantity"
              value={quantity}
              onChange={this.props.handleChange}
              variant="standard"
              placeholder="0"
              size="small"
              fullWidth
              type="number"
            />
          </div>
        )}
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="12px">
          <InputLabel id="Input label">Any items</InputLabel>
          <Select
            labelId="Input label"
            id="Select"
            name="appliesTo"
            value={appliesTo}
            onChange={this.props.handleChange}
            size="large"
            variant="standard"
            fullWidth
          >
            {discountAppliedOn.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {appliesTo === "Specific Products" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                marginTop: "15px",
                marginLeft: "5px",
                marginBottom: "5px",
              }}
            >
              <div className="label-products">
                <label>Products</label>
              </div>
              {selectedProductsCustomerBuys.length === 0 ? (
                <AddCircleOutlineIcon
                  onClick={this.nextStep}
                  color="success"
                  fontSize="medium"
                />
              ) : (
                <EditIcon
                  onClick={this.nextStep}
                  color="success"
                  fontSize="medium"
                />
              )}
            </div>
            {selectedProductsCustomerBuys.length === 0 ? (
              <div
                style={{
                  width: "90%",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              >
                <p>No products Added</p>
              </div>
            ) : (
              selectedProductsCustomerBuys.map((product) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "8px",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      width="35"
                      height="35"
                      src="https://cdn.iconscout.com/icon/free/png-256/gallery-187-902099.png"
                    />
                    <p style={{ marginLeft: "10px", textAlign: "center" }}>
                      {product.name.slice(0, 20)}
                    </p>
                  </div>
                  <div>
                    <CancelIcon
                      color="warning"
                      onClick={() => this.props.handleRemove(product.POID)}
                    />
                  </div>
                </div>
              ))
            )}
          </>
        ) : null}
      </div>
    );
  }
}

export default CustomerBuys;
