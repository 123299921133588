const initial_state = {
  discounts: [],
  automatic_discounts: [],
};

const discountReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_DISCOUNTS":
      return Object.assign({}, state, { discounts: action.discounts });
    case "EDIT_AUTOMATED_DISCOUNT":
      console.log("inside edit automated discount!!!");
      let updated_automated_discount = action.updated_automated_discount;
      console.log(
        "updated automated discount: ",
        action.updated_automated_discount
      );
      console.log("DISCOUNTS", state.discounts);
      let automated_discount;
      if (
        action.updated_automated_discount[0].applicationMode === "Automatic"
      ) {
        automated_discount = state.automatic_discounts.filter(
          (discount) => discount.id === action.updated_automated_discount[0].id
        );
      } else {
        automated_discount = state.discounts.filter(
          (discount) => discount.id === action.updated_automated_discount[0].id
        );
      }

      console.log("");
      console.log("From Store: ", automated_discount);
      automated_discount = automated_discount[0];
      console.log("From Store: ", automated_discount);
      if (
        automated_discount.discountCode !==
        updated_automated_discount[0].discountCode
      ) {
        automated_discount.discountCode =
          updated_automated_discount[0].discountCode;
      }
      if (updated_automated_discount[0].type !== automated_discount.type) {
        automated_discount.type = updated_automated_discount[0].type;
      }
      if (
        updated_automated_discount[0].discountPercentage !==
        automated_discount.discountPercentage
      ) {
        automated_discount.discountPercentage =
          updated_automated_discount[0].discountPercentage;
      }
      if (
        updated_automated_discount[0].discountAmount !==
        automated_discount.discountAmount
      ) {
        automated_discount.discountAmount =
          updated_automated_discount[0].discountAmount;
      }
      if (
        updated_automated_discount[0].discountAppliedOn !==
        automated_discount.discountAppliedOn
      ) {
        automated_discount.discountAppliedOn =
          updated_automated_discount[0].discountAppliedOn;
      }
      if (
        updated_automated_discount[0].actionType !==
        automated_discount.actionType
      ) {
        automated_discount.actionType =
          updated_automated_discount[0].actionType;
      }
      if (updated_automated_discount[0].name !== automated_discount.name) {
        automated_discount.name = updated_automated_discount[0].name;
      }
      if (
        updated_automated_discount[0].customerBuys !==
        automated_discount.customerBuys
      ) {
        automated_discount.customerBuys =
          updated_automated_discount[0].customerBuys;
      }
      if (
        updated_automated_discount[0].customerGets !==
        automated_discount.customerGets
      ) {
        automated_discount.customerGets =
          updated_automated_discount[0].customerGets;
      }
      if (
        updated_automated_discount[0].endDate !== automated_discount.endDate
      ) {
        automated_discount.endDate = updated_automated_discount[0].endDate;
      }
      if (
        updated_automated_discount[0].minimumQuantityOrAmountToBuy !==
        automated_discount.minimumQuantityOrAmountToBuy
      ) {
        automated_discount.minimumQuantityOrAmountToBuy =
          updated_automated_discount[0].minimumQuantityOrAmountToBuy;
      }
      if (
        updated_automated_discount[0].quantitycustomerGets !==
        automated_discount.quantitycustomerGets
      ) {
        automated_discount.quantitycustomerGets =
          updated_automated_discount[0].quantitycustomerGets;
      }
      if (
        updated_automated_discount[0].startDate !== automated_discount.startDate
      ) {
        automated_discount.startDate = updated_automated_discount[0].startDate;
      }
      if (
        updated_automated_discount[0].quantityOrAmount !==
        automated_discount.quantityOrAmount
      ) {
        automated_discount.quantityOrAmount =
          updated_automated_discount[0].quantityOrAmount;
      }
      if (
        updated_automated_discount[0].eligibleCustomers !==
        automated_discount.eligibleCustomers
      ) {
        automated_discount.eligibleCustomers =
          updated_automated_discount[0].eligibleCustomers;
      }

      return state;

    case "EDIT_DISCOUNT":
      let updated_discount = action.updated_discount;
      console.log("updated discount: ", action.updated_discount);
      let discount = state.discounts.filter(
        (discount) => discount.POID == action.updated_discount.id
      );
      console.log("");
      console.log("From Store: ", discount);
      discount = discount[0];
      console.log("From Store: ", discount);
      if (updated_discount.discountCode != discount.discountCode) {
        discount.discountCode = updated_discount.discountCode;
      }
      if (updated_discount.type != discount.type) {
        discount.type = updated_discount.type;
      }
      if (updated_discount.discountPercentage != discount.discountPercentage) {
        discount.discountPercentage = updated_discount.discountPercentage;
      }
      if (updated_discount.discountAmount != discount.discountAmount) {
        discount.discountAmount = updated_discount.discountAmount;
      }
      if (updated_discount.discountAppliedOn != discount.discountAppliedOn) {
        discount.discountAppliedOn = updated_discount.discountAppliedOn;
      }
      return state;

    case "TOGGLE_STATUS": {
      console.log("Toggle Status Called!!!");
      console.log("action.discount.id", action.discounts[0].id);

      if (action.discounts[0].applicationMode === "Automatic") {
        let selected_discount = state.automatic_discounts.filter(
          (discount) => discount.id === action.discounts[0].id
        );

        selected_discount[0].status = action.discounts[0].status;
      } else {
        let selected_discount = state.discounts.filter(
          (discount) => discount.id === action.discounts[0].id
        );
        selected_discount[0].status = action.discounts[0].status;
      }
      return state;
    }
    case "DELETE_DISCOUNT":
      let deleted_discount = state.discounts.filter(
        (discount) => discount.POID == action.id
      );
      deleted_discount.status = "Archived";
      if (deleted_discount == null) {
        deleted_discount = state.automatic_discounts.filter(
          (discount) => discount.POID == action.id
        );
        deleted_discount.status = "Archived";
      }
      return state;

    case "GET_AUTOMATIC_DISCOUNTS":
      return Object.assign({}, state, {
        automatic_discounts: action.automated_discounts,
      });

    case "POST_DISCOUNTS":
      if (action.discounts[0].applicationMode === "Automatic") {
        return {
          ...state,

          automatic_discounts: state.automatic_discounts.concat(
            action.discounts[0]
          ),
        };
      } else {
        return {
          ...state,
          discounts: state.discounts.concat(action.discounts[0]),
        };
      }

    case "CLEAR_DISCOUNT_DATA":
      return initial_state;

    default:
      return state;
  }
};

export default discountReducer;
