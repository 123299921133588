import React from "react";
import { Button } from "@material-ui/core";
function NotFound(props) {
  const reload = () => {
    window.location.reload();
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "60px",
      }}
    >
      <div style={{ marginTop: "40px" }}>
        <img alt="404" src="./404.png" />
      </div>
      <div style={{ marginTop: "100px" }}>
        <h3>Click here to reload.</h3>
      </div>
      <Button variant="outlined" onClick={reload}>
        Reload
      </Button>
    </div>
  );
}

export default NotFound;
