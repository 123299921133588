import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';


class SearchBar extends React.Component {
    state = {
        searchTerm: ''
    }
    editSearchTerm = (e) => {
        this.setState({ searchTerm: e.target.value });
        console.log(`search term: ${e.target.value}`);
        const filteredList = this.dynamicSearch(this.props.data, e.target.value);
        this.props.onChange(filteredList);
    };
    handleSubmit = (event) => {
        console.log(`clicked on submit`);
        event.preventDefault();
        const filteredList = this.dynamicSearch(this.props.data, this.state.searchTerm);
        this.props.onSubmit(filteredList);
    };
    dynamicSearch = (list, searchTerm) => {
        return list.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()) || (item.email && item.email.toLowerCase().includes(searchTerm.toLowerCase())))
    };
    render() {
        return (
            <div>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px 0px 4px', display: 'flex', alignItems: 'center', width: 'auto', margin: '5px 5px 5px 5px' }}
                    onChange={this.editSearchTerm} onSubmit={this.handleSubmit}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={this.props.barvalue}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
        );
    }
};

export default SearchBar;