const initial_state = {
  receipt_metadata: null,
};
const companyInfoReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "RECEIPT_METADATA":
      console.log(action);
      return Object.assign({}, state, {
        receipt_metadata: action.receipt_metadata,
      });
    case "PUT_RECEIPT_METADATA":
      return {
        ...state,
        receipt_metadata: action.receipt_metadata,
      };

    case "CLEAR_COMPANY_INFO":
      return initial_state;

    default:
      return state;
  }
};

export default companyInfoReducer;
