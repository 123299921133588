
export default function SingleProduct(props){
    return(
        <div className="singleProduct">
            <div className="row">
                <div className="col-lg-1 col-md-2 col-12 align-self-center imgCol">
                    <img src="/assets/tick.png" alt="product-img" />
                    <div className="imgTag">
                        <span>{props.quantity}</span>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 align-self-center nameCol">
                    <h6>{props.name}</h6>
                </div>
                <div className="col-4 col-md-4 col-12 align-self-center priceCol">
                    <p>{props.price}</p>
                </div>
            </div>
        </div>
    )
}
