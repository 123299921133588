const initial_state = {
  individuals: [],
};

const individualsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_INDIVIDUALS":
      console.log(action);
      return Object.assign({}, state, {
        individuals: action.individuals,
      });

    case "CLEAR_DATA":
      state = {
        individuals: [],
      };

    default:
      return state;
  }
};

export default individualsReducer;
