import React, { Component } from "react";
import ClearIcon from "@material-ui/icons/Clear";
class CustomDiscountProduct extends Component {
  render() {
    console.log("Custom Discount Product", this.props);
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              src="https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
              alt=""
              style={{
                width: "50px",
                height: "50px",
                fontSize: "50px",
                marginTo: "10px",
              }}
            />
            <div style={{ marginTop: "15px" }}>
              {this.props.product.productName}
            </div>
          </div>
          <div style={{ cursor: "pointer" }}>
            <ClearIcon
              onClick={() => this.props.isDelete(this.props.product)}
            />
          </div>
        </div>
        <hr />
      </>
    );
  }
}

export default CustomDiscountProduct;
