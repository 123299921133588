import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function AddShipping() {
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          If you're not seeing all your rates, add a customer with a complete
          shipping address.
        </FormLabel>
        <RadioGroup
          aria-label="position"
          name="shipping"
          defaultValue="shipping"
        >
          <FormControlLabel
            value="free_shipping"
            control={<Radio color="primary" />}
            label="Free shipping"
            labelPlacement="end"
          />
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label="Custom"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
