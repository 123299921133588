import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, TextField, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import RichTextEditor from './RictTextEditor';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { convertToRaw } from "draft-js";
import PageTitle from './PageTitle';
import './AddProduct.css';

const AddProductScreen1 = ({ nextStep, handleChange, values, list, statuses, handleDescription, history }) => {

    // for continue event listener
    const Continue = e => {
        e.preventDefault();
        nextStep();
    }
    const setDescription = (value) => {
        console.log('in set Description fucnction');
        // handleDescription('description', extractedValue);
        handleDescription('contentOfDescription', value);
    }
    const ChangeRoute = () => {
        history.push("/Products")
    }

    return (<>
        <div>
            <div>
                <PageTitle textType="h6" value="ADD PRODUCT" Icon={<ArrowBackIosNewIcon />} ChangeRoute={ChangeRoute} />
            </div>
        </div>
        <Container component="main" maxWidth="xs">
            <div>

                <form>
                    <Grid container spacing={2}>
                        {/* product title */}
                        <Grid item xs={12}>
                            <TextField
                                placeholder="Enter Product Title"
                                label="Title"
                                onChange={handleChange('name')}
                                defaultValue={values.name}
                                variant="outlined"
                                autoComplete="name"
                                fullWidth
                            />
                        </Grid>
                        <div className="border">
                            <RichTextEditor values={values} onChange={setDescription} setDesc={(value) => { handleDescription('description', value) }} />
                        </div>
                        <br />
                        <Grid item xs={12}>
                            <InputLabel id="Input label">ProductStatus</InputLabel>
                            <Select
                                labelId="Input label"
                                id="Select"
                                value={values.status}
                                defaultValue={values.status}
                                onChange={handleChange('status')}
                                variant="outlined"
                                fullWidth
                            >
                                {statuses.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <InputLabel id="Input label">Category</InputLabel>
                            <Select
                                labelId="Input label"
                                id="Select"
                                value={values.category}
                                defaultValue={values.category}
                                onChange={handleChange('category')}
                                variant="outlined"
                                fullWidth
                            >
                                {list.map((item, index) => {
                                    return <MenuItem key={item.id} value={item.POID}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                    <br />
                    <Button
                        onClick={Continue}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Next
                    </Button>
                </form>
            </div>
        </Container>
    </>
    )
}

export default AddProductScreen1;



