import React, { Component } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
export default class AddCustomItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      productName: "",
      quantity: 0,
      fulfilled: false,
      disabledBtn: true,
      tax: 0,
      unitOfMeasure: 2,
      action: "add",
      costPrice: 0.0,
      price: 0.0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateParent(this.state, "customItem");
  };

  handleChangeCheck = async (event) => {
    let nam = event.target.name;
    let val = event.target.checked;

    console.log(nam, val);
    await this.setState({ tax: val ? 11 : 0 });
    console.log(this.state);
  };

  handleChange = async (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    await this.setState({ [nam]: val });
    this.setState({ [nam]: val });
    this.setState({ costPrice: this.state.price });

    if (
      this.state.productName.length > 0 &&
      this.state.price.length > 0 &&
      this.state.quantity > 0
    ) {
      this.setState({ disabledBtn: false });
    } else {
      this.setState({ disabledBtn: true });
    }
  };
  render() {
    return (
      <div onSubmit={this.handleSubmit}>
        <Box display="flex" flexDirection="column"  bgcolor="white">
          <Box p={1}>
            {/* <Box p={1}>Line item name</Box> */}
            <TextField
              name="productName"
              label="Line item name"
              type="text"
              onChange={this.handleChange}
            />
          </Box>
          <Box p={1}>
            {/* <Box p={1}>Price per item</Box> */}
            <TextField
              name="price"
              label="Price per item"
              type="number"
              onChange={this.handleChange}
            />
          </Box>
          <Box p={1}>
            {/* <Box p={1}>Quantity</Box> */}
            <TextField
              name="quantity"
              label="Quantity"
              type="number"
              onChange={this.handleChange}
            />
          </Box>
        </Box>
        <Box p={1}>
          <FormControlLabel
            control={
              <Checkbox
                // checked={state.checkedB}
                onChange={this.handleChangeCheck}
                name="checkedB"
                color="primary"
              />
            }
            label="Item is taxable"
          />
        </Box>
       
        <Box p={1}>
          {<hr />}
          <Button
            style={{ textTransform: "none", marginRight: "10px" }}
            onClick={this.props.onClickBtnClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            style={{ textTransform: "none", marginRight: "20px" }}
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
            disabled={this.state.disabledBtn}
          >
            Save line item
          </Button>
        </Box>
      </div>
    );
  }
}
