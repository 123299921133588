import React from "react";
import Product from "./Product";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

import Loader from "../../MobileComponents/Loader";

const style = (theme) => ({
  responsive: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
});
class ProductList extends React.Component {
  state = { loading: false };
  addToCart = (product) => {
    this.props.addToCartAction(product);
  };

  render() {
    const { products, classes } = this.props;

    console.log("POS product List", this.props.products);
    return (
      <div
        className={`item-group-wrapper ${classes.responsive}`}
        style={{
          backgroundColor: "rgba(255,255,255,1)",
          borderRadius: "20px",
        }}
      >
        <div className="item-group">
          {this.props.loading && (
            <div
              style={{
                flex: "1",
              }}
            >
              <Loader value={"Loading Products.."} />
            </div>
          )}
          {products?.map((p) => {
            var sellableProducts = p?.varients?.filter((value) => {
              if (value?.status === "Active") return value;
            });
            if (sellableProducts?.length > 0) {
              return (
                <>
                  {sellableProducts?.map((sellableProduct) => {
                    return (
                      <Product
                        key={sellableProduct.POID}
                        {...sellableProduct}
                        addFunc={this.addToCart}
                      />
                    );
                  })}
                </>
              );
            } else
              return <Product key={p.POID} {...p} addFunc={this.addToCart} />;
          })}
        </div>
      </div>
    );
  }
}
export default withStyles(style)(ProductList);
