import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import Box from "@material-ui/core/Box";
import Popup from "../Popup";
import ImageUrlModal from "./ImageUrlModal";
import "react-dropdown/style.css";
import ImageIcon from "@material-ui/icons/Image";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import "./product.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// import TextEditor from "../Editor/TextEditor";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DropzoneArea } from "material-ui-dropzone";
import GlobalConfig from "../../Config";
import { connect } from "react-redux";

const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },
  formContainer: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "55%",
    },
  },

  formItems: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
  },
  formInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  formList: {
    width: "85%",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formRightBox: {
    width: "40%",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});
const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];
// const options = ["Active", "Draft"];
const status = [
  { code: "Active", label: "Active" },
  { code: "Draft", label: "Draft" },
];
class EditVariant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backdrop: false,
      name: this.props.location.state.name,
      description: this.props.location.state.description,
      status: this.props.location.state.status,
      price: this.props.location.state.price,
      id: this.props.location.state.POID,
      images: this.props.location.state.images,
      quantity: this.props.location.state.quantity,
      varients: this.props.location.state.relatedProducts.length,
      varientLoading: true,
      varientData: [],
    };
  }
  componentDidMount() {
  

    console.log("related product length" + this.state.varients);
    if (this.state.varients > 0) {
      fetch(this.props.global_config.basePath + `/products/${this.state.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        // convert the React state to JSON and send it as the POST body
      })
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            varientLoading: false,
            varientData: data,
          })
        );
    } else {
      this.setState({ varientLoading: false });
    }
  }
  handleChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: event.target.value });
  };
  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: Number(event.target.value) });
  };
  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };
  handleDelete = (product) => {
    this.setState({
      backdrop: true,
    });
    fetch(this.props.global_config.basePath + `/products/${this.state.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      console.log("order response...");
      console.log(response);
      this.props.history.push({
        pathname: "/Products",
      });
    });
  };
  handleSubmit = (event) => {
    this.setState({ backdrop: true });
    event.preventDefault();
    console.log("in handle submit");

    console.log("name :" + this.state.name);
    console.log("description :" + this.state.description);
    console.log("IMAGES :" + this.state.images);
    // console.log("price :" + this.state.price);
    // console.log("offeringStartDate" + this.state.offeringStartDate);
    // console.log("offeringEndDate" + this.state.offeringEndDate);
    const data = {
      name: this.state.name,
      description: this.state.description,
      price: this.state.price,
      status: this.state.status,
      images: this.state.images,

     
    };

    console.log(JSON.stringify(data));
    fetch(this.props.global_config.basePath + `/products/${this.state.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    }).then((response) => {
      //console.log("order response...");
      //console.log(response);
      this.props.history.push({
        pathname: "/Products",
      });
    });
  };
  updateParent = (data) => {
    const obj = {
      imageURL: data.image,
    };
    this.setState({ images: [...this.state.images, obj] });
  };
  render() {
    // const defaultOption = this.state.status;

    console.log("edit products state props", this.state);
    console.log("edit products props", this.props);
   
    const { classes } = this.props;
    // const { type } = this.state;
    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} color="inherit" />
        </Backdrop>

        <h5>
          <ArrowBackIosIcon
            fontSize="large"
            onClick={() => this.props.history.push("/Products")}
            style={{ cursor: "pointer" }}
          />
          Product
        </h5>

        <form onSubmit={this.handleSubmit}>
          <div className={classes.formContainer}>
            <div className={classes.formList}>
              <div className={classes.formItems}>
                <b>Title</b>

                <TextField
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange.bind(this)}
                  variant="outlined"
                  fullWidth={true}
                  InputProps={{ classes: { input: classes.inputHeight } }}
                />

                <div style={{ marginTop: "20px" }}>
                  <b>Description</b>
                </div>

                <div>
                  <ReactQuill
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                  />
                </div>
              </div>

              <div className={classes.formItems}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Media
                  </div>
                  <div>
                    <Box p={1}>
                      <Popup
                        title="Add Media Url"
                        btnText="Add Media Url"
                        btnFloat="right"
                        // marginTop="-20px"
                        btnColor="lightblue"
                        contentTxt={<hr />}
                        contentTxtBe={<hr />}
                        content={
                          <ImageUrlModal
                            updateParent={this.updateParent}
                            onClickBtnClose={() =>
                              this.setState({ modal: false })
                            }
                          />
                        } //component to send
                        openPopup={this.state.modal} //open
                        onClickBtnOpen={() => this.setState({ modal: true })} //on click open
                        onClickBtnClose={() => this.setState({ modal: false })} //on click close
                      />
                    </Box>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    // marginLeft: "2px",
                    display: "flex",
                    height: "300px",
                  }}
                >
                  <DropzoneArea productImages={this.state.images} />
                </div>

                {this.state.images.length === 0 ? (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h5>
                      <ImageIcon
                        fontSize="large"
                        style={{ color: "rgba(16,174,72)" }}
                      />
                      No Images attached for {this.state.name}
                    </h5>
                  </div>
                ) : (
                  this.state.images.map((image, index) => (
                    <img
                      key={index}
                      src={image.imageURL}
                      alt=""
                      style={{
                        // width: "100px",
                        height: "100px",
                        // margin: "15px 0 0 25px",
                      }}
                    />
                  ))
                )}
              </div>

              <div className={classes.formItems}>
                <p style={{ fontWeight: "bold", fontSize: "16px" }}>Pricing</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <label>Price</label>
                    <TextField
                      value={this.state.price}
                      name="price"
                      onChange={this.onPriceChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <label>Compare at price</label>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                    />
                  </div>
                </div>
                <hr></hr>
                <p>Cost per item</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                    />
                  </div>
                  <div style={{ margin: "0 50px 0 50px" }}>Margin</div>
                  <div>Profict</div>
                </div>
                <div>Customer Won't see this</div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkedB}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Charge tax on this variant"
                />
              </div>
              <div className={classes.formItems}>
                <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Inventory
                </p>
                <div className={classes.formInput}>
                  <div>
                    <label>SKU (Sock Keeping Unit)</label>
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                    />
                  </div>
                  <div>
                    <label>Barcode (ISBN, UPC, GTIN, etc.)</label>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                    />
                  </div>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checkedB}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Track quantity"
                  />
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkedB}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Continue selling when out of stock"
                />
                <hr></hr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Quantity
                  </div>
                  {this.state.varients > 0 ? null : (
                    <div>
                      <Button
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/inventryHistory",
                            state: this.state.id,
                          });
                        }}
                      >
                        View Inventory history
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Available</div>
                  <div>Incoming</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                      value={this.state.quantity}
                      type="number"
                      disabled
                    />
                  </div>
                  <div>0</div>
                </div>
              </div>
              <div className={classes.formItems}>
                <p style={{ fontWeight: "bold", fontSize: "16px" }}>Shipping</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checkedB}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="This is a physical product"
                  />
                </div>
                <hr></hr>
                <label>
                  <b>Weight</b>
                </label>
                <p>
                  Used to calculate shipping rates at checkout and label prices
                  during fulfillment.
                </p>
                <label>Weight</label>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <TextField
                    variant="outlined"
                    type="number"
                    style={{ marginRight: "10px" }}
                  />
                  <FormControl
                    style={{ width: "120px" }}
                    variant="outlined"
                    className={classes.formControl}
                    InputProps={{ classes: { input: classes.inputHeight } }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Kg
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Age"
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>oz</MenuItem>
                      <MenuItem value={20}>lb</MenuItem>
                      <MenuItem value={30}>g</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <hr></hr>
                <label>
                  <b>CUSTOMS INFORMATION</b>
                </label>
                <p>
                  Customs authorities use this information to calculate duties
                  when shipping internationally. Shown on printed customs forms.
                </p>
                <label>Country/Region of origin</label>
                <Autocomplete
                  id="combo-box-demo"
                  // options={top100Films}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      InputProps={{
                        classes: { input: classes.inputHeight },
                      }}
                      {...params}
                      variant="outlined"
                      label="Checkboxes"
                      placeholder="Favorites"
                    />
                  )}
                />
                <p>In most cases, where the product is manufactured.</p>
                <label>HS (Harmonized System) code</label>
                <TextField
                  InputProps={{
                    classes: { input: classes.inputHeight },
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth={true}
                />
                <p>Manually enter codes that are longer than 6 numbers.</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <p>No Variants</p>
              </div>
            </div>

            <div className={classes.formRightBox}>
              <div className={classes.formItems}>
                <p
                  style={{
                    margin: "10px 0 0 10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Product Status
                </p>

                <Autocomplete
                  onChange={(event, value) =>
                    this.setState({ status: value.code })
                  } // prints the selected value
                  defaultValue={{ code: this.state.status, label: "Active" }}
                  options={status}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{option.code}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      name="status"
                      value={this.state.status}
                      onChange={this.handleChange}
                      {...params}
                      Status
                      label=""
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />

                
              </div>

              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    Insight
                  </p>
                  <p>Last 90 days</p>
                </div>
                <p
                  style={{
                    color: "#6d7175",
                  }}
                >
                  Sold 5 units to 0 customers for Rs1,170.00 in net sales.
                </p>
                <Link> View Details</Link>
              </div>
              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "rgba(250,251,251,1)",
                  borderRadius: "0.8rem",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Organization
                </h2>
                <p
                  style={{
                    color: "#6d7175",
                  }}
                >
                  <label>Product Type</label>
                  <div>
                    <TextField
                      select
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      fullWidth={true}
                    >
                      {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </div>
                  <label>Vendor</label>
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    fullWidth={true}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </p>
                <hr></hr>
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Collections
                </h2>
                <TextField
                  fullWidth={true}
                  id="outlined-search"
                  label="Search field"
                  type="search"
                  variant="outlined"
                  InputProps={{
                    classes: { input: classes.inputHeight },
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <p
                  style={{
                    marginTop: "15px",
                    color: "rgba(109, 113, 117, 1)",
                  }}
                >
                  Add this product to a collection so it’s easy to find in your
                  store.
                </p>
                <hr></hr>
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Tags
                </h2>
                <TextField
                  fullWidth={true}
                  id="outlined-search"
                  label="Vintage, Cotton, Summer"
                  type="search"
                  variant="outlined"
                  InputProps={{
                    classes: { input: classes.inputHeight },
                  }}
                />
              </div>
              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "rgba(250,251,251,1)",
                  borderRadius: "0.8rem",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    marginBottom: "15px",
                  }}
                >
                  Online Store
                </h2>

                <label>Theme template</label>

                <Autocomplete
                  id="country-select-demo"
                  options={status}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{option.code}</span>
                      {option.label} ({option.code}) +{option.phone}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <p
                  style={{
                    marginTop: "15px",
                    color: "rgba(109, 113, 117, 1)",
                  }}
                >
                  Assign a template from your current theme to define how the
                  product is displayed
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Button variant="contained" size="small">
                Archive Product
              </Button>
              <Button
                onClick={() => this.handleDelete(this.state.id)}
                color="secondary"
                variant="outlined"
                size="small"
                style={{ marginLeft: "10px" }}
              >
                Delete Product
              </Button>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="default"
              size="small"
              onClick={() => this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global_config: state.globalState
  };
};
export default connect(mapStateToProps)(withStyles(style)(EditVariant));
