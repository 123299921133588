import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@mui/material/Tooltip";
import { SKY_BLUE } from "../../colors/colors";

const style = (theme) => ({
  responsive: {
    [theme.breakpoints.down("sm")]: {
      width: "2",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// const { classes } = this.props;
const ITEM_HEIGHT = 30;

const Product = ({
  POID,
  name,
  description,
  price,
  images,
  addFunc,
  unitsOfMeasures,
  relatedProducts,
  costPrice,
  productSpecCharacteristics,

  taxRate,
}) => {
  let defaultUOM = unitsOfMeasures.filter((p) => p.isDefault === true);
  let uOMPOID = defaultUOM[0].POID;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [PorductDetailOpen, setProductDetailOpen] = React.useState(false);
  const [variantDetailOpen, setVariantDetailOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const history = useHistory();
  const html = description;
  const div = document.createElement(`div-`);
  div.innerHTML = html;
  const text = div.textContent || "";
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProductDetailOpen = () => {
    setAnchorEl(null);

    setProductDetailOpen(true);
    history.push({
      state: {
        POID,
        name,
        description,
        price,
        images,
        costPrice,
      },
    });
  };
  const handleProductDetailClose = () => {
    setProductDetailOpen(false);
  };
  const handleVariantDetailOpen = () => {
    setAnchorEl(null);
    setVariantDetailOpen(true);
  };
  const handleVariantDetailClose = () => {
    setVariantDetailOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log("product specs: ", productSpecCharacteristics);

  const dummyImage =
    "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081";
  let imageUrl;
  // console.log(images);
  if (images.length === 0) {
    imageUrl = dummyImage;
  } else imageUrl = images[0].imageURL;

  return (
    <>
      <div className="item">
        <div
          style={{
            width: "230px",
            height: "",
            textAlign: "center",
            borderSizing: "borderBox",
            boxShadow:
              "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
          }}
        >
          <div style={{ float: "right" }}>
            <IconButton onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
          </div>
          {relatedProducts.length > 0 ? (
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "19ch",
                },
              }}
            >
              <MenuItem>View Details</MenuItem>
              <MenuItem onClick={handleVariantDetailOpen}>
                Variant Details
              </MenuItem>
            </Menu>
          ) : (
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "19ch",
                },
              }}
            >
              <MenuItem onClick={handleProductDetailOpen}>
                View Details
              </MenuItem>
            </Menu>
          )}
          <div
            onClick={() =>
              addFunc({
                POID,
                name,
                description,
                images,
                price,
                units: 1,
                uOMPOID,
                productSpecCharacteristics,
                costPrice,
                taxRate,
              })
            }
          >
            <img
              alt="productImage"
              style={{
                marginLeft: "40px",
                textAlign: "center",
                width: "90px",
                height: "90px",
                marginTop: "10px",
                borderRadius: "5px",
              }}
              src={imageUrl}
            ></img>

            <hr />
            <Tooltip title={name} style={{ marginBottom: "60px" }}>
              <p
                style={{
                  textAlign: "center",
                  textTransform: "capitalize",
                  padding: "10px",
                }}
              >
                <b>
                  {name.length < 17
                    ? name
                    : name.split(" ", 3).join(" ").concat(" ", "...")}
                </b>
                <br />
                Price: <b>{price}</b>
                <br />
                Tax Rate: <b>{taxRate}%</b>
                {productSpecCharacteristics?.length > 0 && (
                  <>
                    <hr />
                    {productSpecCharacteristics?.map((productspec, index) => {
                      return (
                        <div style={{ marginTop: "5px" }}>
                          {productspec?.name + ": "}
                          {productspec?.productSpecCharValues?.map(
                            (productSpecCharValues, index) => {
                              console.log(
                                "here is a product specificationvalues",
                                productSpecCharValues
                              );
                              return (
                                <div
                                  style={{
                                    display: "inline-block",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#D3D3D3",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    borderRadius: "30px",
                                  }}
                                >
                                  <b>{productSpecCharValues?.value}</b>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    })}
                    <hr />
                  </>
                )}
              </p>
            </Tooltip>
          </div>
          {/* <IconButton
            aria-label="delete"
            variant="contained"
            onClick={() =>
              addFunc({
                POID,
                name,
                description,
                images,
                price,
                units: 1,
                uOMPOID,
                productSpecCharacteristics,
                costPrice,
                taxRate,
              })
            }
          > */}
          {/* <AddShoppingCartIcon
              fontSize="medium"
              style={{ color: SKY_BLUE }}
            /> */}
          {/* </IconButton> */}
        </div>
      </div>

      {/* Product Detail Modal start*/}
      <div>
        <Dialog
          open={PorductDetailOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
        >
          <DialogTitle>{"Product Detail"}</DialogTitle>
          <Card style={{ width: "500px" }}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {text}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <IconButton
                aria-label="delete"
                variant="contained"
                onClick={() =>
                  addFunc({
                    POID,
                    name,
                    description,
                    images,
                    price,
                    units: 1,
                    uOMPOID,
                    costPrice,
                    productSpecCharacteristics,
                    taxRate,
                  })
                }
              >
                <AddShoppingCartIcon
                  fontSize="medium"
                  style={{ color: SKY_BLUE }}
                />
              </IconButton>
            </CardActions>
          </Card>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Name: {name}
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Description: {text}
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Price: {price}
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Cost Price: {costPrice}
            </DialogContentText>
            <div>
              {images.length === 0 ? (
                <p>No image found</p>
              ) : (
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={images.length && images[0].imageURL}
                  alt="MYIMAGE"
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleProductDetailClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Product Detail Modal close*/}

      {/* variant Modal start */}
      <div>
        <Dialog
          open={variantDetailOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"variant  Detail"}
          </DialogTitle>
          <Card style={{ width: "500px" }}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {text}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() =>
                  addFunc({
                    POID,
                    name,
                    description,
                    images,
                    price,
                    units: 1,
                    uOMPOID,
                    costPrice,
                    taxRate,
                  })
                }
              >
                Add to cart
              </Button>
              <Button size="small" color="primary">
                Share on chanel
              </Button>
            </CardActions>
          </Card>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Name: {name}
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Description: {text}
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Price: {price}
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Cost Price: {costPrice}
            </DialogContentText>
            <div>
              {images.length === 0 ? (
                <p>No image found</p>
              ) : (
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={images.length && images[0].imageURL}
                  alt=""
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleVariantDetailClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* variant Modal close*/}
    </>
  );
};

export default withStyles(style)(Product);
