
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import InputField from './InputField';
import InputFieldWithIcon from './InputFieldWithIcon';
import Label from './label';
import PageTitle from './PageTitle';
import ToggleButton from './ToggleButton';
import Link from './Link';
import CustomButton from './Button';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core';
import './AddProduct-Inventory.css';


const AddProductInventory = ({ nextStep, prevStep, handleChange, values }) => {
    console.log(values);
    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }
    return (
        <>
            <div>
                <PageTitle textType="h6" value="INVENTORY" Icon={<ArrowBackIosNewIcon />} ChangeRoute={prevStep} />
            </div>
            <div className="label">
                <Label value="SKU (Stock Keeping Unit)" />
                <InputField placeholder="Enter SKU" />
            </div>
            <div className="label">
                <Label value="Barcode" />
                <InputFieldWithIcon placeholder="Enter Barcode" />
            </div>
            <div className="label spacing">
                <ToggleButton label="Track Quantity" />
            </div>
            <div className="label spacing">
                <ToggleButton label="Continue selling when out of stock" />
            </div>
            <div className="label spacing">
                <Grid container spacing={2}>

                    {/* first name */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter Quantity"
                            label="Quantity"
                            onChange={handleChange('quantity')}
                            defaultValue={values.quantity}
                            type="number"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </div>
            {/* <div className="link">
                <Link value="View Inventory History" />
            </div>
            <div className="button">
                <CustomButton name="Save" />
            </div> */}

            <div className="label">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Previous}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Continue}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
};

export default AddProductInventory;