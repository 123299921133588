import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const discountTypes = [
  {
    value: "fixed_amount",
    label: "Amount",
  },
  {
    value: "percentage",
    label: "Percentage",
  },
];

export default function Form(props) {
  const [discount_type, set_discount_type] = React.useState("fixed_amount");
  const [values, setValues] = React.useState({});

  const handleChangeDiscountType = (event) => {
    set_discount_type(event.target.value);
  };

  const handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    // this.setState({ [nam]: val });
    // console.log(nam+" "+val);
    let locaValue = JSON.parse(JSON.stringify(values));
    locaValue[nam] = val;
    setValues(locaValue);

    console.log("here is values inside adddiscount",values);
  };
  const handleApply=()=>{
 
  props?.setDiscount({ ...values, discount_type });  
}

  return (
    <div>
      <Box display="flex" flexDirection="row" bgcolor="white">
        <Box p={1} style={{ width: "50%" }}>
          <Box p={1}>Disocunt type</Box>
          <TextField
            style={{ width: "100%" }}
            id="outlined-select-currency"
            select
            value={discount_type}
            onChange={handleChangeDiscountType}
            variant="outlined"
          >
            {discountTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box p={1} style={{ width: "50%" }}>
          <Box p={1}>Discount value</Box>
          <OutlinedInput
            style={{ width: "100%" }}
            name="discount_value"
            type="number"
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Box p={1}>
        <Box p={1}>Reason</Box>
        <OutlinedInput
          name="discount_reason"
          type="text"
          onChange={handleChange}
          fullWidth
        />
        Your customers can see this reason
        {<hr />}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={props.onClickBtnClose}
        >
          Cancel
        </Button>
        <Button
          style={{ textTransform: "none", marginLeft: "10px" }}
          variant="contained"
          onClick={() => handleApply()}
        >
          Apply
        </Button>
      </Box>
    </div>
  );
}
