import React from "react";
import ArchiveIcon from "@material-ui/icons/Archive";
import { Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import toast, { Toaster } from "react-hot-toast";
import helpers from "../../UtilitiesFunctions";

export default function CustomProduct(props) {
  let [qty, setqty] = React.useState(props.product.quantity);
  let [returnItem, setReturnItem] = React.useState(0);
  console.log({ props });
  function getRefundedQuantity() {
    var refundedQuantity = 0;
    if (props?.refundedItems) {
      for (var element of props?.refundedItems) {
        refundedQuantity += element?.quantity;
      }
    }
    return refundedQuantity;
  }

  console.log("props_CP", props);
  const handleChange = (e) => {
    if (props.isRefund === false) {
      if (props.isEdit) {
        setqty(e.target.value);
        var id = null;
        if (props.product.id == null) {
          id = props.product.productId;
        } else {
          id = props.product.id;
        }
        props.getQty(
          {
            id: id,
            quantity: parseInt(e.target.value),
            price: props.product.price,
            tax: props.product.tax,
            productId: props.product.productId,
          },
          id
        );
      } else {
        setqty(e.target.value);
        props.getQty(parseInt(e.target.value), props.product.productName);
      }
    } else if (props.isRefund) {
      if (e.target.value > props.product.quantity) {
        helpers.toastNotify(
          "error",
          `You can only return ${props.product.quantity} ${props.product.productName}`
        );
      } else {
        setReturnItem(parseInt(e.target.value));
        props.getQty(
          {
            id: props.product.id,
            quantity: parseInt(e.target.value),
            returned: true,
            price: props.product.price,
            tax: props.product.tax,
            productId: props.product.productId,
          },
          props.product.id
        );
      }
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between",
          flexDirection: "row",
          textAlign: "left",
          backgroundColor: "white",
          margin: "10px",
        }}
      >
        <ArchiveIcon style={{ fontSize: "50px", margin: "10px" }} />
        <p style={{ flex: "1" }}>
          {props.product.returned ? (
            <>
              {props.product.name == null
                ? props.product.productName + " - Returned Item"
                : props.product.name + " - Returned Item"}
            </>
          ) : (
            <>
              {props.product.name == null
                ? props.product.productName
                : props.product.name}
            </>
          )}
          <br /> Rs {props.product.price}
        </p>

        <OutlinedInput
          style={{ width: 100, height: 40, flex: "1", margin: "10px" }}
          size="small"
          type="number"
          required
          //value={qty}
          value={props.isRefund ? returnItem : qty}
          disabled={props.disableQty}
          variant="outlined"
          onChange={handleChange}
          inputProps={{
            min: 0,
            max: props?.product?.quantity - getRefundedQuantity(),
          }}
         
          endAdornment={
            props.isRefund ? (
              <InputAdornment position="end">
                {"/" + (props?.product?.quantity - getRefundedQuantity())}
              </InputAdornment>
            ) : (
              <></>
            )
          }
        />
        <p style={{ flex: "1", margin: "10px" }}>
          Rs {props.product.price * qty}
        </p>
        {props.isDelete ? (
          <>
            <CloseIcon
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => props.onDelete(props.product)}
            />
          </>
        ) : (
          <>
            <CloseIcon color="disabled" />
          </>
        )}
      </div>
      <Divider style={{ background: "gray" }} />
    </>
  );
}
