module.exports = global.config = {
  ip: "http://127.0.0.1:8080/POS/",

  userManagement:
    // "http://LogicUnit-ZohaibZaman:8081/UserManagement/HULM/cd28202b-371c-40d6-a40b-563c662591e5/",
    "http://worker01vm1:9084/UserManagement/HULM/cd28202b-371c-40d6-a40b-563c662591e5/",
  // other global config variables you wish
  companyName: "",
  instanceId: "",
  basePath: "",
  appPath: "",
  temp: "http://worker01vm1:9086/ViewsService/Views/bucket2/sputnik.hpos.e2a2e917-168e-4d1e-8ff5-81b2628b68e4/image.png",
  imageUrl: "http://worker01vm1:9086/ViewsService/Views/bucket2/",
  // url:
};
