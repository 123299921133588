import React, { Component } from "react";
import DiscountCustomerList from "./DiscountCustomerList";

class DiscountCustomerLists extends Component {
  render() {
    console.log("Discount Customer  List", this.props);
    return (
      <div>
        {this.props.customers.map((customer, index) => (
          <DiscountCustomerList
            key={index}
            customer={customer}
            isDelete={this.props.onDelete}
          
          />
        ))}
      </div>
    );
  }
}

export default DiscountCustomerLists;
