import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { green } from "@material-ui/core/colors";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Link } from "react-router-dom";
import { SKY_BLUE } from '../../colors/colors';
import PageTitle from '../Title-Main';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const style = (theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
});
class AnalyticsDashboardMobile extends Component {
  ChangeRoute = () => {
    const { history } = this.props;
    history.push('/');
  };
  render() {
    const { classes } = this.props;
    const {
      salesSummary,
      totalOrders,
      customers,
      totalProfit,
      loadingSales,
      loadingCustomers,
      loadingProfit,
    } = this.props;

    return (
      <>
        <div>
          <PageTitle textType="h6" value="REPORTS" Icon={<ArrowBackIosNewIcon />} ChangeRoute={this.ChangeRoute} />
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              width: "94%",
              marginBottom: "12px",
            }}
          >
            {/* <div>
            <h4>Reports</h4>
          </div> */}
            <div>
              <Button variant="contained" style={{ backgroundColor: SKY_BLUE }}>
                Create Custom Report
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "35px",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <Card>
                <CardHeader
                  style={{ fontSize: "19px" }}
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: SKY_BLUE }}
                      className={classes.avatar}
                    >
                      <AttachMoneyIcon />
                    </Avatar>
                  }
                  titleTypographyProps={{ variant: "h5" }}
                  title="Sales"
                />

                <CardContent>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    component="p"
                  >
                    Make business decisions by comparing sales across products,
                    staff, channels, and more.
                  </Typography>

                  <div style={{ marginTop: "20px" }}>
                    <Typography color="textSecondary" h5>
                      <b>TOTAL SALES LAST 30 DAYS</b>
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px", fontSize: "28px" }}>
                    {loadingSales ? (
                      <CircularProgress thickness={2} color="inherit" />
                    ) : (
                      <b>RS. {salesSummary}</b>
                    )}
                  </div>
                </CardContent>
                <div>
                  <p style={{ marginLeft: "20px" }}>
                    <b>REPORTS</b>
                  </p>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/sales-over-time"
                  >
                    Sales over Time
                  </Link>
                  <hr></hr>
                </div>

                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/sales-by-product-over-time"
                  >
                    {" "}
                    Sales by product
                  </Link>
                </div>
                <CardActions disableSpacing></CardActions>
              </Card>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <Card>
                <CardHeader
                  style={{ fontSize: "19px" }}
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: SKY_BLUE }}
                      className={classes.avatar}
                    >
                      <AssignmentReturnedIcon />
                    </Avatar>
                  }
                  titleTypographyProps={{ variant: "h5" }}
                  title="Orders"
                />

                <CardContent>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Increase visitor engagement by knowing where your visitors are
                    coming from and measuring the success of your campaigns.
                  </Typography>

                  <div style={{ marginTop: "20px" }}>
                    <Typography color="textSecondary" h5>
                      <b>ORDERS LAST 30 DAYS</b>
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px", fontSize: "28px" }}>
                    {loadingSales ? (
                      <CircularProgress thickness={2} color="inherit" />
                    ) : (
                      <b>{totalOrders}</b>
                    )}
                  </div>
                </CardContent>
                <div>
                  <p style={{ marginLeft: "20px" }}>
                    <b>REPORTS</b>
                  </p>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/orders-over-time"
                  >
                    Orders over Time
                  </Link>
                  <hr></hr>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/product-order-return-over-time"
                  >
                    Product orders and return
                  </Link>
                  <hr></hr>
                </div>

                <div style={{ marginLeft: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/fulfilled-orders-over-time"
                  >
                    Fufillment over time
                  </Link>
                </div>
                <CardActions disableSpacing></CardActions>
              </Card>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Card>
                <CardHeader
                  style={{ fontSize: "19px" }}
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: SKY_BLUE }}
                      className={classes.avatar}
                    >
                      <EmojiPeopleIcon />
                    </Avatar>
                  }
                  titleTypographyProps={{ variant: "h5" }}
                  title="Customers"
                />

                <CardContent>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Gain insights into who your customers are and how they
                    interact with your business.
                  </Typography>

                  <div style={{ marginTop: "20px" }}>
                    <Typography color="textSecondary" h5>
                      <b>CUSTOMERS LAST 30 DAYS</b>
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px", fontSize: "28px" }}>
                    {loadingCustomers ? (
                      <CircularProgress thickness={2} color="inherit" />
                    ) : (
                      <b>{customers[0].totalCustomers}</b>
                    )}
                  </div>
                </CardContent>
                <div>
                  <p style={{ marginLeft: "20px" }}>
                    <b>REPORTS</b>
                  </p>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/customers-over-time"
                  >
                    Customers over time
                  </Link>
                  <hr></hr>
                </div>

                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/onetime-customers-over-time"
                  >
                    One time customers
                  </Link>
                  <hr></hr>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/returning-customers-over-time"
                  >
                    Returning customers
                  </Link>
                </div>
                <CardActions disableSpacing></CardActions>
              </Card>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <Card>
                <CardHeader
                  style={{ fontSize: "19px" }}
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: SKY_BLUE }}
                      className={classes.avatar}
                    >
                      <InventoryIcon />
                    </Avatar>
                  }
                  titleTypographyProps={{ variant: "h5" }}
                  title="Inventory"
                />
                <CardContent>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Track and understand the movement of your products for more
                    efficient inventory management.
                  </Typography>

                  <div style={{ marginTop: "20px" }}>
                    <Typography color="textSecondary" h5>
                      <b> TOTAL SALES LAST 30 DAYS</b>
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px", fontSize: "28px" }}>
                    <b>Rs 0.00</b>
                  </div>
                </CardContent>
                <div>
                  <p style={{ marginLeft: "20px" }}>
                    <b>REPORTS</b>
                  </p>
                </div>
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/percent-of-inventory-sold-over-time"
                  >
                    Percent of inventory sold
                  </Link>
                  <hr></hr>
                </div>

                <div style={{ marginLeft: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/average-inventory-sold-over-time"
                  >
                    Average inventory sold per day
                  </Link>
                </div>
                <CardActions disableSpacing></CardActions>
              </Card>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <Card>
                <CardHeader
                  style={{ fontSize: "19px" }}
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: SKY_BLUE }}
                      className={classes.avatar}
                    >
                      <LocalAtmIcon />
                    </Avatar>
                  }
                  titleTypographyProps={{ variant: "h5" }}
                  title="Profit Margin"
                />

                <CardContent>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Gain insights into who your customers are and how they
                    interact with your business.
                  </Typography>

                  <div style={{ marginTop: "20px" }}>
                    <Typography color="textSecondary" h5>
                      <b>PROFIT LAST 30 DAYS</b>
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px", fontSize: "28px" }}>
                    {loadingProfit ? (
                      <CircularProgress thickness={2} color="inherit" />
                    ) : (
                      <b>{totalProfit.totalProfit}</b>
                    )}
                  </div>
                </CardContent>
                <div>
                  <p style={{ marginLeft: "20px" }}>
                    <b>REPORTS</b>
                  </p>
                </div>

                <div style={{ marginLeft: "20px" }}>
                  <Link
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                    to="/profit-by-product-over-time"
                  >
                    Profit by Product
                  </Link>
                </div>
                <CardActions disableSpacing></CardActions>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(style)(AnalyticsDashboardMobile);
