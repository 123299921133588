import React, { Component } from "react";
// import { Link } from "react-router-dom";
class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      categories: [],
      selectedCat: "All",
    };
  }

  render() {
    const { categories } = this.props;
    return (
      <>
        <div className="categories" style={{ cursor: "pointer" }}>
          <p
            className="category"
            style={{
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
            }}
            onClick={() => {
              this.props.getAllCategories();
              this.setState({
                selectedCat: "All",
              });
            }}
          >
            {this.state.selectedCat == "All" ? <b> All </b> : "All"}
          </p>
          {categories.map((category) => (
            <div
              onClick={() => {
                this.props.filterCategory(category.POID);
                this.setState({
                  selectedCat: category.name,
                });
              }}
            >
              <p
                selected={category.name === category.POID}
                className="category"
                style={{
                  width: "auto",
                  display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                {this.state.selectedCat == category.name ? (
                  <b> {category.name} </b>
                ) : (
                  category.name
                )}
              </p>
            </div>
          ))}
        </div>
      </>
    );
  }
}
export default Categories;
