import React from "react";
import { Typography, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import './PageTitle.css';

const PageTitle = function (props) {
    const { ChangeRoute } = props
    let backRoute = ChangeRoute
    if (ChangeRoute === undefined) {
        backRoute = props.prevStep
    }
    return (
        <div className={`title-bar ${props.border} background`}>
            <IconButton onClick={() => backRoute()} sx={{ color: "#ffff" }}>
                {props.Icon}
            </IconButton>
            <div style={{ marginRight: "90%" }}>
                <Typography
                    sx={{ margin: "5px 5px 5px 5px", color: "#ffff" }}
                    variant={props.textType}
                    align={props.alignment}
                >
                    {props.value}
                </Typography>
            </div>
        </div>
    );
};
PageTitle.defaultProps = {
    textType: "subtitle2",
    alignment: "left",
    value: "provide some text",
    buttonColor: "primary",
    Icon: <CancelIcon />,
    border: 'border'
};

export default PageTitle;

