import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Box, Divider } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { useState } from "react";

export default function CustomProduct(props) {
  console.log(props);
  let [qty, setqty] = useState(props.product.quantity);
  let [returnItem, setReturnItem] = useState(0);
  let [price, setPrice] = useState(props.product.price);
  const priceUpdated = (e) => {
    if (props?.isPriceUpdateable) {
      setPrice(e.target.value);
      props?.updatePrice(parseInt(e.target.value), props?.product?.productName);
    }
  };

  const handleChange = (e) => {
    if (props.isRefund === false) {
      if (props.isEdit) {
        setqty(e.target.value);
        var id = null;
        if (props.product.id == null) {
          id = props.product.productId;
        } else {
          id = props.product.id;
        }
        props.getQty(
          {
            id: id,
            quantity: parseInt(e.target.value),
            price: props.product.price,
            tax: props.product.tax,
            // subtotal:
            //   props.product.price * parseInt(e.target.value) +
            //   (props.product.price *
            //     parseInt(e.target.value) *
            //     props.product.tax) /
            //     100,
          },
          id
        );
      } else {
        setqty(e.target.value);
        props.getQty(parseInt(e.target.value), props.product.productName);
      }
    } else if (props.isRefund) {
      if (e.target.value > props.product.quantity) {
        alert(
          "Return quantity must be less than or equal to qunatity purchased!"
        );
      } else {
        setReturnItem(parseInt(e.target.value));
        props.getQty(
          {
            id: props.product.id,
            quantity: parseInt(e.target.value),
            returned: true,
            price: props.product.price,
            tax: props.product.tax,
          },
          props.product.id
        );
      }
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between",
          flexDirection: "row",
          textAlign: "left",
          backgroundColor: "white",
          margin: "10px",
        }}
      >
        <ShoppingCartIcon style={{ fontSize: "40px", margin: "10px" }} />
        <div
          style={{
            // justifyContent: "space-between",
            flexDirection: "column",

            flex: 1,
          }}
        >
          <div>
            <p style={{}}>
              {props.product.returned ? (
                <>
                  {props.product.name == null
                    ? props.product.productName + " - Returned Item"
                    : props.product.name + " - Returned Item"}
                </>
              ) : (
                <>
                  {props.product.name == null
                    ? props.product.productName
                    : props.product.name}
                </>
              )}
              {/* <br /> Rs {props.product.price} */}
            </p>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              flexDirection: "row",

              display: "flex",
            }}
          >
            <TextField
              size="small"
              type="number"
              value={props.product.price}
              min={0}
              disabled={!props?.isPriceUpdateable}
              id="outlined-basic"
              label="Price Per Unit"
              variant="outlined"
              onChange={priceUpdated}
              inputProps={{
                min: 0,
                classes: {
                  input: {
                    height: 3,
                    marginBottom: "10px",
                  },
                },
              }}
            />

            <TextField
              size="small"
              type="number"
              min={0}
              label="Quantity"
              // required
              //value={qty}
              value={props.isRefund ? returnItem : props.product.quantity}
              disabled={props.disableQty}
              variant="outlined"
              onChange={handleChange}
              inputProps={{ min: 0 }}
              //InputProps={{ inputProps: { min: 1, max: 200 } }}
              endAdornment={
                props.isRefund ? (
                  <InputAdornment position="end">
                    {"/" + props.product.quantity}
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />
            <span>Rs {props.product.price * props.product.quantity}</span>
            {props.isDelete ? (
              <>
                <CloseIcon
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => props.onDelete(props.product)}
                />
              </>
            ) : (
              <>
                <CloseIcon color="disabled" />
              </>
            )}
          </div>
        </div>
      </div>
      <Divider style={{ background: "gray" }} />
    </>
  );
}
