import CustomProduct from "./CustomProduct";
const CustomProducts = ({
  products,
  onDelete,
  onToggle,
  isDelete,
  getQty,
  disableQty,
  isRefund,
  isEdit,
  refundedItems,
}) => {
  console.log({ refundedItems });
  return (
    <>
      {products.map((product, index) => (
        <CustomProduct
          key={index}
          product={product}
          onDelete={onDelete}
          onToggle={onToggle}
          isDelete={isDelete}
          getQty={getQty}
          disableQty={disableQty}
          isRefund={isRefund}
          isEdit={isEdit}
          refundedItems={refundedItems?.filter(
            (item) => product.productId === item.productId
          )}
        />
      ))}
    </>
  );
};

export default CustomProducts;
