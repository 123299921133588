import React from 'react';
import PageTitle from './PageTitle';
import { Grid, TextField, Button } from '@material-ui/core';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import '../ProductMobileComponents/AddProduct-Inventory.css';

const AddCustomerAddress = ({ prevStep, handleChange, values, handlePost }) => {
    const postObject = e => {
        e.preventDefault();
        // nextStep();
        handlePost();
    }
    const Previous = e => {
        e.preventDefault();
        prevStep();
    }
    console.log(values);
    return (
        <>
            <div>
                <PageTitle textType="h6" value="ADD ADDRESS" Icon={<ArrowBackIosNewIcon />} ChangeRoute={prevStep} />
            </div>
            <div className="label spacing">
                <Grid container spacing={2}>

                    {/* price */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter Street"
                            label="Street"
                            onChange={handleChange('street')}
                            defaultValue={values.street}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter City"
                            label="City"
                            onChange={handleChange('city')}
                            defaultValue={values.city}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter State"
                            label="State"
                            onChange={handleChange('stateOrProvince')}
                            defaultValue={values.stateOrProvince}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter Country"
                            label="Country"
                            onChange={handleChange('country')}
                            defaultValue={values.country}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Enter Postal Code"
                            label="Postal Code"
                            onChange={handleChange('postCode')}
                            defaultValue={values.postCode}
                            type="number"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="label">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Previous}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={postObject}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
};

export default AddCustomerAddress;