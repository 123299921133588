import React from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";

import SearchBar from "./SearchBar";
import Tabs from "./Tabs";
import CustomerList from "./CustomerList";
import PageTitle from "../Title-Main";
import { SKY_BLUE, WHITE } from "../../colors/colors";
import "./Product.css";
import "../Title.css";

class Customer extends React.Component {
  state = {
    Customers: [],
    filteredCustomers: [],
    searchedCustomers: [],
    error: null,
  };
  componentDidMount() {
    this.setState({
      Customers: this.props.data,
      filteredCustomers: this.props.data,
    });
  }

  setFilteredCustomers = (newFilteredCustomers) => {
    console.log(
      `size of filtered Customers in customer component is ${newFilteredCustomers.length}`
    );
    this.setState({ filteredCustomers: newFilteredCustomers });
  };
  ChangeRoute = () => {
    const history = this.props.history;
    history.push("/");
  };

  render() {
    const { Customers, filteredCustomers } = this.state;
    return (
      <>
        <div>
          <PageTitle
            textType="h6"
            value="CUSTOMERS"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.ChangeRoute}
          />
        </div>
        <div className="align-right">
          <IconButton
            component={Link}
            to={"/customer"}
            onClick={() => console.log("add customer is clicked")}
          >
            <PersonAddIcon fontSize="large" sx={{ color: SKY_BLUE }} />
          </IconButton>
        </div>
        <div>
          <SearchBar
            barvalue="Search Customers"
            data={Customers}
            onChange={this.setFilteredCustomers}
            onSubmit={this.setFilteredCustomers}
          />
        </div>
        <div>
          <Tabs
            Item1="Active"
            Item2="Archived"
            Item3="New"
            Item4="Returning"
            data={Customers}
            onClick={this.setFilteredCustomers}
          />
        </div>
        <div style={{ paddingBottom: "50px" }}>
          <CustomerList customers={filteredCustomers} />
        </div>
      </>
    );
  }
}
export default Customer;
