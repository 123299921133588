import React, { Component } from "react";
import { Box, Button, OutlinedInput } from "@material-ui/core";
import Select from "react-select";
import CountryData from "react-select-country-list";

export default class ShippingAddressModal extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.options = CountryData().getData();
    console.log(this.options)
    
    console.log('address' in this.props)
    if('address' in this.props){
      console.log(this.props)
      if(this.props.address!=undefined){
        let a = this.options.filter((c) => c.label.toLowerCase().includes(this.props.address.country.toLowerCase()));
        console.log(a)
        let countryName = this.props.address.country;
        console.log(countryName)
      }
    }
    
    this.state = {
      options: this.options,
      address: this.props.address!=undefined && this.props.address.country ? this.props.address.street+" "+this.props.address.city+" "+this.props.address.stateOrProvince+" "+this.props.address.postal_code+" "+this.props.address.country : "",
      stateOrProvince: this.props.address!=undefined && this.props.address.stateOrProvince ? this.props.address.stateOrProvince : "",
      city: this.props.address!=undefined && this.props.address.city ? this.props.address.city : "",
      country: this.props.address!=undefined && this.props.address.country ? this.options.filter((c) => c.label.toLowerCase().includes(this.props.address.country.toLowerCase())) : "Pakistan",
      postal_code: this.props.address!=undefined && this.props.address.postCode ? this.props.address.postCode : "",
      phone: "",
    };
    console.log(this.state)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      address: this.state.address,
      stateOrProvince: this.state.stateOrProvince,
      city: this.state.city,
      country: this.state.country,
      postal_code: this.state.postal_code,
    };
    this.props.updateShippingAddress(data);
  };
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    console.log(nam + " " + val);
  };
  changeHandler = (value) => {
    console.log(value.label);
    this.setState({
      country: value.label,
    });
  };
  render() {
    return (
      <div onSubmit={(e)=>this.handleSubmit(e)}>
        <Box
          display="flex"
          flexDirection="row"
          bgcolor="white"
          margin="10px 0px 0px 10px"
        >
     
        </Box>
        <Box margin="0px 0px 10px 10px">
          <Box>
          
          </Box>
          <Box margin="10px 0px 0px 0px">
            <Box>Address</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40 }}
              name="address"
              type="text"
              value={this.state.address}
              onChange={this.handleChange}
            />
          </Box>
          <Box margin="10px 0px 0px 0px">
            <Box>State or Province</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40 }}
              name="stateOrProvince"
              type="text"
              value={this.state.stateOrProvince}
              onChange={this.handleChange}
            />
          </Box>
          <Box margin="10px 0px 0px 0px">
            <Box>City</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40 }}
              name="city"
              type="text"
              value={this.state.city}
              onChange={this.handleChange}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            bgcolor="white"
            margin="10px 0px 0px 0px"
          >
            <Box margin="0px 0px 0px 0px" width="45%">
              <Box>Country/Region</Box>
              <Select
                //width="100%"
                //name="country"
                // value={this.state.country[0].value}
                options={this.state.options}
                defaultValue={{ label: this.state.country[0].label, value: this.state.country[0].value }}
                onChange={this.changeHandler}
                isSearchable={true}
              />
            </Box>
            <Box margin="0px 0px 0px 0px">
              <Box marginLeft="50px">Postal Code</Box>
              <OutlinedInput
                style={{ width: "90%", height: "50%", marginLeft: "50px" }}
                name="postal_code"
                type="number"
                value={this.state.postal_code}
                onChange={this.handleChange}
              />
            </Box>
          </Box>

          <Box margin="0px 0px 0px 0px">
            
          </Box>

          {<hr />}
          <Button
            style={{
              textTransform: "none",
              float: "right",
            }}
            onClick={this.handleSubmit}
            variant="contained"
            color="dark"
          >
            Save
          </Button>
        
        </Box>
      </div>
    );
  }
}
