const initial_state = {
  purchaseorders: [],
};

const purchaseOrderReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_PURCHASE_ORDERS":
      console.log(action);
      return Object.assign({}, state, {
        purchaseorders: action.purchaseorders,
      });
    case "CLEAR_PURCHASE_ORDER_DATA":
      return initial_state;
    default:
      return state;
  }
};
export default purchaseOrderReducer;
