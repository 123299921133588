import React, { Component } from "react";
import GlobalConfig from "../../Config";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import { DataGrid } from "@material-ui/data-grid";
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid } from "@mui/x-data-grid";
import Box from "@material-ui/core/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import { Typography } from "@mui/material";
import { grey } from "@material-ui/core/colors";
import { QrCode } from "@mui/icons-material";
import helpers from "../../UtilitiesFunctions";
const useStyles = (theme) => ({
  dataGrid: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    //color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    width: "640px",
  },
});

class PurchaseProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      previousProducts: [],
      selectedRows: [],
      loader: true,
      variant: 0,
      btnToggle: true,
      addBtn: false,
      // addBtn: true,
      query: "",
      modelRowsIds: [],
      newSelectedRows: [],
      rows: [],
      productdetailrow: "",
      productids: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  populateRows = (products) => {
    const rows = [];
    console.log("products", products);
    for (var i = 0; i < products.length; i++) {
      console.log("obj[i])", products[i]);
      if (products[i]?.varients?.length > 0) {
        console.log("PUSHING VARIENT");
        const filteredList = products[i]?.varients?.filter((item) => {
          if (item?.status === "Active") return item;
        });
        filteredList?.map((varient, index) => {
          var specificationName = " (";
          varient?.productSpecCharacteristics?.map((productspec, index) => {
            //specificationName += " -";
            productspec?.productSpecCharValues?.map(
              (productSpecCharValues, index) => {
                console.log(
                  "here is a product specificationvalues",
                  productSpecCharValues
                );
                specificationName +=
                  productSpecCharValues?.value +
                  (index + 1 == productspec?.productSpecCharValues?.length &&
                  productspec?.productSpecCharValues?.length > 1
                    ? ", "
                    : "");
                console.log("here is varient product", specificationName);
              }
            );
            specificationName +=
              index + 1 != varient?.productSpecCharacteristics?.length &&
              varient?.productSpecCharacteristics?.length > 1
                ? " - "
                : "";
          });
          specificationName += ")";
          rows.push({
            id: varient.POID,
            productId: varient.POID,
            productName: varient.name + specificationName,
            price: varient.price,
            quantity: 1,
            fulfilled: false,
            tax: varient.taxRate,
            unitOfMeasures: varient.unitsOfMeasures,
            action: "add",
            costPrice: varient.costPrice,
          });
        });
      } else {
        rows.push({
          id: products[i].POID,
          productId: products[i].POID,
          productName: products[i].name,
          price: products[i].price,
          quantity: 1,
          fulfilled: false,
          tax: products[i].taxRate,
          unitOfMeasures: products[i].unitsOfMeasures,
          action: "add",
          costPrice: products[i].costPrice,
        });
      }
    }

    return rows;
  };

  componentDidMount() {
    console.log(
      "here is purchase orders from table",
      this.props.g_purchaseorders
    );

    if (this.props.g_active_products?.length === 0) {
      let url = this.props.global_config.basePath + "/products?status=Active";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then(async (result) => {
          let data = await result?.json();

          if (data.length) {
            this.setState({
              products: data?.filter((product) => product.quantity > 0),
              rows: this.populateRows(
                data?.filter((product) => product.quantity > 0)
              ),
              previousProducts: data?.filter((product) => product.quantity > 0),
              loader: false,
            });

            Store.dispatch({
              type: "GET_ACTIVE_PRODUCTS",
              active_products: data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            products: [],
            previousProducts: [],
            loader: false,
          });
        });
    } else {
      this.setState({
        products: this.props.g_active_products?.filter(
          (product) => product.quantity > 0
        ),
        rows: this.populateRows(
          this.props.g_active_products?.filter(
            (product) => product.quantity > 0
          )
        ),
        previousProducts: this.props.g_active_products?.filter(
          (product) => product.quantity > 0
        ),
        loader: false,
      });
    }
  }
  scanBarCodeAndAddToCart = (barCode) => {
    const foundProducts = this.state?.products?.filter(
      (product) =>
        product?.barcodes?.filter(
          (barCodeValue) => barCodeValue?.code === barCode
        )?.length > 0
    );

    const foundProductIds = foundProducts.map((product) => product.id);

    console.log(this.state?.rows, { foundProducts: foundProducts });
    if (foundProducts.length === 1) {
      this.setState({
        modelRowsIds: [
          ...new Set([...foundProductIds, ...this.state.modelRowsIds]),
        ],
      });
    } else if (foundProducts.length > 1) {
      helpers.toastNotify(
        "error",
        "More than one products with same barcode found. Consider selecting manually."
      );
    } else if (foundProducts.length === 0) {
      helpers.toastNotify("error", "No products found with given barcode.");
    }
  };

  handleSubmit = async (event) => {
    // Loop through each product and create purchaseOrderItems format
    if (this.state.productids === "") {
      helpers.toastNotify("error", "Enter Valid P.O");
      return 0;
    }
    let urll =
      this.props.global_config.basePath +
      "/PurchaseOrderManagement/applyPurchaseOrderItems";
    fetch(urll, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(this.state.productids),
    })
      .then(async (response) => {
        const data = await response.json();
        helpers.toastNotify("success", "Submitted successfully");
      })
      .catch((error) => {
        helpers.toastNotify("error", "something went wrong");
      });

    this.props.handleClose("A");
  };

  //CODE BY SAM
  newHandleSubmit = () => {
    let oldSelectedProducts = this.state.selectedRows; //products which are added after pressing add
    let newSelectedProducts = this.state.newSelectedRows;

    oldSelectedProducts = oldSelectedProducts.concat(newSelectedProducts);

    console.log(oldSelectedProducts);

    this.props.updateParent(oldSelectedProducts, "dbItem", this.state.products);

    this.props.handleClose("A");
  };

  onSelectRow = (rowData) => {
    console.log("rowData", rowData);
    if (this.state.newSelectedRows.length) {
      if (rowData.isSelected) {
        this.state.newSelectedRows = this.state.newSelectedRows.concat(
          rowData.data
        );
      } else {
        this.state.newSelectedRows = this.state.newSelectedRows.filter(
          (existingData) => existingData.id != rowData.data.id
        );
      }
    } else {
      if (rowData.isSelected) {
        this.state.newSelectedRows = this.state.newSelectedRows.concat(
          rowData.data
        );
      }
    }

    console.log(this.state.newSelectedRows);
  };

  onSelectProduct = (selectedProductRowsId) => {
    console.log({ selectedProductRowsId: selectedProductRowsId });
    this.setState({
      modelRowsIds: selectedProductRowsId,
    });
  };

  onDelete = (e) => {
    const id = e.productId;
    this.setState({
      selectedRows: this.state.selectedRows.filter(
        (item) => item.productId !== id
      ),
    });
  };
  handleOnSearch = (e) => {
    console.log("in handle change");
    console.log(e.target.value);
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: this.state.previousProducts,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_active_products,
        e.target.value
      );

      console.log("here is filtered list", filteredList);

      this.setState({
        backdrop: false,
        products: filteredList,
        rows: this.populateRows(filteredList),
      });
    }
  };
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  cancelSearch = () => {
    this.setState({ query: "", products: this.state.previousProducts });
  };
  handleSearchByName = (key, value) => {
    const filteredList = this.dynamicSearch(this.props.g_inventoryItems, value);
    if (filteredList.length !== 0) {
      this.setState({
        products: filteredList,
      });
    }
  };
  searchProductBackend = (value) => {
    if (value === "") {
      helpers.toastNotify("error", "enter valid P.O");
      return 0;
    }

    if (this.props.g_purchaseorders.length === 0) {
      helpers.toastNotify("warning", "load the purchase orders");
      return 0;
    }

    const purchasproducts = this.props.g_purchaseorders.filter(
      (purchaseorder) => purchaseorder.POID === value
    );

    if (purchasproducts.length === 0) {
      helpers.toastNotify("error", "No purchase orders found");
      return 0;
    }

    let itemDetails = purchasproducts[0].purchaseOrderItems.map((item) => {
      let product = this.props.g_active_products.find(
        (prod) => prod.id === item.Product_Id
      );
      return {
        purchaseOrderItemId: item.id,
        productName: product ? product.name : "Product Not Found",
        productId: item.POID,
        status: item.status,
      };
    });

    console.log("here is item details", itemDetails);

    this.setState({ productdetailrow: itemDetails });
  };
  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 150 },
      { field: "productname", headerName: "Product Name", width: 150 },
      //{ field: "qty", headerName: "", width: 130 },
      {
        field: "status",
        headerName: "Status",
        width: 150,
      },
    ];

    var obj = this.state.productdetailrow;
    var rows = [];
    for (var i = 0; i < obj.length; i++) {
      rows[i] = {
        id: obj[i]?.productId || i,

        productname: obj[i].productName,
        status: obj[i].status,
        // discountamount: obj[i].discountAmount,
        // payable: obj[i].payableAmount,
        // payment: obj[i].paid ? "Paid" : "Unpaid",
        // fulfillment: obj[i].fulfilled ? "Fulfilled" : "Unfulfilled",
        // items: obj[i].lineItems.length + " item/s",
        // deliverymethod: "COD",
      };
    }

    return (
      <div>
        <Box style={{ margin: "10px" }}>
          <TextField
            fullWidth={true}
            // defaultValue={this.state.query}
            name="query"
            onChange={(e) => this.handleOnSearch(e)}
            id="outlined-basic"
            placeholder="Search Purchase Order"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ cursor: "pointer" }}>
                  {/* <IconButton> */}
                  <SearchTwoToneIcon
                    onClick={() => this.searchProductBackend(this.state.query)}
                  />

                  {/* </IconButton> */}
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <div style={{ width: "100%" }}>
          {this.state.productdetailrow?.length > 0 && (
            <DataGrid
              getRowHeight={() => "auto"}
              sx={{
                [`& .MuiDataGrid-cell .MuiDataGrid-cellContent`]: {
                  whiteSpace: "normal",
                  overflow: "wrap",
                },
              }}
              style={{
                position: "relative",
                marginBottom: "40px",
                marginTop: "20px",
              }}
              onSelectionModelChange={(selectedRowArrIndex) => {
                var productids = [];
                let convertedArray = selectedRowArrIndex.map((item) => {
                  return productids.push({ id: item });
                });

                console.log("here is all product ids", productids);

                this.setState({ productids: productids });

                this.onSelectProduct(selectedRowArrIndex);
              }}
              checkboxSelection
              autoHeight={true}
              loading={this.state.loader}
              scrollbarSize={1}
              rows={rows}
              columns={columns}
              selectionModel={this.state.modelRowsIds}
            />
          )}

          {this.state.productdetailrow?.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "200px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              <h5>No Purchase Order Products found</h5>
            </div>
          )}
        </div>
        <Box
          display="flex"
          justifyContent={"flex-end"}
          p={1}
          style={{
            background: "white",
            position: "absolute",
            width: "100%",
            bottom: "0",
            left: "0",
          }}
        >
          {/* <Box p={1}>
            <Link to="/ProductForm">
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                style={{}}
              >
                Create a new product
              </Button>
            </Link>
          </Box> */}
          <Box p={1}>
            <Button
              style={{ backgroundColor: SKY_BLUE }}
              variant="outlined"
              onClick={this.handleSubmit}
              disabled={this.state.addBtn}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_active_products: state.productsState.active_products,
    global_config: state.globalState,
    g_purchaseorders: state.purchaseOrderState.purchaseorders,
  };
};
export default connect(mapStateToProps, null)(PurchaseProductsTable);
