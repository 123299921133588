import { Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LIGHT_GREY, SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
import { addCommasToNumber } from "../../utilities/CommaNumberFormatter";

export function DotMatrixViewRefund(props) {
  console.log(`Order Porps`, props);

  const contentToPrint = useRef(null);
  const history = useHistory();
  const [orderObject, updateOrderObject] = useState(props?.orderObject);
  const [refundObject, updateRefundObject] = useState(props?.refundObject);
  const [customerObject, updateCustomerObject] = useState(
    props?.customerObject
  );
  const [receiptMetadata, updateReceiptMetadata] = useState(
    props?.receiptMetadata
  );

  const [returnedLineItems, updateReturnedLineItems] = useState([]);

  const printDiv = () => {
    const content = contentToPrint.current.outerHTML;
    const contentHeight = content.offsetHeight - 500;
    const printWindow = window.open(
      "",
      `Receipt # ${orderObject?.orderId}`,
      `height=${3267},width=600`
    );

    console.log("here is order object: " + props.orderObject);

    if (!printWindow) {
      alert(
        "Please enable popups first; then click PRINT to print the receipt."
      );
      return;
    }

    printWindow.document.write(
      `<style>@media print { @page {size: 76mm 3276mm; margin: 0; } }</style>`
    );

    printWindow.document.write(
      `<html><head><title>Refund Receipt # ${orderObject?.orderId}</title>`
    );
    printWindow.document.write(
      `<style>body { width: 65mm; height: 3276mm; padding: 0mm; }</style>`
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write(content);
    printWindow.document.write("</body></html>");
    printWindow.document.close();

    printWindow.addEventListener("afterprint", () => {
      printWindow.close();
    });
    printWindow.onbeforeunload = function () {
      props?.onFinish();
    };

    printWindow.print();
  };

  useEffect(() => {
    console.log(`Order Porps`, orderObject, props?.orderObject, props);
    function printLoop() {
      if (contentToPrint.current) {
        console.log("Ref has current value:", contentToPrint.current);
        printDiv();
      } else {
        setTimeout(printLoop, 500);
      }
    }
    printLoop();
  }, [contentToPrint]);

  useEffect(() => {
    var updatedItems = [];
    console.log({ refundObject: refundObject });
    for (var lineItem of refundObject?.lineItems) {
      var orderItem = orderObject.lineItems.filter(
        (item) => item.productId === lineItem.productId && !item.returned
      )[0];
      orderItem.quantity = lineItem.quantity;

      orderItem.total =
        (parseFloat(lineItem?.tax) / 100) * parseFloat(lineItem?.price) +
        parseFloat(lineItem?.price) * parseFloat(lineItem?.quantity);
      updatedItems.push(orderItem);
    }
    updateReturnedLineItems(updatedItems);
  }, [orderObject]);

  const styles = {
    th: { margin: 0, padding: 0, textAlign: "center" },
    td: { margin: 0, padding: 0, textAlign: "center" },
    tdLeft: {
      margin: 0,
      padding: 0,
      paddingTop: 2,
      paddingBottom: 2,
      textAlign: "left",
    },
    tdRight: { margin: 0, padding: 0, textAlign: "right" },
  };

  return (
    <Dialog open={true} keepMounted onClose={false}>
      <div
        id="print"
        ref={contentToPrint}
        style={{
          width: "55mm",
          border: "1px solid black",
          fontFamily: "sans-serif",
          fontSize: "12px",
          // padding: "1px",
        }}
      >
        <center>
          {!receiptMetadata ||
            (Object.keys(receiptMetadata).length === 0 && (
              <>
                <img
                  src="/logo1.png"
                  alt="LOGO"
                  style={{ width: "50%", marginTop: "10px" }}
                />
                <p>
                  <b>Hulm Solutions POS</b> <br />
                  support@hulmsolutions.com
                </p>
              </>
            ))}
          {receiptMetadata && Object.keys(receiptMetadata).length > 0 && (
            <>
              <img
                src={
                  receiptMetadata?.logoUrl
                    ? receiptMetadata?.logoUrl
                    : "/logo1.png"
                }
                alt="LOGO"
                style={{ width: "50%", marginTop: "10px" }}
              />
              <p>
                <b>{receiptMetadata?.businessName}</b>
                <br />
                {receiptMetadata?.businessAddress}
                <br />
                {receiptMetadata?.phoneNumber} <br />
                {receiptMetadata?.emailAddress}
              </p>
            </>
          )}
          <p style={{ padding: 0, margin: 0 }}>
            <hr />
            {helpers.convertTime(orderObject?.createdAt)}
            <br />
            Order # {orderObject?.orderId}
          </p>
          <p>
            <hr style={{ padding: 0, margin: 0 }} />
            <b>REFUND RECEIPT</b>
            <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
          </p>
        </center>
        <div>
          <table style={{ width: "100%", fontSize: "12px" }}>
            <tr>
              <th style={{ ...styles.th, ...{ width: "20%" } }}>Qty</th>
              {/* <th style={{ ...styles.th, ...{ widh: "45%" } }}>Item</th> */}
              <th style={{ ...styles.th, ...{ width: "30%" } }}>Price</th>
              <th style={{ ...styles.th, ...{ width: "20%" } }}>Tax(%)</th>
              <th style={{ ...styles.th, ...{ width: "30%" } }}>Total</th>
            </tr>

            {returnedLineItems?.map((object, index) => {
              return [
                <tr style={{ height: 5 }}></tr>,

                <tr>
                  <td
                    rowSpan={2}
                    style={{
                      ...styles.td,
                      ...{},
                    }}
                  >
                    {object?.quantity}
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      ...styles.td,
                      ...{
                        wordBreak: "break-all",
                        borderBottom: "0.1px solid black",
                      },
                    }}
                  >
                    <span
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        maxHeight: "3em", // Adjust based on your line height
                      }}
                    >
                      {object?.productName}
                    </span>
                  </td>
                </tr>,
                <tr>
                  {/* <td style={{ ...styles.td, ...{} }}>{object?.quantity}</td>
                  <td
                    style={{ ...styles.tdLeft, ...{ wordBreak: "break-all" } }}
                  >
                    {object?.productName}
                  </td> */}

                  <td style={{ ...styles.td, ...{} }}>
                    {addCommasToNumber(object?.price)}
                  </td>
                  <td style={{ ...styles.td, ...{} }}>
                    {addCommasToNumber(object?.tax)}
                  </td>
                  <td style={{ ...styles.tdRight, ...{} }}>
                    {addCommasToNumber(object?.total)}
                  </td>
                </tr>,
              ];
            })}

            <tr>
              <td colSpan={5} style={{ ...styles.td, ...{ textAlign: "end" } }}>
                <hr style={{ padding: 0, marginBottom: 5, margin: 0 }} />
                <p style={{ paddingBottom: 5 }}>
                  <u>
                    <b>Order Summary</b>
                  </u>
                </p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
              >
                Price Sum:
              </td>

              <td colSpan={2} style={{ ...styles.tdRight, ...{} }}>
                <b>{addCommasToNumber(orderObject?.subTotal)}</b>
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
              >
                Total Tax:
              </td>

              <td colSpan={2} style={{ ...styles.tdRight, ...{} }}>
                <b>{addCommasToNumber(orderObject?.taxAmount)}</b>
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{
                  ...styles.tdRight,
                  ...{ paddingRight: 3, paddingBottom: 10 },
                }}
              >
                Total Amount:
              </td>

              <td
                colSpan={2}
                style={{ ...styles.tdRight, ...{ paddingBottom: 10 } }}
              >
                <b>{addCommasToNumber(orderObject?.total)}</b>
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
              >
                Discount Amount:
              </td>

              <td colSpan={2} style={{ ...styles.tdRight, ...{} }}>
                <b>{addCommasToNumber(orderObject?.discountAmount)}</b>
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
              >
                <b>Paid Amount:</b>
              </td>
              <td colSpan={2} style={{ ...styles.tdRight, ...{} }}>
                <b>{addCommasToNumber(orderObject?.payableAmount)}</b>
              </td>
            </tr>

            <tr>
              <td colSpan={5} style={{ ...styles.td, ...{ textAlign: "end" } }}>
                <hr style={{ padding: 0, marginBottom: 5, margin: 0 }} />
                <p style={{ paddingBottom: 5 }}>
                  <u>
                    <b>Refund Summary</b>
                  </u>
                </p>
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
              >
                Price Sum:
              </td>

              <td colSpan={2} style={{ ...styles.tdRight, ...{} }}>
                <b>{addCommasToNumber(refundObject?.subTotalAmount)}</b>
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
              >
                Tax Amount:
              </td>

              <td colSpan={2} style={{ ...styles.tdRight, ...{} }}>
                <b>{addCommasToNumber(refundObject?.taxAmount)}</b>
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{
                  ...styles.tdRight,
                  ...{ paddingBottom: 10, paddingRight: 3 },
                }}
              >
                Total Amount:
              </td>

              <td
                colSpan={2}
                style={{
                  ...styles.tdRight,
                  ...{ paddingBottom: 10 },
                }}
              >
                <b>{addCommasToNumber(refundObject?.totalAmount)}</b>
              </td>
            </tr>
            {refundObject?.discountAdjustment > 0 && (
              <tr>
                <td
                  colSpan={3}
                  style={{
                    ...styles.tdRight,
                    ...{ paddingRight: 3 },
                  }}
                >
                  Discount Adjustment:
                </td>

                <td
                  colSpan={2}
                  style={{
                    ...styles.tdRight,
                  }}
                >
                  <b>{addCommasToNumber(refundObject?.discountAdjustment)}</b>
                </td>
              </tr>
            )}
            <tr>
              <td
                colSpan={3}
                style={{
                  ...styles.tdRight,
                  ...{ paddingRight: 3 },
                }}
              >
                <b>Refundable Amount:</b>
              </td>

              <td
                colSpan={2}
                style={{
                  ...styles.tdRight,
                }}
              >
                <b>{addCommasToNumber(refundObject?.refundableAmount)}</b>
              </td>
            </tr>
          </table>

          {customerObject && customerObject !== "" && (
            <>
              <center>
                <p>
                  <hr style={{ padding: 0, marginBottom: 5, margin: 0 }} />
                  <b>Customer Information</b>
                  <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
                </p>
              </center>
              <table
                style={{ textAlign: "left", width: "100%", fontSize: "12px" }}
              >
                <tr>
                  <td style={{ ...styles.tdLeft, ...{ width: "20%" } }}>
                    Name
                  </td>
                  <td style={{ ...styles.tdLeft, ...{ width: "5%" } }}>:</td>
                  <td style={{ ...styles.tdLeft, ...{ width: "75%" } }}>
                    {customerObject?.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ ...styles.tdLeft, ...{ width: "20%" } }}>
                    Contact
                  </td>
                  <td style={{ ...styles.tdLeft, ...{ width: "5%" } }}>:</td>
                  <td style={{ ...styles.tdLeft, ...{ width: "75%" } }}>
                    {customerObject?.phoneNumber}
                  </td>
                </tr>
                <tr>
                  <td style={{ ...styles.tdLeft, ...{ width: "20%" } }}>
                    Email
                  </td>
                  <td style={{ ...styles.tdLeft, ...{ width: "5%" } }}>:</td>
                  <td style={{ ...styles.tdLeft, ...{ width: "75%" } }}>
                    {customerObject?.email}
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>

        <center>
          <p>
            <hr
              style={{ padding: 0, marginBottom: 5, margin: 0, marginTop: 10 }}
            />
            <b>Refund Instructions</b>
            <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
          </p>
        </center>
        <ol
          style={{
            fontSize: "10px",
            paddingRight: "1mm",
            paddingLeft: "3.5mm",
            textAlign: "justify",
          }}
        >
          <li>
            <strong>Eligibility:</strong> We accept returns for items that meet
            our return policy criteria. Please refer to our policy for details.
          </li>

          <li>
            <strong>Timeframe:</strong> Requests for refunds must be made within
            7 days of your original purchase.
          </li>

          <li>
            <strong>Proof of Purchase:</strong> Please have your receipt or
            order confirmation handy when requesting a refund.
          </li>

          <li>
            <strong>Item Condition:</strong> To qualify for a refund, items must
            be unused, undamaged, and in their original packaging.
          </li>

          <li>
            <strong>Refund Method:</strong> Your refund will be issued in the
            same form of payment used for the original purchase.
          </li>

          <li>
            <strong>Need Help?:</strong> If you have any questions or need
            assistance, don't hesitate to reach out to our friendly customer
            service team at [phone number/email address].
          </li>
        </ol>

        <hr style={{ padding: 0, marginBottom: 5, margin: 0, marginTop: 10 }} />
        <center>
          <Barcode
            value={orderObject?.orderId}
            height={50}
            width={2}
            fontSize={15}
          />
          <p style={{ fontSize: 20, padding: 0, margin: 0 }}>Thank You!</p>

          <p
            style={{
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            ------------------------------------- <br /> Powered By:{" "}
            <b>Hulm Solutions</b> <br />
            sales@hulmsolutions.com
          </p>
        </center>
      </div>
      <center>
        <div
          style={{
            margin: 10,
            justifyContent: "space-around",
            display: "flex",
          }}
        >
          <button
            className="stripe-button"
            style={{
              // marginLeft: "3%",
              backgroundColor: SKY_BLUE,
            }}
            onClick={() => {
              printDiv();
              //handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </button>

          <button
            className="stripe-button"
            style={{
              // marginLeft: "3%",
              backgroundColor: LIGHT_GREY,
            }}
            onClick={() => {
              props?.onFinish();
              //handlePrint(null, () => contentToPrint.current);
            }}
          >
            Close
          </button>
        </div>
      </center>
    </Dialog>
  );
}
