import React, { Component } from "react";
import { DataGrid } from "@material-ui/data-grid";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import GlobalConfig from "../../Config";
import { connect } from "react-redux";

class CustomersAllOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      productId: this.props.location.state,
      CustomersAllOrders: [],
    };
  }

  componentDidMount() {
    fetch(
      this.props.global_config.basePath +
        `/customers/${this.state.productId}/orders`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        // convert the React state to JSON and send it as the POST body
      }
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          loading: false,
          CustomersAllOrders: data,
        })
      );
  }

  dates = (dates) => {
    // var dates="2021.06.30.10.17";
    const formatedDates = dates.split(".");
    let year = formatedDates[0];
    let month = formatedDates[1];
    let day = formatedDates[2];
    let hours = formatedDates[3];
    let minutes = formatedDates[4];
    let seconds = formatedDates[5];
    console.log("month" + formatedDates[1]);
    console.log("day" + formatedDates[2]);
    console.log("hours" + formatedDates[3]);
    console.log("hours" + formatedDates[4]);
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };
  render() {
    const columns = [
      {
        field: "id",
        header: "ID",
        width: 70,
      },
      {
        field: "orderId",
        headerName: "Order Id",
        width: 110,
        editable: true,
      },
      {
        field: "items",
        headerName: "Number Of Items",
        width: 170,
        editable: true,
      },
      {
        field: "status",
        headerName: "Order Status",
        type: "number",
        width: 150,
        editable: true,
      },
      {
        field: "total",
        headerName: "Total Amount",
        type: "number",
        width: 150,
      },
      {
        field: "date",
        headerName: "Date",
        width: 200,
        editable: true,
      },
    ];

    const rows = [];
    const obj = this.state.CustomersAllOrders;

    for (var i = 1; i < obj.length; i++) {
      rows[i] = {
        id: i,
        orderId: obj[i].orderId,
        items: obj[i].lineItems.length,
        status: obj[i].status,
        total: obj[i].total,
        date: this.dates(obj[i].createdAt),
      };
    }

    console.log(this.state.CustomersAllOrders);
    return (
      <>
        {this.state.loading ? (
          <>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          </>
        ) : (
          <>
            <div style={{ marginBottom: "30px" }}>
              <h5>
                <ArrowBackIosIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => this.props.history.push("/Products")}
                />
                Customers Orders
              </h5>
            </div>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                hideFooter
                autoHeight
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection={false}
                disableSelectionOnClick
              />
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(CustomersAllOrders);
