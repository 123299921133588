import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Popup from "../Popup";
import SearchIcon from "@material-ui/icons/Search";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomProducts from "../ProductsModule/CustomProducts";
import Backdrop from "@material-ui/core/Backdrop";
import BrowseProducts from "../OrderModals/BrowseProducts";
import Box from "@material-ui/core/Box";
import CustomDisocuntProducts from "./CustomDisocuntProducts";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";
import CartList from "../cart/CartList";
import {
  addToCartAction,
  updateCartUnits,
} from "../../Redux/action/cart_actions";
import DiscountCustomerLists from "./DiscountCustomerLists";
import BrowseCustomer from "./BrowseCustomer";
import GlobalConfig from "../../Config";
import Store from "../../index";
import { SKY_BLUE } from "../../colors/colors";
import { connect } from "react-redux";
import helpers from "../../UtilitiesFunctions";

const style = (theme) => ({
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },

  responsiveForm: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      margin: "0px",
    },
  },
  hideOnSmallScreen: {
    width: "32%",
    position: "fixed",
    paddingRight: "3rem",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      padding: "0px",
    },
  },
  parentBox: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  responsive: {
    display: "flex",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  paper: {
    backgroundColor: "white",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },

  routeheader: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
      marginLeft: "10px",
    },
  },

  automateddiscountbottom: {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  bottombuttonmargin: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
    },
  },
});
class DiscountCodesForm extends Component {
  state = {
    checked: false,
    backdrop: false,

    actionType: "discount",
    discountAmount: "",
    applicationMode: "Automatic",
    customerBuys: [],
    customerGets: [],
    discountCode: "discountCode",
    discountPercentage: "",
    minimumQuantityOrAmountToBuy: "",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),

    quantitycustomerGets: 0,
    status: "Active",
    type: "",
    quantityOrAmount: "quantity",
    discountAppliedOn: "productIds",
    eligibleCustomers: [],

    open: false,
    productList: [],
    customerList: [],
    snackbar: false,

    selectedEndDate: new Date(),
    // customerEligibility: "",
    selectedDate: new Date(),
  };
  addToCart = (product) => {
    console.log("order added");
    this.props.addToCartAction(product);
  };
  handleCheckBox = () => {
    this.setState({ checked: !this.state.checked });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAllproducts = () => {
    this.setState({ customerBuys: ["0"] });
  };
  handleAllCustomers = () => {
    this.setState({ eligibleCustomers: ["0"] });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });

    this.setState({ startDate: !this.state.startDate });
    console.log(this.state.startDate);
  };
  handleEndDateChange = (event) => {
    let date = new Date(event.toString());
    let currentDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getDate();
    let newEndDate = currentDate.split("T");
    this.setState({ endDate: newEndDate[0] });
  };

  handleStartDateChange = (event) => {
    let date = new Date(event.toString());
    let currentDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getDate();
    let newStartDate = currentDate.split("T");
    this.setState({ endDate: newStartDate[0] });
  };
  handleSubmit = (e) => {
    this.setState({ backdrop: true });
    e.preventDefault();
    console.log(this.state.quantityOrAmount);
    console.log(this.state);

    const data = {
      actionType: this.state.actionType,
      discountAmount: this.state.discountAmount,
      customerBuys: this.state.customerBuys,
      customerGets: this.state.customerGets,
      discountCode: this.state.discountCode,
      discountPercentage: this.state.discountPercentage,
      startDate: "2021-10-07",
      endDate: "2021-10-07",
      minimumQuantityOrAmountToBuy:
        typeof this.state.minimumQuantityOrAmountToBuy != "number"
          ? this.state.minimumQuantityOrAmountToBuy.length > 0
            ? this.state.minimumQuantityOrAmountToBuy
            : 0
          : 0,
      name: this.state.name,
      quantitycustomerGets: this.state.quantitycustomerGets,
      status: this.state.status,
      type: this.state.type,
      quantityOrAmount: this.state.quantityOrAmount,
      discountAppliedOn: this.state.discountAppliedOn,
      eligibleCustomers: this.state.eligibleCustomers,
      applicationMode: this.state.applicationMode,
    };
    console.log(data);
    if (
      this.state.actionType.length < 0 ||
      typeof this.state.minimumQuantityOrAmountToBuy.length < 1
    ) {
      helpers.toastNotify(
        "error",
        "Validation Error! Please check entered data!"
      );
      this.setState({ backdrop: false });
    } else {
      const data = {
        actionType: this.state.actionType,
        discountAmount: this.state.discountAmount,
        customerBuys: this.state.customerBuys,
        customerGets: this.state.customerGets,
        discountCode: this.state.discountCode,
        discountPercentage: this.state.discountPercentage,
        startDate: "2021-10-07",
        endDate: "2021-10-07",
        minimumQuantityOrAmountToBuy:
          typeof this.state.minimumQuantityOrAmountToBuy != "number"
            ? this.state.minimumQuantityOrAmountToBuy.length > 0
              ? this.state.minimumQuantityOrAmountToBuy
              : 0
            : 0,
        name: this.state.name,
        quantitycustomerGets: this.state.quantitycustomerGets,
        status: this.state.status,
        type: this.state.type,
        quantityOrAmount: this.state.quantityOrAmount,
        discountAppliedOn: this.state.discountAppliedOn,
        eligibleCustomers: this.state.eligibleCustomers,
        applicationMode: this.state.applicationMode,
      };
      console.log(JSON.stringify(data));
      fetch(this.props.global_config.basePath + "/promotions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        // convert the React state to JSON and send it as the POST body
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resp) => {
          fetch(
            this.props.global_config.basePath + "/promotions/" + resp.POID,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
              },
            }
          )
            .then((response2) => response2.json())
            .then((data2) => {
              Store.dispatch({
                type: "POST_DISCOUNTS",
                discounts: data2,
              });
              this.setState({ backdrop: false, snackbar: false });

              helpers.toastNotify("success", "Discount Successfully Created!");
            });

          this.props.history.push("/AutomatedDiscount");
        })
        .catch((e) => {
          this.setState({ backdrop: false });
          console.log(e);
          helpers.toastNotify(
            "error",
            "Cannot create promotion. Please recheck the entered data!"
          );
        });
    }
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    console.log("on close");
  };
  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: Number(event.target.value) });
  };
  handleDiscount = (e) => {
    this.setState({ backdrop: true });
    e.preventDefault();

    const data = {
      actionType: this.state.actionType,
      discountAmount: this.state.discountAmount,
      customerBuys: this.state.customerBuys,
      customerGets: this.state.customerGets,
      discountCode: this.state.discountCode,
      discountPercentage: this.state.discountPercentage,
      endDate: this.state.endDate,
      minimumQuantityOrAmountToBuy: this.state.minimumQuantityOrAmountToBuy,
      name: this.state.name,
      quantitycustomerGets: this.state.quantitycustomerGets,
      startDate: this.state.startDate,
      status: this.state.status,
      type: this.state.type,
      quantityOrAmount: this.state.quantityOrAmount,
      discountAppliedOn: this.state.discountAppliedOn,
      eligibleCustomers: this.state.eligibleCustomers,
      applicationMode: this.state.applicationMode,
    };
    console.log(data);
    fetch(this.props.global_config.basePath + "/promotions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    }).then((response, props) => {
      this.setState({ backdrop: false, snackbar: false });
      helpers.toastNotify("error", "Empty Field Detected!");
    });
  };

  onDelete = (e) => {
    console.log(e);
    var name = null;
    if (e.productId === null) {
      name = e.productName;
      this.setState({
        productList: this.state.productList.filter(
          (item) => item.productName !== name
        ),
        customerBuys: this.state.customerBuys.filter(
          (item) => item !== e.productId
        ),
      });
    } else {
      name = e.productId;
      this.setState({
        productList: this.state.productList.filter(
          (item) => item.productId !== name
        ),
        customerBuys: this.state.customerBuys.filter(
          (item) => item !== e.productId
        ),
      });
    }
  };

  onCustomerDelete = (e) => {
    console.log(e.POID);
    var name = null;
    if (e.POID === null) {
      name = e.name;
      this.setState({
        customerList: this.state.customerList.filter(
          (item) => item.name !== name
        ),
        eligibleCustomers: this.state.eligibleCustomers.filter(
          (item) => item.POID !== e.POID
        ),
      });
    } else {
      name = e.POID;
      this.setState({
        customerList: this.state.customerList.filter(
          (item) => item.POID !== name
        ),
        eligibleCustomers: this.state.eligibleCustomers.filter(
          (item) => item.POID !== e.POID
        ),
      });
    }
  };

  updateCustomerCounter = (data) => {
    console.log(data);
    this.setState({
      customerList: [...data],
      eligibleCustomers: Object.values(data.map((id) => id.POID)),
    });
    console.log("Eligible customers", this.state.eligibleCustomers);
    console.log("customer List", this.state.customerList);
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  updateMyCounter = (data) => {
    console.log(data);
    this.setState({
      productList: [...data],
      customerBuys: Object.values(data.map((id) => id.productId)),
      customerGets: Object.values(data.map((id) => id.productId)),
    });
    console.log("customers Buys", this.state.customerBuys);
  };

  render() {
    const { cart, addToCartAction, updateCartUnits } = this.props;

    const { classes } = this.props;
    const { type } = this.state;
    console.log(this.state.customerList);

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} size={90} color="inherit" />
        </Backdrop>
        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={3000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                color="success"
                severity="success"
                variant="filled"
                onClose={this.closeSnack}
              >
                Automated Discount Created Successfuly
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className={classes.routeheader}>
            <ArrowBackIosIcon
              fontSize="large"
              style={{ cursor: "pointer" }}
              onClick={(props) => this.props.history.push("/AutomatedDiscount")}
            />
            Create automatic discounts
          </h5>

          <div className={classes.responsive}>
            <div className={classes.parentBox}>
              <div
                className={classes.responsiveForm}
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Automatic discount
                  </div>
                  <span
                    style={{
                      backgroundColor: "#49A5AC",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.setState({
                        name: "promo-" + Math.random().toString(36).slice(2),
                      });
                    }}
                  >
                    Generate Code
                  </span>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <TextField
                    name="name"
                    size="small"
                    placeholder="e.g. SUMMERSALE"
                    value={this.state.name}
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{ classes: { input: classes.inputHeight } }}
                  />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <p style={{ color: "#6d7175", fontWeight: "400" }}>
                    Customers will enter this discount code at checkout.
                  </p>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Types
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <RadioGroup aria-label="type" name="type" value={type}>
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={this.handleChange}
                          value="Percentage"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Percentage"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value="Fixed Amount"
                          color="primary"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Fixed Amount"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          value="Buy One Get One"
                          color="primary"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Buy X get Y"
                    />
                  </RadioGroup>
                </div>
              </div>
              {type === "Buy One Get One" && (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer Buys
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="quantityOrAmount"
                      value={this.state.quantityOrAmount}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="quantity"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minumum Quantity of items"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="amount"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum purchase amount "
                      />
                    </RadioGroup>
                  </div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {this.state.quantityOrAmount === "quantity" ? (
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="Quantity"
                          type="number"
                          name="minimumQuantityOrAmountToBuy"
                          onChange={this.handleChange}
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="amount"
                          name="minimumQuantityOrAmountToBuy"
                          onChange={this.handleChange}
                          type="number"
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                    )}
                    <div style={{ width: "63%" }}>
                      <FormControl
                        inputprops={{
                          classes: { input: classes.inputHeight },
                        }}
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Any Items from
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                          label="Any Items from "
                        >
                          <MenuItem value={10}>Specific Products</MenuItem>
                          <MenuItem value={20}>Specific Collections</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "75%", marginTop: "18px" }}>
                      <TextField
                        fullWidth={true}
                        id="filled-search"
                        label="Search Products"
                        // type={this}
                        variant="outlined"
                        onChange={this.handleChange}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Box>
                        <Popup
                          title="Select products"
                          btnVar="contained"
                          btnText="Browse"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <BrowseProducts
                              updateParent={this.updateMyCounter}
                              onClickBtnClose={() => this.handleClose()}
                            />
                          }
                          openPopup={this.state.open}
                          closePop={() => this.handleClose}
                          onClickBtnOpen={() => this.handleOpen()}
                          onClickBtnClose={() => this.handleClose()}
                        />
                      </Box>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Box>
                      <CustomDisocuntProducts
                        products={this.state.productList}
                        onDelete={this.onDelete}
                      />
                    </Box>
                  </div>
                  <hr />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer Gets
                    </div>
                  </div>
                  <p>
                    Customers must add the quantity of items specified below to
                    their cart.
                  </p>

                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <TextField
                        name="quantitycustomerGets"
                        onChange={this.handleChange}
                        id="filled-search"
                        label="Quantity"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                      />
                    </div>
                    <div style={{ width: "63%" }}>
                      <FormControl
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Any Items from
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                          label="Any Items from "
                        >
                          <MenuItem value={10}>Specific Products</MenuItem>
                          <MenuItem value={20}>Specific Collections</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "75%", marginTop: "18px" }}>
                      <TextField
                        onFocus={this.handleOpen}
                        fullWidth={true}
                        onClose={() => this.handleClose}
                        // autoFocus={true}
                        label="Search Products"
                        type="search"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Box>
                        <Popup
                          title="Select products"
                          btnVar="contained"
                          btnText="Browse"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <BrowseProducts
                              updateParent={this.updateMyCounter}
                              onClickBtnClose={() => this.handleClose()}
                            />
                          } //component to send
                          openPopup={this.state.open} //open
                          closePop={() => this.handleClose} //close
                          onClickBtnOpen={() => this.handleOpen()} //on click open
                          onClickBtnClose={() => this.handleClose()} //on click close
                        />
                      </Box>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Box>
                        <CustomDisocuntProducts
                          products={this.state.productList}
                          onDelete={this.onDelete}
                        />
                      </Box>
                    </div>
                  </div>
                  <hr />
                  <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                    <p>AT A DISCOUNT VALUE</p>
                  </div>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value="minqty"
                      control={<Radio color="primary" size="small" />}
                      label="Percentage"
                    />
                    <FormControlLabel
                      value="minamount"
                      control={<Radio color="primary" size="small" />}
                      label="Free "
                    />
                  </RadioGroup>
                  <FormControlLabel
                    value="start"
                    control={<Checkbox color="primary" />}
                    label="Set a maximum number of uses per order"
                    // labelPlacement="start"
                  />
                </div>
              )}

              {type === "Percentage" || type === "Fixed Amount" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Value
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <p style={{ color: "#6d7175", fontWeight: "400" }}>
                      Discount value
                    </p>
                  </div>

                  {type === "Percentage" && (
                    <div>
                      <TextField
                        value={this.state.discountPercentage}
                        variant="outlined"
                        type="number"
                        label="0"
                        name="discountPercentage"
                        onChange={this.onPriceChange}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                  {type === "Fixed Amount" && (
                    <div>
                      <TextField
                        value={this.state.discountAmount}
                        variant="outlined"
                        name="discountAmount"
                        onChange={this.onPriceChange}
                        type="number"
                        label="0"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment position="start">Rs</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}

                  <hr />
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Applies to
                  </div>

                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="discountAppliedOn"
                      value={this.state.discountAppliedOn}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="allproducts"
                            size="small"
                            onChange={this.handleAllproducts}
                          />
                        }
                        label="All Products"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="specificcollection"
                            size="small"
                            onChange={this.handleChange}
                          />
                        }
                        label="Specific collections"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            onChange={this.handleChange}
                            value="specificproducts"
                          />
                        }
                        label="Specific products"
                      />
                      {this.state.discountAppliedOn === "specificcollection" ||
                      this.state.discountAppliedOn === "specificproducts" ? (
                        <>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              label={
                                this.state.discountAppliedOn ===
                                "specificcollection"
                                  ? "Specific Collection"
                                  : "Specific Products"
                              }
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: { input: classes.inputHeight },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <div>
                              <Box>
                                <Popup
                                  title="Select products"
                                  btnVar="contained"
                                  btnText="Browse"
                                  contentTxt={<hr />}
                                  contentTxtBe={<hr />}
                                  content={
                                    <BrowseProducts
                                      updateParent={this.updateMyCounter}
                                      onClickBtnClose={() => this.handleClose()}
                                    />
                                  } //component to send
                                  openPopup={this.state.open} //open
                                  closePop={() => this.handleClose} //close
                                  onClickBtnOpen={() => this.handleOpen()} //on click open
                                  onClickBtnClose={() => this.handleClose()} //on click close
                                />
                              </Box>
                              {/* </Button> */}
                            </div>
                          </div>
                          <div style={{ width: "100%" }}>
                            <Box>
                              <CustomDisocuntProducts
                                products={this.state.productList}
                                onDelete={this.onDelete}
                              />
                            </Box>
                          </div>
                        </>
                      ) : null}
                    </RadioGroup>
                  </div>
                </div>
              ) : (
                ""
              )}
              {type === "Freeshipping" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Countries
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={type}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="allcountries"
                        control={<Radio color="primary" size="small" />}
                        label="All Countries"
                      />
                      <FormControlLabel
                        value="selectedcountries"
                        control={<Radio color="primary" size="small" />}
                        label="Selected Countries"
                      />
                    </RadioGroup>
                    <hr />
                    {/* <FormGroup> */}
                    <p>SHIPPING RATES</p>
                    <FormControlLabel
                      value="start"
                      control={<Checkbox color="primary" />}
                      label="Exclude shipping rates over a certain amount"
                    />
                  </div>
                </div>
              ) : null}
              {type === "Percentage" ||
              type === "Fixed Amount" ||
              type === "Freeshipping" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Minimum Requirements
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      name="quantityOrAmount"
                      value={this.state.quantityOrAmount}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="amount"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum purchase amount (Rs)"
                      />
                      {this.state.quantityOrAmount === "amount" && (
                        <div>
                          <TextField
                            name="minimumQuantityOrAmountToBuy"
                            label="RS 0.00"
                            value={this.state.minimumQuantityOrAmountToBuy}
                            onChange={this.handleChange}
                            variant="outlined"
                            InputProps={{
                              classes: { input: classes.inputHeight },
                            }}
                          />
                          <p
                            style={{
                              margin: "10px 0 10px 7px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Applies to All Products
                          </p>
                        </div>
                      )}
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="quantity"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum quantity of items"
                      />
                      {this.state.quantityOrAmount === "quantity" && (
                        <div>
                          <TextField
                            name="minimumQuantityOrAmountToBuy"
                            value={this.state.minimumQuantityOrAmountToBuy}
                            onChange={this.handleChange}
                            variant="outlined"
                            InputProps={{
                              classes: { input: classes.inputHeight },
                            }}
                          />
                          <p
                            style={{
                              margin: "10px 0 10px 7px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Applies to All Products
                          </p>
                        </div>
                      )}
                    </RadioGroup>
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Customer eligibility
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <RadioGroup
                    aria-label="type"
                    size="small"
                    name="customerEligibility"
                    value={this.state.customerEligibility}
                    onChange={this.handleAllCustomers}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          value="everyone"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Everyone"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          size="small"
                          value="SpecificGroupofCustomer"
                          onChange={this.handleChange}
                        />
                      }
                      label="specific group of customers"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          size="small"
                          value="SpecificCustomer"
                          onChange={this.handleChange}
                        />
                      }
                      label="specific Customers"
                    />
                    {this.state.customerEligibility ===
                    "SpecificGroupofCustomer" ? (
                      <>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              name="SpecificGroupofCustomer"
                              label="Specific group of customers"
                              // value="minpurchaseamount"
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: { input: classes.inputHeight },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Box>
                              <Popup
                                title="Select Customers"
                                btnVar="contained"
                                btnText="Browse"
                                contentTxt={<hr />}
                                contentTxtBe={<hr />}
                                content={
                                  <BrowseProducts
                                    updateParent={this.updateCustomerCounter}
                                    onClickBtnClose={() => this.handleClose()}
                                  />
                                } //component to send
                                openPopup={this.state.open} //open
                                closePop={() => this.handleClose} //close
                                onClickBtnOpen={() => this.handleOpen()} //on click open
                                onClickBtnClose={() => this.handleClose()} //on click close
                              />
                            </Box>{" "}
                          </div>
                          <p
                            style={{
                              margin: "10px 0 0 10px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Select Group of Customers that can use this discount
                            <Link>Learn more</Link>
                          </p>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Box>
                            <CustomDisocuntProducts
                              products={this.state.productList}
                              onDelete={this.onDelete}
                            />
                          </Box>
                        </div>
                      </>
                    ) : null}
                    {this.state.customerEligibility === "SpecificCustomer" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            name="SpecificCustomer"
                            label="Specific Customers"
                            // value="minpurchaseamount"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                              classes: { input: classes.inputHeight },
                              endAdornment: (
                                <InputAdornment>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Box>
                            <Popup
                              title="Selcet  Customers"
                              btnVar="contained"
                              btnText="Browse"
                              contentTxt={<hr />}
                              contentTxtBe={<hr />}
                              content={
                                <BrowseCustomer
                                  updateParent={this.updateCustomerCounter}
                                  onClickBtnClose={() => this.handleClose()}
                                />
                              }
                              openPopup={this.state.open}
                              closePop={() => this.handleClose}
                              onClickBtnOpen={() => this.handleOpen()}
                              onClickBtnClose={() => this.handleClose()} //on click close
                            />
                          </Box>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Box>
                            {/* {this.state.customerBuys.length > 0 ? ( */}
                            <DiscountCustomerLists
                              customers={this.state.customerList}
                              onDelete={this.onCustomerDelete}
                            />
                          </Box>
                        </div>
                      </>
                    ) : null}
                  </RadioGroup>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Active Dates
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <Grid container justify="space-around">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Start Date"
                        format="yyyy/MM/dd"
                        value={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <div style={{ margin: "10px 0px 0px 30px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checked}
                          name="checked"
                          value={this.checked}
                          onChange={this.handleCheckBox}
                        />
                      }
                      label="Set End Date"
                    />
                  </div>

                  {this.state.checked && (
                    <Grid container justify="space-around">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          mt={2}
                          label="End Date"
                          format="yyyy/MM/dd"
                          value={this.state.endDate}
                          onChange={this.handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.hideOnSmallScreen}>
              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "10px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Summary
                </p>
                <p
                  style={{
                    margin: "15px 0 15px 15px",
                    color: "#6d7175",
                  }}
                >
                  {type === "Percentage" && "No information entered yet"}
                  {type === "FixedAmount" && "No information entered yet"}
                  {type === "Buy One Get One:" && "No information entered yet"}
                  {type === "Freeshipping" && (
                    <>
                      <List className={classes.root} subheader={<li />}>
                        {[0, 1, 2].map((sectionId) => (
                          <li
                            key={`section-${sectionId}`}
                            className={classes.listSection}
                            style={{ listStyleType: "circle" }}
                          >
                            <ul className={classes.ul}>
                              <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            </ul>
                          </li>
                        ))}
                      </List>
                    </>
                  )}
                </p>
                <hr></hr>
                <p
                  style={{
                    margin: "15px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  PERFORMANCE
                </p>
                <p style={{ color: "#6d7175", margin: "10px 0 15px 15px" }}>
                  Discount is not active yet.
                </p>
              </div>

              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "10px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  CAN’T COMBINE WITH OTHER AUTOMATIC DISCOUNTS
                </p>
                <p
                  style={{
                    margin: "10px 0 15px 15px",
                    color: "#6d7175",
                  }}
                >
                  Customers won’t be able to enter a code if an automatic
                  discount is already applied at checkout.
                </p>
              </div>
            </div>
          </div>

          <div className={classes.automateddiscountbottom} style={{}}>
            <Button
              variant="contained"
              size="large"
              onClick={() => this.props.history.push("/AutomatedDiscount")}
              style={{ backgroundColor: SKY_BLUE, color: "white" }}
            >
              Discard
            </Button>
            <Button
              className={classes.bottombuttonmargin}
              type="submit"
              variant="contained"
              onClick={() => this.handleSubmit}
              style={{ backgroundColor: SKY_BLUE, color: "white" }}
            >
              Save Automatic Discount Code
            </Button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(DiscountCodesForm));
