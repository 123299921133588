import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};



export default function RecipeReviewCard(props) {

    const { userName, email, role, POID, deleteUser } =
        props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const wrapperFunction = () => {
        deleteUser(POID)
        handleClose()
    }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ fontSize: "15px" }} variant="h6" component="h5">
                        Are you sure you want to delete this user?
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={wrapperFunction} style={{ backgroundColor: "green" }} variant="contained" startIcon={<CheckBoxIcon />}>YES</Button>
                        <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" startIcon={<CancelIcon />}>NO</Button>
                    </div>
                </Box>
            </Modal>

            <Card sx={{
                p: '5px 5px 5px 5px', transition: "0.3s",
                width: 'auto',
                margin: '5px 0px 10px 0px',
                boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
                "&:hover": {
                    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
                },
            }}>
                <CardContent>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex" }}>
                            {role == "Admin" ? <SupervisorAccountIcon style={{ fill: "green" }} />
                                : role == "agent" ? <SupportAgentIcon style={{ fill: "green" }} />
                                    : <AccountCircleIcon style={{ fill: "green" }} />}

                            <Typography align="left" variant="subtitle2">
                               <b> {userName} </b>
                                <Typography align="left" variant="subtitle2">
                                    {email}
                                </Typography>
                                <Typography align="left" variant="subtitle2">
                                    {role}
                                </Typography>
                            </Typography>

                        </div>
                        <div >
                            <DeleteForeverSharpIcon color="error" onClick={handleOpen} />
                        </div>
                    </div>
                </CardContent>

            </Card>
        </div>
    );
}
