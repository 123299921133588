import React from "react";
import { Typography, IconButton } from "@mui/material";
import './PageTitle.css';

const PageTitle = function (props) {

    const { prevStep } = props

    return (
        <div className={`title-bar ${props.border} background`}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginRight: "20%" }}>

                <IconButton onClick={() => prevStep()} sx={{ color: "#ffff" }}>
                    {props.Icon}
                </IconButton>
                <Typography
                    sx={{ margin: "5px 5px 5px 5px", color: "#ffff" }}
                    variant={props.textType}
                    align={props.alignment}
                >
                    {props.value}
                </Typography>
            </div>
        </div>
    );
};
PageTitle.defaultProps = {
    textType: "subtitle2",
    alignment: "left",
    value: "provide some text",
    buttonColor: "primary",
    border: 'border'
};

export default PageTitle;

