import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Link } from "react-router-dom";
import Loader from "../../MobileComponents/Loader";
import GlobalConfig from "../../Config";
import { connect } from "react-redux";
import Store from "../../index";
import AnalyticsDashboardMobile from "../../MobileComponents/Analytics/AnalyticsDashboardMobile";
import { SKY_BLUE } from "../../colors/colors";
const style = (theme) => ({
  root: {
    maxWidth: 550,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  singleCard: {
    display: "flex",
    // marginLeft: "45px",
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  splitOnDifferentScreen: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      displa: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
  },
  splitSingleCard: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start",
    marginTop: "20px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      displa: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
  },

  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: green[500],
  },
});

class AnalyticsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCustomers: true,
      loadingSales: true,
      loadingProfit: true,
      salesSummary: 0,
      totalOrders: 0,
      customers: 0,
      totalProfit: 0,
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.g_reporting_sales.length === 0) {
      fetch(this.props.global_config.basePath + `/reporting/sales`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            salesSummary: data.totalSales,
            totalOrders: data.totalOrders,
            loadingSales: false,
          });
          Store.dispatch({
            type: "GET_REPORTING_SALES",
            reporting_sales: data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({
        salesSummary: this.props.g_reporting_sales.totalSales,
        totalOrders: this.props.g_reporting_sales.totalOrders,
        loadingSales: false,
      });
    }
    if (this.props.g_reporting_customers_overTime.length === 0) {
      fetch(
        this.props.global_config.basePath + `/reporting/customers/overTime`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            customers: data,
            loadingCustomers: false,
          });
          Store.dispatch({
            type: "GET_REPORTING_CUSTOMERS_OVERTIME",
            reporting_customers_overTime: data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({
        customers: this.props.g_reporting_customers_overTime,
        loadingCustomers: false,
      });
    }

    if (this.props.g_reporting_profitByProduct.length === 0) {
      fetch(this.props.global_config.basePath + `/reporting/profitByProduct`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            totalProfit: data,
            loadingProfit: false,
          });
          Store.dispatch({
            type: "GET_REPORTING_PROFIT_BY_PRODUCT",
            reporting_profitByProduct: data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({
        totalProfit: this.props.g_reporting_profitByProduct,
        loadingProfit: false,
      });
    }

    this.fetchcallback();
  }

  componentWillUnmount() {
    window.removeEventListener("unload", this.fetchcallback);
  }

  fetchcallback() {
    fetch(this.props.global_config.basePath + `/reporting/sales`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          salesSummary: data.totalSales,
          totalOrders: data.totalOrders,
          loadingSales: false,
        });
        Store.dispatch({
          type: "GET_REPORTING_SALES",
          reporting_sales: data,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    fetch(this.props.global_config.basePath + `/reporting/customers/overTime`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          customers: data,
          loadingCustomers: false,
        });
        Store.dispatch({
          type: "GET_REPORTING_CUSTOMERS_OVERTIME",
          reporting_customers_overTime: data,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    fetch(this.props.global_config.basePath + `/reporting/profitByProduct`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          totalProfit: data,
          loadingProfit: false,
        });
        Store.dispatch({
          type: "GET_REPORTING_PROFIT_BY_PRODUCT",
          reporting_profitByProduct: data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { classes, history } = this.props;
    const {
      salesSummary,
      totalOrders,
      customers,
      totalProfit,
      loadingSales,
      loadingCustomers,
      loadingProfit,
    } = this.state;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      this.state.height < 200 ||
      this.state.width < 300
    ) {
      return (
        <AnalyticsDashboardMobile
          salesSummary={salesSummary}
          totalOrders={totalOrders}
          customers={customers}
          totalProfit={totalProfit}
          loadingSales={loadingSales}
          loadingCustomers={loadingCustomers}
          loadingProfit={loadingProfit}
          history={history}
        />
      );
    } else
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <div>
              <h4>Reports</h4>
            </div>
            <div></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className={classes.splitOnDifferentScreen}>
              <div style={{ marginBottom: "20px" }}>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ fontSize: "19px" }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: SKY_BLUE }}
                        className={classes.avatar}
                      >
                        <AttachMoneyIcon />
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title="Sales"
                  />

                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      component="p"
                    >
                      Make business decisions by comparing sales across
                      products, staff, channels, and more.
                    </Typography>

                    <div style={{ marginTop: "20px" }}>
                      <b>
                        <Typography color="textSecondary" h5>
                          TOTAL SALES LAST 30 DAYS
                        </Typography>
                      </b>
                    </div>
                    <div style={{ marginTop: "20px", fontSize: "28px" }}>
                      {this.state.loadingSales ? (
                        <CircularProgress thickness={2} color="inherit" />
                      ) : (
                        <b>RS. {salesSummary}</b>
                      )}
                    </div>
                  </CardContent>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/sales-over-time"
                    >
                      Sales over Time
                    </Link>
                  </div>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/sales-by-product-over-time"
                    >
                      {" "}
                      Sales by product
                    </Link>
                  </div>

                  <CardActions disableSpacing></CardActions>
                </Card>
              </div>

              <div>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ fontSize: "19px" }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: SKY_BLUE }}
                        className={classes.avatar}
                      >
                        <AssignmentReturnedIcon />
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title="Orders"
                  />

                  <CardContent>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      Increase visitor engagement by knowing where your visitors
                      are coming from and measuring the success of your
                      campaigns.
                    </Typography>

                    <div style={{ marginTop: "20px" }}>
                      <b>
                        <Typography color="textSecondary" h5>
                          ORDERS LAST 30 DAYS
                        </Typography>
                      </b>
                    </div>
                    <div style={{ marginTop: "20px", fontSize: "28px" }}>
                      {this.state.loadingSales ? (
                        <CircularProgress thickness={2} color="inherit" />
                      ) : (
                        <b>{totalOrders}</b>
                      )}
                      {/* <b>0</b> */}
                    </div>
                  </CardContent>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/orders-over-time"
                    >
                      Orders over Time
                    </Link>
                  </div>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/product-order-return-over-time"
                    >
                      Product orders and return
                    </Link>
                  </div>
                  <hr></hr>

                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/fulfilled-orders-over-time"
                    >
                      Fufillment over time
                    </Link>
                  </div>
                  <CardActions disableSpacing></CardActions>
                </Card>
              </div>
            </div>

            <div className={classes.splitOnDifferentScreen}>
              <div style={{ marginBottom: "20px" }}>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ fontSize: "19px" }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: SKY_BLUE }}
                        className={classes.avatar}
                      >
                        <EmojiPeopleIcon />
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title="Customers"
                  />

                  <CardContent>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      Gain insights into who your customers are and how they
                      interact with your business.
                    </Typography>

                    <div style={{ marginTop: "20px" }}>
                      <b>
                        <Typography color="textSecondary" h5>
                          CUSTOMERS LAST 30 DAYS
                        </Typography>
                      </b>
                    </div>
                    <div style={{ marginTop: "20px", fontSize: "28px" }}>
                      {this.state.loadingCustomers ? (
                        <CircularProgress thickness={2} color="inherit" />
                      ) : (
                        <b>{customers[0].totalCustomers}</b>
                      )}
                    </div>
                  </CardContent>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/customers-over-time"
                    >
                      Customers over time
                    </Link>
                  </div>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/onetime-customers-over-time"
                    >
                      One time customers
                    </Link>
                  </div>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/returning-customers-over-time"
                    >
                      Returning customers
                    </Link>
                  </div>
                  <hr></hr>

                  <CardActions disableSpacing></CardActions>
                </Card>
              </div>

              <div style={{ marginBottom: "20px" }}>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ fontSize: "19px" }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: SKY_BLUE }}
                        className={classes.avatar}
                      >
                        <ListAltIcon />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title="Inventory"
                  />
                  <CardContent>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      Track and understand the movement of your products for
                      more efficient inventory management.
                    </Typography>

                    <div style={{ marginTop: "20px" }}>
                      <b>
                        <Typography color="textSecondary" h5>
                          TOTAL SALES LAST 30 DAYS
                        </Typography>
                      </b>
                    </div>
                    <div style={{ marginTop: "20px", fontSize: "28px" }}>
                      <b>Rs 0.00</b>
                    </div>
                  </CardContent>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/percent-of-inventory-sold-over-time"
                    >
                      Percent of inventory sold
                    </Link>
                  </div>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/average-inventory-sold-over-time"
                    >
                      Average inventory sold per day
                    </Link>
                  </div>
                  <CardActions disableSpacing></CardActions>
                </Card>
              </div>
            </div>
            <div>
              <div className={classes.singleCard}>
                <Card className={classes.root}>
                  <CardHeader
                    style={{ fontSize: "19px" }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: SKY_BLUE }}
                        className={classes.avatar}
                      >
                        <EmojiPeopleIcon />
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title="Profit Margin"
                  />

                  <CardContent>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      Gain insights into who your customers are and how they
                      interact with your business.
                    </Typography>

                    <div style={{ marginTop: "20px" }}>
                      <b>
                        <Typography color="textSecondary" h5>
                          Profit LAST 30 DAYS
                        </Typography>
                      </b>
                    </div>
                    <div style={{ marginTop: "20px", fontSize: "28px" }}>
                      {this.state.loadingProfit ? (
                        <CircularProgress thickness={2} color="inherit" />
                      ) : (
                        <b>{totalProfit.totalProfit}</b>
                      )}
                    </div>
                  </CardContent>
                  <hr></hr>
                  <div style={{ marginLeft: "20px" }}>
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontSize: "16px",
                      }}
                      to="/profit-by-product-over-time"
                    >
                      Profit by Product
                    </Link>
                  </div>
                  <CardActions disableSpacing></CardActions>
                </Card>
              </div>
            </div>
          </div>
        </>
      );
  }
}
const mapStateToProps = (state) => {
  return {
    g_reporting_sales: state.analyticsState.reporting_sales,
    g_reporting_customers_overTime:
      state.analyticsState.reporting_customers_overTime,
    g_reporting_profitByProduct: state.analyticsState.reporting_profitByProduct,
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(style)(AnalyticsDashboard));
