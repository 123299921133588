const initial_state = {
    reporting_sales: [],
    reporting_customers_overTime: [],
    reporting_profitByProduct: [],
    salesReportsOverTime: [],
    salesByProductReportsOverTime: [],
    customersReportOverTime: [],
    oneTimeCustomerReportOverTime: [],
    returningCustomerReportOverTime: [],
    profitByProductReportOverTime: [],
    inventoryReportsOverTime: [],
    averageInventoryReportsOverTime: [],
    productOrderAndReturnReportOverTime: [],
    fullFilledOrdersReportOverTime: [],
    ordersOverTime: [],
};

const analyticsReducer = (state = initial_state, action) => {
    switch (action.type) {
        case "GET_REPORTING_SALES":
            console.log(action);
            return Object.assign({}, state, { reporting_sales: action.reporting_sales });

        case "GET_REPORTING_CUSTOMERS_OVERTIME":
            console.log(action);
            return Object.assign({}, state, {
                reporting_customers_overTime: action.reporting_customers_overTime,
            });

        case "GET_REPORTING_PROFIT_BY_PRODUCT":
            console.log(action);
            return Object.assign({}, state, {
                reporting_profitByProduct: action.reporting_profitByProduct,
            });

        case "GET_SALES_REPORTS_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, { salesReportsOverTime: action.salesReportsOverTime });

        case "GET_SALES_BY_PRODUCT_REPORTS_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                salesByProductReportsOverTime: action.salesByProductReportsOverTime,
            });

        case "GET_CUSTOMERS_REPORT_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                customersReportOverTime: action.customersReportOverTime,
            });

        case "GET_ONE_TIME_CUSTOMER_REPORT_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                oneTimeCustomerReportOverTime: action.oneTimeCustomerReportOverTime,
            });

        case "GET_RETURNING_CUSTOMER_REPORT_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                returningCustomerReportOverTime: action.returningCustomerReportOverTime,
            });

        case "GET_PROFIT_BY_PRODUCT_REPORT_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                profitByProductReportOverTime: action.profitByProductReportOverTime,
            });

        case "GET_INVENTORY_REPORTS_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                inventoryReportsOverTime: action.inventoryReportsOverTime,
            });

        case "GET_AVERAGE_INVENTORY_REPORTS_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                averageInventoryReportsOverTime: action.averageInventoryReportsOverTime,
            });

        case "GET_PRODUCT_ORDER_AND_RETURN_REPORT_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                productOrderAndReturnReportOverTime: action.productOrderAndReturnReportOverTime,
            });

        case "GET_FULLFILLED_ORDERS_REPORT_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, {
                fullFilledOrdersReportOverTime: action.fullFilledOrdersReportOverTime,
            });

        case "GET_ORDERS_OVER_TIME":
            console.log(action);
            return Object.assign({}, state, { ordersOverTime: action.ordersOverTime });

        case "CLEAR_ANALYTICS_DATA":
            return initial_state;

        default:
            return state;
    }
};

export default analyticsReducer;
