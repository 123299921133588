import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  console.log("card props: ", props.data);
  const {
    POID,
    name,
    email,
    phoneNumber,
    numberOfOrders,
    averageOrderValue,
    totalSpendOnOrder,
    address,
  } = props.data;
  const { street, city, stateOrProvince, postCode, country } = address[0];
  return (
    <Card
      sx={{
        p: "5px 5px 5px 5px",
        transition: "0.3s",
        width: "auto",
        margin: "5px 10px 5px 10px",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
        },
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {name[0]}
          </Avatar>
        }
        title={name}
        subheader={email}
      />
      <CardActions disableSpacing>
        <Typography align="left" variant="subtitle2">
          <b> Details: </b>
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography align="left" variant="subtitle2">
            <b>ID: </b> {POID}
          </Typography>
          <Typography align="left" variant="subtitle2">
            <b>Phone Number: </b> {phoneNumber}
          </Typography>
          <Typography align="left" variant="subtitle2">
            <b>TotalOrders: </b> {numberOfOrders}
          </Typography>
          <Typography align="left" variant="subtitle2">
            <b>Amount Spent: </b> {totalSpendOnOrder}
          </Typography>
          <Typography align="left" variant="subtitle2">
            <b>Address: </b>{" "}
            {`${street}, ${city}, ${stateOrProvince}, ${country}, ${postCode} `}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
