import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import { blue } from "@material-ui/core/colors";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { SKY_BLUE } from "../../colors/colors";

const types = ["Discounted Code", "Automatic Discounts"];

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };


  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Select Discount Type</DialogTitle>

      <List>
        <hr></hr>
        <ListItem button component={Link} to="/discountCode">
          <ListItemText>
            <AutorenewIcon /> <b>Discount Code</b>
          </ListItemText>

          <ExitToAppIcon />
        </ListItem>
        <hr></hr>
        <ListItem button component={Link} to="/DiscountautomatedForm">
          <ListItemText>
            <AddShoppingCartIcon /> <b>Automatic Discount</b>
          </ListItemText>

          <ExitToAppIcon />
        </ListItem>

        <hr></hr>

        <ListItemText style={{ marginLeft: "20px" }}>
          Customers can't combine discounts at checkout.
        </ListItemText>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(types[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button
        style={{ backgroundColor: SKY_BLUE, color: "white" }}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Create Discount
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
