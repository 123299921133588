import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// import BasicModal from './BarcodeModal'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { SKY_BLUE, MARS } from "../../colors/colors"

class SearchBar extends React.Component {
    state = {
        searchTerm: '',
        open: false
    }
    style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        p: 4,
    };
    editSearchTerm = (e) => {
        this.setState({ searchTerm: e.target.value });
        console.log(`search term: ${e.target.value}`);
        const filteredList = this.dynamicSearch(this.props.data, e.target.value);
        this.props.onChange(filteredList);
    };
    handleSubmit = (event) => {
        console.log(`clicked on submit`);
        // event.preventDefault();
        const filteredList = this.dynamicSearch(this.props.data, this.state.searchTerm);
        this.props.onChange(filteredList);
        this.handleClose();
    };
    dynamicSearch = (list, searchTerm) => {
        return list.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    };
    handleOpen = () => this.setState({ open: true })
    handleClose = () => this.setState({ open: false })

    render() {
        return (
            <div>
                <div style={{ marginLeft: "100px" }}>
                    <Modal
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <BarcodeScannerComponent
                            width={250}
                            height={200}
                            onUpdate={(err, result) => {
                                if (result) {
                                    this.setState({ searchTerm: result.text });
                                    console.log(this.state.searchTerm)
                                    this.handleSubmit();
                                }
                            }}
                        />
                    </Modal>
                </div>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px 0px 4px', display: 'flex', alignItems: 'center', width: 'auto', margin: '5px 5px 5px 5px' }}
                    onChange={this.editSearchTerm} onSubmit={this.handleSubmit}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Inventory"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                    <InputAdornment>
                        <SearchIcon style={{ color: SKY_BLUE, marginRight: "5px" }} />
                        <QrCodeScannerIcon sx={{ color: MARS }} onClick={this.handleOpen} />
                    </InputAdornment>
                </Paper>
            </div>

        );
    }
};

export default SearchBar;