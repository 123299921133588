import React from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import CropFreeIcon from '@mui/icons-material/CropFree';
class InputField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
    };
    handleChange = (event) => {
        console.log(event.target.value);
        this.setState({ value: event.target.value });
    };
    handleClick = () => {
        console.log("click");
    };
    render() {
        return (
            <>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    label={this.props.label}
                    value={this.state.value}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    // sx={{
                    //     margin: '5px 10px 5px', p: '5px 10px 5px'
                    // }}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <IconButton edge="end" onClick={this.handleClick}>
                                <CropFreeIcon />
                            </IconButton>
                        </InputAdornment>
                        )
                    }}

                />


            </>
        );
    }

};

InputField.defaultProps = {
    //label:'label',
    value: '',
    placeholder: 'write some text here'
};

export default InputField;
