const initial_state = {
  users: [],
  token: [],
  userRole: "",
};
const usersReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "SET_USER_ROLE":
      console.log(action);
      return Object.assign({}, state, { userRole: action.userRole });
    case "GET_USERS":
      console.log(action);
      return Object.assign({}, state, { users: action.users });
    case "GET_TOKEN":
      console.log(action);
      return Object.assign({}, state, { token: action.token });
    case "POST_USERS":
      return Object.assign({}, state, { users: action.users });
    case "DELETE_USERS":
      const filteredUsers = state.users.filter(
        (user) => user.POID !== action.POID
      );
      return {
        ...state,
        users: filteredUsers,
      };

    case "EDIT_USER":
      console.log("EDIT_USER");
      let updated_user = action.updated_user;
      console.log("action.updated_user: ", updated_user);
      let user = state.users.filter((user) => user.POID === updated_user.POID);
      user = user[0];
      if (user.email !== updated_user.email) {
        user.email = updated_user.email;
      }
      if (user.userName !== updated_user.userName) {
        user.userName = updated_user.userName;
      }
      if (user.email !== updated_user.email) {
        user.email = updated_user.email;
      }
      if (user.firstName !== updated_user.firstName) {
        user.firstName = updated_user.firstName;
      }
      if (user.lastName !== updated_user.lastName) {
        user.lastName = updated_user.lastName;
      }
      if (user.role[0].name !== updated_user.role[0].name) {
        user.role[0].name = updated_user.role[0].name;
      }
      return state;
    case "CLEAR_USERS_DATA":
      return initial_state;
    default:
      return state;
  }
};

export default usersReducer;
