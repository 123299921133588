import React from "react";
import Typography from "@mui/material/Typography";

const Label = function (props) {
  return (
    <div>
      <Typography
        sx={{ margin: "5px 5px 5px 5px" }}
        variant={props.textType}
        align={props.alignment}
      >
        {props.value}
      </Typography>
    </div>
  );
};
Label.defaultProps = {
  textType: "subtitle2",
  alignment: "left",
  value: "provide some text"
};

export default Label;
