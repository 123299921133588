import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import toast, { Toaster } from "react-hot-toast";
import ResponsiveDrawer from "./router/ResponsiveDrawer";
import Keycloak from "keycloak-js";
import { fade, withStyles } from "@material-ui/core/styles";
import Store from "./index";

import { connect, useSelector } from "react-redux";
import KeycloackForm from "./components/KeycloackForm";
import helpers from "./UtilitiesFunctions";

const drawerWidth = 390;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },

  appBar: {
    marginLeft: drawerWidth,
    backgroundColor: "rgba(16,174,72)",

    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      backgroundColor: "rgba(16,174,72)",
    },
  },
  menuButton: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      display: "none",
      backgroundColor: "#412b69",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    paddingTop: "65px",
    marginTop: "65px",
    width: drawerWidth,
  },
  search: {
    color: "white",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.87),
      color: "black",
    },
    marginRight: theme.spacing(2),
    // width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(25),
      width: "50rem",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(8),
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keycloak: "",
      authenticated: false,
      mobileOpen: false,
      userInfo: "",
      token: "",
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  //intializing keycloka along with dynamic instances
  keycloakInit = () => {
    // var instance = "HPOS-";
    const url = new URLSearchParams(document.location.search);

    var instance = url.get("appType") + "-";
    // var instance = "HVMS-";
    if (instance === "null-") {
    } else {
      Store.dispatch({
        type: "GET_BASE_URL",
        payload: {
          appType: instance,
        },
      });
    }

    // getting configuration from redux
    const keycloak = Keycloak({
      realm: this.props.global_config.companyName,
      // "url": "http://127.0.0.1:8080/auth/",
      // "url": "http://worker01vm2:8080/auth/",
      url: this.props.global_config.keyCloakAuth,
      "ssl-required": "external",
      resource:
        this.props.global_config.appType + this.props.global_config.instanceId,
      clientId:
        this.props.global_config.appType + this.props.global_config.instanceId,
      "public-client": true,
      "verify-token-audience": true,
      "use-resource-role-mappings": true,
      "confidential-port": 0,
    });

    keycloak
      .init({ onLoad: "login-required" })
      .then((authenticated) => {
        // console.log(authenticated);
        // console.log(keycloak);
        keycloak.resourceAccess.instance = instance;
        this.setState({
          token: keycloak.token,
          keycloak: keycloak,
          authenticated: authenticated,
        });

        keycloak.loadUserInfo().then((UserInfo) => {
          this.setState({ userInfo: UserInfo });
        });
      })
      .catch((e) => console.log(e));
  };

  initialize = async () => {
    const url = new URLSearchParams(document.location.search);

    var companyName = url.get("companyName");
    var instanceId = url.get("instanceId");
    // console.log("here");
    if (companyName != null && instanceId != null) {
      if (
        companyName.toLocaleLowerCase() !=
        this.props.global_config.companyName.toLocaleLowerCase()
      ) {
        helpers.clearData();
      } else {
      }
      console.log(this.props);
      await Store.dispatch({
        type: "GET_BASE_URL",
        payload: {
          companyName: companyName,
          instanceId: instanceId,
        },
      });
      // }
    }
    this.keycloakInit();
  };
  async componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);

    this.initialize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    console.log(window.innerHeight);
    console.log(window.innerWidth);

    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  async logout() {
    this.state.keycloak.logout();
    helpers.LogOut();
  }

  render() {
    // const { classes } = this.props;
    const { userInfo, keycloak, token, authenticated } = this.state;
    const { classes, cart } = this.props;
    console.log("here is cart lenght" + cart?.length);

    console.log(
      "here is  app TYPE",
      this.props.global_config.appType,
      authenticated,
      keycloak,
      userInfo
    );

    if (this.props.global_config.companyName) {
      return (
        <>
          {!authenticated ? (
            <div className="blobcontainer">
              <div class="shapes"></div>
            </div>
          ) : (
            <>
              <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                  className: "",
                  style: {
                    boxShadow: "0 0 10px 0 rgba(0,0,0, 0.4)",
                    fontWeight: "bold",
                  },
                }}
              />

              <ResponsiveDrawer
                keycloak={keycloak}
                userInfo={userInfo}
                token={token}
                cartlength={cart?.length}
                appType={this.props.global_config.appType}
              />
            </>
          )}
        </>
      );
    } else {
      return <KeycloackForm keycloak={keycloak} />;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(useStyles)(App));
