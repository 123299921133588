import React, { Component } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const discountTypeList = [
  {
    POID: 1,
    value: "Percentage",
  },
  {
    POID: 2,
    value: "Fixed Amount",
  },
  {
    POID: 3,
    value: "Buy X Get Y",
  },
];

const appliesToList = [
  {
    POID: 1,
    value: "All Products",
  },
  {
    POID: 2,
    value: "Specific Products",
  },
];

class CreateDiscount extends Component {
  constructor() {
    super();
    this.state = {
      discountCode: "",
      discountType: "Percentage",
      discountPercentage: 10,
      appliesTo: "All Products",
    };
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name == "appliesTo") {
      this.props.nextStep();
    }
  };

  onHandleBack = () => {
    this.props.setStep(4);
  };
  HandleBack = () => {
    const history = this.props.history;
    console.log("Changing route to view discount...");
    history.push("/Discount");
  };
  onHandleAppliesTo = () => {
    this.props.setStep(2);
  };
  onHandleCustomerEligibility = () => {
    this.props.setStep(5);
  };
  onHandleSetDate = () => {
    this.props.setStep(7);
  };
  onHandleCustomerBuys = () => {
    this.props.setStep(8);
  };
  onHandleCustomerGets = () => {
    this.props.setStep(10);
  };
  dates = (dates) => {
    var year = "" + dates.getFullYear();
    var month = "" + (dates.getMonth() + 1);
    var day = "" + dates.getDate();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    console.log(month);
    return year + "-" + month + "-" + day;
  };
  render() {
    const {
      discountCode,
      discountType,
      discountPercentage,
      appliesTo,
      discountAmount,
      quantityOrAmount,
      minimumQuantityOrAmountToBuy,
      customerEligibility,
      startDate,
      endDate,
    } = this.props.values;
    const { pageTitle, discountLabel } = this.props
    return (
      <>
        <div>
          <PageTitle
            textType="h6"
            value={pageTitle}
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.HandleBack}
          />
        </div>
        <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
          <TextField
            id="standard-helperText"
            name="discountCode"
            label={discountLabel}
            value={discountCode}
            onChange={this.props.handleChange}
            placeholder={discountLabel}
            helperText="Customers will enter this code at checkout"
            variant="standard"
            size="large"
            fullWidth
          />
        </div>
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="8px">
          <InputLabel id="Input label">Discount type</InputLabel>
          <Select
            labelId="Input label"
            id="Select"
            name="discountType"
            value={discountType}
            // defaultValue={values.status}
            onChange={this.props.handleChange}
            size="small"
            variant="outlined"
            fullWidth
          >
            {discountTypeList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {discountType === "Percentage" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="discountPercentage"
              label="Discount Percentage"
              value={discountPercentage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={this.props.handleChange}
              variant="standard"
              size="large"
              fullWidth
              type="number"
            />
          </div>
        )}
        {discountType === "Fixed Amount" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="discountAmount"
              label="Discount Amount"
              value={discountAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onChange={this.props.handleChange}
              variant="standard"
              size="large"
              fullWidth
              type="number"
            />
          </div>
        )}
        {discountType !== "Buy X Get Y" && (
          <div
            style={{ marginLeft: "5px", marginTop: "8px", width: "95%" }}
            onClick={this.onHandleAppliesTo}
          >
            <p style={{ fontWeight: "bold" }}>Applies To</p>
            <p>{appliesTo}</p>
          </div>
        )}
        {discountType !== "Buy X Get Y" && (
          <div
            style={{ marginLeft: "5px", marginTop: "8px", width: "95%" }}
            onClick={this.onHandleBack}
          >
            <p style={{ fontWeight: "bold" }}>Minimum Requirements</p>
            <p>{quantityOrAmount}</p>
          </div>
        )}
        {discountType === "Buy X Get Y" && (
          <div
            style={{ marginLeft: "5px", marginTop: "8px", width: "95%" }}
            onClick={this.onHandleCustomerBuys}
          >
            <p style={{ fontWeight: "bold" }}>Customer Buys</p>
            <p>Add Items</p>
          </div>
        )}
        {discountType === "Buy X Get Y" && (
          <div
            style={{ marginLeft: "5px", marginTop: "8px", width: "95%" }}
            onClick={this.onHandleCustomerGets}
          >
            <p style={{ fontWeight: "bold" }}>Customer Gets</p>
            <p>Add Items at % off</p>
          </div>
        )}
        <div
          style={{ marginLeft: "5px", marginTop: "8px", width: "95%" }}
          onClick={this.onHandleCustomerEligibility}
        >
          <p style={{ fontWeight: "bold" }}>Customer Eligibility</p>
          <p>{customerEligibility}</p>
        </div>
        <div
          style={{
            marginLeft: "5px",
            marginTop: "8px",
            width: "95%",
            marginBottom: "50px",
          }}
          onClick={this.onHandleSetDate}
        >
          <p style={{ fontWeight: "bold" }}>Set Dates</p>
          <p>Start Date: {this.dates(startDate)}</p>
          <p>End Date: {this.dates(endDate)}</p>
        </div>
      </>
    );
  }
}

export default CreateDiscount;
