import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Popup from "../Popup";
import CustomersTable from "./CustomersTable";

export default function BrowseCustomer(props) {
  const [openA, setOpenA] = React.useState(false);

  const handleClickOpen = (name) => {
    if (name === "A") {
      setOpenA(true);
    }
  };

  const handleClose = (name) => {
    if (name === "A") {
      setOpenA(false);
    }
  };
  console.log("Browse Customer Props", props);
  return (
    <div>
      <ReactSearchAutocomplete />
      <ButtonGroup
        style={{ marginTop: "10px" }}
        orientation="vertical"
        fullWidth
      >
        <Popup
          title="All Customer"
          btnText="All Customer"
          btnVar="outlined"
          btnWidth={true}
          contentTxt={<hr />}
          content={
            <CustomersTable
              updateParent={props.updateParent}
              handleClose={handleClose}
              onClickBtnClose={() => handleClose("A")}
            />
          }
          openPopup={openA} //open
          closePop={() => handleClose("A")} //close
          onClickBtnOpen={() => handleClickOpen("A")} //on click open
          onClickBtnClose={() => handleClose("A")} //on click close
        />
      </ButtonGroup>
    </div>
  );
}
