import React, { useMemo, useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import DoneIcon from "@material-ui/icons/Done";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PaymentIcon from "@material-ui/icons/Payment";
import CancelIcon from "@mui/icons-material/Cancel";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import "./common.css";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { emptyCart } from "../../Redux/action/cart_actions";
// import helpers from "../UtilitiesFunctions";
import useResponsiveFontSize from "./userResponseFontSize";
import { Box, Button, Grid } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import GlobalConfig from "../../Config";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import EditInfoModal from "../../components/OrderModals/EditContactInfoModal";
import ShippingAddressModal from "../../components/OrderModals/ShippingAddressModal";
import AddCustomerModal from "../../components/Customers/AddCustomerModal";
import Popup from "../../components/Popup";
import Loader from "../../MobileComponents/Loader";
import SnackBar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { LIGHT_GREY, SKY_BLUE } from "../../colors/colors";
import Store from "../../index";
import * as localForage from "localforage";
import helpers from "../../UtilitiesFunctions";
import { ThermalPrinterView } from "../print/thermalPrinterView";
import AddDiscount from "../OrderModals/AddDiscount";
import { DotMatrixPrinterView } from "../print/dotMatrixPrinterView";
const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const Checkout = (props) => {
  // const stripe = useStripe();
  const [open, setOpen] = useState(false);
  // const elements = useElements();
  const [setSuccess] = useState(false);
  const [setFailure] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState("");
  const [backdrop, setBackdrop] = useState(false);
  const [addShippingModal, setAddShippingModal] = useState(false);
  const [addCustomItemModal, setAddCustomItemModal] = useState(false);
  const [shippingAddressModal, setShippingAddressModal] = useState(false);
  const [editInfoModal, setEditInfoModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [loader, setLoader] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [addDiscountModal, setAddDiscountModal] = useState(false);
  const [discount, setDiscount] = useState("");
  const [reason, setDiscountReason] = useState("");
  const [discounttype, setDiscounttype] = useState("");
  const [totalTax] = useState(
    props.location.state
      ? props.location.state
          .map((obj) =>
            parseFloat(((obj.price / 100) * obj.taxRate * obj.units).toFixed(2))
          )
          .reduce((a, b) => a + b, 0)
      : 0
  );
  const [subtotal] = useState(
    props.location.state
      ? props.location.state
          .map((obj) => Number(obj.price * obj.units))
          .reduce((a, b) => a + b, 0)
      : 0
  );
  const [totalAmount] = useState(totalTax + subtotal);

  const [totalWithTax] = useState(totalAmount);
  const [orderObject, updateOrderObject] = useState();
  const [printReciept, updatePrintReciept] = useState(false);
  const [balance, setBalance] = useState(totalAmount);
  const history = useHistory();
  const global_config = useSelector((state) => state.globalState);
  const [calculateddisc] = useState(
    helpers.getDiscountAmount({
      discount_type: discounttype,
      discount_value: discount,
      totalWithTax: totalWithTax,
    })
  );

  useEffect(() => {
    console.log(
      "global check",
      global_config,
      props?.company_info?.receipt_metadata
    );
  }, []);

  const startReceiptPrint = (orderObject) => {
    updateOrderObject(orderObject);
    updatePrintReciept(true);
  };

  console.log("total tax by me", totalTax);

  const options = useOptions();

  const handleOnSelect = (item, name) => {
    if (name === "product") {
      addItem(item, name);
    } else if (name === "customer") {
      addItem(item, name);
    }
  };

  const adddDiscount = async (props) => {
    await setDiscount(props.discount_value);
    await setDiscountReason(props.discount_reason);
    await setDiscounttype(props.discount_type);

    const discountedAmount = await helpers.getDiscountAmount({
      discount_type: props.discount_type,
      discount_value: props.discount_value,
      totalWithTax: totalWithTax,
    });

    if (totalAmount - discountedAmount < 0) {
      await setDiscount("");
      await setDiscountReason("");
      await setDiscounttype("");
      helpers.toastNotify(
        "error",
        "Discount amount cannot be greater than the total amount"
      );
      return;
    }

    handleClose("addDiscountModal");
  };

  const handleOnSearchCustomers = (string, results) => {
    console.log(string, results);
  };
  const addZero = (x, n) => {
    while (x.toString().length < n) {
      x = "0" + x;
    }
    return x;
  };

  const getOfflineDate = () => {
    const d = new Date();
    let day = addZero(d.getDate(), 2);
    let month = addZero(d.getMonth() + 1, 2);
    let year = d.getFullYear();
    let h = addZero(d.getHours(), 2);
    let m = addZero(d.getMinutes(), 2);
    let s = addZero(d.getSeconds(), 2);
    let ms = addZero(d.getMilliseconds(), 3);
    let time =
      year + "." + month + "." + day + "." + h + "." + m + "." + s + "." + ms;
    return time;
  };
  const getOfflinePaymentDate = () => {
    const d = new Date();
    let day = addZero(d.getDate(), 2);
    let month = addZero(d.getMonth() + 1, 2);
    let year = d.getFullYear();
    return year + "-" + month + "-" + day;
  };
  //creating order line item of order object
  const getOrderLineItems = () => {
    let lineItems = [];

    let lineItemsFromState = props.location.state;
    console.log("location from state:", props.location.state);
    for (var i = 0; i < lineItemsFromState.length; i++) {
      var varientproduct = " (";
      lineItemsFromState[i]?.productSpecCharacteristics?.map(
        (productspec, index) => {
          productspec?.productSpecCharValues?.map(
            (productSpecCharValues, index) => {
              console.log(
                "here is a product specificationvalues",
                productSpecCharValues
              );
              varientproduct +=
                productSpecCharValues?.value +
                (index + 1 == productspec?.productSpecCharValues?.length &&
                productspec?.productSpecCharValues?.length > 1
                  ? ", "
                  : "");
              console.log("here is varient product", varientproduct);
            }
          );
          varientproduct +=
            index + 1 !=
              lineItemsFromState[i]?.productSpecCharacteristics?.length &&
            lineItemsFromState[i]?.productSpecCharacteristics?.length > 1
              ? " - "
              : "";
        }
      );
      varientproduct += ")";
      varientproduct = varientproduct === " ()" ? "" : varientproduct;

      var lineItem = {
        productId: lineItemsFromState[i].POID,
        fulfilled: true,
        productName: lineItemsFromState[i].name + varientproduct,
        price: lineItemsFromState[i].price,
        quantity: lineItemsFromState[i].units,
        costPrice: lineItemsFromState[i].costPrice,
        unitOfMeasure: lineItemsFromState[i].unitOfMeasurs,
        tax: lineItemsFromState[i].taxRate,
        // productSpecCharacteristics:
        //   lineItemsFromState[i].productSpecCharacteristics,
        action: "add",
      };
      lineItems.push(lineItem);
    }
    console.log("lineItems return", lineItems);
    return lineItems;
  };

  //debounce function
  const debounce = (func, delay) => {
    console.log("inDebounced Function");
    let inDebounce;
    return function () {
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func(), delay);
    };
  };
  //order object ends here
  let lineItems = () => {
    var generatedLineItems = getOrderLineItems();

    //setLoader(true);
    console.log(loader);

    console.log("line item called", generatedLineItems);
    let productIds = [];
    let productQuantity = [];
    // setLoader(true);
    let orderId = (
      Math.max.apply(
        Math,
        props.g_orders.map((o) => {
          return o.orderId;
        })
      ) + 1
    ).toString();
    let subTotal = generatedLineItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    let taxAmount = generatedLineItems.reduce((total, item) => {
      return total + (item.price * item.quantity * item.tax) / 100;
    }, 0);

    console.log("sub total and tax amount", subTotal, taxAmount);

    var orderObject = {
      paid: true,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      //TODO customer id is required for walk in customer
      customerId:
        currentCustomer === "" || !currentCustomer
          ? null
          : currentCustomer.POID,
      lineItems: generatedLineItems,
      discountAmount: helpers.getDiscountAmount({
        discount_type: discounttype,
        discount_value: discount,
        totalWithTax: totalWithTax,
      }),
      // payableAmount: totalWithTax,

      discountReason: reason === "" ? null : reason,
    };
    var offlineCreateOrder = {
      customerId: currentCustomer.id ? currentCustomer.id : "null",
      lineItems: generatedLineItems,
      paid: true,
      active: true,
      close: true,
      createdAt: getOfflineDate(),
      draft: false,
      fulfilled: true,
      fulfillmentDate: "null",
      orderId: orderId,
      partiallyFulfilled: false,
      paymentDate: getOfflinePaymentDate(),
      paymentId: "",
      returned: false,
      returnedAmount: 0,
      status: "Active",
      subTotal: subTotal,
      taxAmount: taxAmount,
      taxRate: generatedLineItems.tax,
      total: subTotal + taxAmount,
    };

    for (let i in orderObject.lineItems) {
      productIds.push(orderObject.lineItems[parseInt(i)].productId);
      productQuantity.push(orderObject.lineItems[parseInt(i)].quantity);
    }
    updateProductQuantity(productIds, productQuantity, offlineCreateOrder);

    localForage.setItem(orderId, orderObject).then((response) => {
      console.log("calling postOrders", response, orderObject, orderId);
      postOrders(orderObject);
    });
  };

  // lineItems = debounce(lineItems, 4000);

  function OrderCalling(orderObject, error) {
    console.log("printing orderCalling", orderObject);
    fetch(global_config.basePath + "/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(orderObject),
    })
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(async ({ response, status }) => {
        console.log("jawad response is here", response);
        //response.json();
        console.log("RESPONSEEEE");
        console.log(response);

        if (status !== 200) {
          setLoader(false);
          helpers.toastNotify("error", response.errors[0]);
          return;
        }
        Store.dispatch({
          type: "APPEND_ORDERS",
          orders: response,
        });

        if (status === 200) {
          // var responseJson = await response.json();
          setLoader(false);
          helpers.toastNotify("success", "Order successfully created!");
          // console.log(responseJson);
          // console.log(response.orderId);
          startReceiptPrint(response);
        }
      })
      .then((response) => {
        // localForage.clear();
        console.log(response);
        setLoader(false);
      })
      .catch(function (err) {
        // alert("Unable to create order");
        error = 1;
        console.log(err);
        setLoader(false);
        helpers.toastNotify("error", "Could Not Created Order.");
      });
  }

  //async function
  async function postOrders(orderObjectParam) {
    setLoader(true);

    let error = 0;
    let orderObject = {};
    let orderKey = "";
    console.log("here is me", localForage);
    OrderCalling(orderObjectParam, error);

    localForage
      .iterate(async function (value, key, iterationNumber) {
        console.log("here is me inside forage");
        console.log([key, value]);
        Object.assign(orderObject, value);
        console.log(orderObject);
        orderKey = key;

        console.log("orderKey", orderKey);
        console.log("orderObject", orderObject);
      })
      .then(function () {
        console.log("Iteration has completed");
      })
      .catch(function (err) {
        console.log(err);
        setLoader(false);
        helpers.toastNotify("error", "Something went wrong!");
      });
  }

  const onPrintFinish = () => {
    history.push(`/pos`);
  };

  const addItem = (item) => {
    setCurrentCustomer(item);
    console.log("current selected customer is: ", item);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const calculateTotal = () => {};

  const handleClose = (name) => {
    if (name === "addCustomItemModal") {
      setAddCustomItemModal(false);
    } else if (name === "addShippingModal") {
      setAddShippingModal(false);
    } else if (name === "editInfoModal") {
      setEditInfoModal(false);
    } else if (name === "shippingAddressModal") {
      setShippingAddressModal(false);
    } else if (name === "addDiscountModal") {
      setAddDiscountModal(false);
    }
  };
  const handleClickOpen = (name) => {
    if (name === "addCustomItemModal") {
      setAddCustomItemModal(true);
    } else if (name === "addShippingModal") {
      setAddShippingModal(true);
    } else if (name === "editInfoModal") {
      setEditInfoModal(true);
    } else if (name === "shippingAddressModal") {
      setShippingAddressModal(true);
    } else if (name === "AddCustomerModal") {
      setAddCustomerModal(true);
    } else if (name === "addDiscountModal") {
      setAddDiscountModal(true);
    }
  };
  const updateProductQuantity = (
    productIds,
    productQuantity,
    offlineCreateOrder
  ) => {
    console.log("inside update product quantity");
    console.log(offlineCreateOrder);
    props.emptyCart();
    Store.dispatch({
      type: "EDIT_INVENTORY_ITEM_QUANTITY_ON_ORDER",
      id: productIds,
      quantity: productQuantity,
    });
    Store.dispatch({
      type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_ON_ORDER",
      id: productIds,
      quantity: productQuantity,
    });
    // Store.dispatch({
    //   type: "POST_MARK_AS_PAID__FULFILLED_ORDERS",
    //   orders: offlineCreateOrder,
    // });
  };

  const createPayment = () => {
    setOpen(false);
    setBackdrop(true);

    const data = {
      AccountRef_Id: "1",
      description: "example Payment",
      dutyFreeAmountUnits: "USD",
      dutyFreeAmountValue: totalAmount,
      name: "example1",
      paymentDate: "2021-08-25",
      RelatedPartyRef_Id: "1",
      status: "Success",
      taxAmountUnits: "USD",
      taxAmountValue: 0.0,
      totalAmountUnits: "USD",
      totalAmountValue: totalAmount,
    };

    fetch(global_config.basePath + "/Payments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    })
      .then((response) => {
        setSuccess(true);
        setBackdrop(false);
      })
      .catch((err) => {
        setFailure(true);
      });
  };

  console.log("here is state", props.location.state);
  const renderCheckoutPage = () => {
    if (loader) {
      return <renderLoader />;
    } else {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 0 5%",
            }}
          >
            <div>
              <h5 style={{ textAlign: "center" }}>
                <b> Bill Description </b>
              </h5>

              <table
                style={{
                  width: "500px",
                  textAlign: "center",
                  marginTop: "40px",
                }}
              >
                <tr style={{ border: "1px solid", padding: "5px" }}>
                  <th></th>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total Tax</th>
                  <th>Total Price</th>
                  <th>Gross Total</th>
                </tr>

                {props.location.state ? (
                  props.location.state.map((p, index) => {
                    var varientproduct = " (";
                    p?.productSpecCharacteristics?.map((productspec, index) => {
                      productspec?.productSpecCharValues?.map(
                        (productSpecCharValues, index) => {
                          console.log(
                            "here is a product specificationvalues",
                            productSpecCharValues
                          );
                          varientproduct +=
                            productSpecCharValues?.value +
                            (index + 1 ==
                              productspec?.productSpecCharValues?.length &&
                            productspec?.productSpecCharValues?.length > 1
                              ? ", "
                              : "");
                          console.log(
                            "here is varient product",
                            varientproduct
                          );
                        }
                      );
                      varientproduct +=
                        index + 1 != p?.productSpecCharacteristics?.length &&
                        p?.productSpecCharacteristics?.length > 1
                          ? " - "
                          : "";
                    });
                    varientproduct += ")";
                    varientproduct =
                      varientproduct === " ()" ? "" : varientproduct;
                    return (
                      <tr
                        key={index}
                        style={{ marginTop: "10px", border: "1px solid" }}
                      >
                        <td>{index + 1}</td>
                        <td>{p.name + varientproduct}</td>
                        <td>{p.price}</td>
                        <td>{p.units}</td>
                        <td>
                          {((p.price / 100) * p.taxRate * p.units).toFixed(2) +
                            " @ (" +
                            p.taxRate +
                            "%)"}
                        </td>
                        <td>{p.price * p.units}</td>
                        <td>
                          <b>
                            {(
                              p.units * p.price +
                              parseFloat((p.price / 100) * p.taxRate * p.units)
                            ).toFixed(2)}
                          </b>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">
                      <b>No Product is selected</b>
                    </td>
                  </tr>
                )}
                {props.location.state ? (
                  <>
                    <tr
                      key={1}
                      style={{
                        border: "1px solid",
                        borderBottom: "none",
                      }}
                    >
                      <td colSpan={5} style={{ textAlign: "right" }}>
                        Total Price Sum:
                      </td>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        <b>{subtotal.toFixed(2)}</b>
                      </td>
                    </tr>
                    <tr
                      key={1}
                      style={{
                        border: "1px solid",
                        borderBottom: "none",
                        borderTop: "none",
                      }}
                    >
                      <td colSpan={5} style={{ textAlign: "right" }}>
                        Total Tax Amount:
                      </td>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        <b>{totalTax.toFixed(2)}</b>
                      </td>
                    </tr>

                    <tr
                      key={1}
                      style={{
                        border: "1px solid",
                        borderBottom: "none",
                        borderTop: "none",
                      }}
                    >
                      <td colSpan={5} style={{ textAlign: "right" }}>
                        Total Amount:
                      </td>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        <b>{totalAmount.toFixed(2)}</b>
                      </td>
                    </tr>
                    <tr
                      key={1}
                      style={{
                        border: "1px solid",
                      }}
                    >
                      <td colSpan={5} style={{ textAlign: "right" }}>
                        Discount Amount:
                      </td>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        <b>
                          {helpers
                            .getDiscountAmount({
                              discount_type: discounttype,
                              discount_value: discount,
                              totalWithTax: totalWithTax,
                            })
                            .toFixed(2)}
                        </b>
                      </td>
                    </tr>
                    <tr key={1} style={{ border: "1px solid" }}>
                      <td colSpan={5} style={{ textAlign: "right" }}>
                        <b>Payable Amount:</b>
                      </td>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        <b>
                          <b>
                            {(
                              totalAmount -
                              helpers.getDiscountAmount({
                                discount_type: discounttype,
                                discount_value: discount,
                                totalWithTax: totalWithTax,
                              })
                            ).toFixed(2)}
                          </b>
                        </b>
                      </td>
                    </tr>
                  </>
                ) : null}
              </table>
            </div>
            <div
              style={{
                width: "40%",
              }}
            >
              <div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  {currentCustomer === "" ? (
                    <>
                      <Paper>
                        <Box>
                          <Box p={1} flexGrow={1} bgcolor="white">
                            <h6>
                              Find or create a customer
                              <PersonAddIcon
                                style={{
                                  float: "right",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleClickOpen("AddCustomerModal")
                                }
                              />
                            </h6>
                            <ReactSearchAutocomplete
                              items={props.g_active}
                              fuseOptions={{
                                keys: ["name", "email"],
                              }}
                              resultStringKeyName="name"
                              onSearch={handleOnSearchCustomers}
                              onSelect={(item) => {
                                handleOnSelect(item, "customer");
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "25px",
                              }}
                            >
                              <Box>
                                <Popup
                                  title="Add Customer"
                                  contentTxt={<hr />}
                                  contentTxtBe={<hr />}
                                  btnVar="text"
                                  content={
                                    <AddCustomerModal
                                      onClickBtnClose={() =>
                                        setAddCustomerModal(false)
                                      }
                                      setCurrentCustomer={addItem}
                                    />
                                  } //component to send
                                  openPopup={addCustomerModal} //open
                                  closePop={() => setAddCustomerModal(false)} //close
                                  onClickBtnOpen={() =>
                                    setAddCustomerModal(true)
                                  } //on click open
                                  onClickBtnClose={() =>
                                    setAddCustomerModal(false)
                                  } //on click close
                                />
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </Paper>
                    </>
                  ) : (
                    <>
                      <Paper
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "red",
                          }}
                        >
                          <CancelIcon onClick={() => setCurrentCustomer("")} />
                        </Box>
                        <Box p={1}>
                          Customer
                          <Box>{currentCustomer.name}</Box>
                        </Box>
                        <hr />
                        <Box p={1}>
                          CONTACT INFORMATION
                          <Popup
                            title="Edit contact information"
                            btnText="Edit"
                            btnFloat="right"
                            marginTop="-30px"
                            btnColor="lightblue"
                            contentTxt={<hr />}
                            contentTxtBe={<hr />}
                            content={<EditInfoModal />}
                            openPopup={editInfoModal} //open
                            onClickBtnOpen={() =>
                              handleClickOpen("editInfoModal")
                            }
                            onClickBtnClose={() => handleClose("editInfoModal")}
                          />
                          <Box>
                            {currentCustomer.email +
                              "\n" +
                              currentCustomer.phoneNumber}
                          </Box>
                        </Box>

                        <hr />
                        <Box p={1}>
                          SHIPPING ADDRESS
                          <Popup
                            title="Edit shipping address"
                            btnText="Edit"
                            btnFloat="right"
                            marginTop="-30px"
                            btnColor="lightblue"
                            contentTxt={<hr />}
                            contentTxtBe={<hr />}
                            content={
                              <ShippingAddressModal
                                state={shippingAddressModal}
                              />
                            }
                            openPopup={shippingAddressModal} //open
                            onClickBtnOpen={() =>
                              handleClickOpen("shippingAddressModal")
                            }
                            onClickBtnClose={() =>
                              handleClose("shippingAddressModal")
                            }
                          />
                          <Box>
                            {currentCustomer.name +
                              "\n\n" +
                              currentCustomer.phoneNumber +
                              "\n\n" +
                              currentCustomer.address[0].street +
                              "\n\n" +
                              currentCustomer.address[0].stateOrProvince +
                              "\n\n" +
                              currentCustomer.address[0].postCode +
                              "\n\n" +
                              currentCustomer.address[0].country}
                          </Box>
                        </Box>

                        <hr />
                        <Box p={1}>
                          BILLING ADDRESS
                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              marginTop: "-10px",
                              float: "right",
                              textTransform: "none",
                            }}
                          >
                            Edit
                          </Button>
                          <Box>No billing address provided</Box>
                        </Box>
                      </Paper>
                    </>
                  )}
                </Grid>
              </div>
            </div>
          </div>

          <Backdrop
            style={{
              zIndex: 1,
              color: "#fff",
            }}
            open={backdrop}
          >
            <CircularProgress thickness={2} color="inherit" />
          </Backdrop>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* coming */}
            <Popup
              title="Add Discount"
              btnText="Add Discount"
              backgroundColor="lightGray"
              height={46}
              size="15px"
              btnFontSize={"15px"}
              marginTop={10}
              marginRight={10}
              contentTxt={<hr />}
              contentTxtBe={<hr />}
              content={
                <AddDiscount
                  onClickBtnClose={() => handleClose("addDiscountModal")}
                  setDiscount={adddDiscount}
                  setDiscountNew={setDiscount}
                  setDiscountNewReason={setDiscountReason}
                />
              } //component to send
              openPopup={addDiscountModal} //open
              closePop={() => handleClose("addDiscountModal")} //close
              onClickBtnOpen={() => handleClickOpen("addDiscountModal")} //on click open
              onClickBtnClose={() => handleClose("addDiscountModal")} //on click close
            />

            <button
              onClick={() => {
                lineItems();
                // setLoader(true);
              }}
              className="stripe-button"
              style={{
                // marginLeft: "3%",
                backgroundColor: SKY_BLUE,
              }}
            >
              Complete Checkout
            </button>
          </div>

          {printReciept && orderObject && (
            <>
              {(props?.company_info?.receipt_metadata?.printerPaperWidth ===
                "80mm" ||
                !props?.company_info?.receipt_metadata?.printerPaperWidth) && (
                <ThermalPrinterView
                  orderObject={orderObject}
                  customerObject={currentCustomer}
                  receiptMetadata={props?.company_info?.receipt_metadata}
                  onFinish={onPrintFinish}
                />
              )}
              {props?.company_info?.receipt_metadata?.printerPaperWidth ===
                "76mm" && (
                <DotMatrixPrinterView
                  orderObject={orderObject}
                  customerObject={currentCustomer}
                  receiptMetadata={props?.company_info?.receipt_metadata}
                  onFinish={onPrintFinish}
                />
              )}
            </>

            // <ThermalPrinterView
            //   orderObject={orderObject}
            //   customerObject={currentCustomer}
            //   receiptMetadata={props?.company_info?.receipt_metadata}
            //   onFinish={onPrintFinish}
            // />
          )}
          <div>
            {snackbar ? (
              <SnackBar message={snackBarMessage} type={snackbarType} />
            ) : null}
          </div>
        </>
      );
    }
  };
  const renderLoader = () => {
    return <Loader value="Creating Order..It may take a while" />;
  };
  const renderPage = () => {
    if (loader) {
      return renderLoader();
    } else {
      if (snackbar === true)
        setTimeout(() => {
          setSnackbar(false);
        }, 6000);
      return renderCheckoutPage();
    }
  };
  return renderPage();
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      emptyCart,
    },
    dispatch
  );
};
const mapStateToProps = (state) => {
  return {
    g_active: state.customersState.active_customers,
    g_orders: state.ordersState.orders,
    company_info: state.companyInfoState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
