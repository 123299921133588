import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Popup from "../Popup";
import ProductsTable from "../ProductsModule/ProductsTable";
import { MARS, WHITE } from "../../colors/colors";
import PurchaseProductsTable from "../ProductsModule/PurchaseProductsTable";

export default function Form(props) {
  const [openA, setOpenA] = React.useState(false);

  const handleClickOpen = (name) => {
    if (name === "A") {
      //console.log(name);
      setOpenA(true);
    }
  };

  const handleClose = (name) => {
    if (name === "A") {
      //console.log(name);
      setOpenA(false);
    }
  };
  console.log("Browse Product Props", props);
  return (
    <div>
      <ButtonGroup
        orientation="vertical"
        //color="black"
        fullWidth
      >
        <Popup
          title="Purchase Orders"
          btnText="PURCHASE ORDERS"
          btnVar="outlined"
          btnWidth={true}
          backgroundColor={MARS}
          btnColor={WHITE}
          // contentTxt={<hr />}
          //contentTxtBe={<hr />}
          content={
            <PurchaseProductsTable
              updateParent={props.updateParent}
              handleClose={handleClose}
              onClickBtnClose={() => handleClose("A")}
            />
          } //component to send
          openPopup={openA} //open
          closePop={() => handleClose("A")} //close
          onClickBtnOpen={() => handleClickOpen("A")} //on click open
          onClickBtnClose={() => handleClose("A")} //on click close
        />
      </ButtonGroup>
    </div>
  );
}
