import React, { Component } from "react";
import { Box, Button, OutlinedInput } from "@material-ui/core";
import Select from "react-select";
import CountryData from "react-select-country-list";

export default class CustomerAddressInfoModel extends Component {
  constructor(props) {
    super(props);
    this.options = CountryData().getData();
    this.state = {
      options: this.options,
      value: null,
      city: this.props.city,
      country: this.props.country,
      postCode: this.props.postCode,
      stateOrProvince: this.props.stateOrProvince,
      street: this.props.street,
    };
    this.handleChange = this.handleChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
  }
  changeHandler = (value) => {
    console.log(value);
    this.setState({
      country: value,
    });
  };
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    this.props.updateAddressInfo(this.state);
  };
  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Box margin="0px 0px 10px 10px">
            <Box margin="10px 0px 0px 0px">
              <Box>Address</Box>
              <OutlinedInput
                required
                style={{ width: "99%", height: 40, marginTop: "10px" }}
                name="street"
                type="text"
                value={this.state.street}
                onChange={this.handleChange}
              />
            </Box>
            <Box margin="10px 0px 0px 0px">
              <Box>State Or Province</Box>
              <OutlinedInput
                required
                style={{ width: "99%", height: 40, marginTop: "10px" }}
                name="stateOrProvince"
                type="text"
                value={this.state.stateOrProvince}
                onChange={this.handleChange}
              />
            </Box>
            <Box margin="10px 0px 0px 0px">
              <Box>City</Box>
              <OutlinedInput
                required
                style={{ width: "99%", height: 40, marginTop: "10px" }}
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.handleChange}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              bgcolor="white"
              margin="10px 0px 0px 0px"
            >
              <Box margin="0px 0px 0px 0px" width="45%">
                <Box>Country/Region</Box>
                <Select
                  //width="100%"

                  options={this.state.options}
                  value={this.state.country}
                  onChange={this.changeHandler}
                />
              </Box>
              <Box margin="0px 0px 0px 0px">
                <Box marginLeft="50px">Postal Code</Box>
                <OutlinedInput
                  style={{
                    width: "90%",
                    height: "50%",
                    marginLeft: "50px",
                    marginTop: "5px",
                  }}
                  name="postCode"
                  type="number"
                  value={this.state.postCode}
                  onChange={this.handleChange}
                />
              </Box>
            </Box>
            {<hr />}
            <Button
              style={{
                textTransform: "none",
                float: "right",
              }}
              onClick={this.handleSubmit}
              variant="contained"
              color="dark"
            >
              Save
            </Button>
            <Button
              style={{
                textTransform: "none",
                marginRight: "10px",
                float: "right",
              }}
              onClick={this.props.onClickBtnClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </form>
      </>
    );
  }
}
