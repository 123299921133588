import React from "react";
import PageTitle from "./PageTitle";
import { Grid, TextField, Button } from "@material-ui/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../ProductMobileComponents/AddProduct-Inventory.css";

const AddCustomerOverview = ({
  nextStep,
  prevStep,
  handleChange,
  values,
  ChangeRoute,
}) => {
  // console.log(values);
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };
  console.log(values);
  return (
    <>
      <div>
        <PageTitle
          textType="h6"
          value="ADD CUSTOMER"
          Icon={<ArrowBackIosNewIcon />}
          ChangeRoute={ChangeRoute}
        />
      </div>
      <div className="label spacing">
        <Grid container spacing={2}>
          {/* price */}
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Enter Name"
              label="Name"
              onChange={handleChange("name")}
              defaultValue={values.name}
              type="text"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="someone@example.com"
              label="Email"
              onChange={handleChange("email")}
              defaultValue={values.email}
              type="email"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="0331XXXXXXX"
              label="Phone Number"
              onChange={handleChange("phoneNumber")}
              defaultValue={values.phoneNumber}
              type="number"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div className="label">
        <form>
          <Grid container spacing={2}>
        
            <Grid item xs={12} sm={6}>
              <Button
                onClick={Continue}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default AddCustomerOverview;
