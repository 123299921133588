import React from 'react';
import AddUser from './AddUser'
import '../ProductMobileComponents/Product.css';
import ShowUsers from './ShowUsers'

class UserMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      error: null,
      step: 1,
      user: [],
      count: 0,
    };
  }
  reRender = () => {
    this.forceUpdate();
  };
  componentDidMount() {
    this.setState({ users: this.props.data})
    console.log(this.state.users)
    console.log("Mobile Comp Token",this.props.data)
  };


  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });

  }


  render() {
   console.log("user mobile users",this.state.users)
    switch (this.state.step) {
      case 1:
        return (
          <ShowUsers
            users={this.props.data}
            nextStep={this.nextStep}
            token={this.props.token}

          />

        )
      case 2:
        return (
          <AddUser
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            token={this.props.token}

          />
        )
      default:
      //do none
    };
  };
}

export default UserMobile;