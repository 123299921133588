import React, { Component } from "react";
import { Grid, Select, MenuItem, TextField, InputLabel } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const discountAppliedOn = [
  {
    POID: 1,
    value: "All Products",
  },
  {
    POID: 2,
    value: "Specific Products",
  },
];

const atDiscount = [
  {
    POID: 1,
    value: "Percentage",
  },
  {
    POID: 2,
    value: "Free",
  },
];

class CustomerGets extends Component {
  onHandleBack = () => {
    this.props.setStep(1);
  };
  nextStep = () => {
    this.props.setStep(11);
  };
  render() {
    const {
      quantityCustomerGets,
      appliesTo,
      selectedProductsCustomerGets,
      atDiscountValue,
      atDiscountValuePercentage
    } = this.props.values;
    return (
      <div>
        <div>
          <PageTitle
            textType="h6"
            value="Customer Gets"
            Icon={<ArrowBackIosNewIcon />}
            ChangeRoute={this.onHandleBack}
          />
        </div>
        <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
          <TextField
            id="standard-helperText"
            name="quantityCustomerGets"
            label="Quantity of items"
            value={quantityCustomerGets}
            onChange={this.props.handleChange}
            variant="standard"
            //placeholder="PKR0"
            size="large"
            fullWidth
            type="number"
          />
        </div>
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="12px">
          <InputLabel id="Input label">Any items</InputLabel>
          <Select
            labelId="Input label"
            id="Select"
            name="appliesTo"
            value={appliesTo}
            onChange={this.props.handleChange}
            size="large"
            variant="standard"
            fullWidth
          >
            {discountAppliedOn.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {appliesTo === "Specific Products" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                marginTop: "15px",
                marginLeft: "5px",
                marginBottom: "5px",
              }}
            >
              <div className="label-products">
                <label>Products</label>
              </div>
              {selectedProductsCustomerGets.length === 0 ? (
                <AddCircleOutlineIcon
                  onClick={this.nextStep}
                  color="success"
                  fontSize="medium"
                />
              ) : (
                <EditIcon
                  onClick={this.nextStep}
                  color="success"
                  fontSize="medium"
                />
              )}
            </div>
            {selectedProductsCustomerGets.length === 0 ? (
              <div
                style={{
                  width: "90%",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              >
                <p>No products Added</p>
              </div>
            ) : (
              selectedProductsCustomerGets.map((product) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "8px",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      width="35"
                      height="35"
                      src="https://cdn.iconscout.com/icon/free/png-256/gallery-187-902099.png"
                    />
                    <p style={{ marginLeft: "10px", textAlign: "center" }}>
                      {product.name.slice(0, 20)}
                    </p>
                  </div>
                  <div>
                    <CancelIcon
                      color="warning"
                      onClick={() => this.props.handleRemove(product.POID)}
                    />
                  </div>
                </div>
              ))
            )}
          </>
        ) : null}
        <Grid item xs={12} width="95%" marginLeft="5px" marginTop="12px">
          <InputLabel id="Input label">At discount value</InputLabel>
          <Select
            labelId="Input label"
            id="Select"
            name="atDiscountValue"
            value={atDiscountValue}
            onChange={this.props.handleChange}
            size="large"
            variant="standard"
            fullWidth
          >
            {atDiscount.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {atDiscountValue === "Percentage" && (
          <div style={{ marginTop: "20px", marginLeft: "5px", width: "95%" }}>
            <TextField
              id="standard-helperText"
              name="atDiscountValuePercentage"
              label="Value"
              value={atDiscountValuePercentage}
              onChange={this.props.handleChange}
              placeholder="%"
              variant="standard"
              size="large"
              fullWidth
            />
          </div>
        )}
      </div>
    );
  }
}

export default CustomerGets;
