import React from "react";
import ProductCard from "./Card";
import Typography from "@mui/material/Typography";

class InventoryItems extends React.Component {

  componentDidUpdate() {
    console.log(this.props.inventoryItems);
  }
  displayData = () => {
    const data =
      this.props.inventoryItems.length > 0 ? (
        this.props.inventoryItems.map(
          ({
            POID,
            name,
            description,
            quantity,
            value
          }) => {
            return (
              <div key={POID}>
                <ProductCard
                  POID={POID}
                  name={name}
                  description={description}
                  quantity={quantity}
                  value={value}
                  nextStep={this.props.nextStep}
                />{" "}
              </div>
            );
          }
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="subtitle1">
            Empty Inventory
          </Typography>
        </div>
      );
    return data;
  };
  render() {
    return (
      <div>
        {this.displayData()}
      </div>
    );
  }
}

InventoryItems.defaultProps = {
  inventoryItems: [],
};
export default InventoryItems;
