import React, { Component } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Button, Box } from "@material-ui/core"
export default class EditContactInfoModal extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    console.log(this.props.state.customer)
    console.log(props.customer)
    if(this.props.state.customer){
      this.state = {
        name: this.props.state.customer ? this.props.state.customer.name : props?.customer?.name,
        email: this.props.state.customer ? this.props.state.customer.email : props?.customer?.email,
        phone_number: this.props.state.customer ? this.props.state.customer.phoneNumber : props?.customer?.phoneNumber,
      };
    }
    else{
      this.state = {
        name: props?.customer?.name,
        email: props?.customer?.email,
        phone_number: props?.customer?.phoneNumber,
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.updateContactInfo(this.state);
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    console.log(nam + " " + val);
  };
  render() {
    return (
      <div onSubmit={this.handleSubmit}>
        <Box>
          <Box>
            <Box>Name</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40 }}
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" bgcolor="white">
          <Box>
            <Box>Email</Box>
            <OutlinedInput
              style={{ width: "260%", height: "50%" }}
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Box>Phone Number</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40 }}
              name="phone_number"
              type="number"
              value={this.state.phone_number}
              onChange={this.handleChange}
            />
          </Box>
          {<hr />}
          <Button
            style={{
              textTransform: "none",
              float: "right",
            }}
            onClick={this.handleSubmit}
            variant="contained"
            color="dark"
          >
            Save
          </Button>
      
        </Box>
      </div>
    );
  }
}
