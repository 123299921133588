import React from "react";
import AddCustomerOverView from "./AddCustomerOverview";
import AddCustomerAddress from "./AddCustomer-Address";
import GlobalConfig from "../../Config";
import SnackBar from "../ProductMobileComponents/Snackbar";
import Loader from "../Loader";
import Store from "../../index";
import { connect } from "react-redux";
import helpers from "../../UtilitiesFunctions";

class AddCustomer extends React.Component {
    state = {
        step: 1,
        snackbar: false,
        snackbarMessage: "",
        snackbarType: "",
        loading: false,
        email: "",
        name: "",
        phoneNumber: "",
        status: "",

        city: "",
        country: "",
        postCode: "",
        stateOrProvince: "",
        street: "",
        preffered: true,
    };
    changeRouteToCustomers = () => {
        const history = this.props.history;
        console.log("Changing route to view customers...");
        history.push("/Customers");
    };
    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    };

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    };

    // Handle fields change
    handleChange = (input) => (e) => {
        console.log("key is: " + input);
        console.log(e.target.value);
        this.setState({ [input]: e.target.value });
    };
    
    setSnackbarToFalse = () => {
        setTimeout(() => {
            this.setState({ snackbar: false });
        }, 3000);
    };

    getSpecificCustomer = (customerId) => {
        console.log("in getSpecificProduct function");
        var url = this.props.global_config.basePath + "/customers/" + customerId;
        console.log("url: " + url);
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer token",
            },
        })
            .then((response2) => response2.json())
            .then((data2) => {
                console.log("dispatching product in redux store");
                Store.dispatch({
                    type: "POST_ACTIVE_CUSTOMERS",
                    active_customers: data2,
                });
                this.changeRouteToCustomers();
            })
            .catch((error) => {
                this.changeRouteToCustomers();

                helpers.toastNotify("error", "Error Occured!");

                this.setState({
                    snackbar: false,
                    snackbarMessage: "unable to set product in redux store",
                    snackbarType: "error",
                });
            });
    };
    postCustomer = () => {
        const customer = JSON.stringify(this.createObjectForPosting());
        console.log("object ready for posting is: ", customer);
        //setLoader to true
        this.setState({ loading: true });
        fetch(this.props.global_config.basePath + "/customers", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
            },
            body: customer,
        }).then((response) => {
            response
                .json()
                .then((resp) => {
                    console.log("response is ", resp);
                    const createdCustomerId = resp.POID;
                    this.getSpecificCustomer(createdCustomerId);

                    helpers.toastNotify("success", "Customer Successfully Created!");

                    this.setState({
                        snackbar: false,
                        snackbarMessage: "Customer Created Successfully",
                        snackbarType: "success",
                        loading: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        snackbar: false,
                        snackbarMessage: "Something went wrong! unable to create customer",
                        snackbarType: "error",
                        loading: false,
                    });

                    helpers.toastNotify("error", "Could Not Create Customer.");
                });

           
        });
    };

    createObjectForPosting = () => {
        const { email, name, phoneNumber, city, country, postCode, stateOrProvince, street } =
            this.state;
        const customer = {
            name: name,
            phoneNumber: phoneNumber,
            status: "Active",
            email: email,
            address: [
                {
                    preferred: true,
                    city: city,
                    country: country,
                    postCode: postCode,
                    stateOrProvince: stateOrProvince,
                    street: street,
                },
            ],
        };
        return customer;
    };

    render() {
        const {
            step,
            email,
            name,
            phoneNumber,
            status,
            city,
            country,
            postCode,
            stateOrProvince,
            street,
            snackbar,
            snackbarMessage,
            snackbarType,
            loading,
        } = this.state;
        const values = {
            email,
            name,
            phoneNumber,
            status,
            city,
            country,
            postCode,
            stateOrProvince,
            street,
        };
        if (snackbar) {
            console.log("in snackbar condiiton");
            this.setSnackbarToFalse();
        }
        switch (step) {
            case 1:
                return (
                    <AddCustomerOverView
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values}
                        ChangeRoute={this.changeRouteToCustomers}
                    />
                );
            case 2:
                return (
                    <>
                        {loading ? (
                            <Loader value={"Creating Customer.."} />
                        ) : (
                            <>
                                <AddCustomerAddress
                                    nextStep={this.nextStep}
                                    prevStep={this.prevStep}
                                    handleChange={this.handleChange}
                                    values={values}
                                    handlePost={this.postCustomer}
                                />
                                <div>
                                    {snackbar ? (
                                        <SnackBar message={snackbarMessage} type={snackbarType} />
                                    ) : null}
                                </div>
                            </>
                        )}
                    </>
                );
            default:
        }
    }
}

const mapStateToProps = (state) => {
    return {
        global_config: state.globalState,
    };
};
export default connect(mapStateToProps)(AddCustomer);
