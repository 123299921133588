import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function SwitchLabels(props) {
    const [check, setCheck] = useState(false);

    const handleChange = (event) => {
        console.log(event.target.checked);
        setCheck(event.target.checked);
    };
    return (
        <FormGroup>
            <FormControlLabel control={<Switch color="success" checked={check} onChange={handleChange} />} label={props.label} />
        </FormGroup>
    );
}

SwitchLabels.defaultProps = {
    label: 'name',
};
