import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export default function Tax(props) {
  return (
    <div width="50%">
      <FormControlLabel
        control={<Checkbox name="checkedB" color="primary" />}
        label="Charge taxes"
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={props.onClickBtnClose}
        >
          Cancel
        </Button>
        <Button
          style={{ textTransform: "none", marginLeft: "10px" }}
          variant="contained"
        >
          Apply
        </Button>
      </Box>
    </div>
  );
}
