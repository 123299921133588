import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//

export default function CustomizedSnackbars(props) {
    const [open, setOpen] = React.useState(true);

    // const handleClick = () => {
    //     setOpen(true);
    //     console.log('on click called');
    // };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        console.log('handle close called & reason: ' + reason);

    };

    const { message, type } = props;
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

CustomizedSnackbars.defaultProps = {
    message: 'this is sample message',
    type: "success",
};
