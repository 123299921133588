export function getDraftOrders(orders) {
    console.log("orders to be filtered are: ", orders.length);
    const filteredOrders = orders.filter((order) => { return order.draft === true });
    console.log("filtered draft orders are: ", filteredOrders.length);
    return filteredOrders;
};

export function getOpenOrders(orders) {
    console.log("orders to be filtered are: ", orders.length);
    const filteredOrders = orders.filter((order) => { return order.active === true });
    console.log("filtered opened orders are: ", filteredOrders.length);
    return filteredOrders;
};

export function getCloseOrders(orders) {
    console.log("orders to be filtered are: ", orders.length);
    const filteredOrders = orders.filter((order) => { return order.close === true });
    console.log("filtered closed orders are: ", filteredOrders.length);
    return filteredOrders;
};

export function getUnpaidOrders(orders) {
    console.log("orders to be filtered are: ", orders.length);
    const filteredOrders = orders.filter((order) => { return order.paid === false });
    console.log("filtered unpaid orders are: ", filteredOrders.length);
    return filteredOrders;
};

export function getUnfulfilledOrders(orders) {
    console.log("orders to be filtered are: ", orders.length);
    const filteredOrders = orders.filter((order) => { return order.fulfilled === false });
    console.log("filtered unfulfilled orders are: ", filteredOrders.length);
    return filteredOrders;
};





