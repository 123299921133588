const initial_state = {
  products: [],
  active_products: [],
  draft_products: [],
  archived_products: [],
  categories: [],
};

const productsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_CATEGORIES":
      console.log(action);
      return Object.assign({}, state, {
        categories: action.categories,
      });
    case "GET_PRODUCTS":
      console.log(action);
      return Object.assign({}, state, {
        products: action.products,
      });
    case "POST_PRODUCTS":
      
      if (action.products.status == "Active") {
        return {
          ...state,
          active_products: state.active_products.concat(action.products),
          products: state.products.concat(action.products),
        };
      } else {
        return {
          ...state,
          draft_products: state.draft_products.concat(action.products),
          products: state.products.concat(action.products),
        };
      }
    case "GET_ACTIVE_PRODUCTS":
      return Object.assign({}, state, {
        active_products: action.active_products,
      });
    case "GET_DRAFT_PRODUCTS":
      return Object.assign({}, state, {
        draft_products: action.draft_products,
      });
    case "GET_ARCHIVED_PRODUCTS":
      return Object.assign({}, state, {
        archived_products: action.archived_products,
      });
    case "POST_ACTIVE_PRODUCTS":
      return {
        ...state,
        active_products: [...state.active_products, action.active_products],
      };
    case "POST_DRAFT_PRODUCTS":
      return {
        ...state,
        draft_products: [...state.draft_products, action.draft_products],
      };
    case "POST_ARCHIVED_PRODUCTS":
      return {
        ...state,
        archived_products: [
          ...state.archived_products,
          action.archived_products,
        ],
      };

    case "EDIT_ACTIVE_PRODUCTS_QUANTITY": {
      let updated_product = action.updated_product[0];

    


      let product = state.products?.filter(
        (product) => product?.POID === updated_product?.childOf === "null" ? updated_product?.POID : updated_product?.childOf
      );

      if(updated_product?.childOf === "null"){
        product = product?.varients?.filter((child) => child?.POID === updated_product?.POID)
      }


      if (product[0].quantity !== updated_product.quantity) {
        product[0].quantity = updated_product.quantity;
      }
      return state;
    }


    case "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB": {
      let updated_product = action.updated_product;
      let product = state.products?.filter(
        (product) => product?.POID === updated_product?.childOf === "null" ? updated_product?.POID : updated_product?.childOf
      );

      if(updated_product?.childOf === "null"){
        product = product?.filter((child) => child?.POID === updated_product?.POID)
      }

      console.log("updated_product from reducer",updated_product)
      if (product[0]?.quantity !== updated_product?.quantity) {
        product[0].quantity = updated_product?.quantity;
      }
      return state;
    }
    case "EDIT_ACTIVE_PRODUCTS_QUANTITY_ON_ORDER": {
      for (let i = 0; i < action.id.length; i++) {
        let product = state.products.filter(
          (product) => product.POID === action.id[i]
        );
        console.log("The Product", product);
        console.log(action);
        if (product.length > 0) {
          if (action.add !== undefined) {
            product[0].quantity = product[0].quantity + action.quantity[i];
          } else {
            product[0].quantity = product[0]?.quantity - action?.quantity[i];
          }
        }
      }

      return state;
    }

    case "SUBTRACT_ACTIVE_MULTIPLE_PRODUCTS_QUANTITY_ON_ORDER": {
      for (let i = 0; i < action.orders.length; i++) {
        state.products.some((product) => {
          if (product.POID == action.orders[i].productId) {
            product.quantity = product?.quantity - action?.orders[i].quantity;
          }
        });
      }

      return state;
    }

    case "EDIT_ACTIVE_PRODUCTS": {
      let updated_product = action.updated_product;
      let newProduct = state.products.map((product) => {
        let returnVal = { ...product };
        if (product.id == updated_product[0].id) {
          returnVal = updated_product[0];
        }

        return returnVal;
      });

   
      return {
        ...state,
        products: newProduct,
      };
    }
    case "DELETE_ACTIVE_PRODUCTS":
      let prods = state.active_products.filter(
        (product) => product.POID !== action.POID
      );
      return {
        ...state,
        active_products: prods,
      };

    case "CLEAR_PRODUCT_DATA":
      return initial_state;

    case "CLEAR_DATA":
      state = {
        // products: [],
        active_products: [],
        // draft_products: [],
        // archived_products: [],
        // categories: [],
      };

    default:
      return state;
  }
};

export default productsReducer;
