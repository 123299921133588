import React, { Component } from "react";
import CreateDiscount from "./CreateDiscount";
import AppliesTo from "./AppliesTo";
import GlobalConfig from "../../Config";
import ProductsForDiscount from "./ProductsForDiscount";
import MinimumRequirements from "./MinimumRequirements";
import CustomerEligibility from "./CustomerEligibility";
import CustomersForDiscount from "./CustomersForDiscount";
import SelectDates from "./SelectDates";
import CustomerBuys from "./CustomerBuys";
import ProductsCustomerBuys from "./ProductsCustomerBuys";
import CustomerGets from "./CustomerGets";
import ProductsCustomerGets from "./ProductsCustomerGets";
import Store from '../../index'
import { connect } from "react-redux";

class Discount extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      discountCode: "",
      discountType: "Percentage",
      discountPercentage: null,
      appliesTo: "All Products",
      discountAmount: null,
      selectedProducts: [],
      products: [
        { POID: "1", name: "mouse", status: "Active", price: 340 },
        { POID: "2", name: "handsfree", status: "Active", price: 250 },
        { POID: "3", name: "charger", status: "Active", price: 290 },
      ],
      loading: true,
      quantityOrAmount: "Minimum purchase amount",
      minimumQuantityOrAmountToBuy: null,
      amount: null,
      quantity: null,
      customersLoading: false,
      customers: [
        { POID: "11", name: "usman" },
        { POID: "12", name: "zohain" },
        { POID: "13", name: "shayan" },
      ],
      customerEligibility: "EveryOne",
      selectedCustomers: [],
      startDate: new Date(),
      endDate: new Date(),
      productsCustomerBuys: [
        { POID: "1", name: "mouse", status: "Active", price: 340 },
        { POID: "2", name: "handsfree", status: "Active", price: 250 },
        { POID: "3", name: "charger", status: "Active", price: 290 },
      ],
      selectedProductsCustomerBuys: [],
      productsCustomerGets: [
        { POID: "1", name: "mouse", status: "Active", price: 340 },
        { POID: "2", name: "handsfree", status: "Active", price: 250 },
        { POID: "3", name: "charger", status: "Active", price: 290 },
      ],
      selectedProductsCustomerGets: [],
      quantityCustomerGets: null,
      atDiscountValue: "Free",
      atDiscountValuePercentage: null,
    };
  }

  componentDidMount() {
    if (this.props.g_active_products.length == 0) {
    let url = this.props.global_config.basePath + "/products?status=Active";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          products: data,
          loading: false,
          productsCustomerBuys:data,
          productsCustomerGets:data
        });
        Store.dispatch({ type: "GET_PRODUCTS", products: data });
      });
    }
    if (this.props.g_active.length === 0) {
    fetch(this.props.global_config.basePath + "/customers?status=Active", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          customers: data,
          customersLoading: false,
        });
        Store.dispatch({
          type: "GET_ACTIVE_CUSTOMERS",
          active_customers: data,
        });
      }).catch((e)=>{
        console.log(e)
      })
    }
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  setStep = (steps) => {
    const { step } = this.state;
    this.setState({ step: steps });
  };

  handleDropDown = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeProduct = (id) => {
    let products = this.state.products.filter((product) => {
      if (product.POID === id) {
        product.checked = !product.checked;
        return product;
      } else return product;
    });
    this.setState({ products: products });
    let selectedProducts = this.state.products.filter(
      (product) => product.checked === true
    );
    this.setState({ selectedProducts: selectedProducts });
  };

  handleRemoveSelectedProduct = (id) => {
    let selectedProducts = this.state.selectedProducts.filter(
      (product) => product.POID !== id
    );
    let products = this.state.products.filter((product) => {
      if (product.POID === id) {
        product.checked = !product.checked;
        return product;
      } else return product;
    });
    this.setState({ selectedProducts: selectedProducts });
    this.setState({ products: products });
  };

  handleRemoveSelectedCustomer = (id) => {
    let selectedCustomers = this.state.selectedCustomers.filter(
      (customer) => customer.POID !== id
    );
    let customers = this.state.customers.filter((customer) => {
      if (customer.POID === id) {
        customer.checked = !customer.checked;
        return customer;
      } else return customer;
    });
    this.setState({
      selectedCustomers: selectedCustomers,
      customers: customers,
    });
  };

  handleChangeCustomer = (id) => {
    let customers = this.state.customers.filter((customer) => {
      if (customer.POID === id) {
        customer.checked = !customer.checked;
        return customer;
      } else return customer;
    });
    this.setState({ customers: customers });
    let selectedCustomers = this.state.customers.filter(
      (customer) => customer.checked === true
    );
    this.setState({ selectedCustomers: selectedCustomers });
  };

  handleStartDate = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDate = (date) => {
    this.setState({ endDate: date });
  };

  handleChangeProductCustomerBuys = (id) => {
    let products = this.state.productsCustomerBuys.filter((product) => {
      if (product.POID === id) {
        product.checked = !product.checked;
        return product;
      } else return product;
    });
    this.setState({ productsCustomerBuys: products });
    let selectedProducts = this.state.productsCustomerBuys.filter(
      (product) => product.checked === true
    );
    this.setState({ selectedProductsCustomerBuys: selectedProducts });
  };
  handleRemoveProductCustomerBuys = (id) => {
    let selectedProducts = this.state.selectedProductsCustomerBuys.filter(
      (product) => product.POID !== id
    );
    let products = this.state.productsCustomerBuys.filter((product) => {
      if (product.POID === id) {
        product.checked = !product.checked;
        return product;
      } else return product;
    });
    this.setState({ selectedProductsCustomerBuys: selectedProducts });
    this.setState({ products: products });
  };

  handleChangeProductCustomerGets = (id) => {
    let products = this.state.productsCustomerGets.filter((product) => {
      if (product.POID === id) {
        product.checked = !product.checked;
        return product;
      } else return product;
    });
    this.setState({ productsCustomerGets: products });
    let selectedProducts = this.state.productsCustomerGets.filter(
      (product) => product.checked === true
    );
    this.setState({ selectedProductsCustomerGets: selectedProducts });
  };
  handleRemoveProductCustomerGets = (id) => {
    let selectedProducts = this.state.selectedProductsCustomerGets.filter(
      (product) => product.POID !== id
    );
    let products = this.state.productsCustomerGets.filter((product) => {
      if (product.POID === id) {
        product.checked = !product.checked;
        return product;
      } else return product;
    });
    this.setState({ selectedProductsCustomerGets: selectedProducts });
    this.setState({ products: products });
  };
  render() {
    const {
      step,
      discountCode,
      discountType,
      discountPercentage,
      appliesTo,
      selectedProducts,
      discountAmount,
      products,
      loading,
      quantityOrAmount,
      minimumQuantityOrAmountToBuy,
      amount,
      quantity,
      customerEligibility,
      selectedCustomers,
      customersLoading,
      startDate,
      endDate,
      selectedProductsCustomerBuys,
      productsCustomerBuys,
      productsCustomerGets,
      selectedProductsCustomerGets,
      quantityCustomerGets,
      atDiscountValue,
      atDiscountValuePercentage,
    } = this.state;
    const customers = this.props.g_active
    const values = {
      discountCode,
      discountType,
      discountPercentage,
      appliesTo,
      selectedProducts,
      discountAmount,
      products,
      loading,
      quantityOrAmount,
      minimumQuantityOrAmountToBuy,
      amount,
      quantity,
      customerEligibility,
      customers,
      selectedCustomers,
      customersLoading,
      startDate,
      endDate,
      selectedProductsCustomerBuys,
      productsCustomerBuys,
      productsCustomerGets,
      selectedProductsCustomerGets,
      quantityCustomerGets,
      atDiscountValue,
      atDiscountValuePercentage,
    };
    console.log("values from props", this.state);
    // eslint-disable-next-line default-case
    switch (step) {
      case 1:
        return (
          <CreateDiscount
            values={values}
            nextStep={this.nextStep}
            handleChange={this.handleDropDown}
            setStep={this.setStep}
            history={this.props.history}
            pageTitle="Create Discount Code"
            discountLabel="Discount Code"
          />
        );
      case 2:
        return (
          <AppliesTo
            values={values}
            handleChange={this.handleDropDown}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleRemove={this.handleRemoveSelectedProduct}
          />
        );

      case 3:
        return (
          <ProductsForDiscount
            values={values}
            handleChange={this.handleChangeProduct}
            prevStep={this.prevStep}
            setStep={this.setStep}
          />
        );
      case 4:
        return (
          <MinimumRequirements
            values={values}
            handleChange={this.handleDropDown}
            setStep={this.setStep}
          />
        );

      case 5:
        return (
          <CustomerEligibility
            values={values}
            handleChange={this.handleDropDown}
            setStep={this.setStep}
            nextStep={this.nextStep}
            handleRemove={this.handleRemoveSelectedCustomer}
          />
        );
      case 6:
        return (
          <CustomersForDiscount
            values={values}
            handleChange={this.handleChangeCustomer}
            prevStep={this.prevStep}
          />
        );
      case 7:
        return (
          <SelectDates
            values={values}
            handleChangeStartDate={this.handleStartDate}
            handleChangeEndDate={this.handleEndDate}
            setStep={this.setStep}
          />
        );
      case 8:
        return (
          <CustomerBuys
            values={values}
            handleChange={this.handleDropDown}
            setStep={this.setStep}
            handleRemove={this.handleRemoveProductCustomerBuys}
          />
        );
      case 9:
        return (
          <ProductsCustomerBuys
            values={values}
            setStep={this.setStep}
            handleChange={this.handleChangeProductCustomerBuys}
          />
        );
      case 10:
        return (
          <CustomerGets
            values={values}
            setStep={this.setStep}
            handleChange={this.handleDropDown}
            handleRemove={this.handleRemoveProductCustomerGets}
          />
        );
      case 11:
        return (
          <ProductsCustomerGets
            values={values}
            setStep={this.setStep}
            handleChange={this.handleChangeProductCustomerGets}
          />
        );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    g_active_products: state.productsState.active_products,
    g_active: state.customersState.active_customers,
    global_config: state.globalState
  };
};

export default connect(mapStateToProps, null)(Discount);
