import React, { Component } from "react";
import GlobalConfig from "../../Config";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Store from "../../index";

const useStyles = (theme) => ({
  dataGrid: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    //color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    width: "640px",
  },
});

class CustomersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      selectedRows: [],
      loader: true,
      variant: 0,
      btnToggle: true,
      addBtn: false,
      modelRows: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.g_active.length === 0) {
      let url = this.props.global_config.basePath + "/customers";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((result) => {
          result
            .clone()
            .text()
            .then((resp) => {
              if (resp === "No products in Product Catalog") {
                console.log(true);
              } else {
                console.log(resp);
                this.setState({ customers: JSON.parse(resp), loader: false });
              }
            });
          result.json().then((resp) => {
            console.log(resp);
            Store.dispatch({
              type: "GET_ACTIVE_CUSTOMERS",
              active_customers: resp,
            });
            this.setState({ customers: resp, loader: false });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({ customers: this.props.g_active, loader: false });
    }
  }

  onSelectCustomer = (selectedCustomerRows) => {
    this.setState({
      modelRows: selectedCustomerRows,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let newSelectedCustomer = this.state.selectedRows;

    console.log("old customs",newSelectedCustomer);

    this.state.modelRows.selectionModel.map((rowId) => {
      newSelectedCustomer = newSelectedCustomer.concat({
        POID: this.state.customers.flat()[rowId].POID,
        name: this.state.customers.flat()[rowId].name,
      });
    });
    this.props.updateParent(
      // this.state.selectedRows,
      newSelectedCustomer,
      "dbItem",
      this.state.customers
    );
    // this.props.handleClose("A");
  };

  onDelete = (e) => {
    const id = e.POID;
    this.setState({
      selectedRows: this.state.selectedRows.filter((item) => item.POID !== id),
    });
  };

  render() {
    const items = [
      {
        id: 0,
        name: "Cobol",
      },
      {
        id: 1,
        name: "JavaScript",
      },
      {
        id: 2,
        name: "Basic",
      },
      {
        id: 3,
        name: "PHP",
      },
      {
        id: 4,
        name: "Java",
      },
    ];

    const handleOnSearch = (string, results) => {
      
      console.log(string, results);
    };

   

    const columns = [
      { field: "id", headerName: "ID", width: 150 },
      { field: "name", headerName: " Name", width: 150 },
     
    ];

    const data = this.state;
    const obj = data.customers;
    //console.log(this.props);

    const rows = [];

    for (var i = 0; i < obj.length; i++) {
      rows[i] = {
        id: i,
        POID: obj[i].POID,
        name: obj[i].name,
       
      };
    }

    return (
      <div>
        <Box style={{ margin: "10px" }}>
          <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
          
          />
        </Box>
        <DataGrid
          style={{ position: 'relative' }}
          checkboxSelection
          onSelectionModelChange={(selectedRowArrIndex) =>
            this.onSelectCustomer(selectedRowArrIndex)
          }
          autoHeight={true}
          loading={this.state.loader}
          //className={classes.dataGrid}
          rows={rows}
          columns={columns}
          pageSize={10}
        
        />
        <Box display="flex" style={{position: 'absolute', width: '100%', bottom: '0', left: '0'}} p={1}>
          <Box p={1} flexGrow={1}>
            <Button
              style={{ textTransform: "none" }}
              variant="outlined"
              disabled={this.state.btnToggle}
            >
              {this.state.variant} Variants selected
            </Button>
          </Box>
          <Box p={1}>
            <Button
              onClick={this.props.onClickBtnClose}
              style={{ textTransform: "none" }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
          <Box p={1}>
            <Button
              style={{ textTransform: "none" }}
              variant="outlined"
              onClick={this.handleSubmit}
              disabled={this.state.addBtn}
            >
              Add
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_active: state.customersState.active_customers,
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(useStyles)(CustomersTable));
