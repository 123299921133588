import CircularProgress from "@material-ui/core/CircularProgress";
import { SKY_BLUE } from '../colors/colors';
const Loader = (props) => {
    return (
        <div
            style={{
                textAlign: "center",
                alignSelf: "center",
                //  backgroundColor:"red",
                marginTop: "250px",
            }}
        >
            <CircularProgress
                size={40}
                thickness={2.5}
                style={{ color: SKY_BLUE }}
            />
            <h6>{props.value}</h6>
        </div>
    );
};

Loader.defaultProps = {
    value: 'Loading..'
};

export default Loader;