import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import helpers from "../../UtilitiesFunctions";
import GlobalConfig from "../../Config";
import Store from "../../index";
import { useState } from "react";
import { Toaster } from "react-hot-toast";

function CancelOrder(props) {
  const { id } = useParams();
  let [baseUrl, setBaseUrl] = useState("");

  // on page load
  useEffect(() => {
    console.log(props);

    const initalizeCancelOrder = async () => {
      await Store.dispatch({ type: "GET_BASE_URL", text: "" });
      console.log(Store.getState().globalState);
      const url = await Store.getState().globalState;
      await setBaseUrl(Store.getState().globalState);
      await fetch(`${url}/orders/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response2) => {
          console.log(response2);
          if (!response2.ok) {
            helpers.toastNotify("error", "Cannot Fetch Order!");
            props.history.push(`/viewOrder/${id}`);
            throw new Error("Something went wrong");
          }
          response2.json();
        })
        .then((data2) => {})
        .catch((e) => {
          console.log(e);
        });
    };
    initalizeCancelOrder();
  }, []);

  return (
    <div className="cancelOrder">
      <h1>asdsad</h1>
    </div>
  );
}

export default CancelOrder;
