import React, { Component } from "react";
import GlobalConfig from "../../Config";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { green, grey } from "@material-ui/core/colors";
import helpers from "../../UtilitiesFunctions";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { connect } from "react-redux";
import Loader from "../../MobileComponents/Loader";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import * as localForage from "localforage";

import { Link } from "react-router-dom";
import { CreateRounded, PrintOutlined } from "@material-ui/icons";
import { QrCode, ReceiptLong, SyncOutlined } from "@mui/icons-material";
import { ReceiptEditModal } from "../../components/print/receiptEditModal";
import { ThermalPrinterView } from "../../components/print/thermalPrinterView";
import { DotMatrixPrinterView } from "../../components/print/dotMatrixPrinterView";
import Store from "../../index.js";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  cardRoot: {
    maxWidth: 345,
    marginBottom: "10px",
    padding: "0px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: green[500],
  },
  ordersecondheader: {
    // marginLeft: "50%",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    // width: "100%",

    [theme.breakpoints.only("xs")]: {
      justifyContent: "flex-start",
      display: "flex",
      flexDirection: "row",
      marginLeft: "0%",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ViewOrders extends Component {
  constructor(props) {
    super(props);
    console.log("ORDERS PROPS: ", this.props);
    this.state = {
      printReciept: false,
      showReceiptEditModal: false,
      goingForPrinting: false,
      ordersFetched: false,
      categoryModal: false,
      orders: this.props.g_orders ? this.props.g_orders : [],
      singleOrderDetail: [],
      rows: [],
      dataRows: [],
      loader: false,
      syncingLoader: true,
      returnedItems: [],
      customerData: null,
      filterOrders: this.props.g_orders ? this.props.g_orders : [],
      syncOrderBtn: true,
      syncBtnLoader: false,
      indexDbOrders: 0,
      indexDbOrdersKeys: [],
      customerdetail: [],
      tempOrder: [
        {
          expanded: false,
          active: true,
          close: false,
          createdAt: "2021.09.20.16.28.15.764",
          customerId: "2",
          draft: false,
          fulfilled: false,
          fulfillmentDate: "null",
          lineItems: [
            {
              costPrice: 75,
              fulfilled: false,
              fulfillmentDate: "null",
              id: "1031",
              price: 80,
              productId: "1",
              productName: "Lays Chilli Flavour",
              quantity: 4,
              rejected: false,
              returned: false,
              status: "Unfulfilled",
              subTotal: 320,
              tax: 11,
              taxAmount: 35.2,
              total: 355.2,
              unitOfMeasure: "1",
            },
            {
              costPrice: 9,
              fulfilled: false,
              fulfillmentDate: "null",
              id: "1032",
              price: 10,
              productId: "2",
              productName: "Prince Biscuit",
              quantity: 5,
              rejected: false,
              returned: false,
              status: "Unfulfilled",
              subTotal: 50,
              tax: 11,
              taxAmount: 5.5,
              total: 55.5,
              unitOfMeasure: "2",
            },
          ],
          orderId: "1027",
          paid: true,
          partiallyFulfilled: false,
          paymentDate: "2021-09-20",
          paymentId: "17",
          returned: false,
          returnedAmount: 0,
          status: "Active",
          subTotal: 370,
          taxAmount: 40.700012,
          taxRate: 11.000004,
          total: 410.7,
        },
        {
          expanded: true,
          active: false,
          close: true,
          createdAt: "2021.09.15.18.34.40.286",
          customerId: "2",
          draft: false,
          fulfilled: true,
          fulfillmentDate: "2021-09-16",
          lineItems: [
            {
              costPrice: 75,
              fulfilled: true,
              fulfillmentDate: "2021-09-16",
              id: "1021",
              price: 80,
              productId: "1",
              productName: "Lays Chilli Flavour",
              quantity: 1,
              rejected: false,
              returned: false,
              status: "fulfilled",
              subTotal: 80,
              tax: 11,
              taxAmount: 8.8,
              total: 88.8,
              unitOfMeasure: "1",
            },
          ],
          orderId: "1020",
          paid: true,
          partiallyFulfilled: false,
          paymentDate: "2021-09-16",
          paymentId: "7",
          returned: false,
          returnedAmount: 0,
          status: "Closed",
          subTotal: 80,
          taxAmount: 8.800003,
          taxRate: 11.000004,
          total: 88.8,
        },
      ],
      filterOrder: [],
      expanded: false,
    };
  }

  scanBarCodeOrOrderID = (barCode) => {
    const foundOrders = this.state?.orders?.filter(
      (order) => order.orderId === barCode
    );

    if (foundOrders.length > 0) {
      this.setState({ singleOrderDetail: [foundOrders[0]] }, () => {
        this.openCategoryModal();
      });
      if (foundOrders.length > 1) {
        helpers.toastNotify(
          "warning",
          "More than one orders were found with same order id / barcode; showing top most order."
        );
      }
    } else if (foundOrders.length === 0) {
      helpers.toastNotify(
        "error",
        "No orders were found with given barcode / order id!"
      );
    }
  };

  componentDidMount() {
    console.log("here is customer", this.props.customer.active_customers);
    if (this.props.customer.active_customers?.length === 0) {
      this.fetchCustomerifnotExists();
    }
    this.orderstosync();
    this.timer = setInterval(() => {
      this.orderstosync();
    }, 600000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  fetchCustomerifnotExists() {
    fetch(`${this.props.global_config.basePath}/customers?status=Active`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          customers: data,
          previousCustomers: data,
          loader: false,
        });

        Store.dispatch({
          type: "GET_ACTIVE_CUSTOMERS",
          active_customers: data,
        });
      })
      .catch((err) => {
        // let localCustomers = JSON.parse(localStorage.getItem("customers"));
        this.setState({
          //  customers: localCustomers,
          loader: false,
        });
      });
  }

  orderstosync() {
    this.setState({
      syncingLoader: true,
    });
    fetch(`${this.props.global_config.basePath}/orders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log(data);
        if (data.length > 0) {
          this.setState({
            orders: data,
            syncingLoader: false,
          });
          Store.dispatch({ type: "GET_ORDERS", orders: data });
        } else {
          this.setState({
            syncingLoader: false,
          });
          Store.dispatch({ type: "GET_ORDERS", orders: [] });
          helpers.toastNotify("error", "Orders Not Found!");
        }
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          syncingLoader: false,
        });
        helpers.toastNotify("error", "Error Fetching Orders!");
      });
  }

  checkRecordsInDb = () => {
    let keyArray = [];
    localForage
      .iterate((value, key, iterationNumber) => {
        keyArray.push(iterationNumber);
      })
      .then(() => {
        console.log("Iteration has completed", keyArray);
        if (keyArray.length > 0) {
          this.setState({
            syncOrderBtn: false,
            indexDbOrders: keyArray.length,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  addItem = (item) => {
    console.log(item);
    this.setState(function (currentState) {
      return { returnedItems: [...currentState.returnedItems, item] };
    });
  };

  getReturnedItems = (items) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].returned) {
        this.addItem(items[i]);
      }
    }
  };
  onHandleBack = (props) => {
    this.props.history.push("/");
  };

  onPrintFinish = () => {
    this.setState({ printReciept: false });
  };

  findCustomerById = (id) => {
    const customerName = this.props.customer.active_customers.filter(
      (customer) => customer.id === id
    );
    return customerName[0];
  };

  openCategoryModal = () => {
    this.setState({ categoryModal: true });
  };
  closeCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  handleClick = () => {
    this.setState({ open: !this.setState.open });
  };
  postOrders(e) {
    let orderObject = {};
    let orderKey = "";
    if (navigator.onLine) {
      this.setState({ syncBtnLoader: true });
      localForage
        .iterate((value, key, iterationNumber) => {
          console.log([key, value]);
          Object.assign(orderObject, value);
          orderKey = key;
          fetch(this.props.global_config.basePath + "/orders", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
            // convert the React state to JSON and send it as the POST body
            body: JSON.stringify(orderObject),
          })
            .then((response) => {
              if (response.status === 201) {
                localForage.removeItem(orderKey, (err, value) => {
                  console.log(err, value);
                });
              }
            })
            .then((response) => {
              localForage.clear();
            })
            .then(() => {
              this.setState({
                syncOrderBtn: true,
                syncBtnLoader: false,
                indexDbOrders: 0,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          console.log("orderKey", orderKey);
          console.log("orderObject", orderObject);
        })
        .then(() => {
          console.log("Iteration has completed");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // alert("No Internet")
      helpers.toastNotify("error", "No Internet Connection Found!");
    }
  }
  findOrderInDb = () => {
    let keyArray = [];
    localForage
      .iterate((value, key, iterationNumber) => {
        keyArray.push(key);
      })
      .then(() => {
        console.log("Iteration has completed", keyArray);
        if (keyArray.length > 0) {
          this.setState({ indexDbOrdersKeys: keyArray });
        }
      })
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  };
  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 100, type: "number" },
      { field: "date", headerName: "Date", width: 180 },
      { field: "customer", headerName: "Customer Name", width: 140 },
      {
        field: "total",
        headerName: "Total Amount",
        type: "number",
        width: 140,
      },
      {
        field: "discountamount",
        headerName: "Discount Amount",
        type: "number",
        width: 140,
      },
      {
        field: "payable",
        headerName: "Payable Amount",
        type: "number",
        width: 140,
      },
      {
        field: "payment",
        headerName: "Payment",
        width: 110,
      },
      {
        field: "fulfillment",
        headerName: "Fulfillment",
        width: 130,
      },
      {
        field: "items",
        headerName: "Items",
        width: 110,
      },
    ];
    const { loader } = this.state;
    const customer = this.props.location.state;
    const data = this.state;
    const obj = customer
      ? data.orders.filter(
          (specificcustomer) => specificcustomer.customerId === customer?.POID
        )
      : data.orders;

    console.log("customer from edit customer page", customer?.POID);

    var rows = [];
    for (var i = 0; i < obj.length; i++) {
      rows[i] = {
        id: obj[i].orderId,
        date:
          obj[i].createdAt.substring(0, 10) +
          " at " +
          obj[i].createdAt.substring(11, 16), //obj[i].createdAt,
        customer:
          obj[i].customerId === "null"
            ? "guest"
            : this.findCustomerById(obj[i].customerId)?.name,
        total: obj[i].total,
        discountamount: obj[i].discountAmount,
        payable: obj[i].payableAmount,
        payment: obj[i].paid ? "Paid" : "Unpaid",
        fulfillment: obj[i].fulfilled ? "Fulfilled" : "Unfulfilled",
        items: obj[i].lineItems.length + " item/s",
        // deliverymethod: "COD",
      };
    }
    const { classes } = this.props;
    const handlePrint = () => {
      // this.state.goingForPrinting = true;
      // Implement your custom printing logic here
      this.setState({ goingForPrinting: true });
      setTimeout(() => {
        window.print();
        this.setState({ goingForPrinting: false });
      }, 2000);
    };

    const showReceiptEditModal = () => {
      this.setState({ showReceiptEditModal: true });
    };
    const hideReceiptEditModal = () => {
      this.setState({ showReceiptEditModal: false });
    };

    function CustomToolbar() {
      const handlePrint = () => {
        // Implement your custom printing logic here
        window.print();
      };

      return (
        <div
          style={{
            width: "400px",
          }}
        >
          <GridToolbarContainer style={{}}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "5px",
                  backgroundColor: "#EFEFEF",
                  border: "1px solid black",
                  margin: "20px",
                  width: "100px",
                  height: "50px",
                }}
              >
                <GridToolbarExport />
              </div>
            </div>
          </GridToolbarContainer>
        </div>
      );
    }

    return (
      <>
        {loader ? (
          <Loader value={"Loading Orders"} />
        ) : (
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper style={{ backgroundColor: SKY_BLUE }}>
                  <Box className={classes.box} p={1} width="100%">
                    <Grid container alignItems="center">
                      <Grid item></Grid>
                      <Grid item>
                        <Typography variant="h5" style={{ color: WHITE }}>
                          All Orders
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Grid className={classes.ordersecondheader} item>
                  <Box
                    pl={1}
                    pr={1}
                    ml={1}
                    mr={1}
                    style={{
                      // padding: "10px",
                      height: "38px",
                      border: "1px solid #dfe1e5",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      hoverBackgroundColor: "#eee",
                      color: "#212121",
                      fontSize: "16px",
                      fontFamily: "Arial",
                      iconColor: "grey",
                      lineColor: "rgb(232, 234, 237)",
                      placeholderColor: "grey",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <QrCode fontSize="small" sx={{ color: grey[600] }} />
                    </div>
                    <div
                      style={{
                        flex: 1,
                        marginLeft: "10px",
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        placeholder="Receipt Barcode / Order ID"
                        style={{
                          height: "100%",
                          outline: "none",
                          border: "none",
                          width: "100%",
                          padding: 0,
                          margin: 0,
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.scanBarCodeOrOrderID(e.target.value);
                            e.target.value = "";
                          }
                        }}
                      />
                    </div>
                  </Box>
                  <div style={{ display: "flex", flex: 1 }}></div>
                  <div
                    style={{
                      marginRight: "10px",

                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  >
                    <Link to="/CreateOrders">
                      <Button variant="contained">Add an Order</Button>
                    </Link>
                  </div>

                  <div
                    style={{
                      marginRight: "10px",
                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  >
                    <Button variant="contained" onClick={showReceiptEditModal}>
                      <ReceiptLong />
                    </Button>
                  </div>

                  <div
                    style={{
                      marginRight: "10px",
                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  >
                    <Button variant="contained" onClick={handlePrint}>
                      <PrintOutlined />
                    </Button>
                  </div>

                  <div
                    style={{
                      marginRight: "10px",
                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => this.orderstosync()}
                      style={{ backgroundColor: MARS, color: "white" }}
                    >
                      <SyncOutlined
                        sx={
                          this.state.syncingLoader
                            ? {
                                animation: "rotateReverse 2s linear infinite",
                                "@keyframes rotateReverse": {
                                  "0%": {
                                    transform: "rotate(0deg)",
                                  },

                                  "100%": {
                                    transform: "rotate(-360deg)",
                                  },
                                },
                              }
                            : {}
                        }
                      />
                    </Button>
                  </div>
                </Grid>

                <Paper className={classes.paper}>
                  <DataGrid
                    loading={
                      this.state.syncingLoader && this.state.orders?.length == 0
                        ? true
                        : false
                    }
                    rows={rows}
                    autoHeight
                    components={{
                      Toolbar: this.state.goingForPrinting ? "" : CustomToolbar,
                    }}
                    sortModel={[
                      {
                        field: "id",
                        sort: "desc",
                      },
                    ]}
                    columns={columns}
                    onRowClick={(info) => {
                      this.getReturnedItems(
                        this.state.orders.filter(
                          (product) => product.orderId == info.row.id
                        )[0]?.lineItems
                      );
                      const singleOrder = this.state.orders?.filter(
                        (order) => order.orderId == info.row.id
                      );
                      console.log("here is orders", this.state.orders);
                      console.log("here is id", info.row.id);

                      this.setState({ singleOrderDetail: singleOrder }, () => {
                        this.openCategoryModal();
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Paper>
              </Grid>
            </Grid>
            {this.state.singleOrderDetail?.map((order) => {
              return (
                <Dialog
                  fullWidth={true}
                  open={this.state.categoryModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.closeCategoryModal}
                >
                  <DialogTitle>{"Order Detail"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            Order Id:<b> {order.orderId}</b>
                          </DialogContentText>
                          <DialogContentText id="alert-dialog-slide-description">
                            Status: <b>{order.status}</b>
                          </DialogContentText>
                          <DialogContentText id="alert-dialog-slide-description">
                            Order Items:
                            {order.lineItems?.map((lineItem, index) => {
                              return (
                                <div style={{ marginLeft: "10px" }}>
                                  {/* <br /> */}
                                  <b>
                                    {" "}
                                    {index + 1}. {lineItem?.productName}
                                  </b>
                                  {"    "}[{lineItem?.quantity} Unit(s)]
                                  {lineItem?.returned ? " - RETURNED" : ""}
                                </div>
                              );
                            })}
                          </DialogContentText>
                          <DialogContentText id="alert-dialog-slide-description">
                            Total Amount: <b>{order?.total}</b>
                          </DialogContentText>
                          {order?.discountAmount > 0 && (
                            <DialogContentText id="alert-dialog-slide-description">
                              Discount Amount: <b>{order?.discountAmount}</b>
                            </DialogContentText>
                          )}

                          <DialogContentText id="alert-dialog-slide-description">
                            Paid Amount: <b>{order?.payableAmount}</b>
                          </DialogContentText>

                          <DialogContentText id="alert-dialog-slide-description">
                            Paid: <b>{order.paid.toString()}</b>
                          </DialogContentText>
                          <DialogContentText id="alert-dialog-slide-description">
                            PaymentId: <b>{order?.paymentId}</b>
                          </DialogContentText>

                          {/* <DialogContentText id="alert-dialog-slide-description">
                            <b>Image:</b>{" "}
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={this.state.image}
                              alt="MYIMAGE"
                            />
                          </DialogContentText> */}
                        </DialogContent>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeCategoryModal} color="secondary">
                      Close
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({ printReciept: true }, () => {
                          console.log(
                            this.state.printReciept,
                            this.state.singleOrderDetail
                          );
                        });
                      }}
                      color="primary"
                    >
                      Print Receipt
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/ViewOrder/${order.orderId}`,
                          state: {
                            order: this.state.orders[order.orderId],
                            returnedItems: this.state.returnedItems,
                            receipt_metadata:
                              this.props?.company_info?.receipt_metadata,
                          },
                        });
                      }}
                      color="primary"
                    >
                      Edit
                    </Button>
                  </DialogActions>
                </Dialog>
              );
            })}
            <ReceiptEditModal
              open={this.state.showReceiptEditModal}
              close={hideReceiptEditModal}
              transition={Transition}
              receipt_metadata={this?.props?.company_info?.receipt_metadata}
              basePath={this?.props?.global_config?.basePath}
            />
            {this.state.printReciept && this.state.singleOrderDetail && (
              <>
                {(this.props?.company_info?.receipt_metadata
                  ?.printerPaperWidth === "80mm" ||
                  !this.props?.company_info?.receipt_metadata
                    ?.printerPaperWidth) && (
                  <ThermalPrinterView
                    orderObject={this.state.singleOrderDetail[0]}
                    customerObject={
                      this.state.singleOrderDetail[0]?.customerId !== "null"
                        ? this.findCustomerById(
                            this.state.singleOrderDetail[0]?.customerId
                          )
                        : ""
                    }
                    receiptMetadata={this.props?.company_info?.receipt_metadata}
                    onFinish={this.onPrintFinish}
                  />
                )}
                {this.props?.company_info?.receipt_metadata
                  ?.printerPaperWidth === "76mm" && (
                  <DotMatrixPrinterView
                    orderObject={this.state.singleOrderDetail[0]}
                    customerObject={
                      this.state.singleOrderDetail[0]?.customerId !== "null"
                        ? this.findCustomerById(
                            this.state.singleOrderDetail[0]?.customerId
                          )
                        : ""
                    }
                    receiptMetadata={this.props?.company_info?.receipt_metadata}
                    onFinish={this.onPrintFinish}
                  />
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_orders: state.ordersState.orders,
    g_unfulfilled_orders: state.ordersState.unfulfilled_orders,
    g_unpaid_orders: state.ordersState.unpaid_orders,
    g_draft_orders: state.ordersState.draft_orders,
    g_open_orders: state.ordersState.open_orders,
    g_close_orders: state.ordersState.close_orders,
    g_active: state.customersState.active_customers,
    global_config: state.globalState,
    customer: state.customersState,
    company_info: state.companyInfoState,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(ViewOrders));
