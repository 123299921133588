import React, { Component } from "react";
import Loader from "../Loader";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../MobileComponents/Customers/PageTitle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from '@mui/icons-material/Cancel';

class ProductsCustomerGets extends Component {
  handleBack = () => {
    this.props.setStep(10);
  };
  Products = () => {
    return (
      <>
        <div>
          <PageTitle
            textType="h6"
            value="Product Customer Gets"
            Icon={<CancelIcon />}
            ChangeRoute={this.handleBack}
          />
        </div>
        <div style={{ marginTop: "8px", marginBottom: "34px" }}>
          {this.props.values.productsCustomerGets.map((product) => (
            <div style={{ display: "flex", borderBottom: "1px ridge" }}>
              <Checkbox
                checked={product.checked}
                onChange={() => this.props.handleChange(product.POID)}
              />
              <div>
                <b>{product.name.slice(0, 20)}</b>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {" "}
                  <p>{product.price}</p> - <p>{product.status}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  render() {
    return (
      <>{this.props.values.loading == true ? <Loader /> : this.Products()}</>
    );
  }
}

export default ProductsCustomerGets;
