import React, { Component } from "react";
import { DataGrid, GridToolbarContainer } from "@material-ui/data-grid";
import * as XLSX from "xlsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WebIcon from "@material-ui/icons/Web";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LanguageIcon from "@material-ui/icons/Language";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import InventorySharpIcon from "@mui/icons-material/InventorySharp";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Menu from "@material-ui/core/Menu";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import Loader from "../../MobileComponents/Loader";
import { connect } from "react-redux";
import Store from "../../index";
import { getFilteredProducts } from "../../utilities/ProductsFilter";
import { checkCategoryAvailibility } from "../../utilities/CategoryLogic";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import helpers from "../../UtilitiesFunctions";
import { grey } from "@material-ui/core/colors";
import { QrCode } from "@mui/icons-material";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "rgb(39, 136, 6)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        textDecoration: "none",
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    textDecoration: "none",
  },
  drawer: {
    marginTop: "30px",
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      width: 0,
      flexShrink: 0,
    },
  },
  hideSearch: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  appBar: {
    backgroundColor: "rgb(39, 136, 6)",

    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: "0",
      width: "100%",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 0,

    paddingTop: "56px",
    zIndex: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      margin: 0,

      padding: 0,
    },
  },
  mecuIcon: {
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: "5px",

    color: "#000",

    "&:hover": {
      backgroundColor: SKY_BLUE,

      color: "white",
      textDecoration: "none",
    },
  },
  draweMenu: {
    textDecoration: "none",

    fontWeight: "bold",
    fontSize: "20px",

    marginTop: "5px",

    // minHeight: "80%",
    color: "#000",
    // textDecoration: "none",
    "&:hover": {
      backgroundColor: SKY_BLUE,
      color: "white",
      textDecoration: "none",
    },
  },
  activeLink: {
    backgroundColor: SKY_BLUE,
    color: "white",
    textDecoration: "none",
    "&:hover": {
      // backgroundColor: SKY_BLUE,
      // color: "white",
      textDecoration: "none",
    },
  },
}));

const style = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 200,
    width: 300,
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    background: SKY_BLUE,
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: SKY_BLUE,
        // border: "1px solid black",
        borderRadius: "13px",
        // outline: "1px solid slategrey",
      },
    },
  },
  search: {
    [theme.breakpoints.down("sm")]: {
      color: "red",
      margin: "10px",
      display: "block",
    },
  },
  productAddButtonAndTitle: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "20px",
      marginLeft: "150px",
      display: "flex",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
    [theme.breakpoints.only("md")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },

    [theme.breakpoints.only("lg")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
  },

  productDisplayTite: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  importsmenu: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
    addproductbutton: {
      marginTop: "10px",
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      list: [],
      loaderMessage: "Loading Products..",
      rows: [],
      loading: true,
      backdrop: false,
      query: "",
      previousProducts: [],
      snackbar: false,
      value: 0,
      mode: "online",
      filter: "Active",
      categoryModal: false,
      categoryName: "",
      categoryDescription: "",
      dropdown: null,
      CategorySnackbar: false,
      customSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success",
      singleProductDetail: [],
      toggle: false,
      barcode: "",
      ExportButtonLoader: false,
      ImportButtonLoader: false,
      categoryid: "",
      description: "",
      id: "",
      image: "",
      productname: "",
      quantity: "",
      status: "",
    };
  }
  openCategoryModal = () => {
    this.setState({ categoryModal: true });
  };
  closeCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  scanBarcodeAndShowProduct = (barCode) => {
    const foundProducts = this.state.products?.filter(
      (product) =>
        product?.barcodes?.filter(
          (barCodeValue) => barCodeValue?.code === barCode
        )?.length > 0
    );

    if (foundProducts.length > 0) {
      this.setState(
        { singleProductDetail: [foundProducts[0]], id: foundProducts[0]?.id },
        () => {
          this.openCategoryModal();
        }
      );
      if (foundProducts.length > 1) {
        helpers.toastNotify(
          "warning",
          "More than one products were found with same barcode; showing top most product."
        );
      }
    } else if (foundProducts.length === 0) {
      helpers.toastNotify(
        "error",
        "No Products found associated with provided barcode in selected group / status."
      );
    }
  };

  componentDidMount() {
    // if (this.props.g_categories.length === 0) {
    let url = this.props.global_config.basePath + "/categories";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Store.dispatch({ type: "GET_CATEGORIES", categories: data });
      })
      .catch((err) => {
        console.log(err);
      });
    // }

    if (!this.props.company_info.receipt_metadata) {
      let url =
        this.props.global_config.basePath + "/companyInfo/receiptMetaData";

      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(
            "receipt Response",
            data,
            this.props.company_info.receipt_metadata
          );
          Store.dispatch({ type: "RECEIPT_METADATA", receipt_metadata: data });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (this.props.g_products.length == 0) {
    } else {
      console.log("here ", this.props.g_products.length);
      this.setState({
        products: this.props.g_products,
        loading: false,
        mode: "online",
      });
    }

    this.getAllProducts();
  }

  prepareInventoryData = (products) => {
    const inventory = [];
    console.log("products", products);
    products?.map((product) => {
      if (product?.varients?.length > 0) {
        console.log("PUSHING VARIENT");
        const filteredList = product?.varients?.filter((item) => {
          if (item?.isSellable) return item;
        });
        filteredList?.map((varient, index) => {
          inventory.push(varient);
        });
      } else {
        inventory.push(product);
      }
    });

    console.log("rows: ", inventory);

    return inventory;
  };

  getAllProducts = () => {
    let url = this.props.global_config.basePath + "/products?status=All";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Store.dispatch({ type: "GET_PRODUCTS", products: data });
        const activeProducts = getFilteredProducts(data, "active");
        this.setState({ products: activeProducts });
        Store.dispatch({
          type: "GET_INVENTORY_ITEMS",
          inventoryItems: this.prepareInventoryData(activeProducts),
        });
        Store.dispatch({
          type: "GET_ACTIVE_PRODUCTS",
          active_products: activeProducts,
        });
        Store.dispatch({
          type: "GET_ARCHIVED_PRODUCTS",
          archived_products: getFilteredProducts(data, "archived"),
        });
        Store.dispatch({
          type: "GET_DRAFT_PRODUCTS",
          draft_products: getFilteredProducts(data, "draft"),
        });
        this.setState({
          previousProducts: data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          mode: "offline",
        });
      });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) => item.name.includes(searchTerm));
  };
  handleChangeSearch = (e) => {
    let productList = [];

    if (this.state.filter === "All") {
      productList = this.props.g_products;
    }
    if (this.state.filter === "Active") {
      productList = this.props.g_active_products;
    } else if (this.state.filter === "Archived") {
      productList = this.props.g_archived_products;
    } else if (this.state.filter === "Draft") {
      productList = this.props.g_draft_products;
    }

    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: productList,
        filter: this.state.filter,
      });
      productList = [];
    } else {
      this.setState({ [e.target.name]: e.target.value });

      const filteredList = this.dynamicSearch(productList, e.target.value);
      this.setState({
        backdrop: false,
        products: filteredList,
      });
    }
  };
  findCategoriesById(id) {
    const categoryList = this.props.g_categories.filter(
      (category) => category.id === id
    );
    return categoryList[0]?.name;
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  closeCategorySnackbar = () => {
    this.setState({ CategorySnackbar: false });
  };
  handleSearch = (key, value) => {
    if (this.state.query.length <= 0) {
      this.setState({ snackbar: false });
      helpers.toastNotify("error", "Empty Field Detected!");
    } else {
      this.setState({ backdrop: true, products: [] });
      let url =
        this.props.global_config.basePath +
        `/products/search?key=${key}&value=${value}`;

      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((responseData) => {
          this.setState({
            backdrop: false,
            products: responseData,
          });
        })
        .catch((err) => {
          this.setState({
            backdrop: false,
          });
        });
    }
  };
  handleSearchByBarcode = (key, value) => {
    this.setState({ backdrop: true });
    fetch(
      this.props.global_config.basePath +
        `/products/search?key=${key}&value=${value}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData, "after search querry");
        this.setState({
          backdrop: false,
          products: responseData,
        });
      })
      .catch((e) => {
        this.setState({
          backdrop: false,
        });
      });
  };
  cancelSearch = () => {
    this.setState({
      query: "",
      products: this.state.previousProducts,
    });
  };

  getProductsByStatus = (status) => {
    const allProducts = this.props.g_products;
    this.setState({ loading: true, products: [] });
    if (status === "All") {
      this.setState({
        products: allProducts,
        previousProducts: allProducts,
        query: "",
        loading: false,
      });
    } else if (status === "Active") {
      this.setState({
        products: getFilteredProducts(allProducts, "active"),
        previousProducts: getFilteredProducts(allProducts, "active"),
        query: "",
        loading: false,
      });
    } else if (status === "Draft") {
      this.setState({
        products: getFilteredProducts(allProducts, "draft"),
        previousProducts: getFilteredProducts(allProducts, "draft"),
        query: "",
        loading: false,
      });
    } else if (status === "Archived") {
      this.setState({
        products: getFilteredProducts(allProducts, "archived"),
        previousProducts: getFilteredProducts(allProducts, "archived"),
        query: "",
        loading: false,
      });
    }
  };
  submitCategory = () => {
    let isAvailable = checkCategoryAvailibility(
      this.props.g_categories,
      this.state.categoryName
    );
    if (!isAvailable) {
      helpers.toastNotify(
        "error",
        'category  "' + this.state.categoryName + '" already exist'
      );
      return;
    }
    if (this.state.categoryName == "" || this.state.categoryDescription == "") {
      helpers.toastNotify("error", "Fields are empty!");
      return;
    }
    this.setState({ backdrop: true, categoryModal: false });

    const data = {
      name: this.state.categoryName,
      description: this.state.categoryDescription,
    };

    fetch(this.props.global_config.basePath + "/categories", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.setState({ backdrop: false, CategorySnackbar: false });
        helpers.toastNotify("success", "Category Successfully Added!");

        fetch(this.props.global_config.basePath + "/categories", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              categories: data,
              linearSpinner: false,
            });
            Store.dispatch({ type: "GET_CATEGORIES", categories: data });
          })
          .catch((e) => {
            console.log(e);
            helpers.toastNotify("error", "Something went wrong!");
          });
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Failed To Create Category. ");
      });
  };
  openDropdown = (event) => {
    this.setState({ dropdown: event.currentTarget });
  };

  closeDropdown = () => {
    this.setState({ dropdown: null });
  };

  handleFileUpload = (event) => {
    this.enableImportButtonLoader();
    const selectedFile = event.target.files[0];
    let url = this.props.global_config.basePath + "/products/importProducts";
    const formData = new FormData();
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if (
        jsonData[0].length > 10 ||
        jsonData[0][0].toLowerCase() !== "name" ||
        jsonData[0][0] === "" ||
        jsonData[0][1].toLowerCase() !== "description" ||
        jsonData[0][1] === "" ||
        jsonData[0][2].toLowerCase() !== "price" ||
        jsonData[0][2] === "" ||
        jsonData[0][3].toLowerCase() !== "cost_price" ||
        jsonData[0][3] === "" ||
        jsonData[0][4].toLowerCase() !== "status" ||
        jsonData[0][4] === "" ||
        jsonData[0][5].toLowerCase() !== "tax_rate" ||
        jsonData[0][5] === "" ||
        jsonData[0][6].toLowerCase() !== "quantity" ||
        jsonData[0][6] === "" ||
        jsonData[0][7].toLowerCase() !== "category" ||
        jsonData[0][7] === "" ||
        jsonData[0][8].toLowerCase() !== "brand" ||
        jsonData[0][8] === "" ||
        jsonData[0][9].toLowerCase() !== "image" ||
        jsonData[0][9] === ""
      ) {
        helpers.toastNotify(
          "error",
          "Could Not Import please select xlsx format."
        );
        this.disableImportButtonLoader();
        return;
      } else {
        formData.append("file", selectedFile);
        fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Bearer token",
          },
          body: formData,
        })
          .then((response) => {
            this.disableImportButtonLoader();
            alert("Products imported successfully");
          })
          .catch((err) => {
            this.disableImportButtonLoader();
            helpers.toastNotify(
              "error",
              "Could Not Import please select xlsx format."
            );

            console.log(err);
          });
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };
  enableExportButtonLoader = () => {
    this.setState({ ExportButtonLoader: true });
  };
  disableExportButtonLoader = () => {
    this.setState({ ExportButtonLoader: false });
  };
  enableImportButtonLoader = () => {
    this.setState({ ImportButtonLoader: true });
  };
  disableImportButtonLoader = () => {
    this.setState({ ImportButtonLoader: false });
  };
  ExportProducts = () => {
    this.enableExportButtonLoader();
    let url = this.props.global_config.basePath + "/products/exportProducts";
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer token",
      },
    })
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Hulmproducts.xlsx";
          a.click();
          this.disableExportButtonLoader();
          alert("Products exported Successfully");
        });
      })
      .catch((err) => {
        this.disableExportButtonLoader();
        alert("Error! Something went wrong in exporting products");
        console.log(err);
      });
  };
  defaultImages = (images) => {
    return images.filter((image) => image.delete === false);
  };
  render() {
    const { history } = this.props;

    const dummyImage = {
      imageURL:
        "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081",
    };

    const {
      products,
      query,
      loading,
      backdrop,
      value,
      previousProducts,
      ExportButtonLoader,
      ImportButtonLoader,
    } = this.state;
    const { classes } = this.props;
    console.log("here is categories", this.props.g_categories);

    const columns = [
      { field: "id", headerName: "ID", width: 100, type: "number" },

      {
        field: "image",
        width: 150,
        headerName: "image",
        renderCell: (params) => (
          <img
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
            }}
            src={params.value === undefined ? dummyImage : params.value}
            alt="productImage"
            variant="contained"
            color="primary"
            size="small"
          />
        ),
      },

      { field: "name", headerName: "Name", width: 150 },
      {
        field: "description",
        headerName: "Description",
        width: 150,
      },
      { field: "category", headerName: "Category", width: 150 },
      { field: "status", headerName: "Status", width: 120 },
      {
        field: "varientQuantity",
        headerName: "Quantity",
        width: 120,
      },
      {
        field: "varients",
        headerName: "VarientsQuantity",
        width: 120,
      },
    ];

    const data = this.state;
    const obj = data.products;

    const rows = [];
    if (data.products) {
      for (
        var i = 0;
        obj.length !== null && obj.length !== 0 && i < obj.length;
        i++
      ) {
        const html = obj[i].description;
        const div = document.createElement(`div-${i}`);
        div.innerHTML = html;
        const text = div.textContent || "";
        var filteredImages = this.defaultImages(obj[i].images);

        rows[i] = {
          id: obj[i].POID,
          name: obj[i].name,
          status: obj[i].status,
          description: text,
          category: this.findCategoriesById(obj[i].categoryId),
          image:
            filteredImages.length > 0
              ? filteredImages[filteredImages.length - 1].imageURL
              : dummyImage.imageURL,
          quantity: obj[i].quantity,
          varientQuantity:
            obj[i].relatedProducts.length > 0
              ? obj[i].quantity +
                " in stock for " +
                obj[i]?.relatedProducts?.length +
                " varients"
              : obj[i]?.quantity,
          varients: obj[i]?.varients?.filter(
            (varproduct) => varproduct.status === "Active"
          )?.length,
        };
      }
    }

    function CustomToolbar() {
      return (
        <>
          <div style={{ width: "400px" }}>
            <GridToolbarContainer
              style={{
                backgroundColor: "#EFEFEF",
                border: "1px solid black",
                margin: "20px",
                width: "100px",
                height: "50px",
              }}
            ></GridToolbarContainer>
          </div>
        </>
      );
    }

    return (
      <>
        {loading ? (
          <Loader value={this.state.loaderMessage} />
        ) : (
          <>
            <Backdrop
              style={{
                zIndex: 1,
                color: "#fff",
              }}
              open={backdrop}
            >
              <CircularProgress thickness={2} size={90} color="inherit" />
            </Backdrop>
            {this.state.snackbar ? (
              <>
                <Snackbar
                  open={this.state.snackbar}
                  autoHideDuration={2000}
                  onClose={this.closeSnack}
                >
                  <MuiAlert
                    severity="error"
                    variant="filled"
                    color="error"
                    onClose={this.closeSnack}
                  >
                    Please enter valid name!
                  </MuiAlert>
                </Snackbar>
              </>
            ) : null}
            {this.state.CategorySnackbar ? (
              <>
                <Snackbar
                  open={this.state.CategorySnackbar}
                  autoHideDuration={4000}
                  onClose={this.closeSnack}
                >
                  <MuiAlert
                    severity="success"
                    variant="filled"
                    color="success"
                    onClose={this.closeSnack}
                  >
                    Category Created Successfully
                  </MuiAlert>
                </Snackbar>
              </>
            ) : null}
            {this.state.mode === "offline" ? (
              <MuiAlert severity="warning">Can not connect to server!</MuiAlert>
            ) : null}
            {products === 0 && !loading && previousProducts === 0 ? (
              <>
                <div className={classes.root}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={this.handleTabChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                  >
                    <Tab
                      label="Add Product"
                      icon={
                        <OpenInBrowserIcon
                          fontSize="large"
                          style={{ color: SKY_BLUE }}
                        />
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Add Customized Theme"
                      icon={
                        <WebIcon fontSize="large" style={{ color: SKY_BLUE }} />
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Add Domain"
                      icon={
                        <LanguageIcon
                          fontSize="large"
                          style={{ color: SKY_BLUE }}
                        />
                      }
                      {...a11yProps(2)}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Button
                      style={{
                        backgroundColor: SKY_BLUE,
                        color: "white",
                      }}
                      variant="contained"
                      color="white"
                      component={Link}
                      to="/ProductForm"
                    >
                      Add Products
                    </Button>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Button
                      style={{
                        backgroundColor: SKY_BLUE,
                        color: "white",
                      }}
                      variant="contained"
                      color="white"
                      component={Link}
                      to="/ProductForm"
                    >
                      Add Theme
                    </Button>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Button
                      style={{
                        backgroundColor: SKY_BLUE,
                        color: "white",
                      }}
                      variant="contained"
                      color="white"
                      component={Link}
                      to="/ProductForm"
                    >
                      Add Domain
                    </Button>{" "}
                  </TabPanel>
                </div>
              </>
            ) : (
              <>
                <div className={classes.productAddButtonAndTitle}>
                  <h5
                    className={classes.productDisplayTite}
                    style={{ marginTop: "8px" }}
                    onClick={() => this.props.history.push("/Products")}
                  >
                    Products
                  </h5>
                  <div>
                    <div>
                      <Link to="/ProductForm">
                        <Button
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          variant="contained"
                          style={{
                            backgroundColor: SKY_BLUE,
                            color: "white",
                          }}
                        >
                          Add Product
                        </Button>
                      </Link>

                      <StyledMenu
                        id="customized-menu"
                        anchorEl={this.state.dropdown}
                        keepMounted
                        open={Boolean(this.state.dropdown)}
                        onClose={this.closeDropdown}
                      >
                        <StyledMenuItem component={Link} to="/ProductForm">
                          <ListItemIcon>
                            <InventorySharpIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Add Products" />
                        </StyledMenuItem>

                        <StyledMenuItem onClick={this.openCategoryModal}>
                          <ListItemIcon>
                            <CategoryRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Create category" />
                        </StyledMenuItem>
                      </StyledMenu>
                    </div>
                  </div>
                </div>
                <Dialog
                  fullWidth={true}
                  open={this.state.categoryModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.closeCategoryModal}
                >
                  <DialogTitle>{"Product Detail"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {this.state?.singleProductDetail.map((data) => {
                          data.activeVarients = data?.varients?.filter(
                            (value) => {
                              if (value?.status === "Active") return value;
                            }
                          );

                          return (
                            <DialogContent>
                              <DialogContentText id="alert-dialog-slide-description">
                                Id: <b>{data?.id}</b>
                              </DialogContentText>
                              <DialogContentText id="alert-dialog-slide-description">
                                Name: <b>{data?.name}</b>
                              </DialogContentText>
                              <DialogContentText id="alert-dialog-slide-description">
                                Description:
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data?.description,
                                  }}
                                ></div>
                              </DialogContentText>
                              <DialogContentText id="alert-dialog-slide-description">
                                price: <b>{data?.price}</b>
                              </DialogContentText>

                              <DialogContentText id="alert-dialog-slide-description">
                                Category:{" "}
                                <b>
                                  {this.findCategoriesById(data?.categoryId)}
                                </b>
                              </DialogContentText>
                              <DialogContentText id="alert-dialog-slide-description">
                                Status: <b>{data?.status}</b>
                              </DialogContentText>
                              <DialogContentText id="alert-dialog-slide-description">
                                Quantity: <b>{data?.quantity}</b>
                              </DialogContentText>
                              {/* <DialogContentText id="alert-dialog-slide-description">
                                <b>Image:</b>{" "}
                                <img
                                  style={{ width: "50px", height: "50px" }}
                                  src={this.state.image}
                                  alt="MYIMAGE"
                                />
                              </DialogContentText> */}
                              {data?.activeVarients?.length > 0 && (
                                <DialogContentText id="alert-dialog-slide-description">
                                  <b>Varients:</b>
                                  <div style={{ marginLeft: "15px" }}>
                                    {data.activeVarients.map(
                                      (varientsdetail, index) => {
                                        var varientproduct = index + 1 + ". ";

                                        varientsdetail?.productSpecCharacteristics?.map(
                                          (productspec, index) => {
                                            varientproduct +=
                                              productspec?.name + ": ";
                                            productspec?.productSpecCharValues?.map(
                                              (
                                                productSpecCharValues,
                                                index
                                              ) => {
                                                console.log(
                                                  "here is a product specificationvalues",
                                                  productSpecCharValues
                                                );
                                                varientproduct +=
                                                  productSpecCharValues?.value +
                                                  (index + 1 ==
                                                    productspec
                                                      ?.productSpecCharValues
                                                      ?.length &&
                                                  productspec
                                                    ?.productSpecCharValues
                                                    ?.length > 1
                                                    ? ", "
                                                    : " ");
                                                console.log(
                                                  "here is varient product",
                                                  varientproduct
                                                );
                                              }
                                            );
                                            varientproduct +=
                                              index + 1 !=
                                                varientsdetail
                                                  ?.productSpecCharacteristics
                                                  ?.length &&
                                              varientsdetail
                                                ?.productSpecCharacteristics
                                                ?.length > 1
                                                ? " & "
                                                : "";
                                          }
                                        );
                                        return <p>{varientproduct}</p>;
                                      }
                                    )}
                                  </div>
                                </DialogContentText>
                              )}
                            </DialogContent>
                          );
                        })}
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeCategoryModal} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/editproduct/${this.state.id}`,
                        });
                      }}
                      color="primary"
                    >
                      Edit
                    </Button>
                  </DialogActions>
                </Dialog>

                <div style={{ marginTop: "30px" }}>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "blue",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  ></div> */}

                  <div
                    style={{
                      width: "100%",

                      backgroundColor: "white",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <div
                          className={classes.importsmenu}
                          style={{ marginLeft: "20px", marginTop: "10px" }}
                        ></div> */}
                        <Box
                          pl={1}
                          pr={1}
                          // ml={1}
                          // mr={1}
                          style={{
                            // padding: "10px",
                            height: "38px",
                            border: "1px solid #dfe1e5",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            hoverBackgroundColor: "#eee",
                            color: "#212121",
                            fontSize: "16px",
                            fontFamily: "Arial",
                            iconColor: "grey",
                            lineColor: "rgb(232, 234, 237)",
                            placeholderColor: "grey",
                            display: "flex",
                            //marginRight: "20px",
                            //marginTop: "40px",
                          }}
                        >
                          <div
                            style={{
                              height: "100%",
                              justifyContent: "center",
                              flexDirection: "column",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <QrCode
                              fontSize="small"
                              sx={{ color: grey[600] }}
                            />
                          </div>
                          <div
                            style={{
                              flex: 1,
                              marginLeft: "10px",
                              height: "100%",
                              justifyContent: "center",
                              flexDirection: "column",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              placeholder="Scan/Enter Barcode"
                              style={{
                                height: "100%",
                                outline: "none",
                                border: "none",
                                width: "100%",
                                padding: 0,
                                margin: 0,
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.scanBarcodeAndShowProduct(
                                    e.target.value
                                  );
                                  e.target.value = "";
                                }
                              }}
                            />
                          </div>
                        </Box>
                        <div
                          style={
                            {
                              // marginTop: "30px",
                            }
                          }
                        >
                          <Stack direction="row" spacing={2}>
                            <input
                              ref="fileInput"
                              onChange={this.handleFileUpload}
                              type="file"
                              style={{ display: "none" }}
                              accept=".xlsx"
                            />
                            <LoadingButton
                              loading={ImportButtonLoader}
                              loadingPosition="start"
                              startIcon={<FileUploadIcon />}
                              variant="conatined"
                              onClick={() => this.refs.fileInput.click()}
                              sx={{ backgroundColor: WHITE, color: MARS }}
                            >
                              Import
                            </LoadingButton>
                            <LoadingButton
                              loading={ExportButtonLoader}
                              loadingPosition="start"
                              startIcon={<FileDownloadIcon />}
                              variant="conatined"
                              onClick={this.ExportProducts}
                              sx={{ backgroundColor: WHITE, color: SKY_BLUE }}
                            >
                              Export
                            </LoadingButton>
                          </Stack>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: "40px",

                          display: "flex",
                        }}
                      >
                        <Button
                          className={
                            this.state.filter === "All" ? classes.button : null
                          }
                          onClick={() =>
                            this.getProductsByStatus(
                              "All",
                              this.setState({ filter: "All" })
                            )
                          }
                          style={{ marginRight: "20px" }}
                        >
                          All
                        </Button>

                        <Button
                          className={
                            this.state.filter === "Active"
                              ? classes.button
                              : null
                          }
                          onClick={() =>
                            this.getProductsByStatus(
                              "Active",
                              this.setState({ filter: "Active" })
                            )
                          }
                          style={{ marginRight: "20px" }}
                        >
                          Active
                        </Button>
                        <Button
                          className={
                            this.state.filter === "Draft"
                              ? classes.button
                              : null
                          }
                          onClick={() =>
                            this.getProductsByStatus(
                              "Draft",
                              this.setState({ filter: "Draft" })
                            )
                          }
                          style={{ marginRight: "20px" }}
                        >
                          Draft
                        </Button>
                        <Button
                          className={
                            this.state.filter === "Archived"
                              ? classes.button
                              : null
                          }
                          onClick={() =>
                            this.getProductsByStatus(
                              "Archived",
                              this.setState({ filter: "Archived" })
                            )
                          }
                        >
                          Archive
                        </Button>
                      </div>
                      <div style={{ margin: "20px 0 10px 20px" }}>
                        <span>
                          {products.length ? (
                            <b>Showing {products.length} Products </b>
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <DataGrid
                      style={{
                        cursor: "pointer",
                      }}
                      autoHeight={true}
                      sortModel={[
                        {
                          field: "id",
                          sort: "desc",
                        },
                      ]}
                      rowHeight={85}
                      headerHeight={75}
                      loading={loading}
                      onRowEditStop
                      rows={rows}
                      columns={columns}
                      {...this.state.columns}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      onRowClick={(info) => {
                        console.log("This is info", info.row);
                        this.setState({
                          id: info.row.id,
                        });
                        const product = products.filter(
                          (product) => product.POID === info.row.id
                        );
                        this.setState({ singleProductDetail: product });
                        console.log(
                          "here is single product detail ",
                          this.state.singleProductDetail
                        );

                        this.openCategoryModal();
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
    // }
  }
}

const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    g_active_products: state.productsState.active_products,
    g_draft_products: state.productsState.draft_products,
    g_archived_products: state.productsState.archived_products,
    g_categories: state.productsState.categories,
    global_config: state.globalState,
    company_info: state.companyInfoState,
  };
};
export default connect(mapStateToProps, null)(withStyles(style)(ProductList));
