const targetIp = "https://api-pos.hulmsolutions.com";

// const targetIp = "http://192.168.18.214:8080";

const initial_state = {
  targetIp: `${targetIp}/POS`,
  ip: `${targetIp}/POS`,
  userManagement: `http://${targetIp}:9084/UserManagement/HULM/cd28202b-371c-40d6-a40b-563c662591e5/`,
  companyName: "",
  instanceId: "",
  basePath: "",
  appType: "",
  appPath: "",
  // gatewayUrl: `http://192.168.18.214:5003`,
  gatewayUrl: "https://api.hulmsolutions.com",

  temp: `http://${targetIp}:9086/ViewsService/Views/bucket2/sputnik.hpos.e2a2e917-168e-4d1e-8ff5-81b2628b68e4/image.png`,
  imageUrl: `http://${targetIp}:9086/ViewsService/Views/bucket2/`,
  // keyCloakAuth: `http://139.59.8.119:8070/auth/`,

  keyCloakAuth: `https://keycloak.hulmsolutions.com/auth/`,
  isAuthenticated: false,
  keycloakinstance: null,
  mediaBaseUrl: `https://s3.hulmsolutions.com/upload`,
};

const globalReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_BASE_URL":
      return {
        ...state,
        basePath: `${state.ip}/${
          action.payload?.companyName
            ? action.payload?.companyName
            : state.companyName
        }/${
          action.payload?.instanceId
            ? action.payload?.instanceId
            : state.instanceId
        }`,
        companyName: action.payload?.companyName
          ? action.payload?.companyName
          : state.companyName,
        instanceId: action.payload?.instanceId
          ? action.payload?.instanceId
          : state.instanceId,
        appType: action.payload?.appType
          ? action.payload?.appType
          : state.appType,
      };

    case "GET_GLOBAL_URL":
      return state;

    case "SETTING_AUTHENTICATION":
      return {
        ...state,
        isAuthenticated: action.payload?.isAuthenticated,
        keycloakinstance: action.payload?.keycloakinstance,
      };
    default:
      return state;
  }
};

export default globalReducer;
