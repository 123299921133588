import React from "react";

const PrintNew = (props) => {
  return (
    <div className="printSlip">
      <div className="container-fluid" style={{ padding: "0" }}>
        <div className="orderPreview">
          <div className="header">
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-6 leftt align-self-center">
                <img
                  src="http://worker01vm1:3000/static/media/logo.b6c05877.png"
                  style={{ width: "55px" }}
                />
              </div>
              <div
                className="col-6 rightt align-self-center"
                style={{ textAlign: "right" }}
              >
                {/* Order #2892 */}
                Order #{props.orderData.orderId}
                <br />
                {/* March 8, 2022 */}
                {props.orderData.createdAt.substring(0, 10)}
              </div>
            </div>
            <hr />
            <div
              className="row"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div className="col-6" style={{ textAlign: "left" }}>
                <b>SHIP TO</b>
                <p>C-27, Block 14, Gulistan E Jauhar</p>
              </div>
              <div className="col-6" style={{ textAlign: "left" }}>
                <b>BILL TO</b>
                <p>C-27, Block 14, Gulistan E Jauhar</p>
              </div>
            </div>
          </div>
          {/* <hr /> */}
          <div
            className=""
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              border: "1px solid black",
              borderRadius: "5px!important",
              padding: "5px",
            }}
          >
            <div className="row" style={{}}>
              <div
                className="col-2"
                style={{ textAlign: "center", padding: "3px" }}
              >
                <b>Product Name</b>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center", padding: "3px" }}
              >
                <b>Quantity</b>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center", padding: "3px" }}
              >
                <b>Unit Price</b>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center", padding: "3px" }}
              >
                <b>Sum Price</b>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center", padding: "3px" }}
              >
                <b>Tax Amount</b>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center", padding: "3px" }}
              >
                <b>Total Amount</b>
              </div>
            </div>
            {props.orderData.lineItems.map((p, { index }) => {
              {
              }
              return (
                <div className="row" style={{}}>
                  <div className="col-2" style={{ textAlign: "center" }}>
                    {p.productName}
                  </div>
                  <div className="col-2" style={{ textAlign: "center" }}>
                    {p.quantity}
                  </div>
                  <div className="col-2" style={{ textAlign: "center" }}>
                    {p.price}
                  </div>
                  <div className="col-2" style={{ textAlign: "center" }}>
                    {p.subTotal}
                  </div>
                  <div className="col-2" style={{ textAlign: "center" }}>
                    {p.tax}
                  </div>
                  <div className="col-2" style={{ textAlign: "center" }}>
                    {p.total}
                  </div>
                  {/* <div className="col-3" style={{ textAlign: "" }}>
                    {p.quantity}
                  </div> */}
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              //   flexDirection: "column",
              width: "auto",
              height: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "5px",
                }}
              >
                <span>
                  <b>Tax:</b>
                </span>
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {props.orderData.taxAmount}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "5px",
                }}
              >
                <span>
                  <b>Subtotal:</b>
                </span>
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {props.orderData.subTotal}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "5px",
                }}
              >
                <span>
                  <b>Grand Total:</b>
                </span>
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {props.orderData.total}
                </span>
              </div>
            </div>
          </div>

          <hr />
          <div
            className="footer"
            style={{ marginTop: "20px", textAlign: "center" }}
          >
            <div className="row">
              <div className="col-12">
                <p>Thank you for shopping with us!</p>
                <b>HULMSOLUTIONS-POS</b>
                <br />
                C-27, Gulistan E Jauhar, Karachi 75300, Pakistan
                <br />
                support@hulmsolutions.com
                <br />
                https://www.hulmsolutions.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintNew;
