import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CartList from "../../components/cart/CartList";
import Categories from "../../components/categories/Categories";
import ProductList from "../../components/Products/ProductList";
import { Grid, Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from "../../MobileComponents/Loader";
import { SKY_BLUE } from "../../colors/colors";
import TextField from "@mui/material/TextField";
import {
  addToCartAction,
  updateCartUnits,
  emptyCart,
} from "../../Redux/action/cart_actions";
import "./index.css";
import {
  getDraftOrders,
  getCloseOrders,
  getOpenOrders,
  getUnpaidOrders,
  getUnfulfilledOrders,
} from "../../utilities/OrdersFilter";
import Store from "../../index";
import { QrCode } from "@mui/icons-material";
import { grey } from "@material-ui/core/colors";
import helpers from "../../UtilitiesFunctions";
import PaginatedItem from "../../components/pagination/PaginatedItem";
import { InputAdornment } from "@mui/material";
import Search from "@material-ui/icons/Search";

const style = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(66, 63, 62)",
      border: "1px solid black",
      borderRadius: "13px",
      outline: "1px solid slategrey",
    },
  },
  root: {
    width: "60%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      marginLeft: "50px",
    },
  },
  responsiveForm: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
      marginBottom: "20px",
      boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
      outline: ".1rem solid transparent",
    },
  },
  hideOnSmallScreen: {
    // width: "57%",
    position: "fixed",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      padding: "0px",
    },
  },
  parentBox: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      marginBottom: "20px",
    },
  },
  responsive: {
    display: "flex",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  contentHeader: {
    display: "none",
    backgroundColor: "red",
  },
  Categories: {
    display: "none",
  },
  possearches: {
    [theme.breakpoints.only("xs")]: {},
  },
});
class PosContainer extends React.Component {
  state = {
    products: [],
    loading: true,
    categories: [],
    filterByCategory: [],
    data: "Not Found",
    barcode: "No barcode",
    toggle: false,
    linearSpinner: false,
    loaderMessage: "Loading POS..",
    customers: [],
    previousCustomers: [],
    barcodetext: "",
    previousProducts: [],
    filteredProducts: [],
  };

  productMappingToVarients = (products) => {
    const productvar = [];
    products
      ?.filter((product) => product.quantity > 0)
      ?.map((pr, index) => {
        if (pr?.varients?.length > 0) {
          pr?.varients?.map((varient, index) => {
            if (varient?.status === "Active") {
              productvar.push(varient);
            }
          });
        } else {
          productvar.push(pr);
        }
      });

    return productvar;
  };

  prepareInventoryData = (products) => {
    const inventory = [];
    console.log("products", products);
    products?.map((product) => {
      if (product?.varients?.length > 0) {
        const filteredList = product?.varients?.filter((item) => {
          if (item?.isSellable) return item;
        });
        filteredList?.map((varient, index) => {
          inventory.push(varient);
        });
      } else {
        inventory.push(product);
      }
    });

    console.log("rows: ", inventory);

    return inventory;
  };

  componentDidMount() {
    // Simple GET request using fetch
    let productData = [];
    this.setState({ linearSpinner: true });
    if (this.props.g_categories.length === 0) {
      fetch(this.props.global_config.basePath + "/categories", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            categories: data,
            linearSpinner: false,
          });
          Store.dispatch({ type: "GET_CATEGORIES", categories: data });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({
        categories: this.props.g_categories,
        linearSpinner: false,
      });
    }

    if (true) {
      let url =
        this.props.global_config.basePath + "/companyInfo/receiptMetaData";

      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(
            "receipt Response",
            data,
            this.props.company_info.receipt_metadata
          );
          Store.dispatch({ type: "RECEIPT_METADATA", receipt_metadata: data });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (this.props.g_active_products?.length === 0) {
      fetch(this.props.global_config.basePath + "/products?status=Active", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          productData = this.props.g_active_products?.filter(
            (product) => product.quantity > 0
          );

          const productPosData = data?.filter(
            (product) => product.quantity > 0
          );

          Store.dispatch({
            type: "GET_INVENTORY_ITEMS",
            inventoryItems: this.prepareInventoryData(
              productData.length > 0 ? productData : productPosData
            ),
          });
          Store.dispatch({
            type: "GET_ACTIVE_PRODUCTS",
            active_products:
              productData.length > 0 ? productData : productPosData,
          });
          this.setState({
            products: productData.length > 0 ? productData : productPosData,
            previousProducts:
              productData.length > 0 ? productData : productPosData,
            loading: this.state.customers.length > 0 ? false : true,
            filterByCategory:
              productData.length > 0 ? productData : productPosData,
            filteredProducts: this.productMappingToVarients(
              productData.length > 0 ? productData : productPosData
            ),
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const products = this.props.g_active_products?.filter(
        (product) => product.quantity > 0
      );
      console.log("here is product quantity in numbers", products);
      this.setState({
        products: products,
        previousProducts: products,
        loading: false,
        filterByCategory: products,
        filteredProducts: this.productMappingToVarients(products),
      });
    }

    if (this.props.g_active.length === 0) {
      fetch(this.props.global_config.basePath + "/customers?status=Active", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            customers: data,
            previousCustomers: data,
            loading: this.state.products.length > 0 ? false : true,
          });
          // localStorage.setItem("customers", JSON.stringify(data));
          Store.dispatch({
            type: "GET_ACTIVE_CUSTOMERS",
            active_customers: data,
          });
        })
        .catch((err) => {
          this.setState({
            //  customers: localCustomers,
            loading: false,
          });
        });
    } else {
      this.setState({ customers: this.props.g_active });
    }
    if (this.props.g_orders.length === 0) {
      let url = this.props.global_config.basePath + "/orders";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          Store.dispatch({ type: "GET_ORDERS", orders: data });
          Store.dispatch({
            type: "UNFULFILLED_ORDERS",
            unfulfilled_orders: getUnfulfilledOrders(data),
          });
          Store.dispatch({
            type: "UNPAID_ORDERS",
            unpaid_orders: getUnpaidOrders(data),
          });
          Store.dispatch({
            type: "DRAFT_ORDERS",
            draft_orders: getDraftOrders(data),
          });
          Store.dispatch({
            type: "CLOSE_ORDERS",
            close_orders: getCloseOrders(data),
          });
          Store.dispatch({
            type: "OPEN_ORDERS",
            open_orders: getOpenOrders(data),
          });
          this.disableLoader();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  filterCategory = (id) => {
    let products = this.state.products.filter(
      (product) => product.categoryId === id
    );
    this.setState({ filterByCategory: products });
  };

  barcodeToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  getAllCategories = () => {
    this.setState({ filterByCategory: this.state.products });
  };
  handleProductSelect = (e, value) => {
    console.log("here is value when selectin product", value);
    if (value === "" || value === null) {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: this.state.previousProducts.filter(
          (product) => product.quantity > 0
        ),
        filterByCategory: this.state.previousProducts.filter(
          (product) => product.quantity > 0
        ),
      });
    } else {
      const filterList = this.props.g_active_products?.filter(
        (product) => product.name === value && product.quantity > 0
      );
      console.log("here is filter list from selected", filterList);
      this.searchProductAddToCart(...filterList);
      this.setState({
        backdrop: false,
        filterByCategory: filterList,
        // rows: this.populateRows(filteredList),
      });
    }
  };

  handleOnSearch = (e, value) => {
    console.log("in handle change", e.target.value, e.target.name, value);
    console.log("here is target name", e.target.name);
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: this.state.previousProducts.filter(
          (product) => product.quantity > 0
        ),
        filterByCategory: this.state.previousProducts.filter(
          (product) => product.quantity > 0
        ),
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_active_products,
        e.target.value
      );

      console.log(
        "here is filtered list",
        filteredList,
        this.props.g_active_products
      );

      this.setState({
        backdrop: false,
        products: filteredList,
        filterByCategory: filteredList,
        // rows: this.populateRows(filteredList),
      });
    }
  };
  dynamicSearch = (list, searchTerm) => {
    console.log("here is list and search term", list, searchTerm);

    console.log(
      "here is als",
      list.filter(
        (item) =>
          item.quantity > 0 &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return list.filter(
      (item) =>
        item.quantity > 0 &&
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  cancelSearch = () => {
    this.setState({
      query: "",
      products: this.state.previousProducts.filter(
        (product) => product.quantity > 0
      ),
    });
  };

  scanBarCodeAndAddToCart = (barCode) => {
    const foundProducts = this.state?.filteredProducts?.filter(
      (product) =>
        product?.barcodes?.filter(
          (barCodeValue) => barCodeValue?.code === barCode
        )?.length > 0
    );

    if (foundProducts.length === 1) {
      this.props.addToCartAction({
        POID: foundProducts[0]?.POID,
        name: foundProducts[0]?.name,
        description: foundProducts[0]?.description,
        images: foundProducts[0]?.images,
        price: foundProducts[0]?.price,
        units: 1,
        uOMPOID: foundProducts[0]?.unitsOfMeasures[0]?.POID,
        costPrice: foundProducts[0]?.costPrice,
        productSpecCharacteristics:
          foundProducts[0]?.productSpecCharacteristics,
        taxRate: foundProducts[0]?.taxRate,
      });
    } else if (foundProducts.length > 1) {
      this.setState({ filterByCategory: foundProducts });
    } else if (foundProducts.length === 0) {
      helpers.toastNotify("error", "barcode not found");
    }
  };

  searchProductAddToCart = ({
    POID,
    name,
    description,
    images,
    price,
    costPrice,
    taxRate,
    productSpecCharacteristics,
    unitsOfMeasures,
  }) => {
    let defaultUOM = unitsOfMeasures.filter((p) => p.isDefault === true);
    let uOMPOID = defaultUOM[0].POID;
    console.log("id", uOMPOID);
    this.props.addToCartAction({
      POID,
      name,
      description,
      images,
      price,
      units: 1,
      uOMPOID,
      costPrice,
      productSpecCharacteristics,
      taxRate,
    });
  };

  render() {
    const { classes } = this.props;
    const { products, loading, categories, filterByCategory } = this.state;
    const { cart, addToCartAction, updateCartUnits } = this.props;

    const barcodes = products
      .map((product) => product?.barcodes)
      .map((barcode, index) => barcode);
    return (
      <>
        {loading ? (
          <Loader value={this.state.loaderMessage} />
        ) : (
          <>
            {this.state.linearSpinner ? (
              <div className={classes.root}>
                <LinearProgress
                  style={{
                    backgroundColor: SKY_BLUE,
                    color: "white",
                  }}
                />
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Categories
                  getAllCategories={this.getAllCategories}
                  categories={categories}
                  filterCategory={(id) => this.filterCategory(id)}
                />
                {this.state.toggle ? (
                  <div style={{}}>
                    <BarcodeScannerComponent
                      width={250}
                      height={200}
                      onUpdate={(err, result) => {
                        if (result && result.text != this.state.barcode) {
                          this.setState({ barcode: result.text }, () => {
                            this.scanBarCodeAndAddToCart(result.text);
                          });
                        }
                      }}
                    />
                    <p style={{ textAlign: "center" }}>{this.state.barcode}</p>
                  </div>
                ) : null}
              </div>
            )}

            <Box className={classes.possearches}>
              <Box p={1} mt={1.5} flexGrow={1}>
                <Autocomplete
                  style={{
                    // border: "1px solid #dfe1e5",
                    borderRadius: "200px",
                  }}
                  freeSolo
                  options={this.state.products
                    .slice(0, 10)
                    .map((option) => option.name)}
                  onChange={this.handleProductSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Products"
                      style={{}}
                      onChange={(e, value) => {
                        console.log("here is value", value);
                        this.handleOnSearch(e, value);
                      }}
                    />
                  )}
                />
              </Box>
              <Box p={1} mt={1.5} flexGrow={1}>
                <TextField
                  placeholder="Scan/Enter Barcode"
                  style={{
                    height: "100%",
                    outline: "none",
                    border: "none",
                    width: "100%",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.scanBarCodeAndAddToCart(e.target.value);
                      e.target.value = "";
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QrCode fontSize="small" sx={{ color: grey[600] }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={1.5} ml={1.5}>
                {!this.state.toggle ? (
                  <Button
                    onClick={this.barcodeToggle}
                    variant="contained"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Open Scanner
                  </Button>
                ) : (
                  <Button onClick={this.barcodeToggle} variant="contained">
                    Close Scanner
                  </Button>
                )}{" "}
              </Box>
            </Box>

            <Grid
              container
              style={{
                margin: "10px",
                width: "auto",
              }}
            >
              <Grid item lg={7} sm={12} xs={12} md={6}>
                <Box
                  style={{
                    overflow: "auto",
                  }}
                >
                  {/* <ProductList
                    loading={loading}
                    products={filterByCategory}
                    addToCartAction={addToCartAction}
                  /> */}
                  <PaginatedItem
                    loading={loading}
                    products={filterByCategory.filter(
                      (item) => item.quantity > 0
                    )}
                    addToCartAction={addToCartAction}
                    itemsPerPage={20}
                  />
                </Box>
              </Grid>

              <Grid item lg={5} sm={12} md={6} xs={12} mt={10}>
                <CartList
                  cart={cart}
                  updateCartUnits={updateCartUnits}
                  customers={this.props.g_active}
                  emptyCart={emptyCart}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}
// }

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    g_active_products: state.productsState.active_products,
    g_active: state.customersState.active_customers,
    g_categories: state.productsState.categories,
    g_orders: state.ordersState.orders,
    global_config: state.globalState,
    company_info: state.companyInfoState,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCartAction,
      updateCartUnits,
      emptyCart,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(style)(PosContainer));
