// import "../App.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import GlobalConfig from "../../Config";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import DiscountModal from "./DiscountModal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import LabelIcon from "@material-ui/icons/Label";
import CancelIcon from "@material-ui/icons/Cancel";
import Loader from "../../MobileComponents/Loader";
import { NavLink } from "react-router-dom";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";

const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(39,136,6)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  button: {
    "&.active": {
      backgroundColor: "rgb(39,136,6)",
      color: "white",
      disabled: "true",
    },
  },
});

class Discount extends Component {
  state = {
    backdrop: false,
    promotions: [],
    loading: true,
    query: "",
    previousPromotions: [],
    snakbar: false,
    searchTerm: "",
    loaderMessage: "Loading Discounts..",
    expireBtn: "black",
    activeBtn: "primary",
  };

  componentDidMount() {
    if (1 == 1) {
      // if (this.props.g_discounts.length === 0) {
      let url =
        this.props.global_config.basePath +
        `/promotions?key=applicationMode&value=Manual`;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            promotions: data.filter(
              (item) => item.applicationMode === "Manual"
            ),
            previousPromotions: data.filter(
              (item) => item.applicationMode === "Manual"
            ),
            loading: false,
          });
          // localStorage.setItem("discounts", JSON.stringify(data));
          Store.dispatch({
            type: "GET_DISCOUNTS",
            discounts: data.filter((item) => item.applicationMode === "Manual"),
          });
        })
        .catch((err) => {
         
          this.setState({ loading: false });
          helpers.toastNotify("error", "Something Went Wrong!");
          // alert("Error! Something went wrong");
        });
    } else {
      this.setState({
        promotions:
        
          this.props.g_discounts,
        loading: false,
        previousPromotions:
        
          this.props.g_discounts,
        mode: "online",
      });
    }
  }

  cancelSearch = () => {
    this.setState({ query: "", promotions: this.state.previousPromotions });
  };

  handleSearchByDiscountCode = () => {
    if (this.state.query.length <= 0) {
      this.setState({ snackbar: false });
      helpers.toastNotify("error", "Empty Field Detected!");
    } else {
      this.setState({ backdrop: true });
      fetch(
        this.props.global_config.basePath +
          `/promotions/search?key=discountCode&value=${this.state.query}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        }
      )
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData, "after search query");
          this.setState({
            backdrop: false,
            promotions: responseData,
          });
        });
    }
  };
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  handleChange = (e) => {
    console.log("in handle change");
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        promotions: this.props.g_discounts,
      });
    } else {
      // this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_discounts,
        e.target.value
      );
      this.setState({
        backdrop: false,
        promotions: filteredList,
      });
    }
  };

  setColorManualDiscount = () => {
    return this.props.location.pathname === "/Discount" ? "primary" : "default";
  };
  getExpiredPromotions = () => {
    let newPromotions = this.props.g_discounts.filter(
      (promotion) =>
        promotion.status === "Archived" &&
        promotion.applicationMode === "Manual"
    );
    console.log("Archived Discounts", newPromotions);
    this.setState({
      promotions: newPromotions,
      activeBtn: "black",
      expireBtn: "primary",
    });
  };
  ActivePromotions = () => {
    let newPromotions = this.props.g_discounts.filter(
      (promotion) =>
        promotion.status === "Active" && promotion.applicationMode === "Manual"
    );
    this.setState({
      promotions: newPromotions,
      activeBtn: "primary",
      expireBtn: "black",
    });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  render() {
    const { classes } = this.props;
    console.log(this.state.promotions);
    const columns = [
      {
        field: "id",
        headerName: "ID",
        headerAlign: "center",

        width: 100,
      },
      {
        field: "discountCode",
        headerName: "Discount Code",
        width: 220,
      },
      { field: "type", headerName: "Type", width: 250 },

      {
        field: "applicationMode",
        headerName: "Application Mode",
        width: 200,
      },

      {
        field: "status",
        headerName: "status",
        width: 200,
      },
    ];
    function CustomToolbar() {
      return (
        <>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <GridToolbarContainer
              style={{
                backgroundColor: "#EFEFEF",
                border: "1px solid black",
                margin: "20px",
                width: "100px",
                height: "50px",
              }}
            >
              <GridToolbarExport />
            </GridToolbarContainer>
          </div>
        </>
      );
    }
    const { promotions, loading, previousPromotions, query } = this.state;
   
    return (
      <>
        {loading ? (
          <Loader value={this.state.loaderMessage} />
        ) : (
          <>
            <Backdrop
              style={{
                zIndex: 1,
                color: "#fff",
              }}
              open={this.state.backdrop}
            >
              <CircularProgress thickness={2} size={90} color="inherit" />
            </Backdrop>
            {this.state.snackbar ? (
              <>
                <Snackbar
                  open={this.state.snackbar}
                  autoHideDuration={2000}
                  onClose={this.closeSnack}
                >
                  <MuiAlert
                    severity="error"
                    variant="filled"
                    color="error"
                    onClose={this.closeSnack}
                  >
                    Please enter valid name!
                  </MuiAlert>
                </Snackbar>
              </>
            ) : null}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "20px",
              }}
            >
              <h5>Discounts</h5>

              <DiscountModal />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                flexDirection: "row",
                marginLeft: "15px",
              }}
            >
              <Button
                component={Link}
                to="/Discount"
                color={this.setColorManualDiscount()}
              >
                Discounted Code
              </Button>
              <Button
                className={classes.button}
                component={NavLink}
                to="/AutomatedDiscount"
              >
                Automatic Discounts
              </Button>
            </div>

            {this.props.g_discounts.length === 0 &&
            !loading &&
            previousPromotions.length === 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "white",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <img
                    src={"../assets/automaticdiscount.svg"}
                    alt="discount_image"
                    width="200px"
                  />
                  <h5>Manage discounts and promotions</h5>
                  <p>
                    Create discount codes and automatic discounts that apply at
                    checkout. You can also combine discounts with
                    <Link> compare at prices.</Link>
                  </p>
                  <Button
                    color={SKY_BLUE}
                    variant="contained"
                    component={Link}
                    to="/discountCode"
                  >
                    Create Discount Code
                  </Button>
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  height: "100%",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                    <TextField
                      defaultValue={query}
                      name="query"
                      onChange={this.handleChange}
                      style={{ width: "400px", marginRight: "20px" }}
                      id="outlined-basic"
                      label="Search discount"
                      // placeholder="search discount by discount code"
                      size="medium"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ marginRight: "20px" }}>
                            {/* <IconButton> */}
                            <SearchTwoToneIcon />

                            {/* </IconButton> */}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment>
                            {this.state.query === "" ? null : (
                              <div
                                style={{ marginRight: "10px" }}
                                variant="outlined"
                                color={SKY_BLUE}
                                onClick={() => this.cancelSearch()}
                              >
                                <CancelIcon style={{ color: "red" }} />
                              </div>
                            )}
                            <Button
                              style={{
                                borderColor: { SKY_BLUE },
                                marginRight: "4px",
                              }}
                              variant="outlined"
                              onClick={() => this.handleSearchByDiscountCode()}
                            >
                              <LabelIcon style={{ color: SKY_BLUE }} />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "50px", marginLeft: "15px" }}>
                    <Button
                      onClick={() => this.ActivePromotions()}
                      color={this.state.activeBtn}
                    >
                      Active
                    </Button>
                    <Button
                      onClick={() => this.getExpiredPromotions()}
                      color={this.state.expireBtn}
                    >
                      Expired
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: "0px 0 10px 20px" }}>
                    <span>
                      {promotions.length ? (
                        <b>Showing {promotions.length} Discounts </b>
                      ) : null}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // marginTop: "20px",
                    }}
                  ></div>
                </div>

                <div style={{ height: 500, width: "100%" }}>
                  <DataGrid
                    sortModel={[
                      {
                        field: "id",
                        sort: "asc",
                      },
                    ]}
                    // {...this.state.columns}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    rows={promotions}
                    columns={columns}
                    onRowClick={(info) => {
                      this.props.history.push({
                        pathname: `/editdiscount/${info.row.id}`,
                        state: promotions[info.rowIndex],
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_discounts: state.discountState.discounts.filter(
      (item) => item.applicationMode === "Manual"
    ),
    global_config: state.globalState,
  };
};

export default connect(mapStateToProps)(withStyles(style)(Discount));
