const initial_state = {
  active_customers: [],
  archived_customers: [],
};

const customersReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_ACTIVE_CUSTOMERS":
      return Object.assign({}, state, {
        active_customers: action.active_customers,
      });
    case "GET_ARCHIVED_CUSTOMERS":
      return Object.assign({}, state, {
        archived_customers: action.archived_customers,
      });
    case "POST_ACTIVE_CUSTOMERS":
      return {
        ...state,
        active_customers: [...state.active_customers, action.active_customers],
      };
    case "POST_ARCHIVED_CUSTOMERS":
      return {
        ...state,
        archived_customers: [
          ...state.archived_customers,
          action.deleted_customer,
        ],
      };
    case "EDIT_CUSTOMERS": {
      console.log("EDIT_CUSTOMERS");
      let updated_customer = action.updated_customer;
      console.log("action.updated_customer: ", updated_customer);
      let customer = state.active_customers.filter(
        (customer) => customer.POID === updated_customer.POID
      );
      let arch_customer = state.archived_customers.filter(
        (customer) => customer.POID === updated_customer.POID
      );
      if (customer.length === 0) {
        customer = arch_customer;
      }
      console.log("customer: ", customer);
      customer = customer[0];
      console.log("customer: ", customer);
      if (customer?.name !== updated_customer?.name) {
        customer.name = updated_customer?.name;
      }
      if (customer?.email !== updated_customer?.email) {
        customer.email = updated_customer?.email;
      }
      if (customer?.phoneNumber !== updated_customer?.phoneNumber) {
        customer.phoneNumber = updated_customer?.phoneNumber;
      }
      if (customer?.status !== updated_customer?.status) {
        customer.status = updated_customer?.status;
        if (customer.status.toLowerCase() === "active") {
          state.active_customers.push(customer);
          let filteredArchived = state.archived_customers.filter(
            (item) => item.POID !== customer.POID
          );
          return {
            ...state,
            active_customers: state.active_customers,
            archived_customers: filteredArchived,
          };
        }
      }
      return state;
    }
    case "EDIT_CUSTOMER_ON_ORDER": {
      console.log("EDIT_CUSTOMERS");

      let customer = state.active_customers.filter(
        (customer) => customer.POID === action.orders.customerId
      );
      customer = customer[0];

      let newTotalSpendOnOrder = action.orders.lineItems.reduce(
        (total, item) => {
          return total + item.price;
        },
        customer.totalSpendOnOrder
      );
      let newWumberOfOrders = customer.numberOfOrders + 1;
      let newAverageOrderValue = newTotalSpendOnOrder / newWumberOfOrders;

      customer.averageOrderValue = newAverageOrderValue;
      customer.numberOfOrders = newWumberOfOrders;
      customer.totalSpendOnOrder = newTotalSpendOnOrder;

      return state;
    }
    case "EDIT_ACTIVE_CUSTOMER":
      console.log("EDIT_CUSTOMERS");
      let updated_customer = action.updated_customer;
      console.log("action.updated_customer: ", updated_customer);
      let customer = state?.active_customers.filter(
        (customer) => customer.POID === updated_customer.POID
      );
      let arch_customer = state?.archived_customers.filter(
        (customer) => customer.POID === updated_customer.POID
      );
      if (customer.length === 0) {
        customer = arch_customer;
      }
      console.log("customer: ", customer);
      customer = customer[0];
      console.log("customer: ", customer);

      if (customer?.address?.length === 0) {
        customer.address = [
          {
            addressContactId: "",
            addressId: "",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street: "",
          },
        ];
      }

      if (customer?.address[0]?.city !== updated_customer?.address[0]?.city) {
        customer.address[0].city = updated_customer.address[0]?.city;
      }
      if (
        customer?.address[0]?.country !== updated_customer?.address[0]?.country
      ) {
        customer.address[0].country = updated_customer?.address[0]?.country;
      }
      if (
        customer?.address[0]?.postCode !==
        updated_customer?.address[0]?.postCode
      ) {
        customer.address[0].postCode = updated_customer.address[0].postCode;
      }
      if (
        customer?.address[0]?.stateOrProvince !==
        updated_customer.address[0].stateOrProvince
      ) {
        customer.address[0].stateOrProvince =
          updated_customer.address[0].stateOrProvince;
      }
      if (
        customer?.address[0]?.street !== updated_customer?.address[0]?.street
      ) {
        customer.address[0].street = updated_customer.address[0].street;
      }
      if (
        customer?.address[0]?.addressContactId !==
        updated_customer?.address[0]?.addressContactId
      ) {
        customer.address[0].addressContactId =
          updated_customer.address[0].addressContactId;
      }
      if (
        customer?.address[0]?.addressId !==
        updated_customer?.address[0]?.addressId
      ) {
        customer.address[0].addressId = updated_customer.address[0].addressId;
      }
      return state;

    case "DELETE_ACTIVE_CUSTOMERS":
      console.log("delete reducer!!!");
      console.log("action: ", action.POID);
      let customers = state.active_customers.filter(
        (customer) => customer.POID !== action.POID
      );
      let delCustomers = state.active_customers.filter(
        (customer) => customer.POID === action.POID
      );
      delCustomers[0].status = "Archived";
      console.log(delCustomers);
      return {
        ...state,
        active_customers: customers,
        archived_customers: state.archived_customers.concat(delCustomers),
      };
    case "CLEAR_CUSTOMERS_DATA":
      return initial_state;
    default:
      return state;
  }
};

export default customersReducer;
